import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faEdit,
	faTools,
	faPlusCircle,
	faSlidersH,
	faPlus,
	faAngleDown,
	faCreditCard,
	faFileInvoiceDollar,
	faTachometer,
	faList,
	faChartBar,
	faSearch,
	faQuestionCircle,
	faCog,
	faHistory,
	faBell,
	faSortAlt,
	faLock,
	faCalendar,
	faAngleLeft,
	faAngleRight,
	faCheck,
	faBars,
	faArrowLeft,
	faArrowRight,
	faUsers,
	faEnvelope,
	faFile,
	faFileAlt,
	faFilePlus,
	faLink,
	faClock,
	faTimes,
	faBan,
	faDollarSign,
	faPenSquare,
	faDownload,
	faUserCircle,
	faArrowAltSquareRight,
	faExternalLink,
	faSignOut,
	faInfoCircle,
	faBuilding as faBuildingLight,
	faLandmark,
	faComment,
	faPrint,
	faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle as faCircleRegular, faCheckCircle as faCheckCircleRegular } from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle, faCheckCircle, faHome, faEllipsisH, faUser, faBuilding } from '@fortawesome/pro-solid-svg-icons';

export function loadIcons() {
	library.add(
		faEdit,
		faTools,
		faPlusCircle,
		faSlidersH,
		faPlus,
		faAngleDown,
		faCreditCard,
		faFileInvoiceDollar,
		faTachometer,
		faList,
		faChartBar,
		faSearch,
		faQuestionCircle,
		faCog,
		faHistory,
		faBell,
		faSortAlt,
		faLock,
		faCalendar,
		faAngleLeft,
		faAngleRight,
		faCheck,
		faBars,
		faArrowLeft,
		faArrowRight,
		faMinusCircle,
		faCheckCircle,
		faHome,
		faEllipsisH,
		faBuilding,
		faUser,
		faUsers,
		faEnvelope,
		faFile,
		faFileAlt,
		faFilePlus,
		faLink,
		faClock,
		faTimes,
		faBan,
		faDollarSign,
		faPenSquare,
		faDownload,
		faUserCircle,
		faArrowAltSquareRight,
		faExternalLink,
		faSignOut,
		faInfoCircle,
		faCircleRegular,
		faCheckCircleRegular,
		faBuildingLight,
		faLandmark,
		faComment,
		faPrint,
		faExclamationTriangle
	);
}
