import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	place-content: center;
	text-align: center;
	height: inherit;
	background-color: white;

	h3 {
		margin: 0px 0px 20px;
	}
	p {
		color: ${colors.neutralText};

		a,
		a:link,
		a:visited {
			color: inherit !important;
			text-decoration: underline;
		}
	}
`;

export const Container = styled.div`
	max-width: 740px;
	width: 100%;
	padding: 2rem;
`;

export const Image = styled.img`
	width: 130px;
	margin: 0px auto 30px;
`;
