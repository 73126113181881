import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Modal } from '../../components';
import * as S from './SearchSearchingModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

const SearchSearchingModal = ({ isOpen, setIsOpen }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<S.ModalContainer>
				<FontAwesomeIcon icon={['fal', 'search']} />
				<Typography tag={isHigherThan768 ? 'h1' : 'h3'} weight="bold" center>
					Searching for Matches...
				</Typography>
				<S.ParaContainer>
					<Typography tag={isHigherThan768 ? 'p' : 'h6'} variation="1" center>
						Searching for accounts that match the information provided. This process will take a few minutes.
					</Typography>
				</S.ParaContainer>
			</S.ModalContainer>
		</Modal>
	);
};
export default SearchSearchingModal;

SearchSearchingModal.propTypes = {
	isOpen: PropTypes.bool?.isRequired,
	setIsOpen: PropTypes.func?.isRequired,
};
