import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	flex-wrap: wrap;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`;

export const BadgeWrapper = styled.div`
	flex: 1;
	margin-bottom: 1rem;

	@media screen and (min-width: 768px) {
		margin-left: 1rem;
		margin-right: 1rem;
		margin-bottom: 0rem;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.5rem;
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;

		& > * + * {
			margin-top: 0;
			margin-left: 0.5rem;
		}
	}
`;
