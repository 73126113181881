import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Table } from '../Table';
import { BadgeCell, DateTimeCell } from '../Table';
import * as S from './PendingRequests.styles';
import { useHistory } from 'react-router-dom';
import { getRequestTypes } from '../../services/allUsers';
import { retrieveAdminUserRequests } from '../../services/adminService';
import { Spinner } from '../Spinner';
import { EmptyComponent } from '../EmptyComponent';
import { tableSorterDate } from '../Table/Table';
import { useSelector } from 'react-redux';

export const PendingRequests = ({ className }) => {
	const history = useHistory();
	const user = useSelector((state) => state.user.value);
	const [status, setStatus] = useState('loading');
	const [requests, setRequests] = useState([]);

	const [columns] = useState([
		{
			Header: 'Username',
			accessor: 'username',
		},
		{
			Header: 'Request Type',
			accessor: 'requestType',
		},
		{
			Header: 'Date Requested',
			accessor: 'dateRequested',
			Cell: DateTimeCell,
			CellName: 'DateTimeCell',
			sortType: tableSorterDate,
		},
		{
			Header: 'Assigned To',
			accessor: 'assignedTo',
		},
		{
			Header: 'Source',
			accessor: 'source',
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: BadgeCell,
			CellName: 'BadgeCell',
		},
	]);

	function navigateTo(request) {
		history.push(`requests/${request.RequestKey}`);
	}

	async function init() {
		try {
			const types = await getRequestTypes();
			const requestObjs = await retrieveAdminUserRequests({});
			setRequests(
				requestObjs
					.sort((a, b) => {
						return new Date(b.RequestDate) - new Date(a.RequestDate);
					})
					.filter((request) => {
						return request.IsOpen === true;
					})
					.map((request) => {
						let status = 'incomplete';
						if (request.IsOpen === true) {
							status = 'new';
							if (request.AssignedTo) {
								status = 'inprogress';
							}
						} else if (request.ClosedDate != null) {
							status = 'closed';
						}
						return {
							id: request.RequestKey,
							RequestKey: request.RequestKey,
							username: request.PortalUser,
							requestType: types.find((type) => type.RequestTypeID === request.RequestType)?.RequestType,
							dateRequested: request.RequestDate,
							assignedTo: request.AssignedTo || '',
							status,
						};
					})
			);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, []);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (requests.length === 0) {
			return <EmptyComponent icon={['fal', 'file-alt']} title="No Current Requests" description="Check back to view recent service requests." />;
		}
		return <Table columns={columns} data={requests} handleClick={navigateTo} />;
	};

	return (
		<Card className={className} title="New Requests" action={[user.IsAdmin && { id: 1, label: 'View All', onClick: () => history.push('/requests'), type: 'text' }].filter(Boolean)}>
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</Card>
	);
};
PendingRequests.propTypes = {
	className: PropTypes.string,
};
