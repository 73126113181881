import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Card } from '../Card';
import { Table, DateCell, CurrencyCell } from '../Table';
import * as S from './ScheduledPayments.styles';
import { Spinner } from '../Spinner';
import { EmptyComponent } from '../EmptyComponent';
import { formatDate } from '../../utils/time-formatter';
import { retrieveScheduledPayments, walletTransactionLog } from '../../services/walletService';
import { useHistory } from 'react-router-dom';
import { BadgeCell, tableSorterDate } from '../Table/Table';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { CancelScheduledPaymentModal } from '../CancelScheduledPaymentModal';

export const ScheduledPayments = ({ className, isUserDashboard, filterCount, onFilterButtonClick, filterSelectedOptions }) => {
	const [status, setStatus] = useState('loading');
	const [payments, setPayments] = useState([]);
	const [cancelPaymentModalOpen, setCancelPaymentModalOpen] = useState(false);
	const [activePaymentKey, setActivePaymentKey] = useState(null);

	const currentLocation = useSelector((state) => state.location.value);
	const history = useHistory();

	const [columns] = useState([
		{
			Header: 'Date',
			accessor: 'date',
			Cell: DateCell,
			CellName: 'DateCell',
			sortType: tableSorterDate,
		},
		{
			Header: 'Amount',
			accessor: 'amount',
			Cell: CurrencyCell,
			CellName: 'CurrencyCell',
		},
		{
			Header: 'Payment Type',
			accessor: 'paymentType',
		},
		{
			Header: 'Method',
			accessor: 'walletNickname',
		},
		{
			Header: 'Type',
			accessor: 'type',
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: BadgeCell,
			CellName: 'BadgeCell',
		},
		{
			Header: '',
			accessor: 'canCancel',
			// eslint-disable-next-line react/prop-types
			Cell: ({ value, row }) => {
				return value ? (
					<S.CancelButtonCell>
						<Button
							variant="text"
							variation="warning"
							onClick={() => {
								// eslint-disable-next-line react/prop-types
								promptCancelPayment(row.original.id);
							}}
						>
							<Typography tag="span" variation="2" weight="extrablack">
								Remove
							</Typography>
						</Button>
					</S.CancelButtonCell>
				) : null;
			},
			hideHeader: true,
			ownInternal: true,
		},
	]);

	const promptCancelPayment = (scheduledPaymentKey) => {
		setActivePaymentKey(scheduledPaymentKey);
		setCancelPaymentModalOpen(true);
	};

	const renderPaymentType = (type) => {
		if (type.toLowerCase() === 'autopay' || type.toLowerCase() === 'auto pay' || type.toLowerCase() === 'auto_pay') {
			return 'Autopay';
		} else if (type.toLowerCase() === 'card') {
			return 'Card';
		} else if (type.toLowerCase() === 'ach' || type.toLowerCase() === 'bank draft' || type.toLowerCase() === 'bank_draft') {
			return 'ACH';
		}
	};

	async function init() {
		try {
			let paymentArray = [];
			let rawPayments = await retrieveScheduledPayments();
			if (rawPayments.length > 0) {
				paymentArray = rawPayments.filter((payment) => {
					let validPayment = currentLocation?.LocationID === payment.LocationID;
					if (!validPayment) return validPayment;
					if (filterSelectedOptions.billTypes.utility === true || filterSelectedOptions.billTypes.greenlight === true) {
						if (payment.AccountType === 1 && !filterSelectedOptions.billTypes.utility) validPayment = false;
						if (payment.AccountType === 2 && !filterSelectedOptions.billTypes.greenlight) validPayment = false;
					}
					if (filterSelectedOptions.amount.min != null && filterSelectedOptions.amount.min !== -1 && filterSelectedOptions.amount.max != null && filterSelectedOptions.amount.max !== -1) {
						if (payment.PaymentAmount < filterSelectedOptions.amount.min || payment.PaymentAmount > filterSelectedOptions.amount.max) validPayment = false;
					}
					if (filterSelectedOptions.paymentMethods.card === true || filterSelectedOptions.paymentMethods.bankDraft === true || filterSelectedOptions.paymentMethods.eCheck === true) {
						if (renderPaymentType(payment.PaymentType) === 'Card' && filterSelectedOptions.paymentMethods.card !== true) validPayment = false;
						if (renderPaymentType(payment.PaymentType) === 'ACH' && filterSelectedOptions.paymentMethods.bankDraft !== true) validPayment = false;
					}
					if (filterSelectedOptions.paymentTypes.autopay === true || filterSelectedOptions.paymentTypes.manual === true) {
						if (renderPaymentType(payment.PaymentType) === 'Autopay' && filterSelectedOptions.paymentTypes.autopay !== true) validPayment = false;
						if (!(renderPaymentType(payment.PaymentType) === 'Autopay') && filterSelectedOptions.paymentTypes.manual !== true) validPayment = false;
					}
					return validPayment;
				});
			}
			paymentArray.sort((a, b) => new Date(b.ScheduledPaymentDate).getTime() - new Date(a.ScheduledPaymentDate).getTime());
			setPayments(
				paymentArray.map((payment) => {
					return {
						id: `${payment.ScheduledPaymentKey}`,
						date: formatDate(payment.ScheduledPaymentDate),
						amount: payment.PaymentAmount,
						type: payment.AccountType === 1 ? 'Utility' : 'Greenlight',
						status: payment.IsCanceled ? 'canceled' : payment.IsProcessed ? 'complete' : 'scheduled',
						paymentType: renderPaymentType(payment.PaymentType),
						canCancel: !payment.IsCanceled && !payment.IsProcessed,
						walletNickname: payment.WalletNickname || '',
					};
				})
			);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, [filterSelectedOptions]);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (payments.length === 0 && filterCount === 0) {
			return <EmptyComponent icon={['fal', 'credit-card']} title="No Scheduled Payments" description="Check back to view your scheduled Utilities and Greenlight payments." />;
		}
		return (
			<>
				<Table columns={columns} data={payments} isFilterButton={isUserDashboard} filterCount={filterCount} onFilterButtonClick={onFilterButtonClick} />
				<CancelScheduledPaymentModal isOpen={cancelPaymentModalOpen} onRequestClose={() => setCancelPaymentModalOpen(false)} scheduledPayment={activePaymentKey} afterSubmit={init} />
			</>
		);
	};

	return (
		<Card className={className} title="Scheduled Payments" action={[{ id: 1, onClick: () => history.push('/settings'), label: 'Edit Settings', icon: ['fal', 'edit'], type: 'outline' }]}>
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</Card>
	);
};
ScheduledPayments.propTypes = {
	className: PropTypes.string,
	isUserDashboard: PropTypes.bool,
	filterCount: PropTypes.number,
	onFilterButtonClick: PropTypes.func,
	filterSelectedOptions: PropTypes.shape(),
};
