import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 0.5rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const ItemWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ItemLeftWrapper = styled.div`
	flex-grow: 1;
`;

export const ItemRightWrapper = styled.div``;
