import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import envelopeOpenText from '../../assets/envelope-open-text.svg';

import logo from '../../assets/wilson-logo.png';
import { Typography, Spinner, Button, TextInput, PasswordInput } from '../../components';
import * as S from './ResetPassword.styles';
import { pattern, required } from '../../utils/form-default-errors';
import { PASSWORD_12Ch_1Up_1Low_1Num } from '../../utils/common-regex';
import { setPassword, validateResetPassword } from '../../services/authenticationService';
import toast from 'react-hot-toast';
import { META_TITLE } from '../../utils/constants';

export default function ResetPassword() {
	const { token } = useParams();
	const [status, setStatus] = useState('loading');
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isSubmitSuccessful },
		getValues,
		reset,
		control,
	} = useForm();
	const history = useHistory();
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	async function init() {
		try {
			await validateResetPassword({ token });
			setStatus('valid');
		} catch (e) {
			setStatus({ value: 'error', message: e.message });
		}
	}

	const onSubmit = async ({ password }) => {
		try {
			await setPassword({ newPassword: password, resetToken: token });
		} catch (e) {
			reset(undefined, { keepValues: true });
			throw e;
		}
	};

	function handleGoBackToLogin() {
		history.push('/login');
	}

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		// Set document title
		document.title = `Reset Password | ${META_TITLE}`;
	}, []);

	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			{status === 'loading' ? (
				<Spinner />
			) : status === 'valid' ? (
				<S.Form onSubmit={(e) => handleSubmit(onSubmit)(e).catch((e) => toast.error(e.message))}>
					<S.MainContainer>
						<S.LogoContainer>
							<img src={envelopeOpenText} alt="" />
						</S.LogoContainer>
						<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
							{!isSubmitSuccessful ? 'Reset Your Password' : 'Password Reset Complete'}
						</Typography>
						<S.ParaContainer>
							<Typography tag="h6" variation="2" center>
								{!isSubmitSuccessful ? 'Enter a new password (must have at least 12 characters, an upper & lowercase letter, and a number)' : 'Your password has been reset. Log in to continue to your account.'}
							</Typography>
						</S.ParaContainer>
						<S.InputsWrapper>
							{!isSubmitSuccessful ? (
								<>
									<Controller
										name="password"
										control={control}
										rules={{ required: required('Password'), pattern: pattern('Password', PASSWORD_12Ch_1Up_1Low_1Num) }}
										render={({ field: { onChange, value } }) => <PasswordInput label="Password" id="password" type="password" error={errors.password} disallowedContent={[]} value={value} onChange={onChange} />}
									/>
									<TextInput
										label="Confirm Password"
										id="confirm-password"
										type="password"
										error={errors.confirmPassword}
										{...register('confirmPassword', {
											required: required('Confirm Password'),
											pattern: pattern('Confirm Password', PASSWORD_12Ch_1Up_1Low_1Num),
											validate: {
												equalToPassword: (v) => (getValues('password') === v ? true : "Confirm Password doesn't match with Password"),
											},
										})}
									/>
									<Button size={isHigherThan768 ? 'medium' : 'small'}>
										<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
											{isSubmitting ? 'Loading' : 'Reset Password'}
										</Typography>
									</Button>
								</>
							) : (
								<Button size={isHigherThan768 ? 'medium' : 'small'} type="button" onClick={handleGoBackToLogin}>
									<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
										Back to Login
									</Typography>
								</Button>
							)}
						</S.InputsWrapper>
					</S.MainContainer>
				</S.Form>
			) : (
				<S.MainContainer>
					<S.LogoContainer>
						<img src={envelopeOpenText} alt="" />
					</S.LogoContainer>
					<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
						This Reset Link Is No Longer Valid
					</Typography>
					<S.ParaContainer>
						<Typography tag="h6" variation="2" center>
							{status.message}
						</Typography>
					</S.ParaContainer>
					<S.InputsWrapper>
						<Button size={isHigherThan768 ? 'medium' : 'small'} type="button" onClick={handleGoBackToLogin}>
							<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
								Back to Login
							</Typography>
						</Button>
					</S.InputsWrapper>
				</S.MainContainer>
			)}
		</S.MainWrapper>
	);
}
