import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './EmptyComponent.styles';

export function EmptyComponent({ icon = ['fal', 'bell'], title, description }) {
	return (
		<S.EmptyWrapper>
			<S.InfoWrapper>
				<FontAwesomeIcon icon={icon} />
			</S.InfoWrapper>
			<Typography tag="h5" weight="semibold" center>
				{title}
			</Typography>
			<Typography tag="p" variation="2" center>
				{description}
			</Typography>
		</S.EmptyWrapper>
	);
}

EmptyComponent.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	title: PropTypes.string,
	description: PropTypes.string,
};
