import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { OnboardingHeader, Typography, Card, Table, OnboardingFooter, Button } from '../../components';
import { clsx } from '../../utils/clsx';
import { BadgeCell } from '../../components/Table';
import * as S from './OnboardingWelcome.styles';
import { META_TITLE } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { retrieveUserRequests } from '../../services/userService';

export default function OnboardingWelcome({ showDashboard }) {
	const [columns] = useState([
		{
			Header: 'Address',
			accessor: 'address',
		},
		{
			Header: 'Account Type',
			accessor: 'accountType',
		},
		{
			Header: 'Location Type',
			accessor: 'locationType',
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: BadgeCell,
			CellName: 'BadgeCell',
		},
	]);

	const history = useHistory();
	const locationsData = useSelector((state) => state?.user?.value?.locations);

	const [accountType, setAccountType] = useState('new');
	const [locations, setLocations] = useState([]);

	const onNext = () => {
		if (accountType === 'new') {
			history.push('/onboarding/new-customer/building-type');
		} else if (accountType === 'current') {
			history.push('/onboarding/current-customer/building-type');
		}
	};
	const fetchSearchAccounts = async () => {
		try {
			// Format current active accounts
			let tempData = [];
			let tempLocations = locationsData;
			tempLocations?.forEach((location) => {
				if (location?.UtilityAccount != null) {
					const tempLocation = {
						id: location?.LocationID,
						address: location?.Address,
						locationType: location?.UtilityAccount?.IsCommercial ? 'Commercial' : 'Residential',
						status: !location?.UtilityAccount?.ServiceEnabled ? 'pending' : 'active',
						accountType: 'Utility Account',
					};
					tempData.push(tempLocation);
				}
				if (location?.GreenlightAccount != null) {
					const tempLocation = {
						id: location?.LocationID,
						address: location?.Address,
						locationType: location?.GreenlightAccount?.IsCommercial ? 'Commercial' : 'Residential',
						status: !location?.GreenlightAccount?.ServiceEnabled ? 'pending' : 'active',
						accountType: 'Greenlight Account',
					};
					tempData.push(tempLocation);
				}
			});

			// Fetch pending requests
			const openRequests = await retrieveUserRequests({ isOpen: true });

			// Filter new service requests
			const newServiceRequests = openRequests.filter((request) => request.RequestType === 1);

			// Parse requests
			newServiceRequests.forEach((request) => {
				// Parse request description
				let description = {};
				try {
					let formattedDescription = request.Description.replace(/\\t/g, '')
						.replace(/\\n/g, '')
						.replace(/\\"/g, '"')
						.replace(/},}/g, '}}')
						.replace(/(^"|"$)/g, '');
					description = JSON.parse(formattedDescription);
				} catch (e) {}

				// Get address
				if (description.application && (description.application['item-serviceAddressToConnect'] || description.application['serviceAddressToConnect'])) {
					const address = description.application['item-serviceAddressToConnect'] || description.application['serviceAddressToConnect'];

					// Create utilities account
					if (description.utilities && description.utilities.enableUtilitiesForProperty && description.utilities.enableUtilitiesForProperty.toLowerCase() === 'yes') {
						const tempLocation = {
							id: request.RequestKey,
							address,
							locationType: description.application.locationType,
							status: 'pending',
							accountType: 'Utility Account',
						};
						tempData.push(tempLocation);
					}

					// Create greenlight account
					if (description.greenLight && description.greenLight.isGreenlightInternetService && description.greenLight.isGreenlightInternetService.toLowerCase() === 'yes') {
						const tempLocation = {
							id: request.RequestKey,
							address,
							locationType: description.application.locationType,
							status: 'pending',
							accountType: 'Greenlight Account',
						};
						tempData.push(tempLocation);
					}
				}
			});
			setLocations(tempData);
		} catch (e) {
			console.log('error', e);
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `Create Account | ${META_TITLE}`;
		fetchSearchAccounts();
	}, []);

	return (
		<S.OuterWrapper>
			<OnboardingHeader showExit={false} showDashboard={showDashboard} />

			<S.Container>
				<S.Wrapper>
					<div>
						<Typography tag="h1" weight="bold" center>
							Welcome
						</Typography>
						<Typography tag="p" center>
							Select an option to begin
						</Typography>
					</div>

					{locations?.length > 0 && (
						<Card title="My Accounts">
							<S.PendingWrapper>
								<Table columns={columns} data={locations} withoutSearch />
							</S.PendingWrapper>
						</Card>
					)}

					<Card title="Create a New Account">
						<S.OptionsCard>
							<div>
								<Typography tag="h4" weight="bold">
									Account Type
								</Typography>
								<Typography tag="p">Please select the type of account you wish to create</Typography>
							</div>
							<S.OptionButton onClick={() => setAccountType('new')}>
								<div className={clsx('circle', accountType === 'new' && 'checked')} />
								<FontAwesomeIcon className="icon" icon="user" />
								<div className="content-info">
									<Typography tag="h4" weight="bold">
										New Customer
									</Typography>
									<Typography tag="p">I would like to establish Utility and/or Greenlight services.</Typography>
								</div>
							</S.OptionButton>
							<S.OptionButton onClick={() => setAccountType('current')}>
								<div className={clsx('circle', accountType === 'current' && 'checked')} />
								<FontAwesomeIcon className="icon" icon="home" />
								<div className="content-info">
									<Typography tag="h4" weight="bold">
										Current Customer
									</Typography>
									<Typography tag="p">I have active City of Wilson, Wilson Energy and/or Greenlight services.</Typography>
								</div>
							</S.OptionButton>
						</S.OptionsCard>
					</Card>
				</S.Wrapper>
			</S.Container>

			<OnboardingFooter>
				<Button variant="outline" onClick={onNext} disabled={!accountType}>
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.OuterWrapper>
	);
}

OnboardingWelcome.propTypes = {
	showDashboard: PropTypes.bool,
};
