import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '../../Typography';
import * as S from './ProfileOptions.styles';
import { useHistory } from 'react-router-dom';
import useClickOutside from '../../../utils/hooks/useClickOutside';
import { formatInitials } from '../../../utils/user-formatter';
import { useSelector } from 'react-redux';

export const ProfileOptions = () => {
	const user = useSelector((state) => state.user.value);
	const history = useHistory();
	const [opened, setOpened] = useState(false);
	const OptionMenuRef = React.useRef(null);

	useClickOutside(OptionMenuRef, () => setOpened(false));

	return (
		<S.Wrapper>
			<S.Button opened={opened} onClick={() => setOpened((prev) => !prev)}>
				<S.Avatar>
					<Typography tag="h6">{formatInitials(user)}</Typography>
				</S.Avatar>
			</S.Button>
			<S.Modal opened={opened} ref={OptionMenuRef}>
				<ul>
					<li>
						<button
							onClick={() => {
								history.push('/profile');
								setOpened(false);
							}}
						>
							<FontAwesomeIcon icon={['fal', 'user-circle']} />
							<Typography tag="h5" weight="regular">
								My Profile
							</Typography>
						</button>
					</li>
					<li>
						<button
							onClick={() => {
								setTimeout(() => {
									window.open('https://www.wilsonnc.org/residents/privacy-policy', '_blank');
								});
								setOpened(false);
							}}
						>
							<FontAwesomeIcon icon={['fal', 'external-link']} />
							<Typography tag="h5" weight="regular">
								Privacy Policy
							</Typography>
						</button>
					</li>
					<li>
						<button
							onClick={() => {
								window.location = '/logout';
							}}
						>
							<FontAwesomeIcon icon={['fal', 'sign-out']} />
							<Typography tag="h5" weight="regular">
								Logout
							</Typography>
						</button>
					</li>
				</ul>
			</S.Modal>
		</S.Wrapper>
	);
};

ProfileOptions.propTypes = {};
