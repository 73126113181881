import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import { AuthenticatedRouter, NotAuthenticatedRouter, AdminAuthenticatedRouter, OnboardingRouter } from './navigations';
import { fetchUser } from './app/slices/user/userSlice';
import { fetchGreenlightSchema } from './app/slices/greenlight/greenlightSlice';
import { AuthenticationModal, Spinner } from './components';
import toast from 'react-hot-toast';
import { updateCurrentLocation } from './app/slices/location/locationSlice';
import { PLACEHOLDER_USER_DETAILS } from './utils/constants';
import { staffExtendLoginSession, userExtendLoginSession } from './services/authenticationService';
import { fetchConfiguration } from './app/slices/configuration/configurationSlice';

const SESSION_LENGTH = {
	customer: 5 * 60 * 1000, // 5 minutes
	staff: 60 * 60 * 1000, // 60 minutes
};

function Router() {
	// Get role
	const role = window.localStorage.getItem('wilsonnc_role') || 'customer';

	// Get state
	const user = useSelector((state) => state.user.value);
	const currentLocation = useSelector((state) => state.location.value);
	const status = useSelector((state) => state.user.status);
	const configurationIsLoaded = useSelector((state) => state.configuration.isLoaded);
	const error = useSelector((state) => state.user.error);
	const enableMaintenanceMode = useSelector((state) => state.configuration.enableMaintenanceMode);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Create references
	const isUserActive = useRef(true);
	const sessionPromptTimer = useRef(null);
	const authenticationTimer = useRef(null);

	// Create state handlers
	const [authenticationIsOpen, setAuthenticationIsOpen] = useState(false);

	const showAuthModal = () => {
		if (enableMaintenanceMode !== true) {
			setAuthenticationIsOpen(true);
			window.localStorage.setItem('auth_modal_open', 'true');
		}
	};

	const extendSession = async () => {
		if (!authenticationIsOpen) {
			if (role === 'staff') {
				await staffExtendLoginSession();
			} else {
				await userExtendLoginSession();
			}
		}
	};

	const onIdle = async () => {
		if (user != null) {
			// Set user active state
			isUserActive.current = false;

			// Destroy current authentication timer
			if (authenticationTimer.current != null) clearInterval(authenticationTimer.current);
			authenticationTimer.current = null;

			// Automatically extend session token to begin idle session
			await extendSession();

			// Destroy session prompt timer
			if (sessionPromptTimer.current != null) clearTimeout(sessionPromptTimer.current);
			sessionPromptTimer.current = null;

			// Reset auth modal target time
			window.localStorage.setItem('auth_modal_target', 'none');

			// Set new session prompt timer
			sessionPromptTimer.current = setTimeout(() => {
				// Show authentication modal
				showAuthModal();
			}, SESSION_LENGTH[role]);

			// Set auth modal target time
			window.localStorage.setItem('auth_modal_target', new Date().getTime() + SESSION_LENGTH[role]);
		}
	};

	const onActive = async () => {
		if (user != null) {
			// Set user active state
			isUserActive.current = true;

			// Destroy session prompt timer
			if (sessionPromptTimer.current != null) clearTimeout(sessionPromptTimer.current);
			sessionPromptTimer.current = null;

			// Reset auth modal target time
			window.localStorage.setItem('auth_modal_target', 'none');

			// Extend session token to begin active session
			await extendSession();

			// Destroy current authentication timer
			if (authenticationTimer.current != null) clearInterval(authenticationTimer.current);
			authenticationTimer.current = null;

			// Set new authentication interval
			authenticationTimer.current = setInterval(async () => {
				await extendSession();
			}, SESSION_LENGTH[role]);
		}
	};

	useIdleTimer({
		onIdle,
		onActive,
		timeout: 1000 * 60 * 0.5, // 30 seconds
		promptTimeout: 0,
		events: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove', 'visibilitychange'],
		immediateEvents: [],
		debounce: 0,
		throttle: 0,
		eventsThrottle: 200,
		element: document,
		startOnMount: true,
		startManually: false,
		stopOnIdle: false,
		crossTab: false,
		name: 'idle-timer',
		syncTimers: 0,
		leaderElection: false,
	});

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchUser({ placeholderUser: PLACEHOLDER_USER_DETAILS }));
			dispatch(fetchConfiguration());
			dispatch(fetchGreenlightSchema());
		}
		if (status === 'failed' && error) {
			toast.error(error);
		}
	}, [error, status, dispatch]);

	useEffect(() => {
		// Parse locations
		if (user?.locations) {
			if (user?.locations.length > 0) {
				if (currentLocation == null) {
					dispatch(updateCurrentLocation(user?.locations[0]));
				} else {
					let locationExists = false;
					for (let i = 0; i < user?.locations.length; i += 1) {
						if (user?.locations[i].LocationID === currentLocation.LocationID) {
							locationExists = true;
							break;
						}
					}
					if (!locationExists) {
						dispatch(updateCurrentLocation(user?.locations[0]));
					}
				}
			}
		}

		// Update authentication state if necessary
		if (status === 'succeeded' || status === 'failed') {
			if (window.localStorage.getItem('auth_modal_open') === 'true') {
				// Show authentication modal
				showAuthModal();
			} else if (window.localStorage.getItem('auth_modal_target') && window.localStorage.getItem('auth_modal_target') !== 'none') {
				// Destroy session prompt timer
				if (sessionPromptTimer.current != null) clearTimeout(sessionPromptTimer.current);
				sessionPromptTimer.current = null;

				// Set new session prompt timer
				sessionPromptTimer.current = setTimeout(() => {
					// Show authentication modal
					showAuthModal();
				}, parseInt(window.localStorage.getItem('auth_modal_target'), 10) - new Date().getTime());
			}
		}
	}, [user]);

	useEffect(() => {
		setInterval(() => {
			if (role !== 'staff') {
				dispatch(fetchConfiguration());
			}
		}, 1 * 60 * 1000);
	}, []);

	function renderedContent() {
		if (status === 'loading' || !configurationIsLoaded) {
			return <Spinner />;
		} else if (status === 'succeeded') {
			if (user) {
				if (role === 'staff') {
					return <AdminAuthenticatedRouter />;
				} else if (user.onboardingComplete !== true) {
					return <OnboardingRouter />;
				}
				return <AuthenticatedRouter />;
			}
			return <NotAuthenticatedRouter />;
		} else if (status === 'failed') {
			return <NotAuthenticatedRouter />;
		}
	}
	return (
		<BrowserRouter>
			{renderedContent()}
			<AuthenticationModal
				isOpen={authenticationIsOpen}
				authenticationActions={() => {
					onActive();
				}}
				onRequestClose={() => {
					window.localStorage.setItem('auth_modal_open', 'false');
					setAuthenticationIsOpen(false);
				}}
			/>
		</BrowserRouter>
	);
}

export default Router;
