import styled from 'styled-components';

export const Form = styled.form`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1.5rem;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > * + * {
		margin-left: 1rem;
	}
`;
