import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useHistory } from 'react-router-dom';

import { formatToUSD } from '../../utils/currency-formatter';
import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './UtilitiesPanel.styles';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/time-formatter';
import { calculateDailyAverage } from '../../utils/usage-demand';

export const UtilitiesPanel = ({ className, meters }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const history = useHistory();
	const currentLocation = useSelector((state) => state.location.value);

	// Get utilities account
	const UtilityAccount = currentLocation?.UtilityAccount;

	// Format meters
	const meteredList = (meters || []).map((meter) => {
		return {
			...meter,
			AvgDailyConsumption: `${Math.round(calculateDailyAverage(meter?.UsageDays, 'Usage')) || 'N/A'} ${meter?.Units || ''}`,
			AvgDailyDemand: `${Math.round(calculateDailyAverage(meter?.UsageDays, 'MaxDemand')) || 'N/A'} ${'kW' || ''}`,
		};
	});

	function handleOnClick({ MeterType, MeterNumber }) {
		switch (MeterType) {
			case 'Electric':
				history.push(`/services/electric/${MeterNumber}`);
				break;
			case 'Gas':
				history.push(`/services/gas/${MeterNumber}`);
				break;
			case 'Sprinkler':
				history.push(`/services/sprinkler/${MeterNumber}`);
				break;
			case 'Water':
				history.push(`/services/water/${MeterNumber}`);
				break;
		}
	}

	function onManageUtilities() {
		history.push({ pathname: '/manage-utility/edit', state: { backUrl: window.location.pathname } });
	}

	const renderUtilityIcon = (utility) => {
		let path = '';
		switch (utility) {
			case 'Electric':
				path = 'electricity.jpg';
				break;
			case 'Water':
				path = 'water.jpg';
				break;
			case 'Sprinkler':
				path = 'water.jpg';
				break;
			case 'Gas':
				path = 'gas.jpg';
				break;
			case 'Wastewater':
				path = 'wastewater.jpg';
				break;
			case 'Stormwater':
				path = 'stormwater.jpg';
				break;
			case 'Solid Waste':
				path = 'stormwater.jpg';
				break;
		}
		return <S.UtilityIcon image={`${process.env.PUBLIC_URL}/icons/${path}`} />;
	};

	function renderMeteredCards() {
		if (isHigherThan768) {
			return (
				<>
					<Typography tag="h5" weight="bold">
						Metered
					</Typography>
					<S.DesktopWrapper>
						<S.Head>
							<Typography tag="h6" weight="bold">
								Utility
							</Typography>
							<Typography tag="h6" weight="bold">
								Meter Number
							</Typography>
							<Typography tag="h6" weight="bold">
								Avg. Daily Consumption
							</Typography>
							<Typography tag="h6" weight="bold">
								Avg. Daily Demand
							</Typography>
						</S.Head>
						{meteredList.map((meter) => (
							<S.DesktopItem key={meter.MeterNumber} onClick={() => handleOnClick(meter)} className="clickable">
								<div>
									{renderUtilityIcon(meter.MeterType)}
									<Typography tag="p">{meter.MeterType}</Typography>
								</div>
								<Typography tag="p">{meter.MeterNumber}</Typography>
								<Typography tag="p">{meter.AvgDailyConsumption}</Typography>
								<Typography tag="p">{meter.AvgDailyDemand}</Typography>
								<S.ActionIcon icon={['fal', 'arrow-right']} />
							</S.DesktopItem>
						))}
					</S.DesktopWrapper>
				</>
			);
		}
		return (
			<>
				<Typography tag="h5" weight="bold">
					Metered
				</Typography>
				{meteredList.map((meter) => (
					<S.MobileItem key={meter.MeterNumber} onClick={() => handleOnClick(meter)} className="clickable">
						<S.Head>
							<Typography tag="h4" weight="bold">
								{renderUtilityIcon(meter.MeterType)}
								{meter.MeterType}
							</Typography>
							<S.ActionIcon icon={['fal', 'arrow-right']} />
						</S.Head>
						<Row>
							<Col xs={6}>
								<S.ItemTable>
									<Typography tag="h6" weight="bold">
										Meter Number
									</Typography>
									<Typography tag="p">{meter.MeterNumber}</Typography>
								</S.ItemTable>
							</Col>
							<Col xs={6}>
								<S.ItemTable>
									<Typography tag="h6" weight="bold">
										Avg. Daily Consumption
									</Typography>
									<Typography tag="p">{meter.AvgDailyConsumption}</Typography>
								</S.ItemTable>
							</Col>
							{meter.AvgDailyDemand && (
								<Col xs={6}>
									<S.ItemTable>
										<Typography tag="h6" weight="bold">
											Avg. Daily Demand
										</Typography>
										<Typography tag="p">{meter.AvgDailyDemand}</Typography>
									</S.ItemTable>
								</Col>
							)}
						</Row>
					</S.MobileItem>
				))}
			</>
		);
	}

	function renderNonMeteredCards() {
		if (isHigherThan768) {
			return (
				<>
					<Typography tag="h5" weight="bold">
						Non-Metered
					</Typography>
					<S.DesktopWrapper>
						<S.Head>
							<Typography tag="h6" weight="bold">
								Utility
							</Typography>
							<Typography tag="h6" weight="bold">
								Amount
							</Typography>
						</S.Head>
						{nonMeteredList.map((meter) => (
							<S.DesktopItem key={meter.id} onClick={() => handleOnClick(meter)}>
								<Typography tag="p">{meter.utility}</Typography>
								<Typography tag="p">{formatToUSD(meter.amount)}</Typography>
							</S.DesktopItem>
						))}
					</S.DesktopWrapper>
				</>
			);
		}
		return (
			<>
				<Typography tag="h5" weight="bold">
					Non-Metered
				</Typography>
				{nonMeteredList.map((meter) => (
					<S.MobileItem key={meter.id} onClick={() => handleOnClick(meter)}>
						<S.Head>
							<Typography tag="h4" weight="bold">
								{meter.utility}
							</Typography>
						</S.Head>
						<Row>
							<Col xs={6}>
								<S.ItemTable>
									<Typography tag="h6" weight="bold">
										Amount
									</Typography>
									<Typography tag="p">{formatToUSD(meter.amount)}</Typography>
								</S.ItemTable>
							</Col>
						</Row>
					</S.MobileItem>
				))}
			</>
		);
	}

	return (
		<Card className={className} title="Utilities" action={[{ id: 1, onClick: onManageUtilities, label: 'Manage', icon: ['fal', 'cog'] }]}>
			<S.ItemWrapper>
				<Typography tag="h5" weight="bold">
					Statement
				</Typography>
				<S.InfoWrapper>
					<Container fluid>
						<Row>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Current Balance
									</Typography>
									<Typography tag="h2" weight="bold">
										{formatToUSD(UtilityAccount?.CurrentBalance || 0)}
									</Typography>
								</S.Item>
							</Col>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Bill Date
									</Typography>
									<Typography tag="h2" weight="bold">
										{UtilityAccount?.LastBillDate ? formatDate(UtilityAccount?.LastBillDate) : null}
									</Typography>
								</S.Item>
							</Col>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Payment Due Date
									</Typography>
									<Typography tag="h2" weight="bold">
										{UtilityAccount?.CurrentDueDate ? formatDate(UtilityAccount?.CurrentDueDate) : null}
									</Typography>
								</S.Item>
							</Col>
						</Row>
					</Container>
				</S.InfoWrapper>
			</S.ItemWrapper>
			{!isHigherThan768 && (
				<Typography tag="h3" weight="bold">
					Utility Active Services
				</Typography>
			)}
			<S.CardWrapper>{renderMeteredCards()}</S.CardWrapper>
			{/*<S.CardWrapper>{renderNonMeteredCards()}</S.CardWrapper>*/}
		</Card>
	);
};

UtilitiesPanel.propTypes = {
	className: PropTypes.string,
	meters: PropTypes.arrayOf(PropTypes.shape()),
};
