import { fakeAsyncFunction } from '../utils/fake-async-function';

import toast from 'react-hot-toast';

import api from './api';

const ADMIN = 'Admin_';
const SYSTEM = 'System_';

export const errorHandler = (error) => {
	toast.error(error.message || 'Something went wrong, try again later!');
};

// ===============Properties====================

export async function fetchPropertyById(id) {
	// NV - ADMIN - REQUIRES API CHANGE
	return await fakeAsyncFunction({ id, name: 'Property #1' });
}

// ===============System====================

export async function getPortalUserCount() {
	const {
		data: { PortalUserCount },
	} = await api.get(`${SYSTEM}Get_Portal_User_Count`);
	return PortalUserCount;
}

// ===============Articles====================

export async function adminRetrieveArticles({ returnExpired, returnCurrent, returnFuture }) {
	const {
		data: { Articles, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Retrieve_Articles`, { ReturnExpired: returnExpired, ReturnCurrent: returnCurrent, ReturnFuture: returnFuture });
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Articles;
	}
}

export async function adminAddArticle({ title, description, url, startDate, endDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, ArticleAdded, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Add_Article`, {
		Title: title,
		Description: description,
		URL: url,
		StartDate: startDate,
		EndDate: endDate,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return ArticleAdded;
	}
}

export async function adminRemoveArticle({ articleKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, ArticleKeyValid, IsArticleDeleted, ArticleDeleted, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Remove_Article`, {
		ArticleKey: articleKey,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!ArticleKeyValid) {
		throw new Error('Invalid article key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return IsArticleDeleted || ArticleDeleted;
	}
}

export async function adminUpdateArticle({ articleKey, title, description, url, startDate, endDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, ArticleKeyValid, ArticleUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Update_Article`, {
		ArticleKey: articleKey,
		Title: title,
		Description: description,
		URL: url,
		StartDate: startDate,
		EndDate: endDate,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!ArticleKeyValid) {
		throw new Error('Invalid article key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return ArticleUpdated;
	}
}

// ===============Resources====================

export async function adminRetrieveResources({ returnExpired, returnCurrent, returnFuture }) {
	const {
		data: { Resources, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Retrieve_Resources`, { ReturnExpired: returnExpired, ReturnCurrent: returnCurrent, ReturnFuture: returnFuture });
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Resources;
	}
}

export async function adminAddResource({ title, description, url, startDate, endDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, ResourceAdded, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Add_Resource`, {
		Title: title,
		Description: description,
		URL: url,
		StartDate: startDate,
		EndDate: endDate,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return ResourceAdded;
	}
}

export async function adminRemoveResource({ resourceKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, ResourceKeyValid, ResourceDeleted, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Remove_Resource`, {
		ResourceKey: resourceKey,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!ResourceKeyValid) {
		throw new Error('Invalid resource key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return ResourceDeleted;
	}
}

export async function adminUpdateResource({ resourceKey, title, description, url, startDate, endDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, ResourceKeyValid, ResourceUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Update_Resource`, {
		ResourceKey: resourceKey,
		Title: title,
		Description: description,
		URL: url,
		StartDate: startDate,
		EndDate: endDate,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!ResourceKeyValid) {
		throw new Error('Invalid resource key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return ResourceUpdated;
	}
}

// ===============Community Events====================

export async function adminRetrieveCommunityEvents({ returnExpired, returnCurrent, returnFuture }) {
	const {
		data: { CommunityEvents, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Retrieve_Community_Events`, { ReturnExpired: returnExpired, ReturnCurrent: returnCurrent, ReturnFuture: returnFuture });
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return CommunityEvents;
	}
}

export async function adminAddCommunityEvent({ title, description, url, startDate, endDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, CommunityEventAdded, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Add_Community_Event`, {
		Title: title,
		Description: description,
		URL: url,
		StartDate: startDate,
		EndDate: endDate,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return CommunityEventAdded;
	}
}

export async function adminRemoveCommunityEvent({ communityEventKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, CommunityEventKeyValid, CommunityEventDeleted, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Remove_Community_Event`, {
		CommunityEventKey: communityEventKey,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!CommunityEventKeyValid) {
		throw new Error('Invalid community event key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return CommunityEventDeleted;
	}
}

export async function adminUpdateCommunityEvent({ communityEventKey, title, description, url, startDate, endDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, CommunityEventKeyValid, CommunityEventUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Update_Community_Event`, {
		CommunityEventKey: communityEventKey,
		Title: title,
		Description: description,
		URL: url,
		StartDate: startDate,
		EndDate: endDate,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!CommunityEventKeyValid) {
		throw new Error('Invalid community event key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return CommunityEventUpdated;
	}
}

// ==============Notifications====================

export async function getAdminNotificationSettings() {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, NewRequest, UtilityUpdates, GreenlightUpdates, UtilityShutoff, GreenlightShutoff, NewSupportRequest, EquipmentRequest },
	} = await api.get(`${ADMIN}Notification_Settings/${token}`);

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return {
			NewRequest,
			UtilityUpdates,
			GreenlightUpdates,
			UtilityShutoff,
			GreenlightShutoff,
			NewSupportRequest,
			EquipmentRequest,
		};
	}
}

export async function updateAdminNotificationSettings({ newRequest, utilityUpdates, greenlightUpdates, utilityShutoff, greenlightShutoff, newSupportRequest, equipmentRequest }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Updated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Notification_Settings`, {
		LoginToken: token,
		NewRequest: newRequest,
		UtilityUpdates: utilityUpdates,
		GreenlightUpdates: greenlightUpdates,
		UtilityShutoff: utilityShutoff,
		GreenlightShutoff: greenlightShutoff,
		NewSupportRequest: newSupportRequest,
		EquipmentRequest: equipmentRequest,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Updated;
	}
}

// ===============Users====================

export async function adminListPortalUsers() {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Users, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}List_Portal_Users`, { LoginToken: token });

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Users;
	}
}

export async function adminSearchPortalUsers({ searchText }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Users, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Search_Portal_Users`, { LoginToken: token, SearchFor: searchText });

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Users;
	}
}

export async function fetchUserById(id) {
	// NV - ADMIN - REQUIRES API CHANGE
	return await fakeAsyncFunction({ id, name: 'Eduardo Sotero', email: 'eduardo.sotero@toptal.com', phone: 1230000432, mailingAddress: '1 Main St', mailingCity: 'San Francisco', mailingState: 'CA', mailingZip: '94103' });
}

export async function listStaffUsers({}) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Users, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Staff_User_List `, {
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Users;
	}
}

// ===============Accounts====================

export async function inviteFindAccounts({ customerAccountId }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { UnassignedGreenlightAccounts, UnassignedUtilityAccounts, HasErrors, ErrorMsg },
		} = await api.post(`${ADMIN}Invite_Find_Accounts`, { CustomerAccountID: customerAccountId, LoginToken: token });
		if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return {
				unassignedUtilityAccounts: UnassignedUtilityAccounts,
				unassignedGreenlightAccounts: UnassignedGreenlightAccounts,
			};
		}
	} catch (error) {
		throw error;
	}
}

export async function inviteCreateUser({ userEmail, accounts }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, UserEmailTaken, InviteCreated, InviteEmailSent, HasErrors, ErrorMsg },
		} = await api.post(`${ADMIN}Invite_Create_User`, { LoginToken: token, UserEmail: userEmail, Accounts: accounts });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (UserEmailTaken) {
			throw new Error('User email has been taken');
		} else if (!InviteEmailSent) {
			throw new Error('Invite email was not sent successfully');
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return InviteCreated;
		}
	} catch (error) {
		throw error;
	}
}

export async function inviteRevoke({ inviteToken }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, InviteTokenValid, InviteRevoked, HasErrors, ErrorMsg },
		} = await api.post(`${ADMIN}Invite_Revoke`, { LoginToken: token, InviteToken: inviteToken });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (InviteTokenValid === false) {
			throw new Error('Invite token is not valid');
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return InviteRevoked;
		}
	} catch (error) {
		throw error;
	}
}

export async function addCustomerAccount({ userEmail, accounts }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, AccountAdded, NotificationEmailSent, HasErrors, ErrorMsg },
		} = await api.post(`${ADMIN}Add_Customer_Account`, { LoginToken: token, UserEmail: userEmail, Accounts: accounts });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (!NotificationEmailSent) {
			throw new Error('Notification email was not sent successfully');
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return AccountAdded;
		}
	} catch (error) {
		throw error;
	}
}

export async function inviteStatus({ days, unacceptedInvitesOnly }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, Invites, HasErrors, ErrorMsg },
		} = await api.post(`${ADMIN}Invite_Status`, { LoginToken: token, DaysToSearch: days, UnacceptedInvitesOnly: unacceptedInvitesOnly });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return Invites;
		}
	} catch (error) {
		throw error;
	}
}

export async function inviteSendEmail({ inviteToken }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, InviteTokenValid, InviteEmailSent, HasErrors, ErrorMsg },
		} = await api.post(`${ADMIN}Invite_Send_Email`, { LoginToken: token, InviteToken: inviteToken });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (!InviteTokenValid) {
			throw new Error('Invite token is not valid');
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return InviteEmailSent;
		}
	} catch (error) {
		throw error;
	}
}

export async function retrievePortalUserAccounts({ portalUserId }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, ServiceAccounts, HasErrors, ErrorMsg },
		} = await api.post(`${ADMIN}Retrieve_Portal_User_Accounts`, { PortalUserID: portalUserId, LoginToken: token });
		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return ServiceAccounts;
		}
	} catch (error) {
		throw error;
	}
}

// ===============Requests====================

export async function retrieveAdminUserRequests({ isOpen }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Requests, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}User_Retrieve_Requests`, {
		LoginToken: token,
		IsOpen: isOpen,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Requests;
	}
}

export async function requestTakeOwnership({ key }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, RequestKeyValid, RequestUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Request_Take_Ownership`, {
		LoginToken: token,
		RequestKey: key,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!RequestKeyValid) {
		throw new Error('Request key is not valid');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return RequestUpdated;
	}
}

export async function requestChangeOwner({ key, staffId }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, RequestKeyValid, NewOwnerIDValid, RequestUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Request_Change_Owner`, {
		LoginToken: token,
		RequestKey: key,
		NewOwnerID: staffId,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!RequestKeyValid) {
		throw new Error('Request key is not valid');
	} else if (!NewOwnerIDValid) {
		throw new Error('Staff ID is not valid');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return RequestUpdated;
	}
}

// =================Admin Request Messages================

export async function adminSubmitRequestMessage({ requestKey, messageText }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, RequestKeyValid, MessageSubmitted, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Submit_Request_Message`, {
		LoginToken: token,
		RequestKey: requestKey,
		MessageText: messageText,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return {
			requestKeyValid: RequestKeyValid,
			messageSubmitted: MessageSubmitted,
		};
	}
}

export async function adminMarkMessageRead({ messageKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, MessageKeyValid, ReadStatusUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Mark_Message_Read`, {
		LoginToken: token,
		MessageKey: messageKey,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!MessageKeyValid) {
		throw new Error('Invalid Message Key');
	} else {
		return ReadStatusUpdated;
	}
}

// =================Admin Change Request Status================

export async function adminChangeRequestStatus({ requestKey, isOpen }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, RequestKeyValid, RequestUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Change_Request_Status`, {
		LoginToken: token,
		RequestKey: requestKey,
		IsOpen: isOpen,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!RequestKeyValid) {
		throw new Error('Invalid Request Key');
	} else {
		return RequestUpdated;
	}
}

// =================Admin Retrieve Document================

export async function adminRetrieveDoc({ documentKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, DocumentKeyValid, Document, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Retrieve_Document`, {
		LoginToken: token,
		DocumentKey: documentKey,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!DocumentKeyValid) {
		throw new Error('Invalid Document Key');
	} else {
		return Document;
	}
}

export async function adminMarkDocReviewed({ documentKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, DocumentKeyValid, ReviewedUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${ADMIN}Mark_Document_Reviewed`, {
		LoginToken: token,
		DocumentKey: documentKey,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!DocumentKeyValid) {
		throw new Error('Invalid Document Key');
	} else {
		return ReviewedUpdated;
	}
}

// =================Payments================

export async function otcAccountLookup({ type, id }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');

		let tokenValid = null;
		let hasErrors = null;
		let errorMsg = null;
		let data = null;
		if (type === 'greenlight') {
			const {
				data: { TokenValid, SubscriberInfo, HasErrors, ErrorMsg },
			} = await api.post(`${ADMIN}GL_OTC_Account_Lookup`, { LoginToken: token, SubscriberID: id });
			tokenValid = TokenValid;
			hasErrors = HasErrors;
			errorMsg = ErrorMsg;
			data = SubscriberInfo;
		} else {
			const {
				data: { TokenValid, CustomerInfo, HasErrors, ErrorMsg },
			} = await api.post(`${ADMIN}UT_OTC_Account_Lookup`, { LoginToken: token, LocationID: id });
			tokenValid = TokenValid;
			hasErrors = HasErrors;
			errorMsg = ErrorMsg;
			data = CustomerInfo;
		}
		if (tokenValid === false) {
			window.location = '/logout';
		} else if (hasErrors) {
			throw new Error(errorMsg);
		} else if (data === null) {
			throw new Error(type === 'greenlight' ? "We can't find a subscriber with that ID. Please try again." : "We can't find a customer with that location ID. Please try again.");
		} else {
			return data;
		}
	} catch (error) {
		throw error;
	}
}

export async function otcLogTransaction({ type, subscriberId, customerId, locationId, isDepositPayment, depositDocumentNumber, paymentAmount, confirmationInfo, customerNotificationEmail }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');

		let tokenValid = null;
		let customerValid = null;
		let paymentLogged = null;
		let hasErrors = null;
		let errorMsg = null;
		if (type === 'greenlight') {
			const {
				data: { TokenValid, SubscriberValid, PaymentLogged, HasErrors, ErrorMsg },
			} = await api.post(`${ADMIN}GL_OTC_Log_Transaction`, {
				LoginToken: token,
				SubscriberID: subscriberId,
				LocationID: locationId,
				IsDepositPayment: isDepositPayment,
				DepositDocumentNumber: depositDocumentNumber,
				PaymentAmount: paymentAmount,
				ConfirmationInfo: confirmationInfo,
				CustomerNotificationEmail: customerNotificationEmail,
			});
			tokenValid = TokenValid;
			customerValid = SubscriberValid;
			paymentLogged = PaymentLogged;
			hasErrors = HasErrors;
			errorMsg = ErrorMsg;
		} else {
			const {
				data: { TokenValid, CustomerValid, PaymentLogged, HasErrors, ErrorMsg },
			} = await api.post(`${ADMIN}UT_OTC_Log_Transaction`, { LoginToken: token, CustomerID: customerId, LocationID: locationId, PaymentAmount: paymentAmount, ConfirmationInfo: confirmationInfo, CustomerNotificationEmail: customerNotificationEmail });
			tokenValid = TokenValid;
			customerValid = CustomerValid;
			paymentLogged = PaymentLogged;
			hasErrors = HasErrors;
			errorMsg = ErrorMsg;
		}

		if (tokenValid === false) {
			window.location = '/logout';
		} else if (customerValid === false) {
			throw new Error(type === 'greenlight' ? 'Subscriber does not exist' : 'Customer does not exist');
		} else if (hasErrors) {
			throw new Error(errorMsg);
		} else {
			return paymentLogged;
		}
	} catch (error) {
		throw error;
	}
}
