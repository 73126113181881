import styled from 'styled-components';

import colors from '../../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CardItem = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 0;
	border-bottom: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-top: 0rem;
	}

	.card-item-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.cardAccountNameColumn {
			flex-grow: 1;
			min-width: 180px;
		}
		.cardAccountName {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		& > * + * {
			margin-top: 0rem;
		}

		.flex.items-center > svg {
			width: 1.5rem !important;
			height: 1.5rem !important;
			color: ${colors.brandPrimary};
			margin-right: 1.25rem;
		}

		@media screen and (min-width: 768px) {
			flex-direction: row;
			align-items: center;
			gap: 3rem;

			& > * + * {
				margin-top: 0;
				margin-left: 0rem;
			}
		}
	}

	&:first-child {
		padding-top: 0.5rem;
	}

	&:last-child {
		padding-bottom: 0.5rem;
		border: none;
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;

		& > * + * {
			margin-top: 0;
			margin-left: 3rem;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	@media screen and (min-width: 768px) {
		flex: 1;
		align-items: center;
		justify-content: flex-end;

		& > * + * {
			margin-left: 1.5rem;
		}

		button {
			padding: 0;
		}
	}
`;

export const ExpirationContainer = styled.div`
	svg {
		width: 1.2rem !important;
		height: 1.2rem !important;
		position: relative;
		top: 2px;
		color: ${colors.statusBad};
	}
`;

export const InfoIcon = styled(FontAwesomeIcon)`
	margin-left: 10px;
	cursor: pointer;
`;
