import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Button } from '../../components';
import * as S from './Page404.styles';
import { useHistory } from 'react-router-dom';
import { META_TITLE } from '../../utils/constants';

export default function Page404() {
	const history = useHistory();

	useEffect(() => {
		// Set document title
		document.title = `Page Not Found | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<S.BigText>404</S.BigText>
			<Typography tag="h3" weight="bold" center>
				Page Not Found
			</Typography>
			<Typography tag="p" center>
				The link you clicked may be broken or the page may have been removed or renamed.
			</Typography>
			<Button onClick={() => history.goBack()}>
				<FontAwesomeIcon icon={['fal', 'arrow-left']} />
				<Typography weight="extrablack">Go Back</Typography>
			</Button>
		</S.Wrapper>
	);
}
