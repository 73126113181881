import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Col, Row } from 'react-bootstrap';

import { formatToUSD } from '../../utils/currency-formatter';
import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './RecentPayments.styles';
import { useHistory } from 'react-router-dom';

export const RecentPayments = ({ recentPayments = [], className }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const history = useHistory();

	function renderCards() {
		if (isHigherThan768) {
			return (
				<S.DesktopWrapper>
					<S.Head>
						<Typography tag="h6" weight="bold">
							Pay Date
						</Typography>
						<Typography tag="h6" weight="bold">
							Type
						</Typography>
						<Typography tag="h6" weight="bold">
							Amount
						</Typography>
					</S.Head>
					{recentPayments.map((item) => (
						<S.DesktopItem key={item.id}>
							<Typography tag="p">{item.date}</Typography>
							<Typography tag="p">{item.type}</Typography>
							<Typography tag="p">{formatToUSD(item.amount)}</Typography>
						</S.DesktopItem>
					))}
				</S.DesktopWrapper>
			);
		}
		return recentPayments.map((item) => (
			<S.MobileItem key={item.id}>
				<S.Head>
					<Typography tag="h4" weight="bold">
						{item.date}
					</Typography>
				</S.Head>
				<Row>
					<Col xs={6} sm={4}>
						<S.Item>
							<Typography tag="h6" weight="bold">
								Type
							</Typography>
							<Typography tag="p">{item.type}</Typography>
						</S.Item>
					</Col>
					<Col xs={6} sm={4}>
						<S.Item>
							<Typography tag="h6" weight="bold">
								Amount
							</Typography>
							<Typography tag="p">{formatToUSD(item.amount)}</Typography>
						</S.Item>
					</Col>
				</Row>
			</S.MobileItem>
		));
	}

	return (
		<Card className={className} title="Recent Payments" action={[{ id: 1, label: 'View All', onClick: () => history.push('/payment-history'), type: 'text' }]}>
			{renderCards()}
		</Card>
	);
};

RecentPayments.propTypes = {
	recentPayments: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			date: PropTypes.string,
			type: PropTypes.oneOf(['Utilities', 'Greenlight']),
			amount: PropTypes.number,
		})
	),
	className: PropTypes.string,
};
