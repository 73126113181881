import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../../../Typography';
import * as S from './AccountSwitcher.styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentLocation } from '../../../../app/slices/location/locationSlice';
import { useHistory } from 'react-router-dom';

export const AccountSwitcher = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.value);
	const currentLocation = useSelector((state) => state.location.value);
	const [opened, setOpened] = useState(false);
	const history = useHistory();

	const handleSelectedOption = (id) => {
		const selectedAccount = user?.locations.find((location) => location.LocationID === id);
		dispatch(updateCurrentLocation(selectedAccount));
	};

	const renderIconForAccount = ({ GreenlightAccount, UtilityAccount }) => {
		let isCommerical = false;
		if (GreenlightAccount && GreenlightAccount?.IsCommercial === true) {
			isCommerical = true;
		}
		if (UtilityAccount && UtilityAccount?.IsCommercial === true) {
			isCommerical = true;
		}
		return isCommerical ? 'building' : 'home';
	};

	return (
		<S.Wrapper>
			<S.Button opened={opened} onClick={() => setOpened((prev) => !prev)}>
				<Typography tag="h5">{currentLocation?.Address || 'Select Account'}</Typography>
				<FontAwesomeIcon icon={['fal', 'angle-down']} />
			</S.Button>
			<S.Modal opened={opened}>
				<ul>
					{user?.locations?.map(({ LocationID, Address, GreenlightAccount, UtilityAccount }) => (
						<li key={LocationID}>
							<button onClick={() => handleSelectedOption(LocationID)}>
								<FontAwesomeIcon icon={renderIconForAccount({ GreenlightAccount, UtilityAccount })} />
								<Typography tag="h5">{Address}</Typography>
								{LocationID === currentLocation?.LocationID && <FontAwesomeIcon icon="check-circle" />}
							</button>
						</li>
					))}
					<li>
						<button
							className="last"
							onClick={() => {
								history.push('/onboarding');
							}}
						>
							<FontAwesomeIcon icon={['fal', 'plus']} />
							<Typography tag="span" variation="2" weight="extrablack">
								Add New Address
							</Typography>
						</button>
					</li>
				</ul>
			</S.Modal>
		</S.Wrapper>
	);
};

AccountSwitcher.propTypes = {};
