import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 72.875rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 0.5fr;
	grid-gap: 2.25rem;
	& > div {
		height: fit-content;
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;
export const CheckboxContainer = styled.div`
	margin-bottom: 0.8rem;
	label > span {
		font-size: 1.25rem;
		font-weight: 700;
		margin-left: 4px;
	}
`;

export const RadioContainers = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 1.9rem;
`;

export const AmountInputContainer = styled.div`
	position: relative;
	margin: 0.25rem 0;
	margin-left: 1.75rem;
	& > svg {
		color: ${colors.neutralButton};
		font-size: 1.5rem;
		position: absolute;
		left: 7px;
		top: 50%;
		z-index: 2;
		transform: translateY(-50%);
	}

	.amountInput {
		display: inline-block;

		& > input {
			max-width: 15.25rem;
			padding-left: 1.5rem;
			@media screen and (max-width: 576px) {
				max-width: auto;
				width: 100%;
			}
		}
	}
`;

export const HorizontalLine = styled.div`
	height: 1px;
	width: 100%;
	background: ${colors.neutralForm};
	margin-top: 1.625rem;
	margin-bottom: 0.625rem;
`;

export const Card2HeadingWrapper = styled.div`
	margin-bottom: 1.25rem;
`;

export const Card2DetailsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.75rem;
`;

export const HorizontalLine2 = styled.div`
	height: 1px;
	width: 100%;
	background: ${colors.neutralForm};
	margin-bottom: 0.75rem;
`;
export const Card2Total = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const AccountSection = styled.div`
	& > h5 {
		margin-bottom: 12px;
		margin-top: 0.5rem;
	}
`;

export const CheckboxContent = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	position: relative;
	align-items: center;
	justify-content: center;

	.autopayBadge {
		padding: 0.2em 1em;
	}
`;
