import React, { useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { errorHandler, loginStaff } from '../../services/authenticationService';
import logo from '../../assets/wilson-logo.png';
import { TextInput, Button, Typography, Link } from '../../components';
import * as S from './StaffLogin.styles';
import { fetchUser } from '../../app/slices/user/userSlice';
import { required, pattern } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import { META_TITLE, PLACEHOLDER_STAFF_DETAILS } from '../../utils/constants';

export default function StaffLogin() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			...(process.env.REACT_APP_ENV === 'development'
				? {
						email: 'brendan@lithiosapps.com',
						password: 'Test123',
				  }
				: undefined),
		},
	});
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const dispatch = useDispatch();

	async function loginCaller(data) {
		try {
			const user = await loginStaff(data);
			window.localStorage.setItem('wilsonnc_token', user.token || user.LoginToken);
			window.localStorage.setItem('wilsonnc_role', 'staff');
			window.localStorage.setItem(
				'wilsonnc_user',
				JSON.stringify({
					...user,
					onboardingComplete: true,
					token: user.LoginToken,
				})
			);
			dispatch(fetchUser({ placeholderUser: PLACEHOLDER_STAFF_DETAILS }));
		} catch (e) {
			reset(undefined, { keepValues: true });
			throw e;
		}
	}

	function onSubmit(e) {
		handleSubmit(loginCaller)(e).catch(errorHandler);
	}

	useEffect(() => {
		// Set document title
		document.title = `Log In | ${META_TITLE}`;
	}, []);

	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			<S.Form onSubmit={onSubmit}>
				<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
					Staff Login
				</Typography>
				<S.InputsWrapper>
					<TextInput
						label="Email"
						id="email"
						type="email"
						error={errors.email}
						autoComplete="email"
						{...register('email', {
							required: required('Email'),
							pattern: pattern('Email', EMAIL),
						})}
					/>
					<div>
						<TextInput
							label="Password"
							id="password"
							type="password"
							error={errors.password}
							autoComplete="current-password"
							{...register('password', {
								required: required('Password'),
							})}
						/>
						<S.Link to="/staff/forgot-password">Forgot Password</S.Link>
					</div>
					<Button size={isHigherThan768 ? 'medium' : 'small'} type="submit">
						<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
							{isSubmitting ? 'Loading...' : 'Log In'}
						</Typography>
					</Button>
				</S.InputsWrapper>
			</S.Form>
		</S.MainWrapper>
	);
}
