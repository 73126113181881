import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar, CartesianGrid, ComposedChart, Line, Tooltip, ResponsiveContainer, XAxis, YAxis, Label } from 'recharts';
import { format } from 'date-fns';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';

import colors from '../../styles/colors';
import 'react-datepicker/dist/react-datepicker.css';
import { Checkbox } from '../Checkbox';
import { Badge } from '../Badge';
import { Typography } from '../Typography';
import * as S from './ConsumptionUsage.styles';
import { Spinner } from '../Spinner';
import { useSelector } from 'react-redux';
import { retrieveAccountBillHistory, retrieveDailyUsageHistory, retrieveUsageDetail } from '../../services/userService';
import { ButtonToggle } from '../ButtonToggle';
import { formatMeterData } from '../../utils/usage-demand';
import { IconButton } from '../IconButton';
import { EmptyComponent } from '../EmptyComponent';

export const ConsumptionUsage = ({ utilities = ['Electric', 'Gas', 'Water', 'Sprinkler'], data, dataKey = 'Usage', hidePeriodSelector = false, billingMode = false, className }) => {
	const currentLocation = useSelector((state) => state.location.value);
	const [activeUtility, setActiveUtility] = useState(utilities && utilities.length > 0 ? utilities[0] : 'Electric');
	const [meterData, setMeterData] = useState(data);
	const [billingData, setBillingData] = useState(null);
	const [dayData, setDayData] = useState([]);
	const [usageStatus, setUsageStatus] = useState('idle');
	const [billingStatus, setBillingStatus] = useState('idle');
	const [showTemperature, setShowTemperature] = useState(true);
	const [datePickerOpen, setDatePickerOpen] = useState(false);

	const [activePeriod, setActivePeriod] = useState(null);
	const [activeSource, setActiveSource] = useState(dataKey);
	const [options, setOptions] = useState(
		[
			{ id: 'year', label: 'Year', selected: true },
			{ id: 'month', label: 'Month', selected: false },
			{ id: 'day', label: 'Day', selected: false },
		].filter(Boolean)
	);
	const [sourceOptions, setSourceOptions] = useState([
		{ id: 'default', label: activeSource, selected: true },
		{ id: 'billing', label: 'Billing History', selected: false },
	]);

	const getActivePeriod = () => {
		return options.find((period) => {
			return period.selected === true;
		});
	};

	const getActiveSource = () => {
		return sourceOptions.find((source) => {
			return source.selected === true;
		});
	};

	const getCurrentStatus = () => {
		return billingMode ? billingStatus : usageStatus;
	};

	const getDataForPeriod = () => {
		if (billingMode) {
			if (billingData == null) return [];
			return billingData.map((datum) => {
				return {
					UsageDate: datum.BillDate,
					UtilityAmount: datum.UtilityAmount || 0,
					GreenlightAmount: datum.GreenlightAmount || 0,
				};
			});
		} else if (activePeriod == null || activePeriod.period === 'year' || activePeriod.period === 'month') {
			if (meterData == null || meterData.UsageDays.length === 0 || activePeriod == null || activePeriod.currentPeriod == null) return [];
			const data = activePeriod.period === 'year' ? meterData.UsageYears.find((year) => year.Year === activePeriod.currentPeriod).Months : meterData.UsageMonths.find((month) => month.Month === activePeriod.currentPeriod).Days;
			return data.map((datum) => {
				return {
					...datum,
					TempLow: Math.round(datum.TempLow * 10) / 10,
					TempHigh: Math.round(datum.TempHigh * 10) / 10,
					Usage: Math.round(datum.Usage * 10) / 10,
					MaxDemand: Math.round(datum.MaxDemand * 10) / 10,
					Cost: Math.round(datum.Cost * 10) / 10,
				};
			});
		} else {
			if (dayData == null || dayData.length === 0 || activePeriod == null || activePeriod.currentPeriod == null) return [];
			return dayData;
		}
	};

	const handleChangeUtility = (utility) => {
		setActiveUtility(utility);
	};

	const pushCurrentPeriod = (increment) => {
		const period = getActivePeriod().id;
		setActivePeriod({
			period,
			currentPeriod: increment === 1 ? activePeriod.nextPeriod : activePeriod.previousPeriod,
			shouldUpdate: true,
		});
	};

	const jumpToPeriod = (date) => {
		const period = getActivePeriod().id;
		setActivePeriod({
			period,
			currentPeriod: moment(date).format('DD MMMM YYYY'),
			shouldUpdate: true,
		});
	};

	function handleTemperatureCompare(e) {
		setShowTemperature(e.target.checked);
	}

	async function fetchMeterData() {
		if (currentLocation?.UtilityAccount?.AccountKey) {
			try {
				setUsageStatus('loading');
				const meters = await retrieveDailyUsageHistory({ accountKey: currentLocation?.UtilityAccount?.AccountKey, usageDays: 365 });

				// TODO - NEED TO FILTER TO FULL 13TH MONTH

				const meter = meters.find((data) => data.MeterType === activeUtility);
				try {
					setMeterData(formatMeterData(meter));
					setUsageStatus('success');
				} catch (e) {
					setUsageStatus('error');
				}
			} catch (e) {
				setUsageStatus('error');
			}
		} else {
			setUsageStatus('error');
		}
	}

	async function fetchBillingData() {
		try {
			setBillingStatus('loading');
			const bills = [];
			if (currentLocation?.UtilityAccount != null) {
				let utilityBills = await retrieveAccountBillHistory({ accountKey: currentLocation?.UtilityAccount?.AccountKey });
				utilityBills = utilityBills.map((bill) => ({
					...bill,
					UtilityAmount: bill.AmountDue,
				}));
				bills.push(...utilityBills);
			}
			if (currentLocation?.GreenlightAccount != null) {
				let greenlightBills = await retrieveAccountBillHistory({ accountKey: currentLocation?.GreenlightAccount?.AccountKey });
				greenlightBills = greenlightBills.map((bill) => ({
					...bill,
					GreenlightAmount: bill.AmountDue,
				}));
				greenlightBills.forEach((bill) => {
					const { BillDate, GreenlightAmount } = bill;
					const billMonth = new Date(BillDate).getMonth();
					let foundMatch = false;
					for (let i = 0; i < bills.length; i += 1) {
						if (new Date(bills[i].BillDate).getMonth() === billMonth) {
							bills[i].GreenlightAmount = GreenlightAmount;
							foundMatch = true;
						}
					}
					if (!foundMatch) {
						bills.push(bill);
					}
				});
			}
			bills.sort((a, b) => new Date(b.BillDate).getTime() - new Date(a.BillDate).getTime());
			if (bills.length > 13) {
				bills.length = 13;
			}
			bills.sort((a, b) => new Date(a.BillDate).getTime() - new Date(b.BillDate).getTime());
			setBillingData(bills);
			if (bills.length === 0) {
				setBillingStatus('error');
			} else {
				setBillingStatus('success');
			}
		} catch (e) {
			setBillingStatus('error');
		}
	}

	useEffect(() => {
		const period = getActivePeriod().id;
		setActivePeriod({
			period,
			...(meterData != null && meterData.UsageDays.length > 0
				? {
						currentPeriod: period === 'year' ? meterData.UsageYears[meterData.UsageYears.length - 1].Year : meterData.UsageMonths[meterData.UsageMonths.length - 1].Month,
				  }
				: undefined),
		});
	}, [meterData, options]);

	useEffect(() => {
		const source = getActiveSource().id;
		if (source === 'default') {
			setActiveSource(dataKey);
		} else {
			setActiveSource('Cost');
		}
	}, [sourceOptions]);

	useEffect(() => {
		if (billingMode) {
			if (billingData == null) {
				fetchBillingData();
			}
		}
	}, [billingMode]);

	useEffect(async () => {
		if (activePeriod?.period === 'day') {
			if (activePeriod?.shouldUpdate !== false) {
				// Parse current date
				let currentDate = moment(activePeriod?.currentPeriod, 'YYYY', true);
				if (!currentDate.isValid()) {
					currentDate = moment(activePeriod?.currentPeriod, 'MMMM YYYY', true);
				}
				if (!currentDate.isValid()) {
					currentDate = moment(activePeriod?.currentPeriod, 'DD MMMM YYYY', true);
				}
				if (!currentDate.isValid()) {
					currentDate = moment();
				}

				// Get intervals for current day
				const { Intervals } = await retrieveUsageDetail({ accountKey: currentLocation?.UtilityAccount?.AccountKey, usageDate: currentDate.toDate(), meterNumber: meterData?.MeterNumber });

				// Set day data
				setDayData(Intervals);

				// Create new moments
				const previousDate = moment(currentDate);
				const nextDate = moment(currentDate);
				previousDate.subtract(1, 'days');
				nextDate.add(1, 'days');

				// Create new active period
				const newActivePeriod = {
					period: 'day',
					currentPeriod: currentDate.format('DD MMMM YYYY'),
					previousPeriod: previousDate.format('DD MMMM YYYY'),
					...(!nextDate.isAfter()
						? {
								nextPeriod: nextDate.format('DD MMMM YYYY'),
						  }
						: undefined),
				};

				// Set active period
				setActivePeriod({ ...newActivePeriod, shouldUpdate: false });
			}
		} else if (activePeriod != null && activePeriod.currentPeriod != null && activePeriod.previousPeriod == null && activePeriod.nextPeriod == null) {
			const newActivePeriod = { ...activePeriod };
			const periods = activePeriod.period === 'year' ? meterData.UsageYears : meterData.UsageMonths;
			const activeIndex = periods.findIndex((obj) => {
				if (activePeriod.period === 'year') return obj.Year === activePeriod.currentPeriod;
				else return obj.Month === activePeriod.currentPeriod;
			});
			let shouldUpdate = false;
			if (activeIndex > 0) {
				shouldUpdate = true;
				newActivePeriod.previousPeriod = activePeriod.period === 'year' ? periods[activeIndex - 1].Year : periods[activeIndex - 1].Month;
			}
			if (activeIndex < periods.length - 1) {
				shouldUpdate = true;
				newActivePeriod.nextPeriod = activePeriod.period === 'year' ? periods[activeIndex + 1].Year : periods[activeIndex + 1].Month;
			}
			if (shouldUpdate === true) {
				setActivePeriod(newActivePeriod);
			}
		}
	}, [activePeriod]);

	useEffect(() => {
		if (usageStatus !== 'idle' || meterData == null) {
			fetchMeterData();
		}
	}, [activeUtility]);

	const renderUtilityBadge = (utility) => {
		return (
			<Badge
				className={`badge${utility}${activeUtility === utility ? ' active' : ''}`}
				onClick={() => {
					handleChangeUtility(utility);
				}}
				key={utility}
			>
				{utility}
			</Badge>
		);
	};

	if (getCurrentStatus() === 'loading') {
		return (
			<S.SpinnerWrapper>
				<Spinner />
			</S.SpinnerWrapper>
		);
	} else if (getCurrentStatus() === 'error') {
		if (billingMode) {
			return <EmptyComponent icon={['fal', 'credit-card']} title="No Billing History" description="Check back to view your recent Utilities and Greenlight bills." />;
		}
		return <EmptyComponent icon={['fal', 'chart-bar']} title="No Consumption Data" description="Check back to view billing and consumption data." />;
	}

	return (
		<S.Wrapper className={className}>
			<S.HeaderWrapper>
				{!hidePeriodSelector && (
					<S.HeaderLeft>
						<ButtonToggle options={options} setOptions={setOptions} size="small" color="neutralButton" />
					</S.HeaderLeft>
				)}
				{!billingMode && (
					<S.HeaderCenter>
						<IconButton icon={['fal', 'angle-left']} style={{ visibility: activePeriod?.previousPeriod ? 'visible' : 'hidden' }} onClick={() => pushCurrentPeriod(-1)} />
						<Typography
							tag="h4"
							weight="bold"
							onClick={() => {
								if (activePeriod?.period === 'day') {
									setDatePickerOpen(true);
								}
							}}
						>
							{activePeriod?.currentPeriod}
						</Typography>
						{activePeriod?.period === 'day' && (
							<S.DatePickerContainer>
								<ReactDatePicker
									selected={moment(activePeriod?.currentPeriod, 'DD MMMM YYYY', true).isValid() ? moment(activePeriod?.currentPeriod, 'DD MMMM YYYY', true).toDate() : null}
									className="datePicker"
									popperPlacement="bottom"
									open={datePickerOpen}
									onClickOutside={() => {
										if (activePeriod?.period === 'day') {
											setDatePickerOpen(false);
										}
									}}
									includeDates={meterData?.UsageDays?.map((dateObj) => new Date(dateObj.UsageDate)) || []}
									onChange={(date) => {
										jumpToPeriod(date);
										setDatePickerOpen(false);
									}}
								/>
							</S.DatePickerContainer>
						)}
						<IconButton icon={['fal', 'angle-right']} style={{ visibility: activePeriod?.nextPeriod ? 'visible' : 'hidden' }} onClick={() => pushCurrentPeriod(1)} />
					</S.HeaderCenter>
				)}
			</S.HeaderWrapper>
			<S.ChartWrapper billingMode={billingMode}>
				<ResponsiveContainer>
					<ComposedChart data={getDataForPeriod()} margin={{ left: -12 }}>
						<CartesianGrid stroke={colors.neutralForm} />
						<XAxis
							dataKey={activePeriod?.period === 'day' && !billingMode ? 'UsageTime' : 'UsageDate'}
							stroke={colors.neutralForm}
							tickFormatter={(value) => {
								if (!value || value === 'auto') return null;
								if (billingMode) {
									try {
										return format(new Date(value), 'MMM ’yy');
									} catch (e) {
										const dateObj = moment(value, 'HH:mm:ss');
										return format(new Date(dateObj), 'h:mm a');
									}
								}
								if (activePeriod.period === 'year' || activePeriod.period === 'month') {
									let dateFormat = 'MMM';
									if (activePeriod.period === 'month') dateFormat = 'MMM d';
									try {
										return format(new Date(value), dateFormat);
									} catch (e) {
										const dateObj = moment(value, 'HH:mm:ss');
										return format(new Date(dateObj), 'h:mm a');
									}
								} else {
									const dateObj = moment(value, 'HH:mm:ss');
									return format(new Date(dateObj), 'h:mm a');
								}
							}}
						/>
						<YAxis
							yAxisId="left"
							stroke={colors.neutralForm}
							tickFormatter={(value) => {
								return activeSource !== 'Cost' && !billingMode ? value : `$${value}`;
							}}
						>
							<Label
								style={{
									textAnchor: 'middle',
									fontSize: '80%',
									fill: colors.neutralForm,
								}}
								angle={270}
								dx={-20}
								value={(() => {
									if (billingMode) return 'Bill Amount ($)';
									return getActiveSource().id === 'default' ? `${dataKey} (${meterData?.Units})` : 'Cost ($)';
								})()}
							/>
						</YAxis>
						{!billingMode && showTemperature && (
							<YAxis yAxisId="right" orientation="right" stroke={colors.neutralForm}>
								<Label
									style={{
										textAnchor: 'middle',
										fontSize: '80%',
										fill: colors.neutralForm,
									}}
									angle={270}
									dx={6}
									value={'Temperature (°F)'}
								/>
							</YAxis>
						)}
						<Tooltip
							formatter={(value, name) => {
								return name !== 'Cost' && !billingMode ? value : `$${value}`;
							}}
							labelFormatter={(value) => {
								if (!value || value === 'auto') return null;
								if (billingMode) {
									try {
										return format(new Date(value), 'MMM ’yy');
									} catch (e) {
										const dateObj = moment(value, 'HH:mm:ss');
										return format(new Date(dateObj), 'h:mm a');
									}
								}
								if (activePeriod.period === 'year' || activePeriod.period === 'month') {
									let dateFormat = 'MMM';
									if (activePeriod.period === 'month') dateFormat = 'MMM d';
									try {
										return format(new Date(value), dateFormat);
									} catch (e) {
										const dateObj = moment(value, 'HH:mm:ss');
										return format(new Date(dateObj), 'h:mm a');
									}
								} else {
									const dateObj = moment(value, 'HH:mm:ss');
									return format(new Date(dateObj), 'h:mm a');
								}
							}}
						/>
						{billingMode && currentLocation?.UtilityAccount != null && <Bar yAxisId="left" dataKey="UtilityAmount" fill={colors.accentWater} label="Utility Amount" name="Utility Amount" />}
						{billingMode && currentLocation?.GreenlightAccount != null && <Bar yAxisId="left" dataKey="GreenlightAmount" fill={colors.accentGas} label="Greenlight Amount" name="Greenlight Amount" />}
						{!billingMode && <Bar yAxisId="left" dataKey={activeSource} fill={colors[`accent${activeUtility}`]} unit={activeSource !== 'Cost' ? ` ${meterData?.Units}` : ''} />}
						{!billingMode && showTemperature && activePeriod?.period === 'day' && <Line yAxisId="right" dataKey="Temperature" name="Temperature" fill={colors.brandPrimary} stroke={colors.brandPrimary} unit={'°F'} />}
						{!billingMode && showTemperature && (activePeriod?.period === 'year' || activePeriod?.period === 'month') && (
							<Line yAxisId="right" dataKey="TempLow" name={activePeriod?.period === 'day' ? 'Low Temperature' : 'Avg. Low Temperature'} fill={colors.brandPrimary} stroke={colors.brandPrimary} unit={'°F'} />
						)}
						{!billingMode && showTemperature && (activePeriod?.period === 'year' || activePeriod?.period === 'month') && (
							<Line yAxisId="right" dataKey="TempHigh" name={activePeriod?.period === 'day' ? 'High Temperature' : 'Avg. High Temperature'} fill={colors.statusBad} stroke={colors.statusBad} unit={'°F'} />
						)}
					</ComposedChart>
				</ResponsiveContainer>
			</S.ChartWrapper>
			{!billingMode && (
				<S.SettingsWrapper>
					<Typography tag="h5" weight="bold">
						Settings
					</Typography>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Utilities
						</Typography>
						<S.BadgeWrapper>
							{utilities.map((utility) => {
								return renderUtilityBadge(utility);
							})}
						</S.BadgeWrapper>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Compare
						</Typography>
						<Checkbox label="Temperature" id="temperature" defaultChecked onChange={handleTemperatureCompare} />
					</S.ItemWrapper>
				</S.SettingsWrapper>
			)}
		</S.Wrapper>
	);
};

ConsumptionUsage.propTypes = {
	utilities: PropTypes.arrayOf(PropTypes.string),
	data: PropTypes.shape(),
	dataKey: PropTypes.string,
	className: PropTypes.string,
	hidePeriodSelector: PropTypes.bool,
	billingMode: PropTypes.bool,
};
