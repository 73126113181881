import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import logo from '../../assets/wilson-logo.png';
import * as S from './MaintenanceModeWrapper.styles';
import { Typography } from '../Typography';
import { META_TITLE } from '../../utils/constants';

export const MaintenanceModeWrapper = forwardRef(({ children }, ref) => {
	const configuration = useSelector((state) => state.configuration.value);

	useEffect(() => {
		if (configuration?.enableMaintenanceMode === true) {
			// Set document title
			document.title = `Maintenance Mode | ${META_TITLE}`;
		}
	}, [configuration]);

	return configuration?.enableMaintenanceMode !== true ? (
		children
	) : (
		<S.Wrapper ref={ref}>
			<S.Container>
				<S.Image src={logo} alt="" />
				<Typography tag="h3" weight="bold" center>
					MyWilson is currently conducting regularly scheduled system maintenance. We apologize for any inconvenience, appreciate your patience and will restore MyWilson as soon as possible.
				</Typography>
				<Typography tag="p" weight="semibold" center>
					Please call Wilson Express Pay at <a href="tel:2523992200">(252) 399-2200</a>, option 1 to make immediate payment.
				</Typography>
			</S.Container>
		</S.Wrapper>
	);
});

MaintenanceModeWrapper.displayName = 'MaintenanceModeWrapper';

MaintenanceModeWrapper.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
