import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Topbar = styled.div`
	background-color: white;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: 0.6em 1em;
	position: relative;
	z-index: 99999999;
	width: 100%;

	@media screen and (min-width: 768px) {
		padding: 1em 1em;
	}
`;

export const NavigationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const AccountBar = styled.div`
	margin: 1rem auto 0px;
	width: 100%;
	text-align: center;

	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const Picture = styled.picture`
	display: flex;
	max-width: 4.25em;

	img {
		width: 100%;
		object-fit: contain;
	}

	@media screen and (min-width: 768px) {
		display: none;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;

	${({ alignEnd }) =>
		alignEnd &&
		css`
			justify-content: flex-end;
		`}

	& > button {
		color: ${colors.brandPrimary};
	}

	& > * + * {
		margin-left: 1em;

		@media screen and (min-width: 768px) {
			margin-left: 1.5em;
		}
	}
`;

export const Modal = styled.div`
	position: fixed;
	display: none;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: white;
	top: 3.75rem;

	@media screen and (min-width: 768px) {
		top: 3.9375rem;
		left: auto;
		right: 1rem;
		max-width: 22rem;
		background-color: transparent;
		pointer-events: none;
	}

	${({ opened }) =>
		opened &&
		css`
			display: block;
		`}
`;
