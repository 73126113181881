import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageHeader, Tab, ProfileInformation, AccountInformation, AdminNotificationsPreferences } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './AdminProfile.styles';

const ProfileContent = () => {
	const user = useSelector((state) => state.user.value);

	return (
		<S.GridWrapper>
			<ProfileInformation className="a1" user={user} isStaff />
			<AccountInformation className="a2" user={user} isStaff />
		</S.GridWrapper>
	);
};

const SettingsContent = () => {
	return (
		<S.GridWrapper>
			<AdminNotificationsPreferences className="a1" />
		</S.GridWrapper>
	);
};

export default function AdminProfile() {
	const [tabs, setTabs] = useState([
		{ id: 1, label: 'Profile', component: <ProfileContent /> },
		//{ id: 2, label: 'Settings', component: <SettingsContent /> },
	]);

	useEffect(() => {
		// Set document title
		document.title = `My Profile | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<PageHeader>Account</PageHeader>
			<Tab tabs={tabs} setTabs={setTabs} transparent />
		</S.Wrapper>
	);
}
