import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { required } from '../../utils/form-default-errors';
import { errorHandler, login, loginStaff } from '../../services/authenticationService';
import { fetchUser } from '../../app/slices/user/userSlice';

import * as S from './AuthenticationModal.styles';

import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { TextInput } from '../TextInput';
import { Button } from '../Button';
import { PLACEHOLDER_STAFF_DETAILS, PLACEHOLDER_USER_DETAILS } from '../../utils/constants';

const AuthenticationModal = ({ isOpen, onRequestClose, authenticationActions }) => {
	let user = useSelector((state) => state.user.value);

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		reset,
	} = useForm();

	const dispatch = useDispatch();

	async function onSubmit(data) {
		const role = window.localStorage.getItem('wilsonnc_role') || 'customer';
		try {
			if (role !== 'customer') {
				const newUser = await loginStaff({ email: user?.email, password: data.password });
				window.localStorage.setItem('wilsonnc_token', newUser.token || newUser.LoginToken);
				window.localStorage.setItem(
					'wilsonnc_user',
					JSON.stringify({
						...newUser,
						onboardingComplete: true,
						role,
						token: user.LoginToken,
					})
				);
				authenticationActions();
				dispatch(fetchUser({ role, placeholderUser: PLACEHOLDER_STAFF_DETAILS }));
			} else {
				const token = await login({ email: user?.email || user?.altEmail, password: data.password });
				window.localStorage.setItem('wilsonnc_token', token);
				authenticationActions();
				//dispatch(fetchUser({ placeholderUser: PLACEHOLDER_USER_DETAILS }));
			}
			onRequestClose();
		} catch (e) {
			errorHandler(e);
			reset(undefined, { keepValues: true });
		}
	}

	useEffect(() => {
		if (isOpen === true) {
			reset();
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal isOpen={isOpen} allowScroll onRequestClose={onRequestClose}>
			<S.ModalWrapper>
				<S.TitleWrapper>
					<Typography tag="h1" weight="bold" center>
						Account Security
					</Typography>
					<Typography tag="p" center style={{ marginTop: '10px' }}>
						Your current session has expired to protect your account. Please enter your password to continue.
					</Typography>
				</S.TitleWrapper>
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<TextInput
						label="Password"
						id="password"
						type="password"
						error={errors.password}
						autoComplete="current-password"
						{...register('password', {
							required: required('Password'),
						})}
					/>
					<S.FormButtonWrapper>
						<Button>
							<Typography tag="span" variation="1" weight="extrablack">
								{isSubmitting ? 'Loading...' : 'Log In'}
							</Typography>
						</Button>
					</S.FormButtonWrapper>
				</S.Form>
			</S.ModalWrapper>
		</Modal>
	);
};
export default AuthenticationModal;

AuthenticationModal.propTypes = {
	isOpen: PropTypes.bool,
	onRequestClose: PropTypes.func,
	authenticationActions: PropTypes.func,
};
