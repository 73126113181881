import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;

	.red {
		color: ${colors.statusBad};
	}
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 1rem;
`;

export const ApplicationGrid = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: repeat(1, 1fr);
	margin-top: 1rem;
	padding-bottom: 1.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid ${colors.neutralForm};

	grid-template-areas:
		'firstName'
		'lastName'
		'serviceAddressToConnect'
		'mailingAddress'
		'driverLicense'
		'ssn'
		'employer'
		'homePhone'
		'workPhone'
		'cellPhone'
		'movingFromAddress'
		'movingDate';

	.item-firstName {
		grid-area: firstName;
	}
	.item-lastName {
		grid-area: lastName;
	}
	.item-serviceAddressToConnect {
		grid-area: serviceAddressToConnect;
	}
	.item-mailingAddress {
		grid-area: mailingAddress;
	}
	.item-driverLicense {
		grid-area: driverLicense;
	}
	.item-ssn {
		grid-area: ssn;
	}
	.item-employer {
		grid-area: employer;
	}
	.item-homePhone {
		grid-area: homePhone;
	}
	.item-workPhone {
		grid-area: workPhone;
	}
	.item-cellPhone {
		grid-area: cellPhone;
	}
	.item-movingFromAddress {
		grid-area: movingFromAddress;
	}
	.item-movingDate {
		grid-area: movingDate;
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);

		grid-template-areas:
			'firstName firstName lastName lastName'
			'serviceAddressToConnect serviceAddressToConnect mailingAddress mailingAddress'
			'driverLicense ssn employer employer'
			'homePhone workPhone cellPhone .'
			'movingFromAddress movingFromAddress movingDate .';
	}
`;

export const ApplicationGrid1 = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: repeat(1, 1fr);
	margin-top: 1rem;
	//margin-bottom: -2.5rem;

	grid-template-areas:
		'firstName '
		'lastName'
		'businessName';

	.item-firstName {
		grid-area: firstName;
	}
	.item-lastName {
		grid-area: lastName;
	}
	.item-businessName {
		grid-area: businessName;
	}
	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'firstName lastName'
			'businessName businessName';
	}
`;

export const CoApplicationGrid = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: repeat(1, 1fr);
	margin-top: 1rem;

	grid-template-areas:
		'coApplicantFirstName'
		'coApplicantLastName'
		'coApplicantDriverLicense'
		'coApplicantSsn'
		'coApplicantEmployer';

	.item-coApplicantFirstName {
		grid-area: coApplicantFirstName;
	}
	.item-coApplicantLastName {
		grid-area: coApplicantLastName;
	}
	.item-coApplicantDriverLicense {
		grid-area: coApplicantDriverLicense;
	}
	.item-coApplicantSsn {
		grid-area: coApplicantSsn;
	}
	.item-coApplicantEmployer {
		grid-area: coApplicantEmployer;
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);

		grid-template-areas:
			'coApplicantFirstName coApplicantFirstName coApplicantLastName coApplicantLastName'
			'coApplicantDriverLicense coApplicantSsn coApplicantEmployer coApplicantEmployer';
	}
`;

export const DocsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	& > * {
		border-bottom: 1px solid ${colors.neutralForm};
		padding-bottom: 1.5rem;
		margin-top: 1rem;

		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
		}
	}
`;

export const ApplicationTypeSection = styled.div`
	margin: 5px 0 25px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
