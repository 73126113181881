import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 0.5rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const ToggleItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-top: 0.125rem;
	}
`;

export const BillingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;
	border-bottom: 1px solid ${colors.neutralForm};

	button {
		padding: 0;
		border-radius: 0.25rem;
	}

	& > * + * {
		margin-top: 1rem;
	}

	&:last-child {
		padding-bottom: 0;
		border: none;
	}
`;

export const CardWrapper = styled.div`
	padding-top: 0.5rem;

	.flex {
		& > * + * {
			margin-left: 0.75rem;
		}

		svg {
			color: ${colors.brandPrimary};
			width: 1.25rem !important;
			height: 1.25rem !important;
		}

		button {
			margin-left: 1.25rem;
		}
	}
`;

export const InfoIcon = styled(FontAwesomeIcon)`
	margin-left: 10px;
	cursor: pointer;
	width: 1.25rem !important;
	height: 1.25rem !important;
`;
