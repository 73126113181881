import React, { forwardRef, useRef } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import colors from '../../styles/colors';
import * as S from './Select.styles';

export const Select = forwardRef(({ placeholder = '', options = [], label, error, ...rest }, ref) => {
	const colourStyles = {
		clearIndicator: (styles) => ({ ...styles }),
		container: (styles) => ({ ...styles }),
		control: (styles, { isFocused }) => ({
			...styles,
			borderRadius: '0.25em',
			minHeight: 40,
			fontSize: '1.125em',
			borderColor: error ? colors.statusBad : isFocused ? 'black' : colors.neutralForm,
			boxShadow: 'none',
			'&:hover': { borderColor: error ? colors.statusBad : isFocused ? 'black' : colors.neutralForm },
			cursor: 'pointer',
			zIndex: 9999999,
			overflow: 'visible',
		}),
		dropdownIndicator: (styles, { isFocused }) => ({ ...styles, padding: 0, color: error ? colors.statusBad : isFocused ? 'black' : colors.neutralForm }),
		group: (styles) => ({ ...styles }),
		groupHeading: (styles) => ({ ...styles }),
		indicatorsContainer: (styles) => ({ ...styles, padding: '0 0.75rem' }),
		indicatorSeparator: (styles) => ({ ...styles, width: 0 }),
		input: (styles) => ({ ...styles, width: '100%', padding: 0, margin: 0 }),
		loadingIndicator: (styles) => ({ ...styles }),
		loadingMessage: (styles) => ({ ...styles }),
		menu: (styles) => ({ ...styles, zIndex: 9999, overflow: 'visible' }),
		menuList: (styles) => ({ ...styles }),
		menuPortal: (styles) => ({ ...styles, zIndex: 9999, overflow: 'visible' }),
		multiValue: (styles) => ({ ...styles }),
		multiValueLabel: (styles) => ({ ...styles }),
		multiValueRemove: (styles) => ({ ...styles }),
		noOptionsMessage: (styles) => ({ ...styles }),
		option: (styles) => ({ ...styles, cursor: 'pointer' }),
		placeholder: (styles) => ({ ...styles, padding: 0, margin: 0 }),
		singleValue: (styles) => ({ ...styles, padding: 0, margin: 0, color: 'black' }),
		valueContainer: (styles) => ({ ...styles, padding: '0 0.75rem', margin: 0 }),
	};

	const selectRef = useRef(null);

	return (
		<S.Wrapper error={error}>
			{label && (
				<Typography tag="label" onClick={() => (ref ? ref.current.focus() : selectRef.current.focus())}>
					{label}
				</Typography>
			)}
			<ReactSelect ref={ref || selectRef} placeholder={placeholder} options={options} {...rest} styles={colourStyles} />
			{error && error.message && (
				<S.FloatingWrapper>
					<Typography tag="span" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

Select.displayName = 'Select';
Select.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
};
