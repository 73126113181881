import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';

import { Button, Typography, PageHeader, Tab, Card, Spinner, ErrorComponent, ConsumptionUsage, ButtonToggle, Table } from '../../components';
import { formatToDayMonth2DigitYear } from '../../utils/time-formatter';
import { formatToUSD } from '../../utils/currency-formatter';
import * as S from './ServicesElectric.styles';
import { META_TITLE } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { retrieveDailyUsageHistoryWithRange } from '../../services/userService';
import { calculate30DayAverage, calculateDailyAverage, calculate30DayMax, formatMeterData } from '../../utils/usage-demand';

const ConsumptionContent = ({ meterData }) => {
	const [options, setOptions] = useState([
		{ id: 'graph', icon: ['fal', 'chart-bar'], selected: true },
		{ id: 'list', icon: ['fal', 'list'], selected: false },
	]);
	const getActiveOption = () => {
		return options.find((option) => {
			return option.selected === true;
		});
	};

	return (
		<S.GridWrapper>
			<Card className="a1">
				<Typography tag="h6" weight="semibold">
					Avg. Daily Usage
				</Typography>
				<Typography tag="h2" weight="bold">
					{`${Math.round(calculateDailyAverage(meterData?.UsageDays, 'Usage')) || 'N/A'} ${meterData?.Units || ''}`}
				</Typography>
			</Card>
			<Card className="a2">
				<Typography tag="h6" weight="semibold">
					Avg. 30 Day Usage
				</Typography>
				<Typography tag="h2" weight="bold">
					{`${Math.round(calculate30DayAverage(meterData?.UsageDays, 'Usage')) || 'N/A'} ${meterData?.Units || ''}`}
				</Typography>
			</Card>
			<Card
				className="a3"
				title="Consumption"
				action={[
					{
						id: 1,
						component: <ButtonToggle options={options} setOptions={setOptions} size="small" key="toggle" />,
					},
				]}
			>
				{getActiveOption()?.id === 'graph' && <ConsumptionUsage utilities={['Electric']} data={meterData} dataKey="Usage" showAllTime />}
				{getActiveOption()?.id === 'list' && (
					<Table
						columns={[
							{
								Header: 'Period',
								accessor: 'Month',
							},
							{
								Header: 'Number of Days',
								accessor: 'numberOfDays',
							},
							{
								Header: 'Consumption',
								accessor: 'Usage',
							},
						]}
						data={meterData?.UsageMonths.map((month) => {
							return { ...month, Usage: `${Math.round(month.Usage)} ${meterData.Units}`, numberOfDays: month.Days.length };
						})}
						withoutSearch
					/>
				)}
			</Card>
		</S.GridWrapper>
	);
};

ConsumptionContent.propTypes = {
	meterData: PropTypes.shape(),
};

const DemandContent = ({ meterData }) => {
	const [options, setOptions] = useState([
		{ id: 'graph', icon: ['fal', 'chart-bar'], selected: true },
		{ id: 'list', icon: ['fal', 'list'], selected: false },
	]);
	const getActiveOption = () => {
		return options.find((option) => {
			return option.selected === true;
		});
	};

	return (
		<S.GridWrapper>
			<Card className="a1">
				<Typography tag="h6" weight="semibold">
					Avg. Daily Demand
				</Typography>
				<Typography tag="h2" weight="bold">
					{`${Math.round(calculateDailyAverage(meterData?.UsageDays, 'MaxDemand')) || 'N/A'} ${'kW' || ''}`}
				</Typography>
			</Card>
			<Card className="a2">
				<Typography tag="h6" weight="semibold">
					30 Day Max Demand
				</Typography>
				<Typography tag="h2" weight="bold">
					{`${Math.round(calculate30DayMax(meterData?.UsageDays, 'MaxDemand')) || 'N/A'} ${'kW' || ''}`}
				</Typography>
			</Card>
			<Card
				className="a3"
				title="Demand"
				action={[
					{
						id: 1,
						component: <ButtonToggle options={options} setOptions={setOptions} size="small" key="toggle" />,
					},
				]}
			>
				{getActiveOption()?.id === 'graph' && <ConsumptionUsage utilities={['Electric']} data={meterData} dataKey="MaxDemand" showAllTime />}
				{getActiveOption()?.id === 'list' && (
					<Table
						columns={[
							{
								Header: 'Period',
								accessor: 'Month',
							},
							{
								Header: 'Number of Days',
								accessor: 'numberOfDays',
							},
							{
								Header: 'Demand',
								accessor: 'MaxDemand',
							},
						]}
						data={meterData?.UsageMonths.map((month) => {
							return { ...month, MaxDemand: `${Math.round(month.MaxDemand)} ${meterData.Units}`, numberOfDays: month.Days.length };
						})}
						withoutSearch
					/>
				)}
			</Card>
		</S.GridWrapper>
	);
};

DemandContent.propTypes = {
	meterData: PropTypes.shape(),
};

const BillingContent = ({ meterData }) => {
	const [options, setOptions] = useState([
		{ id: 'graph', icon: ['fal', 'chart-bar'], selected: true },
		{ id: 'list', icon: ['fal', 'list'], selected: false },
	]);
	const getActiveOption = () => {
		return options.find((option) => {
			return option.selected === true;
		});
	};

	return (
		<S.GridWrapper>
			<Card className="a1">
				<Typography tag="h6" weight="semibold">
					Avg. Daily Cost
				</Typography>
				<Typography tag="h2" weight="bold">
					{formatToUSD(Math.round(calculateDailyAverage(meterData?.UsageDays, 'Cost')))}
				</Typography>
			</Card>
			<Card className="a2">
				<Typography tag="h6" weight="semibold">
					Avg. 30 Day Cost
				</Typography>
				<Typography tag="h2" weight="bold">
					{formatToUSD(Math.round(calculate30DayAverage(meterData?.UsageDays, 'Cost')))}
				</Typography>
			</Card>
			<Card
				className="a3"
				title="Costs"
				action={[
					{
						id: 1,
						component: <ButtonToggle options={options} setOptions={setOptions} size="small" key="toggle" />,
					},
				]}
			>
				{getActiveOption()?.id === 'graph' && <ConsumptionUsage utilities={['Electric']} data={meterData} dataKey="Cost" showAllTime />}
				{getActiveOption()?.id === 'list' && (
					<Table
						columns={[
							{
								Header: 'Period',
								accessor: 'Month',
							},
							{
								Header: 'Number of Days',
								accessor: 'numberOfDays',
							},
							{
								Header: 'Cost',
								accessor: 'Cost',
							},
						]}
						data={meterData?.UsageMonths.map((month) => {
							return { ...month, Cost: formatToUSD(Math.round(month.Cost)), numberOfDays: month.Days.length };
						})}
						withoutSearch
					/>
				)}
			</Card>
		</S.GridWrapper>
	);
};

BillingContent.propTypes = {
	meterData: PropTypes.shape(),
};

export default function ServicesElectric() {
	const history = useHistory();
	const { meterNumber } = useParams();
	const [status, setStatus] = useState('loading');
	const [meterData, setMeterData] = useState(null);
	const currentLocation = useSelector((state) => state.location.value);
	const tabContent = [
		{ id: 1, label: 'Consumption', component: <ConsumptionContent meterData={meterData} /> },
		{ id: 2, label: 'Demand', component: <DemandContent meterData={meterData} /> },
		/*{ id: 3, label: 'Billing', component: <BillingContent meterData={meterData} /> },*/
	];
	const [tabs, setTabs] = useState(tabContent);

	async function init() {
		if (currentLocation?.UtilityAccount?.AccountKey) {
			try {
				const toDate = moment().format('YYYY-MM-DD');
				const fromDate = moment().subtract(3, 'years').format('YYYY-MM-DD');
				const meters = await retrieveDailyUsageHistoryWithRange({ accountKey: currentLocation?.UtilityAccount?.AccountKey, fromDate, toDate });
				let meter = meters.find((data) => data.MeterNumber === meterNumber);
				if (meter != null) {
					setMeterData(formatMeterData(meter));
					setStatus('success');
				} else {
					setStatus('error');
				}
			} catch (e) {
				toast.error(e.message);
				setStatus('error');
			}
		} else {
			setStatus('error');
		}
	}

	useEffect(() => {
		setTabs(tabContent);
	}, [meterData]);

	useEffect(() => {
		// Set document title
		document.title = `Electric | Services | ${META_TITLE}`;

		// Initialize data
		init();
	}, []);

	const renderContent = () => {
		if (status === 'loading') {
			return (
				<S.FullWrapper>
					<Spinner />
				</S.FullWrapper>
			);
		} else if (status === 'error') {
			return (
				<S.FullWrapper>
					<ErrorComponent />
				</S.FullWrapper>
			);
		}
		return (
			<S.Wrapper>
				<S.HeaderWrapper>
					<Button variant="text" onClick={() => history.push('/services')}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography tag="span" variation="2" weight="extrablack">
							Return to Services
						</Typography>
					</Button>
					<PageHeader>
						<S.PageHeader>
							<S.UtilityIcon image={`${process.env.PUBLIC_URL}/icons/electricity.jpg`} />
							Electric
						</S.PageHeader>
					</PageHeader>
					<S.InfoWrapper>
						<S.InfoItem>
							<Typography tag="h6" weight="semibold">
								Meter Number
							</Typography>
							<Typography tag="span">{meterNumber}</Typography>
						</S.InfoItem>
						<S.InfoItem>
							<Typography tag="h6" weight="semibold">
								Last Read Date
							</Typography>
							<Typography tag="span">{meterData && meterData.UsageDays.length > 0 ? formatToDayMonth2DigitYear(new Date(meterData.UsageDays[meterData.UsageDays.length - 1].UsageDate)) : 'N/A'}</Typography>
						</S.InfoItem>
					</S.InfoWrapper>
				</S.HeaderWrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		);
	};

	return renderContent();
}
