import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: block;
	position: relative;
	z-index: 3;
`;

export const Button = styled.button.attrs({ type: 'button' })`
	appearance: none;
	display: flex;
	align-items: center;
	color: black;
	background-color: transparent;
	border: none;
	overflow: hidden;

	svg {
		color: ${colors.brandPrimary};
		width: 2rem !important;
		height: 2rem !important;
		margin-left: 0.5rem;
		transform: ${({ opened }) => (opened ? 'rotateX(180deg)' : 'rotateX(0deg)')};
	}
`;

export const Modal = styled.div`
	position: absolute;
	display: none;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	width: 19rem; /* 304px */
	top: calc(100% + 0.95rem);
	right: 0;
	background-color: white;

	${({ opened }) =>
		opened &&
		css`
			display: block;
		`}

	ul {
		margin: 0;
		list-style: none;

		li {
			height: 3.3rem;
			&:last-child {
				border-top: 1px solid ${colors.neutralForm};
				padding-top: 0.2rem;
			}

			button {
				width: 100%;
				background-color: transparent;
				border: none;
				display: flex;
				align-items: center;
				color: black;
				padding: 0.625rem 1rem;

				.spacer {
					width: 1.5rem;
					height: 1.5rem;
				}

				svg {
					color: ${colors.brandPrimary};
					width: 1.3rem !important;
					height: 1.3rem !important;
				}

				h5,
				span {
					display: block;
					flex: 1;
					text-align: left;
					margin: 0 0.8rem;
				}

				span {
					color: ${colors.brandPrimary};
				}

				&:hover {
					opacity: 0.8;
				}

				&.last {
					letter-spacing: 1.5px;
					padding: 0.9375rem 1rem;

					svg {
						width: 1rem !important;
						height: 1rem !important;
					}
				}
			}
		}
	}
`;

export const Avatar = styled.div`
	display: grid;
	appearance: none;
	place-items: center;
	background-color: ${colors.brandPrimary};
	color: white;
	width: 1.875em;
	height: 1.875em;
	border-radius: 100%;
	border: none;

	& > h6 {
		color: white;
	}

	&:hover {
		opacity: 0.8;
	}

	@media screen and (min-width: 768px) {
		width: 2em;
		height: 2em;
	}
`;
