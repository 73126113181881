import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	newCustomer: {
		residential: {
			application: {},
			utilities: {},
			greenLight: {},
		},
		commercial: {
			application: {},
			utilities: {},
		},
	},
	currentCustomer: {
		residential: {
			profileInformation: {},
			billingSetup: {},
		},
	},
};

export const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		newCustomerResidentialApplication: (state, action) => {
			state.newCustomer.residential.application = action.payload;
		},
		newCustomerResidentialUtilities: (state, action) => {
			state.newCustomer.residential.utilities = action.payload;
		},
		newCustomerResidentialGreenLight: (state, action) => {
			state.newCustomer.residential.greenLight = action.payload;
		},
		newCustomerCommercialApplication: (state, action) => {
			state.newCustomer.commercial.application = action.payload;
		},
		newCustomerCommercialUtilities: (state, action) => {
			state.newCustomer.commercial.utilities = action.payload;
		},
		currentCustomerResidentialProfileInformation: (state, action) => {
			state.currentCustomer.residential.profileInformation = action.payload;
		},
		currentCustomerResidentialBillingSetup: (state, action) => {
			state.currentCustomer.residential.billingSetup = action.payload;
		},
		clearOnboarding: (state) => {
			state = initialState;
		},
	},
});

export const { newCustomerResidentialApplication, newCustomerResidentialUtilities, newCustomerResidentialGreenLight, newCustomerCommercialApplication, newCustomerCommercialUtilities, currentCustomerResidentialProfileInformation, currentCustomerResidentialBillingSetup, clearOnboarding } =
	onboardingSlice.actions;

export default onboardingSlice.reducer;
