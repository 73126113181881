import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Modal, Button, DatePicker, RangeSlider, Checkbox } from '../../components';
import * as S from './InvoiceHistoryFilterModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useForm } from 'react-hook-form';
import { required } from '../../utils/form-default-errors';
import { FILTER_MAXIMUM_RANGE } from '../../utils/constants';

const InvoiceHistoryFilterModal = ({ isOpen, setIsOpen, setFilterSelectedOptions }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [rangevalue, setRangeValue] = useState([0, FILTER_MAXIMUM_RANGE]);
	const [isUtilities, setIsUtilities] = useState(true);
	const [isGreenlight, setIsGreenlight] = useState(true);

	const handleChange = (event, newValue) => {
		setRangeValue(newValue);
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		getValues,
	} = useForm({
		defaultValues: {},
	});

	const onSubmit = () => {
		setFilterSelectedOptions({
			startDate,
			endDate,
			amount: {
				min: rangevalue[0],
				max: rangevalue[1],
			},
			isUtilities,
			isGreenlight,
		});
		setIsOpen(false);
	};

	const handleResetAllFilters = () => {
		setStartDate(null);
		setEndDate(null);
		setRangeValue([0, FILTER_MAXIMUM_RANGE]);
		setIsUtilities(null);
		setIsGreenlight(null);

		setFilterSelectedOptions({
			startDate: null,
			endDate: null,
			amount: {
				min: 0,
				max: FILTER_MAXIMUM_RANGE,
			},
			isUtilities: true,
			isGreenlight: true,
		});
	};

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<S.ModalForm onSubmit={handleSubmit(onSubmit)}>
				<S.Wrapper>
					<Typography tag={isHigherThan768 ? 'h1' : 'h3'} weight="bold" center>
						Filter Invoice History
					</Typography>
					<S.DatePickerContainer>
						<DatePicker
							{...register('startDate')}
							label="Start Date"
							selectsRange={false}
							selected={startDate}
							onChange={(update) => {
								setStartDate(update);
							}}
						/>
						<DatePicker
							{...register('endDate')}
							label="End Date"
							selectsRange={false}
							selected={endDate}
							onChange={(update) => {
								setEndDate(update);
							}}
						/>
					</S.DatePickerContainer>
					<S.SliderContainer>
						<RangeSlider label="Amount" min={0} max={FILTER_MAXIMUM_RANGE} value={rangevalue} onChange={handleChange} />
					</S.SliderContainer>
					<S.Checkboxes>
						<Typography tag="h6" weight="semibold">
							Bill Type
						</Typography>
						<Checkbox label="Utility" checked={isUtilities} onChange={() => setIsUtilities(isUtilities === true ? false : true)} />
						<Checkbox label="Greenlight" checked={isGreenlight} onChange={() => setIsGreenlight(isGreenlight === true ? false : true)} />
					</S.Checkboxes>
				</S.Wrapper>
				<S.Footer>
					<S.ResetBtn onClick={handleResetAllFilters}>Reset All Filters</S.ResetBtn>
					<S.BtnWrapper>
						<S.BtnCancel>
							<Button type="button" variant="outline" onClick={() => setIsOpen(false)} variation="secondary">
								<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
									Cancel
								</Typography>
							</Button>
						</S.BtnCancel>
						<Button type="submit" variant="solid">
							<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
								Filter
							</Typography>
						</Button>
					</S.BtnWrapper>
				</S.Footer>
			</S.ModalForm>
		</Modal>
	);
};
export default InvoiceHistoryFilterModal;

InvoiceHistoryFilterModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func,
	setFilterSelectedOptions: PropTypes.func,
};
