import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;

	.page-header {
		grid-area: header;
	}
	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}

	grid-template-areas: ${({ showScheduled }) =>
		showScheduled
			? css`
				'header'
				'a1'
				'a2'
			`
			: css`
				'header'
				'a2'
			`};
`;
