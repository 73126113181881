import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './Stepper.styles';

export const Stepper = ({ activeStep = 0, steps = [] }) => {
	return (
		<S.Wrapper>
			<S.Container>
				<S.ProgressBar>{steps.map(({ id }, index) => (index === 0 ? null : <S.Line key={id} status={activeStep >= index ? 'completed' : null} />))}</S.ProgressBar>
				{steps.map(({ id, label }, index) => (
					<S.Dot key={id} status={activeStep === index ? 'active' : activeStep > index ? 'completed' : null}>
						<span>{label}</span>
					</S.Dot>
				))}
			</S.Container>
		</S.Wrapper>
	);
};
Stepper.propTypes = {
	activeStep: PropTypes.number.isRequired,
	steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
