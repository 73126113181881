import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { clearCurrentLocation } from '../../app/slices/location/locationSlice';
import { clearPayment } from '../../app/slices/makePayment/makePaymentSlice';
import { clearUtilities } from '../../app/slices/manageUtility/manageUtilitySlice';
import { clearNotifications } from '../../app/slices/notifications/notificationsSlice';
import { clearOnboarding } from '../../app/slices/onboarding/onboardingSlice';
import { clearUser } from '../../app/slices/user/userSlice';

import { Spinner } from '../../components';
import {
	META_TITLE,
	Current_Cus_Onb_Conf_Acc,
	Manage_Greenlight,
	Manage_Greenlight_Settings,
	Manage_Utility,
	Manage_Utility_Settings,
	New_Cust_Com_Onb_App,
	New_Cust_Com_Onb_Greenlight,
	New_Cust_Com_Onb_Utils,
	New_Cust_Res_Onb_App,
	New_Cust_Res_Onb_Greenlight,
	New_Cust_Res_Onb_Utils,
} from '../../utils/constants';
import * as S from './Logout.styles';

export default function Logout() {
	const dispatch = useDispatch();

	const [shouldRedirect, setShouldRedirect] = useState(false);

	useEffect(() => {
		// Set document title
		document.title = `Logout | ${META_TITLE}`;

		// Clear user state
		window.localStorage.removeItem('wilsonnc_token');
		window.localStorage.removeItem('wilsonnc_role');
		window.localStorage.removeItem('wilsonnc_user');
		window.localStorage.removeItem('wilsonnc_auth_milestone');
		window.localStorage.removeItem('auth_modal_open');
		window.localStorage.removeItem('auth_modal_target');
		window.localStorage.removeItem(Manage_Greenlight);
		window.localStorage.removeItem(Manage_Greenlight_Settings);
		window.localStorage.removeItem(Manage_Utility);
		window.localStorage.removeItem(Manage_Utility_Settings);
		window.localStorage.removeItem(New_Cust_Com_Onb_Greenlight);
		window.localStorage.removeItem(New_Cust_Com_Onb_Utils);
		window.localStorage.removeItem(New_Cust_Res_Onb_App);
		window.localStorage.removeItem(New_Cust_Res_Onb_Greenlight);
		window.localStorage.removeItem(New_Cust_Res_Onb_Utils);
		window.localStorage.removeItem(New_Cust_Com_Onb_App);
		window.localStorage.removeItem(Current_Cus_Onb_Conf_Acc);
		dispatch(clearUser());
		dispatch(clearCurrentLocation());
		dispatch(clearNotifications());
		dispatch(clearOnboarding());
		dispatch(clearUtilities());
		dispatch(clearPayment());
		setShouldRedirect(true);
	}, []);

	return !shouldRedirect ? (
		<S.Wrapper>
			<Spinner />
		</S.Wrapper>
	) : (
		<Redirect
			to={{
				pathname: '/login',
			}}
		/>
	);
}
