import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 0;
	border-bottom: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-top: 1rem;
	}

	.flex-col > * + * {
		margin-top: 0.25rem;
	}

	&:first-child {
		padding-top: 0.5rem;
	}
	&:last-child {
		padding-bottom: 0;
		border: none;
	}
`;
