import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './ProfileInformation.styles';
import { formatToUSPhoneNumber } from '../../utils/phone-formatter';

export const ProfileInformation = ({ className, user, isStaff = false }) => {
	return (
		<Card
			className={className}
			title="Profile Information"
			action={[
				/*!isStaff && {
					id: 1,
					onClick: () => {
						/ * NV - ADMIN - EDIT MODAL * /
					},
					icon: ['fal', 'edit'],
					label: 'Edit',
					type: 'outline',
				},*/
			].filter(Boolean)}
		>
			<S.Wrapper>
				{user?.name && (
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Name
						</Typography>
						<Typography tag="p">{user?.name}</Typography>
					</S.ItemWrapper>
				)}
				{(user?.email || user?.altEmail) && (
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Email
						</Typography>
						<Typography tag="p">{user?.email || user?.altEmail}</Typography>
					</S.ItemWrapper>
				)}
				{!isStaff && user?.phone && (
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Phone
						</Typography>
						<Typography tag="p">{formatToUSPhoneNumber(user?.phone)}</Typography>
					</S.ItemWrapper>
				)}
				{!isStaff && user?.mailingAddress && (
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Mailing Address
						</Typography>
						<Typography tag="p">{`${user?.mailingAddress}, ${user?.mailingCity}, ${user?.mailingState} ${user?.mailingZip}`}</Typography>
					</S.ItemWrapper>
				)}
			</S.Wrapper>
		</Card>
	);
};
ProfileInformation.propTypes = {
	className: PropTypes.string,
	isStaff: PropTypes.bool,
	user: PropTypes.shape(),
};
