import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTable, useGlobalFilter, usePagination, useAsyncDebounce, useSortBy } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../../Typography';
import * as S from './DesktopTable.styles';
import { DatePicker } from '../../DatePicker';

function GlobalFilter({ globalFilter, setGlobalFilter }) {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<S.InputWrapper>
			<FontAwesomeIcon icon={['fal', 'search']} />
			<S.FilterInput
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder="Search"
			/>
		</S.InputWrapper>
	);
}
GlobalFilter.propTypes = {
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func,
};

export function DesktopTable({ columns, data, withoutSearch, handleClick, isFilterButton, onFilterButtonClick, filterCount, isDatePicker }) {
	const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, state, visibleColumns, setGlobalFilter } = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10000 },
			disableSortRemove: true,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	function onClick(item) {
		if (handleClick) {
			handleClick(item);
		}
	}

	return (
		<S.TableWrapper>
			<table {...getTableProps()}>
				<thead>
					{(!withoutSearch || isFilterButton || isDatePicker) && (
						<tr>
							{!withoutSearch && (
								<th className="table-filter" colSpan={isFilterButton ? visibleColumns.length - 1 : visibleColumns.length}>
									<GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
								</th>
							)}
							{isDatePicker && (
								<th className="table-filter" colSpan={isFilterButton ? visibleColumns.length - 1 : visibleColumns.length}>
									<S.DatePickerWrapper>
										<DatePicker
											selectsRange={true}
											placeholderText="Date Range"

											// startDate={new Date()}
											// endDate={new Date()}
											// {...register('dateRange')}
											// onChange={(update) => {
											// 	setDateRange(update);
											// }}
										/>
									</S.DatePickerWrapper>
								</th>
							)}
							{isFilterButton && (
								<th className="table-filter" colSpan={1}>
									<S.FilterButton onClick={onFilterButtonClick}>
										{filterCount > 0 ? (
											<S.FilterCount>
												<Typography tag="span" variation="2" weight="semibold">
													{filterCount}
												</Typography>
											</S.FilterCount>
										) : (
											<FontAwesomeIcon icon={['fal', 'sliders-h']} color="#707781" />
										)}
										<Typography tag="span" variation="2" weight="extrablack">
											Filter
										</Typography>
									</S.FilterButton>
								</th>
							)}
						</tr>
					)}
					{headerGroups.map(({ key, ...headerGroup }) => (
						<tr key={key} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(({ key, ...column }) => (
								<th key={key} {...column.getHeaderProps(column.getSortByToggleProps())}>
									{!column.hideHeader && (
										<Typography tag="h6" weight="bold">
											{column.render('Header')} <FontAwesomeIcon icon={['fal', 'sort-alt']} />
										</Typography>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map(({ key, ...row }) => {
						prepareRow(row);
						return (
							<S.Tr key={key} {...row.getRowProps()} withHoverEffect={!!handleClick} onClick={() => onClick(row.original)}>
								{row.cells.map(({ key, ...cell }) => {
									return (
										<td key={key} {...cell.getCellProps()}>
											{cell.column.ownInternal === true ? (
												cell.render('Cell')
											) : (
												<Typography tag="p" variation="2">
													{cell.render('Cell')}
												</Typography>
											)}
										</td>
									);
								})}
							</S.Tr>
						);
					})}
				</tbody>
			</table>
		</S.TableWrapper>
	);
}
DesktopTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	withoutSearch: PropTypes.bool,
	handleClick: PropTypes.func,
	isFilterButton: PropTypes.bool,
	filterCount: PropTypes.number,
	onFilterButtonClick: PropTypes.func,
	isDatePicker: PropTypes.bool,
};
