import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './AddressAccounts.styles';
import { Card } from '../Card';
import { Table } from '../Table';

export const AddressAccounts = ({ className, addressAccounts }) => {
	const history = useHistory();
	const location = useLocation();
	const [data, setData] = useState([]);
	const [columns] = useState([
		{
			Header: 'Account Type',
			accessor: 'AccountType',
		},
		{
			Header: 'Account Number',
			accessor: 'AccountNumber',
		},
		{
			Header: 'Location ID',
			accessor: 'LocationID',
		},
		{
			Header: 'Auto Pay Enabled',
			accessor: 'AutoPay',
		},
	]);

	function navigateTo(item) {
		history.push(`${location.pathname}/property/${item.id}`);
	}

	useEffect(() => {
		setData(
			addressAccounts?.map((account) => {
				return {
					AccountKey: account.AccountKey,
					AccountType: account.AccountType === 1 ? 'Utility' : 'Greenlight',
					AccountNumber: account.AccountNumber,
					LocationID: `${account.LocationID}`,
					AutoPay: account.AutoPay ? 'Yes' : 'No',
				};
			})
		);
	}, [addressAccounts]);

	return (
		<Card className={className} title="Address Accounts">
			<S.Wrapper>
				<Table columns={columns} data={data} withoutSearch />
			</S.Wrapper>
		</Card>
	);
};
AddressAccounts.propTypes = {
	className: PropTypes.string,
	addressAccounts: PropTypes.arrayOf(PropTypes.shape()),
};
