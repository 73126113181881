import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as S from './PaymentAddNewMethodModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Button, Modal, DatePicker, Typography, Checkbox, TextInput, Radio } from '../../components';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { required } from '../../utils/form-default-errors';
import { useEffect } from 'react';
import { newMethodAdded } from '../../app/slices/makePayment/makePaymentSlice';
import { walletAddAccount } from '../../services/walletService';
import toast from 'react-hot-toast';
import axios from 'axios';
import colors from '../../styles/colors';

const generalStyleContent = `
	#cardElement,
	#ACH {
		font-family: 'Source Sans Pro', sans-serif;
		height: auto;
		padding: 10px 12px;
		border-radius: 5px;
		border: 1px solid ${colors.neutralForm};
		box-sizing: border-box;
		line-height: 0;
		margin: 0px 0px 15px;
		width: 100%;
	}
	@media screen and (min-width: 768px) {
		#cardElement,
		#ACH {
			height: 100px;
		}
	}
	#cardErrorMessage,
	#ACHErrorMessage {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.statusBad};
		font-size: 0.8rem;
		position: absolute;
		margin-top: -12px;
	}
`;

const customStyleContent = `
	.input-style {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.black};
	}
	.input-hint {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.neutralButton};
		border-color: ${colors.neutralForm};
	}
	/* All fields in form */
	.form-wrapper {
	}
	/* Credit Card Number */
	.input-wrapper {
	}
	/* Expiry Date */
	.sub-wrapper {
	}
	.valid-class {
		color: ${colors.black};
	}
	.invalid-class {
		color: ${colors.statusBad};
	}
`;

const PaymentAddNewMethodModal = ({ isOpen, onRequestClose, afterSubmit, showSaveOption, enableACHOption = true, defaultSave, stepData }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const [settingCheck, setSettingCheck] = useState(defaultSave);
	const [paymentError, setPaymentError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const currentLocation = useSelector((state) => state.location.value);
	const dispatch = useDispatch();

	// Create reference for check value
	const settingCheckValue = useRef(settingCheck);

	// Update check value
	settingCheckValue.current = settingCheck;

	// Create tokenpay instances
	const cardTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_UTILITIES_PUBLISH_KEY);
	const ACHTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_UTILITIES_PUBLISH_KEY);

	const {
		register,
		unregister,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		clearErrors,
		resetField,
		reset,
	} = useForm({
		defaultValues: {
			...(stepData
				? {
						...stepData.newMethodAdded,
				  }
				: undefined),
			method: 'Credit Card',
		},
	});

	const method = watch('method');

	const addPaymentAccountToWallet = async (data, accountToken, { cardLast4, expirationDate, cardModifier, cardClass, cardBrand, routingNumber }) => {
		// Format expiration date if necessary
		let expiration = '';
		if (expirationDate != null) {
			const expirationArray = expirationDate.split('/');
			if (expirationArray.length > 1) {
				let year = expirationArray[1];
				if (year.length === 2) year = `20${year}`;
				expirationArray[1] = year;
			}
			expiration = expirationArray.join('/');
		}

		// Get parameters
		const { ACHAccountName, cardAccountName, cardHolderName, method: walletMethod } = data;
		try {
			const accountKey = await walletAddAccount({
				accountType: walletMethod === 'Credit Card' ? 'Card' : 'ACH',
				accountName: walletMethod === 'Credit Card' ? cardAccountName : ACHAccountName,
				accountToken,
				...(walletMethod === 'Credit Card'
					? {
							cardCardholderName: cardHolderName,
							cardExpirationDate: expiration,
							cardLast4,
							cardModifier,
							cardClass,
							cardBrand,
					  }
					: undefined),
				...(walletMethod === 'Bank Draft'
					? {
							ACHRoutingNumber: routingNumber,
					  }
					: undefined),
			});
			return accountKey;
		} catch (e) {
			setIsSubmitting(false);
			setPaymentError(e.message);
		}
		return null;
	};

	const onSubmit = async (data) => {
		// Get parameters
		const { ACHAccountName, cardAccountName, cardHolderName, paymentToken, method: walletMethod } = data;

		// Initialize payment parameters
		let accountToken = '';
		let cardLast4 = '****';
		let expirationDate = '';
		let routingNumber = '';
		const cardModifier = '';
		const cardClass = '';
		const cardBrand = '';

		// Fetch longterm token for payment method
		try {
			const { data } = await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/payment/token`, { utilityToken: paymentToken, greenlightToken: paymentToken, type: walletMethod });
			if (data && data.accountToken) {
				if (data.brand != null && data.brand !== 'visa' && data.brand !== 'mastercard') {
					setIsSubmitting(false);
					setPaymentError('MyWilson currently only supports Visa and Mastercard cards. Please enter another payment method.');
					return;
				}
				accountToken = data.accountToken;
				cardLast4 = data.last4;
				expirationDate = data.expirationDate;
				routingNumber = data.routingNumber;
			} else {
				setIsSubmitting(false);
				setPaymentError('There was an issue verifying your payment method. Please try again.');
				return;
			}
		} catch (e) {
			setIsSubmitting(false);
			setPaymentError(e.message);
			return;
		}

		// Create payment method object
		const paymentMethod = {
			WalletAccountKey: 'new',
			AccountType: walletMethod === 'Credit Card' ? 'Card' : 'ACH',
			AccountName: walletMethod === 'Credit Card' ? cardAccountName : ACHAccountName,
			AccountToken: accountToken,
			CardLast4: cardLast4,
			CardExpirationDate: expirationDate,
			ACHRoutingNumber: routingNumber,
			CardCardholderName: cardHolderName,
		};

		// Add account to wallet
		if (settingCheckValue.current === true) {
			const accountKey = await addPaymentAccountToWallet(data, accountToken, { cardLast4, expirationDate, cardModifier, cardClass, cardBrand, routingNumber });
			paymentMethod.WalletAccountKey = accountKey;
		}

		// Return if account key is null
		if (!paymentMethod.WalletAccountKey) {
			setIsSubmitting(false);
			return;
		}

		// Add new payment method
		if (stepData != null) {
			dispatch(newMethodAdded(paymentMethod));
		}

		// After submit actions
		afterSubmit(paymentMethod);

		// Update state
		setIsSubmitting(false);

		// Close modal
		onRequestClose();
	};

	const onSettingChange = (e) => {
		setValue('settings', e.target.checked);
		setSettingCheck(e.target.checked);
	};

	const onModalClose = () => {
		clearErrors(['ACHAccountName', 'cardAccountName', 'cardHolderName']);
		if (document.getElementById('cardErrorMessage')) document.getElementById('cardErrorMessage').innerHTML = '';
		if (document.getElementById('ACHErrorMessage')) document.getElementById('ACHErrorMessage').innerHTML = '';
		onRequestClose();
	};

	useEffect(() => {
		if (method === 'Credit Card') {
			resetField('ACHAccountName');
			clearErrors();
			if (document.getElementById('cardErrorMessage')) document.getElementById('cardErrorMessage').innerHTML = '';
			if (document.getElementById('ACHErrorMessage')) document.getElementById('ACHErrorMessage').innerHTML = '';
		} else if (method === 'Bank Draft') {
			resetField('cardAccountName');
			resetField('cardHolderName');
			clearErrors();
			if (document.getElementById('cardErrorMessage')) document.getElementById('cardErrorMessage').innerHTML = '';
			if (document.getElementById('ACHErrorMessage')) document.getElementById('ACHErrorMessage').innerHTML = '';
		}
	}, [method]);

	useEffect(() => {
		setIsSubmitting(false);
	}, [errors]);

	useEffect(() => {
		if (isOpen) {
			reset();
		}
	}, [isOpen]);

	useEffect(() => {
		if (paymentError != null) {
			toast.error(paymentError);
			setPaymentError(null);
		}
	}, [paymentError]);

	useEffect(() => {
		if (!settingCheck) {
			unregister('cardAccountName');
			unregister('ACHAccountName');
		}
	}, [settingCheck]);

	useEffect(() => {
		if (isOpen === true) {
			setTimeout(() => {
				// Initialize card element
				try {
					cardTokenpay.initialize({
						dataElement: 'cardElement',
						errorElement: 'cardErrorMessage',
						iframeId: 'paymentCardFormFrame',
						useACH: false,
						useStyles: true,
						disableZip: false,
						disableCvv: false,
					});
				} catch (e) {}

				// Create listener for form submission
				const cardForm = document.getElementById('paymentCardForm');
				if (cardForm != null) {
					cardForm.addEventListener('submit', async function (event) {
						event.preventDefault();
						setIsSubmitting(true);
						try {
							cardTokenpay.createToken(
								function (result) {
									setIsSubmitting(false);
									setValue('paymentToken', result.token);
									handleSubmit(onSubmit)();
								},
								function (result) {
									setIsSubmitting(false);
									setPaymentError('There was an error verifying your card. Please try again.');
								}
							);
						} catch (e) {
							setIsSubmitting(false);
							setPaymentError('There was an error verifying your card. Please try again.');
						}
						return false;
					});
				}

				// Initialize ACH element
				try {
					ACHTokenpay.initialize({
						dataElement: 'ACH',
						errorElement: 'ACHErrorMessage',
						iframeId: 'paymentACHFormFrame',
						useACH: true,
						useStyles: true,
						disableZip: false,
						disableCvv: true,
					});
				} catch (e) {}

				// Create listener for form submission
				const ACHForm = document.getElementById('paymentACHForm');
				if (ACHForm != null) {
					ACHForm.addEventListener('submit', function (event) {
						event.preventDefault();
						setIsSubmitting(true);
						try {
							ACHTokenpay.createToken(
								function (result) {
									setIsSubmitting(false);
									setValue('paymentToken', result.token);
									handleSubmit(onSubmit)();
								},
								function (result) {
									setIsSubmitting(false);
									setPaymentError('There was an error verifying your ACH account. Please try again.');
								}
							);
						} catch (e) {
							setIsSubmitting(false);
							setPaymentError('There was an error verifying your ACH account. Please try again.');
						}
						return false;
					});
				}
			}, 100);
		}
	}, [isOpen]);

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onModalClose}>
			<style>{generalStyleContent}</style>
			<style id="customStyles">{customStyleContent}</style>
			<S.ModalContainer>
				<S.HeadingContainer>
					<Typography center tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold">
						Add New Payment Method
					</Typography>
				</S.HeadingContainer>
				<S.ModalMainWrapper>
					<S.RadioInputsContainer2>
						<Typography tag="h6" weight="semibold">
							Select Payment Method
						</Typography>
						<Radio id="creditCardmethod" label="Credit Card" value="Credit Card" onChange={(e) => setValue('method', e.target.value)} checked={method === 'Credit Card'} name="payMethodValue" />
						<S.SubFormContainer isVisible={method === 'Credit Card'}>
							<TextInput
								containerClassName="inputContainer"
								label="Cardholder Name"
								placeholder="Justin Case"
								id="cardHolderName"
								error={errors.cardHolderName}
								{...register('cardHolderName', {
									required: method === 'Credit Card' && required('Cardholder Name'),
								})}
							/>
							<div className="inputContainer">
								<Typography tag="label">Card Details</Typography>
							</div>
							<form id="paymentCardForm" action={`${process.env.REACT_APP_BRIDGEPAY_URL}/WebSecurity/echo.aspx`} method="post">
								<div id="cardElement" />
								<div id="cardErrorMessage" />
							</form>
							{settingCheck === true && (
								<TextInput
									containerClassName="inputContainer"
									label="Account Nickname"
									placeholder="My Card"
									id="cardAccountName"
									error={errors.cardAccountName}
									{...register('cardAccountName', {
										required: method === 'Credit Card' && required('Account Nickname'),
									})}
								/>
							)}
						</S.SubFormContainer>
						{currentLocation?.IsCreditCardOnly !== true && (
							<div style={{ position: 'relative' }}>
								{!enableACHOption && (
									<S.ComingSoonBadge>
										<Typography variation="2" weight="semibold">
											Not enabled for this account
										</Typography>
									</S.ComingSoonBadge>
								)}
								<Radio id="methodBankDraft" label="E-Check" value="Bank Draft" disabled={!enableACHOption} onChange={(e) => setValue('method', e.target.value)} checked={method === 'Bank Draft'} name="payMethodValue" />
								<S.SubFormContainer isVisible={method === 'Bank Draft'}>
									<div className="inputContainer">
										<Typography tag="label">ACH Details</Typography>
									</div>
									<form id="paymentACHForm" action={`${process.env.REACT_APP_BRIDGEPAY_URL}/WebSecurity/echo.aspx`} method="post">
										<div id="ACH" />
										<div id="ACHErrorMessage" />
									</form>
									{settingCheck === true && (
										<TextInput
											containerClassName="inputContainer"
											label="Account Nickname"
											placeholder="My Account"
											id="ACHAccountName"
											error={errors.ACHAccountName}
											{...register('ACHAccountName', {
												required: method === 'Bank Draft' && required('Account Nickname'),
											})}
										/>
									)}
								</S.SubFormContainer>
							</div>
						)}
						{showSaveOption && (
							<>
								<Typography tag="h6" weight="semibold">
									Settings
								</Typography>
								<Checkbox id="settings" label="Save payment method to wallet" checked={settingCheck} onChange={onSettingChange} />
							</>
						)}
					</S.RadioInputsContainer2>
				</S.ModalMainWrapper>
				<S.ButtonsContainer>
					<Button type="button" variant="outline" variation="secondary" onClick={onModalClose}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button
						variant="solid"
						disabled={isSubmitting}
						onClick={() => {
							if (method === 'Credit Card') {
								document.getElementById('paymentCardForm').dispatchEvent(new Event('submit', { cancelable: true }));
							} else if (method === 'Bank Draft') {
								document.getElementById('paymentACHForm').dispatchEvent(new Event('submit', { cancelable: true }));
							}
						}}
					>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Save &amp; Continue
						</Typography>
					</Button>
				</S.ButtonsContainer>
			</S.ModalContainer>
		</Modal>
	);
};
export default PaymentAddNewMethodModal;

PaymentAddNewMethodModal.propTypes = {
	isOpen: PropTypes.bool?.isRequired,
	onRequestClose: PropTypes.func?.isRequired,
	afterSubmit: PropTypes.func,
	showSaveOption: PropTypes.bool,
	enableACHOption: PropTypes.bool,
	defaultSave: PropTypes.bool,
	stepData: PropTypes.shape(),
};
