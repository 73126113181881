import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './ButtonToggle.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ButtonToggle = ({ size = 'medium', options = [], setOptions, multiple = false, color = 'brandPrimary' }) => {
	const handleSelectedOption = (id) => {
		const selectedOption = options.find((option) => option.id === id);
		if (multiple) {
			setOptions((prev) => {
				const newArray = [...prev];
				const index = newArray.findIndex((option) => option.id === id);
				newArray[index].selected = !newArray[index].selected;
				return newArray;
			});
		} else if (!selectedOption.selected) {
			setOptions((prev) => {
				const newArray = [...prev];
				newArray.forEach((item, index) => {
					if (item.selected) newArray[index].selected = false;
					if (item.id === id) item.selected = true;
				});
				return newArray;
			});
		}
	};

	return (
		<S.Wrapper size={size}>
			{options.map((option) => (
				<S.Button key={option.id} color={color} onClick={() => handleSelectedOption(option.id)} active={option.selected}>
					<Typography weight="extrablack" variation={size === 'medium' ? '1' : '2'}>
						{option.icon && <FontAwesomeIcon icon={option.icon} />}
						{option.label}
					</Typography>
				</S.Button>
			))}
		</S.Wrapper>
	);
};

ButtonToggle.propTypes = {
	size: PropTypes.oneOf(['small', 'medium']),
	options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), icon: PropTypes.arrayOf(PropTypes.string), label: PropTypes.string, selected: PropTypes.bool })).isRequired,
	setOptions: PropTypes.func.isRequired,
	multiple: PropTypes.bool,
	color: PropTypes.string,
};
