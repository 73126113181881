import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: ${({ error }) => (error ? colors.statusBad : colors.neutralButton)};

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.statusBad : colors.black)};
	}
`;

export const InputWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	& > svg {
		position: absolute;
		left: 0.5em;
		width: 1.25em !important;
		height: 1.25em !important;
		pointer-events: none;
	}
`;

export const Input = styled.input`
	flex: 1;
	appearance: none;
	padding: 0.4375rem 0.75rem;
	padding-left: 2em;
	border-radius: 0.25em;
	border: 1px solid ${colors.neutralForm};
	color: black;
	font-size: 1.125em;
	line-height: 1.375em;
	outline: none;

	&:focus {
		border-color: ${colors.neutralButton};
	}

	${({ error }) =>
		error &&
		css`
			color: ${colors.statusBad};
			border-color: ${colors.statusBad};
			:focus {
				border-color: ${colors.statusBad};
			}
		`}
`;
