import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Modal, Link, Button } from '../../components';
import * as S from './SearchNoMatchesModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

const SearchNoMatchesModal = ({ isOpen, setIsOpen, showContinue = true }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const history = useHistory();

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<S.ModalContainer>
				<FontAwesomeIcon icon={['fal', 'ban']} />
				<Typography tag={isHigherThan768 ? 'h1' : 'h3'} weight="bold" center>
					No Matches Found
				</Typography>
				<S.ParaContainer>
					<Typography tag={isHigherThan768 ? 'p' : 'h6'} variation="1" center>
						Make sure the information you provided is correct and try again. For further support, contact customer service at <a href="tel:252-399-2200">252-399-2200</a>.
					</Typography>
				</S.ParaContainer>
				<S.ButtonsContainer>
					<Button type="button" size="medium" variant="outline" onClick={() => setIsOpen(false)}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Edit Search
						</Typography>
					</Button>
					{showContinue && (
						<S.ButtonWrapper>
							<Button type="button" size="medium" variant="solid" onClick={() => history.push('/onboarding')}>
								<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
									Continue
								</Typography>
							</Button>
						</S.ButtonWrapper>
					)}
				</S.ButtonsContainer>
			</S.ModalContainer>
		</Modal>
	);
};
export default SearchNoMatchesModal;

SearchNoMatchesModal.propTypes = {
	isOpen: PropTypes.bool?.isRequired,
	setIsOpen: PropTypes.func?.isRequired,
	showContinue: PropTypes.bool,
};
