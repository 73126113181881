import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const TableWrapper = styled.div`
	display: block;
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;

	table {
		width: 100%;
		border-spacing: 0;

		th,
		td {
			margin: 0;
			padding: 0.875rem 0.5rem;
			border-bottom: 1px solid ${colors.neutralForm};

			width: 1%;

			&.collapse {
				width: 0.0000000001%;
			}
		}

		th.table-filter {
			padding: 1rem 0;

			input {
				font-size: 1.125rem;
				font-weight: 400;
			}
		}
		th.table-filter-button {
			text-align: right;
		}

		th:not(.table-filter) {
			background-color: ${colors.neutralBackground};
			color: ${colors.neutralText};
			padding: 0.4375rem 0.5rem;
			user-select: none;

			h6 {
				white-space: nowrap;
			}

			svg {
				margin-left: 0.5rem;
			}
		}
	}
`;

export const Tr = styled.tr`
	${({ withHoverEffect }) =>
		withHoverEffect &&
		css`
			&:hover {
				cursor: pointer !important;
				opacity: 0.8;
			}
		`}
`;

export const InputWrapper = styled.div`
	position: relative;

	svg {
		position: absolute;
		left: 0.75rem;
		top: 0.5rem;
	}
`;

export const FilterInput = styled.input`
	border-radius: 6.25rem;
	border: 1px solid ${colors.neutralForm};
	padding: 0.0625rem 0.75rem;
	padding-left: 2.25rem;
	min-width: 15.3125rem;
`;

export const FilterButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: transparent;
	padding: 0.0625rem 0.83rem;
	border: 1px solid ${colors.neutralButton};
	border-radius: 113px;
	width: 5.88rem;
	height: 1.75rem;
	margin-left: auto;
	& > span {
		color: ${colors.neutralButton};
		letter-spacing: 0.0625rem;
	}
`;

export const FilterCount = styled.span`
	background: #005da8;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	color: #fff !important;
	padding-bottom: 0.0625rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const DatePickerWrapper = styled.div`
	input {
		height: 1.75rem;
		max-width: 15.3rem;
		border-radius: 6.25rem;
		padding-left: 1.875rem;
		padding-bottom: 0.5rem;
	}
	input::placeholder {
		color: ${colors.neutralText};
		font-size: 0.875rem;
	}
	svg {
		font-size: 0.75rem;
		margin-left: 0.25rem;
	}
`;
