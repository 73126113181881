import styled from 'styled-components';

import colors from '../../styles/colors';

export const SearchWrapper = styled.div`
	position: relative;
	margin: 14px 0 20px;

	svg {
		position: absolute;
		left: 0.75rem;
		top: 0.5rem;
	}
`;

export const FilterInput = styled.input`
	border-radius: 6.25rem;
	border: 1px solid ${colors.neutralForm};
	padding: 0.225rem 0.75rem;
	padding-left: 2.25rem;
	min-width: 20.3125rem;
	max-width: 100%;
`;
