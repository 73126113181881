import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.div`
	max-width: 72.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	padding: 2.5rem 1rem;

	@media screen and (min-width: 768px) {
	}
`;

export const ModalMainWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 40px;
`;

export const HeadingContainer = styled.div`
	margin-bottom: 1.5rem;
`;

export const ParaContainer = styled.div`
	margin-top: 0.25rem;
	margin-bottom: 3.75rem;
`;

export const RadioInputsContainer2 = styled.div`
	margin-top: 0.75rem;
	margin-bottom: 1.0625rem;
	display: flex;
	flex-direction: column;
	div {
		align-items: flex-start;
		margin-bottom: 1.0625rem;
	}
	label {
		margin-bottom: 0;
		margin-left: 0.7rem;
		cursor: pointer;
	}
	& > h6 {
		margin-bottom: 0.5rem;
	}
`;

export const SubFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
	margin-left: 2rem;
	max-width: 34.875rem;
	width: 34.875rem;

	${({ isVisible }) =>
		isVisible
			? css`
					display: block;
			  `
			: css`
					display: none;
			  `}

	.inputContainer {
		align-items: flex-start;
		width: 100%;
		margin-bottom: 0px;

		label {
			margin-left: 0;
			font-weight: 600;
			margin-bottom: 0.5rem;
		}
		input {
			width: 100%;
			margin-bottom: 1.375rem;
		}
	}
	@media screen and (max-width: 576px) {
		margin-left: 1rem;
	}
	@media screen and (max-width: 768px) {
		max-width: 100%;
		width: 100%;
	}
`;

export const RadioContainers = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	& > div {
		margin-bottom: 0.6rem;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	& > button {
		margin-right: 1rem;
	}

	@media screen and (max-width: 400px) {
		flex-direction: column;
		& > button {
			margin-right: 0rem;
			margin-bottom: 1rem;
		}
	}
`;

export const ErrorContainer = styled.div``;
