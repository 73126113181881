import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { CreateAccount, ForgotPassword, Login, Page404, ResetPassword, VerifyYourMail, VerifyEmail, Logout, StaffLogin, StaffForgotPassword, StaffResetPassword, StaffInvite, CreateAccountProfile, CustomerInvite } from '../../pages';
import { MaintenanceModeWrapper } from '../../components';
import { authenticatedLinks } from '../AuthenticatedRouter';

export const notAuthenticatedLinks = [
	{ id: 1, to: '/login' },
	{ id: 2, to: '/staff/login' },
	{ id: 3, to: '/create-account' },
	{ id: 4, to: '/create-account/profile' },
	{ id: 5, to: '/forgot-password' },
	{ id: 6, to: '/staff/forgot-password' },
	{ id: 7, to: '/reset-password/:token' },
	{ id: 8, to: '/invite/:token' },
	{ id: 9, to: '/staff/reset-password/:token' },
	{ id: 10, to: '/staff/invite/:token' },
];

export default function NotAuthenticatedRouter() {
	const role = window.localStorage.getItem('wilsonnc_role') || 'customer';
	return (
		<Switch>
			{/* Customer */}
			<Route
				exact
				path="/create-account"
				component={() => (
					<MaintenanceModeWrapper>
						<CreateAccount />
					</MaintenanceModeWrapper>
				)}
			/>
			<Route
				exact
				path="/create-account/profile"
				component={() => (
					<MaintenanceModeWrapper>
						<CreateAccountProfile />
					</MaintenanceModeWrapper>
				)}
			/>
			<Route
				exact
				path="/login"
				component={() => (
					<MaintenanceModeWrapper>
						<Login />
					</MaintenanceModeWrapper>
				)}
			/>
			<Route
				exact
				path="/forgot-password"
				component={() => (
					<MaintenanceModeWrapper>
						<ForgotPassword />
					</MaintenanceModeWrapper>
				)}
			/>
			<Route
				exact
				path="/verify-your-email"
				component={() => (
					<MaintenanceModeWrapper>
						<VerifyYourMail />
					</MaintenanceModeWrapper>
				)}
			/>
			<Route
				exact
				path="/reset-password/:token"
				component={() => (
					<MaintenanceModeWrapper>
						<ResetPassword />
					</MaintenanceModeWrapper>
				)}
			/>
			<Route
				exact
				path="/invite/:token"
				component={() => (
					<MaintenanceModeWrapper>
						<CustomerInvite />
					</MaintenanceModeWrapper>
				)}
			/>
			<Route
				exact
				path="/verify/:aToken/:bToken"
				component={() => (
					<MaintenanceModeWrapper>
						<VerifyEmail />
					</MaintenanceModeWrapper>
				)}
			/>

			{/* Staff */}
			<Route exact path="/staff/login" component={StaffLogin} />
			<Route exact path="/staff/forgot-password" component={StaffForgotPassword} />
			<Route exact path="/staff/reset-password/:token" component={StaffResetPassword} />
			<Route exact path="/staff/invite/:token" component={StaffInvite} />
			<Route exact path="/logout" component={Logout} />
			{authenticatedLinks.map(({ id, to }) => (
				<Route key={id} exact path={to} component={() => <Redirect to={role === 'staff' ? '/staff/login' : '/login'} />} />
			))}
			<Route path="*" component={Page404} />
		</Switch>
	);
}
