import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.form`
	max-widht: 72.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	padding: 2.5rem 1rem;

	@media screen and (min-width: 768px) {
	}
`;

export const ModalMainWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
`;

export const HeadingContainer = styled.div`
	margin-bottom: 1.5rem;
`;

export const ParaContainer = styled.div`
	margin-top: 0.25rem;
	margin-bottom: 3.75rem;
`;

export const RadioInputsContainer2 = styled.div`
	margin-top: 0.75rem;
	margin-bottom: 1.0625rem;
	display: flex;
	flex-direction: column;
	div {
		align-items: flex-start;
		margin-bottom: 1.0625rem;
	}
	label {
		margin-bottom: 0;
		margin-left: 0.7rem;
		cursor: pointer;
	}
	& > h6 {
		margin-bottom: 0.5rem;
	}
`;

export const SubFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
	margin-left: 2rem;
	max-width: 34.875rem;
	width: 34.875rem;

	.inputContainer {
		align-items: flex-start;
		width: 100%;
		margin-bottom: 0px;

		label {
			margin-left: 0;
			font-weight: 600;
			margin-bottom: 0.5rem;
		}
		input {
			width: 100%;
			margin-bottom: 1.375rem;
		}
	}
	.errorWrapper {
		bottom: 0px;
	}
	@media screen and (max-width: 576px) {
		margin-left: 1rem;
	}
	@media screen and (max-width: 768px) {
		max-width: 100%;
		width: 100%;
	}
`;
export const CreditCardDateContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.625rem;
	width: 100%;
	margin-bottom: 20px;

	@media screen and (max-width: 576px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 0.8rem;
		align-items: flex-start;

		.inputContainer {
			margin-top: -0.5rem;
		}
	}
`;
export const DatePickerContainer = styled.div`
	width: 100%;

	.inputContainer {
		align-items: flex-start;
		width: 100%;

		label {
			margin-left: 0;
			font-weight: 600;
			margin-bottom: 0.5rem;
		}
		input {
			width: 100%;
		}
	}

	label {
		margin-left: 0;
		font-weight: 600;
		margin-bottom: 0.5rem;
		color: ${colors.formLabel};
		height: 20px !important;
		min-width: 10px;
	}

	div {
		margin-bottom: 0;
		div {
			div {
				align-items: flex-start;
				div {
					align-items: center;
					width: 100%;
				}
			}
		}
	}

	svg {
		left: auto;
		right: 0.5em;
	}

	input {
		padding-left: 0.75rem;
		padding-right: 2em;
		width: 100%;
	}
`;

export const ButtonsContainer = styled.div`
	margin-top: 20px;
	display: flex;
	& > button {
		margin-right: 1rem;
	}

	@media screen and (max-width: 400px) {
		flex-direction: column;
		& > button {
			margin-right: 0rem;
			margin-bottom: 1rem;
		}
	}
`;
