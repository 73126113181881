import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Modal, Button, DatePicker, RangeSlider, Checkbox } from '../../components';
import * as S from './PaymentHistoryFilterModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useForm } from 'react-hook-form';
import { required } from '../../utils/form-default-errors';
import { FILTER_MAXIMUM_RANGE } from '../../utils/constants';

const PaymentHistoryFilterModal = ({ isOpen, setIsOpen, filterModalType, selectedOptions, setFilterSelectedOptions }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [rangevalue, setRangeValue] = useState([0, FILTER_MAXIMUM_RANGE]);
	const [paymentMethods, setPaymentMethods] = useState({
		card: false,
		bankDraft: false,
		eCheck: false,
	});
	const [billTypes, setBillTypes] = useState({
		utility: false,
		greenlight: false,
	});
	const [paymentTypes, setPaymentTypes] = useState({
		autopay: false,
		manual: false,
	});

	const handleChange = (event, newValue) => {
		setRangeValue(newValue);
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		getValues,
	} = useForm({
		defaultValues: { paymentMethods, billTypes, paymentTypes },
	});

	const onPaymentMethodChange = (type) => {
		const tempObj = { ...paymentMethods };
		tempObj[type] = !paymentMethods[type];
		setPaymentMethods(tempObj);
		setValue('paymentMethods', tempObj);
	};

	const onBillTypeChange = (type) => {
		const tempObj = { ...billTypes };
		tempObj[type] = !billTypes[type];
		setBillTypes(tempObj);
		setValue('billTypes', tempObj);
	};

	const onPaymentTypeChange = (type) => {
		const tempObj = { ...paymentTypes };
		tempObj[type] = !paymentTypes[type];
		setPaymentTypes(tempObj);
		setValue('paymentTypes', tempObj);
	};

	const onSubmit = () => {
		setFilterSelectedOptions({
			paymentMethods,
			billTypes,
			paymentTypes,
			startDate,
			endDate,
			amount: {
				min: rangevalue[0],
				max: rangevalue[1],
			},
		});
		setIsOpen(false);
	};

	const handleResetAllFilters = () => {
		setStartDate(null);
		setEndDate(null);
		setPaymentMethods({
			card: false,
			bankDraft: false,
			eCheck: false,
		});
		setBillTypes({
			utility: false,
			greenlight: false,
		});
		setPaymentTypes({
			autopay: false,
			manual: false,
		});
		setRangeValue([0, FILTER_MAXIMUM_RANGE]);

		setFilterSelectedOptions({
			paymentMethods: { card: false, bankDraft: false, eCheck: false },
			billTypes: { utility: false, greenlight: false },
			paymentTypes: {
				autopay: false,
				manual: false,
			},
			startDate: null,
			endDate: null,
			amount: {
				min: 0,
				max: FILTER_MAXIMUM_RANGE,
			},
		});
	};

	useEffect(() => {
		if (isOpen) {
			setStartDate(selectedOptions?.startDate);
			setEndDate(selectedOptions?.endDate);
			setRangeValue([selectedOptions?.amount?.min && selectedOptions?.amount?.min !== -1 ? selectedOptions?.amount?.min : 0, selectedOptions?.amount?.max && selectedOptions?.amount?.max !== -1 ? selectedOptions?.amount?.max : FILTER_MAXIMUM_RANGE]);
			setPaymentMethods(
				selectedOptions?.paymentMethods || {
					card: false,
					bankDraft: false,
					eCheck: false,
				}
			);
			setBillTypes(
				selectedOptions?.billTypes || {
					utility: false,
					greenlight: false,
				}
			);
			setPaymentTypes(
				selectedOptions?.paymentTypes || {
					autopay: false,
					manual: false,
				}
			);
		}
	}, [isOpen]);

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<S.ModalForm onSubmit={handleSubmit(onSubmit)}>
				<S.Wrapper>
					<Typography tag={isHigherThan768 ? 'h1' : 'h3'} weight="bold" center>
						Filter {filterModalType || 'Scheduled'} Payments
					</Typography>
					<S.DatePickerContainer>
						<DatePicker
							{...register('startDate')}
							label="Start Date"
							selectsRange={false}
							selected={startDate}
							onChange={(update) => {
								setStartDate(update);
							}}
						/>
						<DatePicker
							{...register('endDate')}
							label="End Date"
							selectsRange={false}
							selected={endDate}
							onChange={(update) => {
								setEndDate(update);
							}}
						/>
					</S.DatePickerContainer>
					<S.SliderContainer>
						<RangeSlider label="Amount" min={0} max={FILTER_MAXIMUM_RANGE} value={rangevalue} onChange={handleChange} />
					</S.SliderContainer>
					<S.Checkboxes>
						<Typography tag="h6" weight="semibold">
							Payment Method
						</Typography>
						<Checkbox label="Card" id="paymentMethodCard" checked={paymentMethods.card} onChange={() => onPaymentMethodChange('card')} />
						<Checkbox label="E-Check" id="paymentMethodBank" checked={paymentMethods.bankDraft} onChange={() => onPaymentMethodChange('bankDraft')} />
					</S.Checkboxes>
					<S.Checkboxes>
						<Typography tag="h6" weight="semibold">
							Bill Type
						</Typography>
						<Checkbox label="Utility" id="billTypeUtility" checked={billTypes.utility} onChange={() => onBillTypeChange('utility')} />
						<Checkbox label="Greenlight" id="billTypeGreenlight" checked={billTypes.greenlight} onChange={() => onBillTypeChange('greenlight')} />
					</S.Checkboxes>
					<S.Checkboxes>
						<Typography tag="h6" weight="semibold">
							Payment Type
						</Typography>
						<Checkbox label="Autopay" id="paymentTypeAutopay" checked={paymentTypes.autopay} onChange={() => onPaymentTypeChange('autopay')} />
						<Checkbox label="Manual" id="paymentTypeManual" checked={paymentTypes.manual} onChange={() => onPaymentTypeChange('manual')} />
					</S.Checkboxes>
				</S.Wrapper>
				<S.Footer>
					<S.ResetBtn onClick={handleResetAllFilters}>Reset All Filters</S.ResetBtn>
					<S.BtnWrapper>
						<S.BtnCancel>
							<Button type="button" variant="outline" onClick={() => setIsOpen(false)} variation="secondary">
								<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
									Cancel
								</Typography>
							</Button>
						</S.BtnCancel>
						<Button type="submit" variant="solid">
							<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
								Filter
							</Typography>
						</Button>
					</S.BtnWrapper>
				</S.Footer>
			</S.ModalForm>
		</Modal>
	);
};
export default PaymentHistoryFilterModal;

PaymentHistoryFilterModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func,
	filterModalType: PropTypes.string,
	setFilterSelectedOptions: PropTypes.func,
	selectedOptions: PropTypes.shape(),
};
