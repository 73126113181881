import React, { useEffect, useState } from 'react';

import { InfoCard, PendingRequests, PageHeader } from '../../components';
import * as S from './AdminHome.styles';
import { META_TITLE } from '../../utils/constants';
import { getPortalUserCount, retrieveAdminUserRequests } from '../../services/adminService';

export default function AdminHome() {
	const [requests, setRequests] = useState([]);
	const [userCount, setUserCount] = useState(0);

	async function init() {
		try {
			const requestObjs = await retrieveAdminUserRequests({});
			setRequests(
				requestObjs.filter((request) => {
					return request.IsOpen === true;
				})
			);
			const userCount = await getPortalUserCount();
			setUserCount(userCount);
		} catch (e) {}
	}

	useEffect(() => {
		// Set document title
		document.title = `Staff Dashboard | ${META_TITLE}`;

		// Init data
		init();
	}, []);

	return (
		<S.Wrapper>
			<PageHeader>Dashboard</PageHeader>
			<InfoCard className="a1" title="Users" value={userCount || 0} />
			<InfoCard className="a2" title="Requests" value={requests?.length || 0} />
			<PendingRequests className="a5" />
			{/*<PendingInvites className="a6" />*/}
			{/*<RecentActivity className="a6" />*/}
		</S.Wrapper>
	);
}
