import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import toast from 'react-hot-toast';

import { Badge } from '../Badge';
import { MobileTable } from './MobileTable';
import { DesktopTable } from './DesktopTable';
import { formatToUSPhoneNumber } from '../../utils/phone-formatter';
import { formatToUSD } from '../../utils/currency-formatter';
import { formatToDayMonth2DigitYear, formatToFullDateWithTime } from '../../utils/time-formatter';
import { BADGE_LABELS } from '../../utils/constants';
import { retrieveAccountBillPdf } from '../../services/userService';
import { openBase64PDF } from '../../utils/pdf';
import { Button } from '../Button';
import { Typography } from '../Typography';

export const tableSorterDate = (rowA, rowB, id, desc) => {
	const dateA = moment(rowA.values[id] || `${desc ? '01/01/1900' : '12/31/3000'}`, 'MM/DD/YYYY').toDate();
	const dateB = moment(rowB.values[id] || `${desc ? '01/01/1900' : '12/31/3000'}`, 'MM/DD/YYYY').toDate();
	if (dateA.getTime() > dateB.getTime()) return 1;
	if (dateA.getTime() < dateB.getTime()) return -1;
	return 0;
};

export const BadgeCell = ({ value }) =>
	value ? (
		<Badge type={BADGE_LABELS[value].type}>
			{BADGE_LABELS[value].icon && <FontAwesomeIcon icon={BADGE_LABELS[value].icon} />}
			{BADGE_LABELS[value].label}
		</Badge>
	) : (
		<span className="fixedWidthBadge" />
	);
BadgeCell.propTypes = {
	value: PropTypes.oneOf(Object.keys(BADGE_LABELS)),
};

export const CurrencyCell = ({ value }) => (isNaN(value) ? '--' : formatToUSD(value));
CurrencyCell.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const DateCell = ({ value }) => formatToDayMonth2DigitYear(new Date(value));
DateCell.propTypes = {
	value: PropTypes.string,
};

export const DateTimeCell = ({ value }) => formatToFullDateWithTime(new Date(value));
DateTimeCell.propTypes = {
	value: PropTypes.string,
};

export const PhoneCell = ({ value }) => formatToUSPhoneNumber(value);
PhoneCell.propTypes = {
	value: PropTypes.string,
};

export const ForwardIconCell = () => <FontAwesomeIcon icon={['fal', 'angle-right']} color="#707781" />;

export const AvailableStatementCell = ({ row }) => {
	const fetchBillPDF = async (bill) => {
		try {
			const pdfData = await retrieveAccountBillPdf({ accountKey: bill.AccountKey, billId: parseInt(bill.BillID) });
			if (pdfData != null) {
				openBase64PDF(pdfData);
			}
		} catch (e) {
			toast.error(e.message);
		}
	};

	const handleOpenStatement = async (bill) => {
		const promise = fetchBillPDF(bill);
		toast.promise(promise, {
			loading: 'Fetching your statement...',
			success: 'Opening statement...',
		});
	};

	if (row.original.IsBillPDFAvailable) {
		return (
			<Button
				type="button"
				size="small"
				variant="outline"
				onClick={() => {
					handleOpenStatement(row.original);
				}}
			>
				<FontAwesomeIcon icon={['fal', 'external-link']} />
				<Typography tag="span" variation="2" weight="extrablack">
					View
				</Typography>
			</Button>
		);
	}
	return null;
};
AvailableStatementCell.propTypes = {
	row: PropTypes.shape(),
};

export function Table({ columns = [], data = [], withoutSearch, handleClick, isFilterButton, filterCount, onFilterButtonClick, isDatePicker }) {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const memoizedColumns = useMemo(() => columns, []);

	return isHigherThan768 ? (
		<DesktopTable columns={memoizedColumns} data={data} withoutSearch={withoutSearch} handleClick={handleClick} isFilterButton={isFilterButton} filterCount={filterCount} onFilterButtonClick={onFilterButtonClick} isDatePicker={isDatePicker} />
	) : (
		<MobileTable columns={columns} data={data} handleClick={handleClick} />
	);
}
Table.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape({ Header: PropTypes.string, Cell: PropTypes.func, accessor: PropTypes.string })),
	data: PropTypes.array,
	withoutSearch: PropTypes.bool,
	handleClick: PropTypes.func,
	isFilterButton: PropTypes.bool,
	filterCount: PropTypes.number,
	onFilterButtonClick: PropTypes.func,
	isDatePicker: PropTypes.bool,
};
