import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;

	label {
		cursor: pointer;
		margin-bottom: 0;
	}

	& > svg {
		position: absolute;
		left: 0.125em;
		width: 1em !important;
		height: 1em !important;
		pointer-events: none;
		color: white;

		& + label {
			margin-left: 0.4375em;
		}
	}
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	display: block;
	appearance: none;
	width: 1.25em;
	height: 1.25em;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.25em;
	cursor: pointer;

	&:checked {
		background-color: ${colors.brandPrimary};
		border-color: ${colors.brandPrimary};
	}

	&:hover {
		opacity: 0.8;
	}
`;
