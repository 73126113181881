import styled, { css } from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: block;
	position: relative;
`;

export const Button = styled.button.attrs({ type: 'button' })`
	appearance: none;
	display: flex;
	align-items: center;
	color: black;
	background-color: transparent;
	border: none;
	overflow: hidden;

	svg {
		color: ${colors.brandPrimary};
		width: 1.5rem !important;
		height: 1.5rem !important;
		margin-left: 0.5rem;
		transform: ${({ opened }) => (opened ? 'rotateX(180deg)' : 'rotateX(0deg)')};
	}
`;

export const Modal = styled.div`
	position: absolute;
	display: none;
	box-shadow: ${colors.sidebarShadow};
	width: 22rem; /* 304px */
	top: calc(100% + 0.5rem);
	max-height: 420px;
	overflow: scroll;
	background-color: white;
	z-index: 99;

	${({ opened }) =>
		opened &&
		css`
			display: block;
		`}

	ul {
		margin: 0;
		list-style: none;

		li {
			border-bottom: 1px solid ${colors.neutralForm};

			&:last-child {
				border: none;
			}

			button {
				width: 100%;
				background-color: transparent;
				border: none;
				display: flex;
				align-items: center;
				color: black;
				padding: 0.625rem 1rem;

				.spacer {
					width: 1.5rem;
					height: 1.5rem;
				}

				svg {
					color: ${colors.brandPrimary};
					width: 1.5rem !important;
					height: 1.5rem !important;
				}

				h5,
				span {
					display: block;
					flex: 1;
					text-align: left;
					margin: 0 0.5rem;
				}

				span {
					color: ${colors.brandPrimary};
				}

				&:hover {
					opacity: 0.8;
				}

				&.last {
					letter-spacing: 1.5px;
					padding: 0.9375rem 1rem;

					svg {
						width: 1rem !important;
						height: 1rem !important;
					}
				}
			}
		}
	}
`;
