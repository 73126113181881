import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../../../Typography';
import * as S from './NavLink.styles';

export const NavLink = forwardRef(({ children, to, icon, onClick = () => null }, ref) => {
	return (
		<S.LinkWrapper>
			<S.NavLink ref={ref} to={to} exact onClick={onClick}>
				<FontAwesomeIcon icon={icon} />
				<Typography tag="h6" weight="semibold">
					{children}
				</Typography>
			</S.NavLink>
		</S.LinkWrapper>
	);
});

NavLink.displayName = 'NavLink';
NavLink.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	to: PropTypes.string.isRequired,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
	onClick: PropTypes.func,
};
