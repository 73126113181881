import React, { useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import moment from 'moment';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, OnboardingFooter, Typography, Card, MakePaymentHeader } from '../../components';
import { ManageUtilityRequestEquipmentModal } from '../ManageUtilityRequestEquipmentModal';
import * as S from './ManageUtilityReview.styles';
import { Manage_Utility, Manage_Utility_Settings, META_TITLE } from '../../utils/constants';
import { submitUserRequest } from '../../services/userService';
import toast from 'react-hot-toast';

export default function ManageUtilityReview() {
	const currentLocation = useSelector((state) => state.location.value);

	const isLowerThan768 = useMediaQuery('(max-width: 768px)');

	const [utilitiesData, setUtilitiesData] = useState([]);
	const [utilitiesSettingData, setUtilitiesSettingData] = useState({});
	const [loading, setLoading] = useState(false);

	const stepData = useSelector((state) => state.manageUtility);
	const history = useHistory();

	const onBack = () => {
		history.push('/manage-utility/settings');
	};

	const finalApiCalls = async () => {
		const strippedData = JSON.parse(JSON.stringify(utilitiesData));
		for (let i = 0; i < strippedData.length; i += 1) {
			delete strippedData[i].status;
		}
		try {
			const { requestCreated } = await submitUserRequest({
				requestType: 2,
				requestDescription: JSON.stringify(`{
					"utilities": ${JSON.stringify(strippedData)},
					"utilitiesSetting": ${JSON.stringify(utilitiesSettingData)},
					"locationId": "${currentLocation?.LocationID || ''}"
				}`),
			});
			if (requestCreated) {
				toast.success('Your Utilities request has been submitted.');
			} else {
				toast.success('There was an issue submitting your request. Please try again.');
			}
		} catch (e) {
			toast.error(e.message);
		}
	};

	const hasAddedUtilities = utilitiesData?.some((utility) => utility.changeStatus === 'added');
	const hasRemovedUtilities = utilitiesData?.some((utility) => utility.changeStatus === 'removed');

	const onNext = async () => {
		setLoading(true);
		await finalApiCalls();
		setLoading(false);
		localStorage.removeItem(Manage_Utility);
		localStorage.removeItem(Manage_Utility_Settings);
		history.push('/services');
	};

	useEffect(() => {
		// Set document title
		document.title = `Manage Utilities | ${META_TITLE}`;

		let tempSettings = localStorage.getItem(Manage_Utility_Settings);
		if (tempSettings) {
			tempSettings = JSON.parse(tempSettings);
		}
		if (tempSettings) {
			setUtilitiesSettingData(tempSettings);
		}
		let tempServices = localStorage.getItem(Manage_Utility);
		if (tempServices) {
			tempServices = JSON.parse(tempServices);
		}
		if (tempServices) {
			setUtilitiesData(tempServices);
		}
	}, []);

	return (
		<>
			<S.Wrapper>
				<MakePaymentHeader />
				<S.FormContentWrapper>
					<S.FormContent>
						<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
							Review Changes
						</Typography>
						{hasAddedUtilities && (
							<Card title="Added Utilities">
								<Typography tag="h5" weight="bold">
									Utilities
								</Typography>
								<S.UtilitiesContainer>
									{utilitiesData?.map?.(
										(utility) =>
											utility?.changeStatus === 'added' && (
												<Typography tag="p" variation="1" key={utility?.name}>
													{utility?.name}
												</Typography>
											)
									)}
								</S.UtilitiesContainer>
								<Typography tag="h5" weight="bold">
									Settings
								</Typography>
								<S.DetailContainer>
									<Typography tag="h6" weight="semibold">
										Utility Start Date
									</Typography>
									<Typography tag="p" variation="1">
										{moment(utilitiesSettingData?.utilityStartDate).format('MM/DD/YY')}
									</Typography>
								</S.DetailContainer>
							</Card>
						)}
						{hasRemovedUtilities && (
							<Card title="Removed Utilities">
								<Typography tag="h5" weight="bold">
									Utilities
								</Typography>
								<S.UtilitiesContainer>
									{utilitiesData?.map?.(
										(utility) =>
											utility?.changeStatus === 'removed' && (
												<Typography tag="p" variation="1" key={utility?.name}>
													{utility?.name}
												</Typography>
											)
									)}
								</S.UtilitiesContainer>
								<Typography tag="h5" weight="bold">
									Settings
								</Typography>
								<S.DetailContainer>
									<Typography tag="h6" weight="semibold">
										Utility End Date
									</Typography>
									<Typography tag="p" variation="1">
										{moment(utilitiesSettingData?.utilityEndDate).format('MM/DD/YY')}
									</Typography>
								</S.DetailContainer>
								<S.DetailContainer>
									<Typography tag="h6" weight="semibold">
										Address to Send Final Bill
									</Typography>
									<Typography tag="p" variation="1">
										{utilitiesSettingData?.address}, {utilitiesSettingData?.city}, {utilitiesSettingData?.state?.label} {utilitiesSettingData?.zipcode}
									</Typography>
								</S.DetailContainer>
							</Card>
						)}
					</S.FormContent>
				</S.FormContentWrapper>
				<OnboardingFooter>
					<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
						<Typography tag="span" variation="1" weight="extrablack">
							Back
						</Typography>
					</Button>
					<Button variant="outline" onClick={onNext} disabled={loading}>
						<Typography tag="span" variation="1" weight="extrablack">
							Submit
						</Typography>
					</Button>
				</OnboardingFooter>
			</S.Wrapper>
		</>
	);
}
