import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	addRemoveUtilities: {},
	settings: {},
	requestEquipment: {},
	addGreenlight: {},
	addGreenlightSettings: {},
};

export const manageUtilitySlice = createSlice({
	name: 'manageUtility',
	initialState,
	reducers: {
		addRemoveUtilities: (state, action) => {
			state.addRemoveUtilities = action.payload;
		},
		settings: (state, action) => {
			state.settings = action.payload;
		},
		requestEquipment: (state, action) => {
			state.requestEquipment = action.payload;
		},
		addGreenlight: (state, action) => {
			state.addGreenlight = action.payload;
		},
		addGreenlightSettings: (state, action) => {
			state.addGreenlightSettings = action.payload;
		},
		clearUtilities: (state) => {
			state = initialState;
		},
	},
});

export const { addRemoveUtilities, requestEquipment, settings, addGreenlight, addGreenlightSettings, clearUtilities } = manageUtilitySlice.actions;

export default manageUtilitySlice.reducer;
