import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fakeAsyncFunction } from '../../../utils/fake-async-function';

export const fetchNotifications = createAsyncThunk('user/fetchNotifications', async () => {
	/*if (process.env.REACT_APP_ENV === 'development') {
		const fakeData = [
			{
				id: 1,
				message: '<strong>Greenlight internet</strong> has been <strong>added</strong> as a service.',
				icon: ['fal', 'tools'],
				createdAt: Date.now() - 29 * 1000, // 29 seconds ago
				isRead: false,
			},
			{
				id: 2,
				message: 'Your <strong>Utility</strong> payment of <strong>$100.00</strong> has been <strong>paid</strong>.',
				createdAt: Date.now() - 2 * 60 * 60 * 1000, // 2 hours ago
				icon: ['fal', 'credit-card'],
				isRead: true,
			},
			{
				id: 3,
				message: 'Your <strong>January 4, 2020 support request</strong> has been <strong>completed</strong>.',
				icon: ['fal', 'question-circle'],
				createdAt: Date.now() - 24 * 60 * 60 * 1000, // 1 day ago
				isRead: true,
			},
		];
		return await fakeAsyncFunction([...fakeData]);
	}*/
	return [];
});

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: {
		status: 'idle',
		error: null,
		value: null,
	},
	reducers: {
		addNotification: (state, action) => {
			state.value.unshift(action.payload);
		},
		clearNotifications: (state) => {
			state.value = [];
		},
	},
	extraReducers: {
		[fetchNotifications.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchNotifications.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchNotifications.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});

export const { addNotification, clearNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
