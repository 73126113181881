import React, { useEffect, useState } from 'react';
import validator from 'validator';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { required } from '../../../utils/form-default-errors';

import { Card } from '../../../components/Card';
import { Toggle } from '../../../components/Toggle';
import { Button } from '../../../components/Button';
import { Typography } from '../../../components/Typography';
import * as S from './AnnouncementBar.styles';
import { TextInput } from '../../../components';
import { toast } from 'react-hot-toast';

export const AnnouncementBar = ({ className, configuration }) => {
	const [showAnnouncementBar, setShowAnnouncementBar] = useState(configuration?.showAnnouncementBar);

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			...(configuration.announcementBar
				? {
						barContent: configuration.announcementBar.content,
						...(configuration.announcementBar.url
							? {
									barUrl: configuration.announcementBar.url,
							  }
							: undefined),
				  }
				: undefined),
		},
	});

	async function onSubmit(data) {
		// Validate parameters
		const { barContent, barUrl } = data;
		if (!barUrl && validator.isURL(barUrl)) {
			toast.error('Please enter a valid link for the announcement bar');
			reset(undefined, { keepValues: true });
			return;
		}

		// Update announcement bar
		try {
			const result = await updateAnnouncementBar({ show: showAnnouncementBar, content: barContent, ...(barUrl ? { url: barUrl } : undefined) });
			if (result) {
				toast.success('The announcement bar has been updated on all devices.');
			}
		} catch (e) {
			toast.error(e.message);
			reset(undefined, { keepValues: true });
		}
	}

	const updateAnnouncementBar = async ({ show, content, url }) => {
		try {
			const token = window.localStorage.getItem('wilsonnc_token');
			await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/configuration`, {
				showAnnouncementBar: show,
				announcementBarContent: content,
				announcementBarContentUrl: url,
				loginToken: token,
			});
			return true;
		} catch (e) {
			toast.error(e.message);
			return false;
		}
	};

	return (
		<>
			<Card className={className} title="Display">
				<S.Wrapper>
					<S.ToggleItemWrapper>
						<div className="flex justify-between items-center">
							<Typography tag="h5" weight="bold" style={{ flexGrow: 1 }}>
								Show Platform Announcement Bar
							</Typography>
							<Toggle
								checked={showAnnouncementBar}
								onChange={(e) => {
									setShowAnnouncementBar(e.target.checked);
									if (e.target.checked === false) {
										updateAnnouncementBar({ show: false });
									}
								}}
							/>
						</div>
						<Typography tag="p" variation="2">
							An announcement bar will be shown to all users at the top of the page
						</Typography>
					</S.ToggleItemWrapper>
					{showAnnouncementBar && (
						<S.SectionWrapper>
							<Typography tag="h5" weight="bold" style={{ flexGrow: 1 }}>
								Customize Announcement Bar
							</Typography>
							<S.Form onSubmit={handleSubmit(onSubmit)}>
								<TextInput
									label="Content"
									id="barContent"
									error={errors.barContent}
									{...register('barContent', {
										required: required('Content'),
									})}
								/>
								<TextInput label="Link" id="barUrl" error={errors.barUrl} {...register('barUrl')} />
								<Button>
									<Typography tag="span" variation="1" weight="extrablack">
										{isSubmitting ? 'Loading...' : 'Publish'}
									</Typography>
								</Button>
							</S.Form>
						</S.SectionWrapper>
					)}
				</S.Wrapper>
			</Card>
		</>
	);
};

AnnouncementBar.propTypes = {
	className: PropTypes.string,
	configuration: PropTypes.shape(),
};
