import React, { useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { createUser, errorHandler, login } from '../../services/authenticationService';
import logo from '../../assets/wilson-logo.png';
import { TextInput, Button, Typography, Link, PasswordInput } from '../../components';
import * as S from './CreateAccount.styles';
import { required, pattern } from '../../utils/form-default-errors';
import { EMAIL, PASSWORD_12Ch_1Up_1Low_1Num } from '../../utils/common-regex';
import { META_TITLE } from '../../utils/constants';

export default function CreateAccount() {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		getValues,
		reset,
		control,
	} = useForm();
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const history = useHistory();

	async function createUserCaller(data) {
		try {
			await createUser(data);
			const token = await login({ email: data.email, password: data.password });
			window.localStorage.setItem('wilsonnc_role', 'customer');
			window.localStorage.setItem('wilsonnc_token', token);
			history.push(`/create-account/profile?e=${encodeURIComponent(data.email)}&v=1`);
		} catch (e) {
			reset(undefined, { keepValues: true });
			throw e;
		}
	}

	function onSubmit(e) {
		handleSubmit(createUserCaller)(e).catch(errorHandler);
	}

	useEffect(() => {
		// Set document title
		document.title = `Sign Up | ${META_TITLE}`;
	}, []);

	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			<S.Form onSubmit={onSubmit}>
				<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
					Sign Up
				</Typography>
				<S.InputsWrapper>
					<TextInput
						label="Email"
						id="email"
						error={errors.email}
						{...register('email', {
							required: required('Email'),
							pattern: pattern('Email', EMAIL),
						})}
					/>
					<Controller
						name="password"
						control={control}
						rules={{ required: required('Password'), pattern: pattern('Password', PASSWORD_12Ch_1Up_1Low_1Num) }}
						render={({ field: { onChange, value } }) => <PasswordInput label="Password" id="password" type="password" error={errors.password} disallowedContent={[]} value={value} onChange={onChange} />}
					/>
					<TextInput
						label="Confirm Password"
						id="confirm-password"
						type="password"
						error={errors.confirmPassword}
						{...register('confirmPassword', {
							required: required('Confirm Password'),
							pattern: pattern('Confirm Password', PASSWORD_12Ch_1Up_1Low_1Num),
							validate: {
								equalToPassword: (v) => (getValues('password') === v ? true : "Confirm Password doesn't match with Password"),
							},
						})}
					/>
					<Button size={isHigherThan768 ? 'medium' : 'small'}>
						<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
							{isSubmitting ? 'Loading...' : 'Sign Up'}
						</Typography>
					</Button>
				</S.InputsWrapper>
				<Typography tag="span" variation="2" center>
					Already have an account? <Link to="/login">Login</Link>
				</Typography>
			</S.Form>
		</S.MainWrapper>
	);
}
