import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	background-color: ${colors.brandPrimary};
	color: white;
`;

export const CollapseHeader = styled.button`
	appearance: none;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;
	background-color: transparent;
	border: none;
	cursor: pointer;

	svg {
		width: 1rem !important;
		height: 1rem !important;
		transform: ${({ opened }) => (opened ? 'rotateX(180deg)' : 'rotateX(0deg)')};
	}

	&:hover {
		opacity: 0.8;
	}
`;

export const CollapseContent = styled.div`
	overflow: hidden;
	display: ${({ opened }) => (opened ? 'flex' : 'none')};

	& > * + * {
		margin-left: 1.75em;
	}
`;

export const ValueItem = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.75em;

	span {
		font-size: 0.625em;
		font-weight: 600;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;

	button {
		margin-top: 0.5rem;

		&:first-child {
			margin-top: 1rem;
		}
	}
`;
