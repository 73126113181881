import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;

	.page-header {
		grid-area: header;
	}
	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}

	grid-template-areas:
		'header'
		'a1'
		'a2'
		'a3';
`;
