import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.div`
	max-width: 72.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	padding: 2rem 1rem;

	@media screen and (min-width: 768px) {
		padding: 3.4rem 0;
	}
`;

export const CheckboxesContainer = styled.div`
	margin-top: 2.25rem;
	margin-bottom: 2.25rem;
`;

export const CheckboxWrapper = styled.button`
	display: flex;
	align-items: center;
	padding: 1rem 1.25rem;
	width: 100%;
	border: 1px solid ${colors.neutralText};
	background: transparent;
	margin-bottom: 0.75rem;

	@media screen and (min-width: 768px) {
		padding: 1.5rem 1.625rem;
	}
`;

export const CheckboxContentContainer = styled.div`
	margin-left: 1.25rem;
	text-align: left;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const ButtonWrapper = styled.div`
	margin-left: 1.125rem;
`;
