import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './Badge.styles';

export const Badge = ({ children, variation = 'pill', type = 'primary', className, onClick }) => {
	return (
		<S.Badge variation={variation} type={type} className={className} onClick={onClick}>
			<Typography tag="span" variation="2" weight="bold">
				{children}
			</Typography>
		</S.Badge>
	);
};

Badge.displayName = 'Badge';
Badge.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'warning', 'success']),
	variation: PropTypes.oneOf(['square', 'pill']),
	className: PropTypes.string,
	onClick: PropTypes.func,
};
