import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Card, CurrentInvoices, InvoiceHistory } from '../../components';
import { FILTER_MAXIMUM_RANGE, META_TITLE } from '../../utils/constants';
import { InvoiceHistoryFilterModal } from '../InvoiceHistoryFilterModal';
import * as S from './Statements.styles';

export default function Statements() {
	const history = useHistory();
	const [paymentFilterModal, setPaymentFilterModal] = useState(false);
	const [filterSelectedOptions, setFilterSelectedOptions] = useState({
		startDate: null,
		endDate: null,
		amount: {
			min: 0,
			max: FILTER_MAXIMUM_RANGE,
		},
		isUtilities: true,
		isGreenlight: true,
	});

	const onFilterButtonClick = () => {
		setPaymentFilterModal(true);
	};

	const appliedFiltersCount = () => {
		let filterCount = 0;
		const { startDate, endDate, amount, isUtilities, isGreenlight } = filterSelectedOptions;
		if (startDate) {
			filterCount++;
		}
		if (endDate) {
			filterCount++;
		}
		if (amount && (amount.min !== 0 || amount.max !== FILTER_MAXIMUM_RANGE)) {
			filterCount++;
		}
		if (isUtilities !== true) {
			filterCount++;
		}
		if (isGreenlight !== true) {
			filterCount++;
		}
		return filterCount;
	};

	useEffect(() => {
		// Set document title
		document.title = `Statements | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<InvoiceHistoryFilterModal isOpen={paymentFilterModal} setIsOpen={setPaymentFilterModal} setFilterSelectedOptions={setFilterSelectedOptions} />
			<PageHeader
				action={[
					{
						id: 1,
						onClick: () => {
							history.push({ pathname: '/make-payment/amount', state: { backUrl: window.location.pathname } });
						},
						label: 'Make Payment',
					},
				]}
			>
				Statements
			</PageHeader>
			<CurrentInvoices className="a1" hideMakePaymentButton />
			<InvoiceHistory filterCount={appliedFiltersCount() || 0} onFilterButtonClick={onFilterButtonClick} filterSelectedOptions={filterSelectedOptions} />
		</S.Wrapper>
	);
}
