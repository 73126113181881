import React, { useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { errorHandler, login, loginStaff } from '../../services/authenticationService';
import logo from '../../assets/wilson-logo.png';
import { TextInput, Button, Typography, Link } from '../../components';
import * as S from './Login.styles';
import { fetchUser } from '../../app/slices/user/userSlice';
import { required, pattern } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import {
	META_TITLE,
	PLACEHOLDER_USER_DETAILS,
	Current_Cus_Onb_Conf_Acc,
	Manage_Greenlight,
	Manage_Greenlight_Settings,
	Manage_Utility,
	Manage_Utility_Settings,
	New_Cust_Com_Onb_App,
	New_Cust_Com_Onb_Greenlight,
	New_Cust_Com_Onb_Utils,
	New_Cust_Res_Onb_App,
	New_Cust_Res_Onb_Greenlight,
	New_Cust_Res_Onb_Utils,
	PLACEHOLDER_STAFF_DETAILS,
} from '../../utils/constants';

export default function Login() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			...(process.env.REACT_APP_ENV === 'development'
				? {
						email: 'shamlett@wilsonnc.org',
						password: 'YoYoYoTest3',
				  }
				: undefined),
		},
	});
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const dispatch = useDispatch();

	async function loginCaller(data) {
		try {
			const token = await login(data);
			window.localStorage.setItem('wilsonnc_token', token);
			window.localStorage.setItem('wilsonnc_role', 'customer');
			window.localStorage.removeItem('wilsonnc_auth_milestone');
			window.localStorage.removeItem('auth_modal_open');
			window.localStorage.removeItem('auth_modal_target');
			window.localStorage.removeItem(Manage_Greenlight);
			window.localStorage.removeItem(Manage_Greenlight_Settings);
			window.localStorage.removeItem(Manage_Utility);
			window.localStorage.removeItem(Manage_Utility_Settings);
			window.localStorage.removeItem(New_Cust_Com_Onb_Greenlight);
			window.localStorage.removeItem(New_Cust_Com_Onb_Utils);
			window.localStorage.removeItem(New_Cust_Res_Onb_App);
			window.localStorage.removeItem(New_Cust_Res_Onb_Greenlight);
			window.localStorage.removeItem(New_Cust_Res_Onb_Utils);
			window.localStorage.removeItem(New_Cust_Com_Onb_App);
			window.localStorage.removeItem(Current_Cus_Onb_Conf_Acc);
			dispatch(fetchUser({ placeholderUser: PLACEHOLDER_USER_DETAILS }));
		} catch (e) {
			try {
				const user = await loginStaff(data);
				window.localStorage.setItem('wilsonnc_token', user.token || user.LoginToken);
				window.localStorage.setItem('wilsonnc_role', 'staff');
				window.localStorage.setItem(
					'wilsonnc_user',
					JSON.stringify({
						...user,
						onboardingComplete: true,
						token: user.LoginToken,
					})
				);
				dispatch(fetchUser({ placeholderUser: PLACEHOLDER_STAFF_DETAILS }));
			} catch (e2) {
				reset(undefined, { keepValues: true });
				throw e;
			}
		}
	}

	function onSubmit(e) {
		handleSubmit(loginCaller)(e).catch(errorHandler);
	}

	useEffect(() => {
		// Set document title
		document.title = `Log In | ${META_TITLE}`;
	}, []);

	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			<S.Form onSubmit={onSubmit}>
				<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
					Login
				</Typography>
				<S.InputsWrapper>
					<TextInput
						label="Email"
						id="email"
						type="email"
						error={errors.email}
						autoComplete="email"
						{...register('email', {
							required: required('Email'),
							pattern: pattern('Email', EMAIL),
						})}
					/>
					<div>
						<TextInput
							label="Password"
							id="password"
							type="password"
							error={errors.password}
							autoComplete="current-password"
							{...register('password', {
								required: required('Password'),
							})}
						/>
						<S.Link to="/forgot-password">Forgot Password</S.Link>
					</div>
					<Button size={isHigherThan768 ? 'medium' : 'small'} type="submit">
						<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
							{isSubmitting ? 'Loading...' : 'Log In'}
						</Typography>
					</Button>
				</S.InputsWrapper>
				<Typography tag="span" variation="2" center>
					Don&apos;t have an account? <Link to="/create-account">Sign up</Link>
				</Typography>
			</S.Form>
		</S.MainWrapper>
	);
}
