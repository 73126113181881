import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './AccountInformation.styles';
import { Button } from '../Button';
import { forgotPassword, staffForgotPassword } from '../../services/authenticationService';

export const AccountInformation = ({ className, user, isStaff }) => {
	const handleResetPassword = async () => {
		try {
			if (isStaff === true) {
				await staffForgotPassword({ email: user?.email || user?.altEmail });
			} else {
				await forgotPassword({ email: user?.email || user?.altEmail });
			}
			toast.success("We've successfully sent a reset link.");
		} catch (e) {
			toast.error(e.message);
		}
	};

	return (
		<Card
			className={className}
			title="Account Information"
			action={
				[
					/*{ // NV - ADMIN
					id: 1,
					onClick: () => {},
					icon: ['fal', 'edit'],
					label: 'Edit',
					type: 'outline',
				},*/
				]
			}
		>
			<S.Wrapper>
				<S.ItemWrapper>
					<S.ItemLeftWrapper>
						<Typography tag="h6" weight="semibold">
							Username
						</Typography>
						<Typography tag="p">{user?.username || user?.email || user?.altEmail}</Typography>
					</S.ItemLeftWrapper>
				</S.ItemWrapper>
				<S.ItemWrapper>
					<S.ItemLeftWrapper>
						<Typography tag="h6" weight="semibold">
							Password
						</Typography>
						<Typography tag="p">************</Typography>
					</S.ItemLeftWrapper>
					<S.ItemRightWrapper>
						<Button type="button" size="small" variant="text" onClick={() => handleResetPassword()}>
							<Typography tag="span" variation="2" weight="extrablack">
								Reset Password
							</Typography>
						</Button>
					</S.ItemRightWrapper>
				</S.ItemWrapper>
			</S.Wrapper>
		</Card>
	);
};
AccountInformation.propTypes = {
	className: PropTypes.string,
	user: PropTypes.shape(),
	isStaff: PropTypes.bool,
};
