import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './ChoicePackage.styles';

import { Toggle } from '../Toggle';

export const ChoicePackage = forwardRef(({ id, value, checked, setValue, icon, title, price, subTitle, details, details2, subHeadings, label, showToggle = false, ...rest }, ref) => {
	return (
		<S.Wrapper>
			<S.Header>
				<S.Heading>
					<Typography tag="h3" weight="bold">
						{title}
					</Typography>
					<S.PackageImg src={icon} alt="package" />
				</S.Heading>
				{showToggle && <Toggle {...rest} checked={checked} onChange={() => setValue(id, value)} />}
			</S.Header>
			{/*<S.PackagePrice>
				<Typography tag="h4" weight="semibold">
					{price}
				</Typography>
			</S.PackagePrice>*/}
			{subTitle && (
				<S.PackageSubHeading>
					<Typography tag="h6" weight="semibold">
						{subTitle}
					</Typography>
				</S.PackageSubHeading>
			)}
			<S.PackageDetailsWrapper>
				<S.PackageDetails>
					{subHeadings?.[0] && (
						<Typography tag="p" weight="semibold">
							{subHeadings?.[0]}
						</Typography>
					)}
					{details?.map((detail, index) => (
						<Typography tag="p" key={index}>
							{detail}
						</Typography>
					))}
				</S.PackageDetails>
				{(subHeadings?.[1] || details2) && (
					<S.PackageDetails>
						{subHeadings?.[1] && (
							<S.SubHeadingWrapper>
								<Typography tag="p" weight="semibold">
									{subHeadings?.[1]}
								</Typography>
							</S.SubHeadingWrapper>
						)}
						{details2?.map((detail, index) => (
							<Typography tag="p" key={index}>
								{detail}
							</Typography>
						))}
					</S.PackageDetails>
				)}
			</S.PackageDetailsWrapper>
			{label && (
				<S.LabelImageContainer>
					<S.LabelImage src={label} />
				</S.LabelImageContainer>
			)}
		</S.Wrapper>
	);
});
ChoicePackage.displayName = 'ChoicePackage';
ChoicePackage.propTypes = {
	icon: PropTypes.node,
	title: PropTypes.string,
	price: PropTypes.string,
	label: PropTypes.string,
	subTitle: PropTypes.string,
	details: PropTypes.array,
	details2: PropTypes.array,
	subHeadings: PropTypes.array,
	showToggle: PropTypes.bool,
	checked: PropTypes.bool,
	setValue: PropTypes.func,
	id: PropTypes.string,
	value: PropTypes.string,
};
