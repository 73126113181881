import styled from 'styled-components';

export const Wrapper = styled.div`
	margin-top: -0.5rem;
`;

export const CancelButtonCell = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
`;
