import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5.625rem 1rem 2rem 1rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 29.75rem;
	margin-top: 2rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const FormButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;

	& > * + * {
		margin-left: 1rem;
	}
`;

export const CheckboxesContainer = styled.div`
	margin-top: 1.75rem;
	margin-bottom: 0rem;
`;

export const CheckboxWrapper = styled.button`
	display: flex;
	align-items: center;
	padding: 1rem 1.25rem;
	width: 100%;
	border: 1px solid ${colors.neutralText};
	background: transparent;
	margin-bottom: 0.75rem;

	@media screen and (min-width: 768px) {
		padding: 1.5rem 1.625rem;
	}
`;

export const CheckboxContentContainer = styled.div`
	margin-right: 1.25rem;
	text-align: left;
	flex-grow: 1;
`;

export const CheckboxRemove = styled.div`
	* {
		color: ${colors.neutralText};
	}
`;
