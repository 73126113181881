import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const CardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		font-size: 1.5rem;
		color: ${colors.brandPrimary};
		cursor: pointer;
	}
`;

export const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 2.5rem;

	@media screen and (max-width: 1040px) {
		width: 80%;
		margin: 0 auto;
		grid-template-columns: 1fr;
	}

	@media screen and (max-width: 576px) {
		width: 100%;
	}

	& > div {
		height: 10.5rem;

		& > div {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
`;

export const DivContainer = styled.div`
	& > h6 {
		margin-bottom: 0.25rem;
	}
`;

export const CardText = styled.div`
	margin-bottom: 1.9rem;
`;

export const DetailsWrapper = styled.div`
	margin-top: 0.9rem;
	& > h6 {
		margin-bottom: 0.625rem;
	}
`;
