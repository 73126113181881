import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addCustomerAccount, adminSearchPortalUsers, fetchUserById, inviteFindAccounts, inviteRevoke, inviteSendEmail, retrievePortalUserAccounts } from '../../services/adminService';
import { Button, Typography, PageHeader, Tab, Spinner, ProfileInformation, NotificationsPreferences, ScheduledPayments, PastPayments, AdminUpcomingBills, AdminInvoiceHistory, Modal, TextInput, ErrorComponent, Badge, AddressAccounts } from '../../components';
import * as S from './AdminUsersDetails.styles';
import { pattern, required } from '../../utils/form-default-errors';
import { BADGE_LABELS, META_TITLE } from '../../utils/constants';
import { useForm } from 'react-hook-form';
import { EMAIL } from '../../utils/common-regex';
import toast from 'react-hot-toast';
import { SearchSearchingModal } from '../SearchSearchingModal';
import { SearchMatchFound } from '../SearchMatchFound';
import { SearchNoMatchesModal } from '../SearchNoMatchesModal';

const OverviewContent = ({ user, isUnassociated }) => {
	return (
		<S.GridWrapper>
			<ProfileInformation className="a1" user={{ name: user?.Name, email: user?.Email || user.PrimaryEmail, phone: user?.Phone, MailingAddress: user?.mailingAddress, MailingCity: user?.mailingCity, MailingState: user?.mailingState, MailingZip: user?.mailingZip }} />
			<AddressAccounts className="a2" addressAccounts={user?.serviceAccounts} />
			{/*<BillingPreferences className="a3" />*/}
			{!isUnassociated && <NotificationsPreferences className="a3" />}
		</S.GridWrapper>
	);
};

OverviewContent.propTypes = {
	user: PropTypes.shape(),
	isUnassociated: PropTypes.bool,
};

const PaymentsContent = ({ user }) => {
	return (
		<S.GridWrapper>
			<ScheduledPayments className="a1" />
			<PastPayments className="a2" />
		</S.GridWrapper>
	);
};

PaymentsContent.propTypes = {
	user: PropTypes.shape(),
};

const StatementsContent = ({ user }) => {
	return (
		<S.GridWrapper>
			<AdminUpcomingBills className="a1" />
			<AdminInvoiceHistory className="a2" />
		</S.GridWrapper>
	);
};

StatementsContent.propTypes = {
	user: PropTypes.shape(),
};

export default function AdminUsersDetails() {
	const [isSearchingModalOpen, setIsSearchingModalOpen] = useState(false);
	const [isResultFoundModalOpen, setIsResultFoundModalOpen] = useState(false);
	const [isResultNotFoundModalOpen, setIsResultNotFoundModalOpen] = useState(false);
	const [isSearchCustomerModalOpen, setIsSearchCustomerModalOpen] = useState(false);
	const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);
	const [isInviteModalOpen, setInviteModalIsOpen] = useState(false);
	const [isRevoking, setIsRevoking] = useState(false);

	const [accounts, setAccounts] = useState({ unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] });
	const [searchedAccounts, setSearchedAccounts] = useState({ unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] });

	const history = useHistory();
	const location = useLocation();
	const isUnassociated = location.pathname.includes('/unassociated');
	const { userId } = useParams();
	const userEmail = decodeURIComponent(userId);
	const [pageStatus, setPageStatus] = useState('idle');
	const [user, setUser] = useState(null);
	const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);

	const tabContent = [
		{ id: 1, label: 'Overview', component: <OverviewContent user={user} isUnassociated /> },
		/*{ id: 2, label: 'Payments', component: <PaymentsContent user={user} /> },
		{ id: 3, label: 'Statements', component: <StatementsContent user={user} /> },*/
	];
	const [tabs, setTabs] = useState(tabContent);

	const {
		handleSubmit: handleSubmitAddAccount,
		register: registerAddAccount,
		formState: { errors: errorsAddAccount, isSubmitting: isSubmittingAddAccount },
		reset: resetAddAccount,
	} = useForm();

	const {
		handleSubmit: handleSubmitSearch,
		register: registerSearch,
		formState: { errors: errorsSearch, isSubmitting: isSubmittingSearch },
		reset: resetSearch,
	} = useForm();

	const {
		handleSubmit: handleSubmitInvite,
		register: registerInvite,
		reset: submitInviteReset,
		formState: { errors: errorsInvite, isSubmitting: isSubmittingInvite },
	} = useForm();

	async function onSubmitInvite(data) {
		// NV - ADMIN - REQUIRES API CHANGE (INVITE)
		toast.success(JSON.stringify(data));
	}

	async function onSubmitAddAccounts() {
		if (accounts.unassignedUtilityAccounts.length === 0 && accounts.unassignedGreenlightAccounts.length === 0) {
			toast.error('Please select at least one account to attach to this user.');
			resetAddAccount(undefined, { keepValues: true });
			return;
		}
		try {
			const success = await addCustomerAccount({
				userEmail: user?.Email || user.PrimaryEmail,
				accounts: [
					...(accounts.unassignedUtilityAccounts || []).map((account) => ({
						AccountType: '1',
						AccountNumber: account.AccountNumber,
						LocationID: account.LocationID,
					})),
					...(accounts.unassignedGreenlightAccounts || []).map((account) => ({
						AccountType: '2',
						AccountNumber: account.AccountNumber,
						LocationID: account.LocationID,
					})),
				],
			});
			if (success) {
				setIsAddAccountModalOpen(false);
				toast.success('New account(s) have been successfully added to the user.');
			} else {
				toast.error('There was an issue adding this account. Please try again.');
				resetAddAccount(undefined, { keepValues: true });
			}
		} catch (e) {
			toast.error(e.message);
			resetAddAccount(undefined, { keepValues: true });
		}
	}

	async function onSubmitSearchForm(data) {
		const { customerAccountId } = data;
		setIsSearchingModalOpen(true);
		try {
			const data = await inviteFindAccounts({ customerAccountId });
			const { unassignedUtilityAccounts, unassignedGreenlightAccounts } = data;
			let tempAccounts = { unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] };
			let totalAccounts = 0;
			if (unassignedUtilityAccounts?.length) {
				totalAccounts = unassignedUtilityAccounts?.length;
				tempAccounts = { ...tempAccounts, unassignedUtilityAccounts };
			}
			if (unassignedGreenlightAccounts?.length) {
				totalAccounts = totalAccounts + unassignedGreenlightAccounts?.length;
				tempAccounts = { ...tempAccounts, unassignedGreenlightAccounts };
			}
			if (totalAccounts) {
				setIsResultFoundModalOpen(true);
			} else {
				setIsResultNotFoundModalOpen(true);
			}
			setSearchedAccounts(tempAccounts);
		} catch (e) {
			resetSearch(undefined, { keepValues: true });
		}
		setIsSearchingModalOpen(false);
	}

	const handleResultSelection = (selections) => {
		const newAccounts = { ...accounts };
		if (selections.unassignedUtilityAccounts) {
			selections.unassignedUtilityAccounts.forEach((account) => {
				const exists = newAccounts.unassignedUtilityAccounts.some((acct) => acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber);
				if (!exists) {
					newAccounts.unassignedUtilityAccounts.push(account);
				}
			});
		}
		if (selections.unassignedGreenlightAccounts) {
			selections.unassignedGreenlightAccounts.forEach((account) => {
				const exists = newAccounts.unassignedGreenlightAccounts.some((acct) => acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber);
				if (!exists) {
					newAccounts.unassignedGreenlightAccounts.push(account);
				}
			});
		}
		setAccounts(newAccounts);
		setIsResultFoundModalOpen(false);
	};

	const moveToAddAccount = () => {
		if (accounts.unassignedUtilityAccounts.length === 0 && accounts.unassignedGreenlightAccounts.length === 0) {
			toast.error('Please select at least one account to add to this user.');
			return;
		}
		setIsSearchCustomerModalOpen(false);
		setIsAddAccountModalOpen(true);
	};

	const removeSelectedAccount = (account, type) => {
		const newAccounts = { ...accounts };
		if (type === 'utility') {
			newAccounts.unassignedUtilityAccounts = newAccounts.unassignedUtilityAccounts.filter((acct) => !(acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber));
		} else {
			newAccounts.unassignedGreenlightAccounts = newAccounts.unassignedGreenlightAccounts.filter((acct) => !(acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber));
		}
		setAccounts(newAccounts);
	};

	async function resendEmailInvite() {
		try {
			await inviteSendEmail({ inviteToken: user?.InviteToken });
			toast.success('An email invite has been resent to the user.');
		} catch (e) {
			toast.error(e.message);
		}
	}

	async function revokeInviteConfirmation() {
		setIsRevokeModalOpen(true);
	}

	async function revokeInvite() {
		setIsRevoking(true);
		try {
			const success = await inviteRevoke({ inviteToken: user?.InviteToken });
			setIsRevoking(false);
			if (success) {
				toast.success('This invite has successfully been revoked.');
				setTimeout(() => {
					history.push('/users');
				}, 2000);
			} else {
				toast.error('There was an issue revoking this invite. Please try again.');
			}
		} catch (e) {
			setIsRevoking(false);
			toast.error(e.message);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			if (isUnassociated) {
				const data = await fetchUserById(userEmail); // NV - ADMIN - REQUIRES API CHANGE
				setUser(data);
				setPageStatus('succeeded');
			} else {
				const userObjs = await adminSearchPortalUsers({ searchText: userEmail });
				const data = userObjs.find((obj) => `${obj.Email}` === userEmail);
				if (data != null) {
					let inviteStatus = '';
					if (data.InviteToken) {
						if (data.IsInviteAccepted) {
							inviteStatus = 'inviteAccepted';
						} else {
							inviteStatus = 'invitePending';
						}
					} else if (!data.IsAccountValidated) {
						inviteStatus = 'pendingOnboarding';
					}
					const serviceAccounts = await retrievePortalUserAccounts({ portalUserId: data.UserID });
					setUser({
						...data,
						serviceAccounts,
						inviteStatus,
					});
					setPageStatus('succeeded');
				} else {
					setPageStatus('error');
				}
			}
		} catch (e) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		setTabs(tabContent);
	}, [user]);

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		// Set document title
		document.title = `User Details | ${META_TITLE}`;
	}, []);

	const renderActionButton = () => {
		if (isUnassociated) {
			return [{ id: 1, onClick: () => setInviteModalIsOpen(true), icon: ['fal', 'envelope'], label: 'Invite User' }];
		} else if (user?.InviteToken && !user?.IsInviteAccepted) {
			return [
				{ id: 1, onClick: () => revokeInviteConfirmation(), label: 'Revoke Invite', variation: 'warning', type: 'outline' },
				{ id: 2, onClick: () => resendEmailInvite(), icon: ['fal', 'envelope'], label: 'Resend Invite Email' },
			];
		} else {
			return [
				{
					id: 1,
					onClick: () => {
						setAccounts({ unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] });
						resetSearch();
						resetAddAccount();
						setIsSearchCustomerModalOpen(true);
					},
					icon: ['fal', 'plus'],
					label: 'Add Existing Accounts',
				},
			];
		}
		return [];
	};

	const renderContent = () => {
		if (pageStatus === 'loading') {
			return <Spinner />;
		} else if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		if (pageStatus === 'succeeded') {
			return (
				<>
					<S.Wrapper>
						<S.HeaderWrapper>
							<Button variant="text" onClick={() => history.push('/users')}>
								<FontAwesomeIcon icon={['fal', 'arrow-left']} />
								<Typography tag="span" variation="2" weight="extrablack">
									Return to Citizens
								</Typography>
							</Button>
							<PageHeader action={renderActionButton()}>{user?.Name || user?.Email || user.PrimaryEmail}</PageHeader>
							<Badge type={BADGE_LABELS[user?.inviteStatus].type}>
								{BADGE_LABELS[user?.inviteStatus].icon && <FontAwesomeIcon icon={BADGE_LABELS[user?.inviteStatus].icon} />}
								{BADGE_LABELS[user?.inviteStatus].label}
							</Badge>
						</S.HeaderWrapper>
						<Tab tabs={tabs} setTabs={setTabs} />
					</S.Wrapper>

					{/* Invite Modal */}
					<Modal shouldCloseOnOverlayClick allowScroll isOpen={isInviteModalOpen} onRequestClose={() => setInviteModalIsOpen(false)}>
						<S.ModalWrapper>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									New Customer Invite
								</Typography>
							</S.TitleWrapper>
							<S.Form onSubmit={handleSubmitInvite(onSubmitInvite)}>
								<TextInput
									label="Email"
									id="email"
									error={errorsInvite.email}
									{...registerInvite('email', {
										required: required('Email'),
										pattern: pattern('Email', EMAIL),
									})}
								/>
								<S.FormButtonWrapper>
									<Button variant="outline" variation="secondary" type="button" onClick={() => setInviteModalIsOpen(false)}>
										<Typography tag="span" variation="1" weight="extrablack">
											Cancel
										</Typography>
									</Button>
									<Button>
										<Typography tag="span" variation="1" weight="extrablack">
											{isSubmittingInvite ? 'Loading...' : 'Send Invite'}
										</Typography>
									</Button>
								</S.FormButtonWrapper>
							</S.Form>
						</S.ModalWrapper>
					</Modal>

					{/* Customer Search Modal */}
					<Modal shouldCloseOnOverlayClick allowScroll isOpen={isSearchCustomerModalOpen} onRequestClose={() => setIsSearchCustomerModalOpen(false)}>
						<S.ModalWrapper>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									Add Existing Accounts
								</Typography>
								<Typography tag="p" center>
									Search for unassociated customer accounts to add to this user.
								</Typography>
							</S.TitleWrapper>
							<S.CheckboxesContainer>
								{accounts?.unassignedUtilityAccounts?.map((item, index) => (
									<S.CheckboxWrapper key={index}>
										<S.CheckboxContentContainer>
											<Typography tag="h5" weight="bold">
												{item?.Address} (Utility Account)
											</Typography>
											<Typography tag="p" variation="2">
												Account Name: {item?.AccountName}
											</Typography>
										</S.CheckboxContentContainer>
										<S.CheckboxRemove>
											<FontAwesomeIcon
												icon={['fal', 'times']}
												onClick={() => {
													removeSelectedAccount(item, 'utility');
												}}
											/>
										</S.CheckboxRemove>
									</S.CheckboxWrapper>
								))}
								{accounts?.unassignedGreenlightAccounts?.map((item, index) => (
									<S.CheckboxWrapper key={index}>
										<S.CheckboxContentContainer>
											<Typography tag="h5" weight="bold">
												{item?.Address} (Greenlight Account)
											</Typography>
											<Typography tag="p" variation="2">
												Account Name: {item?.AccountName}
											</Typography>
										</S.CheckboxContentContainer>
										<S.CheckboxRemove>
											<FontAwesomeIcon
												icon={['fal', 'times']}
												onClick={() => {
													removeSelectedAccount(item, 'greenlight');
												}}
											/>
										</S.CheckboxRemove>
									</S.CheckboxWrapper>
								))}
							</S.CheckboxesContainer>
							<S.Form onSubmit={handleSubmitSearch(onSubmitSearchForm)}>
								<TextInput
									label="Customer Account ID"
									id="customerAccountId"
									error={errorsSearch.customerAccountId}
									{...registerSearch('customerAccountId', {
										required: required('Customer Account ID'),
									})}
								/>
								<Button type="submit">
									<Typography tag="span" variation="1" weight="extrablack">
										{isSubmittingSearch ? 'Loading...' : 'Search'}
									</Typography>
								</Button>
							</S.Form>
							<S.FormButtonWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={() => setIsSearchCustomerModalOpen(false)}>
									<Typography tag="span" variation="1" weight="extrablack">
										Cancel
									</Typography>
								</Button>
								<Button>
									<Typography tag="span" variation="1" weight="extrablack" onClick={moveToAddAccount}>
										Continue
									</Typography>
								</Button>
							</S.FormButtonWrapper>
						</S.ModalWrapper>
					</Modal>

					{/* Customer Invite Modal */}
					<Modal shouldCloseOnOverlayClick allowScroll isOpen={isAddAccountModalOpen} onRequestClose={() => setIsAddAccountModalOpen(false)}>
						<S.ModalWrapper>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									Add New Accounts
								</Typography>
							</S.TitleWrapper>
							<S.CheckboxesContainer>
								{accounts?.unassignedUtilityAccounts?.map((item, index) => (
									<S.CheckboxWrapper key={index}>
										<S.CheckboxContentContainer>
											<Typography tag="h5" weight="bold">
												{item?.Address} (Utility Account)
											</Typography>
											<Typography tag="p" variation="2">
												Account Name: {item?.AccountName}
											</Typography>
										</S.CheckboxContentContainer>
									</S.CheckboxWrapper>
								))}
								{accounts?.unassignedGreenlightAccounts?.map((item, index) => (
									<S.CheckboxWrapper key={index}>
										<S.CheckboxContentContainer>
											<Typography tag="h5" weight="bold">
												{item?.Address} (Greenlight Account)
											</Typography>
											<Typography tag="p" variation="2">
												Account Name: {item?.AccountName}
											</Typography>
										</S.CheckboxContentContainer>
									</S.CheckboxWrapper>
								))}
							</S.CheckboxesContainer>
							<S.Form onSubmit={handleSubmitAddAccount(onSubmitAddAccounts)}>
								<S.FormButtonWrapper>
									<Button
										variant="outline"
										variation="secondary"
										type="button"
										onClick={() => {
											setIsAddAccountModalOpen(false);
											setIsSearchCustomerModalOpen(true);
										}}
									>
										<Typography tag="span" variation="1" weight="extrablack">
											Back
										</Typography>
									</Button>
									<Button>
										<Typography tag="span" variation="1" weight="extrablack">
											{isSubmittingAddAccount ? 'Loading...' : 'Add Accounts'}
										</Typography>
									</Button>
								</S.FormButtonWrapper>
							</S.Form>
						</S.ModalWrapper>
					</Modal>

					{/* Revoke Invite Modal */}
					<Modal
						shouldCloseOnOverlayClick
						isOpen={isRevokeModalOpen}
						onRequestClose={() => {
							setIsRevokeModalOpen(false);
						}}
					>
						<S.ModalWrapper>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									Revoke Invite
								</Typography>
								<Typography tag="p" center>
									Are you sure you want to revoke this invite? You will not be able to undo this action.
								</Typography>
							</S.TitleWrapper>
							<S.FormButtonWrapper>
								<Button
									variant="outline"
									variation="secondary"
									type="button"
									onClick={() => {
										setIsRevokeModalOpen(false);
									}}
								>
									<Typography tag="span" variation="1" weight="extrablack">
										Cancel
									</Typography>
								</Button>
								<Button variation="warning" onClick={revokeInvite}>
									<Typography tag="span" variation="1" weight="extrablack">
										{isRevoking ? 'Loading...' : 'Revoke Invite'}
									</Typography>
								</Button>
							</S.FormButtonWrapper>
						</S.ModalWrapper>
					</Modal>

					{/* Modals */}
					<SearchSearchingModal isOpen={isSearchingModalOpen} setIsOpen={setIsSearchingModalOpen} />
					<SearchMatchFound isOpen={isResultFoundModalOpen} setIsOpen={setIsResultFoundModalOpen} data={searchedAccounts} onContinue={handleResultSelection} storeResults={false} />
					<SearchNoMatchesModal isOpen={isResultNotFoundModalOpen} setIsOpen={setIsResultNotFoundModalOpen} showContinue={false} />
				</>
			);
		}
		return null;
	};

	return renderContent();
}
