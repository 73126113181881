import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Typography } from '../Typography';
import * as S from './RadioInput.styles';
import { useForm } from 'react-hook-form';
import { Radio } from '../Radio/Radio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../styles/colors';

export const RadioInput = forwardRef(({ id, label, stateValue, error, type = 'text', icon = '', tooltip, containerClassName, options, onChangeFunc, disabled, ...rest }, ref) => {
	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<S.LabelContainer>
					<Typography tag="label" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} weight="semibold">
						{label}
					</Typography>
					{icon && <FontAwesomeIcon icon={['fal', icon]} data-for={`${id || 'tooltip'}-tooltip`} data-tip />}
					{tooltip && (
						<ReactTooltip id={`${id || 'tooltip'}-tooltip`} backgroundColor={colors.brandPrimary}>
							<span>{tooltip}</span>
						</ReactTooltip>
					)}
				</S.LabelContainer>
			)}
			<S.RadioContainer>
				{options?.map((option, key) => (
					<Radio id={id ? id + '-' + option?.value : ''} checked={stateValue === option?.value} onChange={(e) => onChangeFunc(id, e.target.value)} key={key} label={option?.label} error={error} value={option?.value} name={id ? id : null} disabled={disabled} />
				))}
			</S.RadioContainer>
			{/* <S.TextInput ref={ref} id={id} error={error} {...rest} type={type} /> */}
			{error && !stateValue && error.message && (
				<S.FloatingWrapper>
					<Typography tag="span" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

RadioInput.displayName = 'RadioInput';
RadioInput.propTypes = {
	onChangeFunc: PropTypes.func,
	containerClassName: PropTypes.string,
	options: PropTypes.array,
	type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
	icon: PropTypes.string,
	tooltip: PropTypes.string,
	stateValue: PropTypes.string,
	disabled: PropTypes.bool,
};
