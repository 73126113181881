import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './Toggle.styles';

export const Toggle = forwardRef(({ id, checked, label, ...rest }, ref) => {
	return (
		<S.Wrapper>
			{label && (
				<Typography tag="label" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					{label}
				</Typography>
			)}
			<S.Toggle ref={ref} {...rest} type="checkbox" id={id} checked={checked} aria-labelledby={id ? `${id}-label` : null} />
		</S.Wrapper>
	);
});

Toggle.displayName = 'Toggle';
Toggle.propTypes = {
	checked: PropTypes.bool,
	label: PropTypes.string,
	id: PropTypes.string,
};
