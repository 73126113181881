import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import colors from '../../styles/colors';

export const CardWrapper = styled.div`
	margin-top: 1rem;
	padding-bottom: 0.5rem;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${colors.neutralForm};

	&:last-of-type {
		border: none;
		padding-bottom: 0;
	}

	@media screen and (min-width: 768px) {
		margin-top: 0.75rem;
		border: none;

		&:last-of-type {
			margin-top: 0.25rem;
		}
	}
`;

export const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${colors.neutralForm};
	padding-bottom: 1.5rem;
	margin-bottom: 1.25rem;
	margin-top: 0.5em;

	&:first-of-type {
		margin-top: 0;
	}

	@media screen and (min-width: 768px) {
		margin-bottom: 0;
	}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;

	h2 {
		margin-top: 0.25rem;
		color: ${({ warning }) => (warning ? colors.statusBad : 'black')};
	}
`;

export const InfoWrapper = styled.div`
	display: flex;

	.container-fluid {
		padding: 0;

		${Item} {
			margin-top: 0.5rem;
		}
	}
`;

export const Head = styled.div`
	display: flex;

	& > * {
		flex: 1;
		display: flex;
		align-items: center;
	}
`;

export const ItemTable = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.75rem;
`;

export const MobileItem = styled.div`
	appearance: none;
	background-color: transparent;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: left;
	padding: 0.75rem;
	border: 1px solid ${colors.neutralForm};
	color: black;
	margin: 0.625rem 0;
	position: relative;

	${Head} {
		justify-content: space-between;
		align-items: center;
	}

	&:hover {
		opacity: 0.8;
	}

	&.clickable {
		cursor: pointer;
	}
`;

export const DesktopWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.75rem;

	${Head} {
		padding: 0.5rem;
		background-color: ${colors.neutralBackground};
		color: ${colors.neutralText};
		border: 1px solid ${colors.neutralForm};
		border-left: none;
		border-right: none;

		& > * {
			flex: 1;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
`;

export const DesktopItem = styled.div`
	appearance: none;
	background-color: transparent;
	border: none;
	display: flex;
	align-items: center;
	text-align: left;
	padding: 0.5rem;
	border-bottom: 1px solid ${colors.neutralForm};
	color: black;
	position: relative;

	& > * {
		flex: 1;
		display: flex;
		align-items: center;
	}

	&:hover {
		opacity: 0.8;
	}

	&.clickable {
		cursor: pointer;
	}
`;

export const ActionIcon = styled(FontAwesomeIcon)`
	position: absolute;
	color: ${colors.brandPrimary};
	right: 10px;
`;

export const UtilityIcon = styled.div`
	background-image: ${(props) => `url(${props.image})`};
	background-position: center;
	background-size: auto 100%;
	background-repeat: no-repeat;
	width: 45px;
	height: 45px;
	margin-right: 5px;
`;
