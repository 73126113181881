import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

import colors from '../../styles/colors';
import { Typography } from '../Typography';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.statusBad : 'inherit')};
	}
`;

export const TextInput = styled.input`
	appearance: none;
	padding: 0.4375rem 0.75rem;
	border-radius: 0.25em;
	border: 1px solid ${colors.neutralForm};
	color: black;
	font-size: 1.125em;
	line-height: 1.375em;
	outline: none;

	&[type='password'] {
		letter-spacing: 0.25em;
	}

	&:focus {
		border-color: ${colors.neutralButton};
	}

	${({ error }) =>
		error &&
		css`
			color: ${colors.statusBad};
			border-color: ${colors.statusBad};
			:focus {
				border-color: ${colors.statusBad};
			}
		`}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	color: ${colors.statusBad};

	&.errorWrapper {
		bottom: -1.2em;
	}
`;

export const Warning = styled(FontAwesomeIcon)`
	color: ${colors.statusBad};
	margin: 0px 0px 0px 8px;
`;
