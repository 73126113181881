import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card } from '../Card';
import { Table, BadgeCell } from '../Table';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Select } from '../Select';
import { Typography } from '../Typography';
import { required } from '../../utils/form-default-errors';
import * as S from './AdminRequestDocuments.styles';
import { formatDate } from '../../utils/time-formatter';
import toast from 'react-hot-toast';
import { adminMarkDocReviewed, adminRetrieveDoc } from '../../services/adminService';
import { openBase64Document } from '../../utils/pdf';
import { EmptyComponent } from '../EmptyComponent';

const EditCell = ({
	row: {
		values: { status },
		original: { Reviewed, DocumentKey, MessageKey, loadRequest },
	},
}) => {
	const statusOptions = [
		{ value: 'complete', label: 'Complete' },
		{ value: 'incomplete', label: 'Incomplete' },
		{ value: 'pending', label: 'Pending' },
	];

	const [isOpen, setIsOpen] = useState(false);
	const {
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: { status },
	});

	async function onSubmit(data) {
		try {
			const reviewedUpdated = await adminMarkDocReviewed({ documentKey: DocumentKey || MessageKey });
			if (reviewedUpdated) {
				toast.success('The document was succesfully marked as reviewed.');
				await loadRequest();
				setIsOpen(false);
			} else {
				reset(undefined, { keepValues: true });
				toast.error('There was an issue updating this document. Please try again.');
			}
		} catch (e) {
			reset(undefined, { keepValues: true });
			toast.error(e.message);
		}
	}

	return (
		!Reviewed && (
			<>
				<S.EditButtonWrapper>
					<Button
						type="button"
						size="small"
						variant="text"
						onClick={(e) => {
							e.stopPropagation();
							setIsOpen(true);
						}}
					>
						<FontAwesomeIcon icon={['fal', 'edit']} />
						<Typography tag="span" variation="2" weight="extrablack">
							Edit
						</Typography>
					</Button>
				</S.EditButtonWrapper>

				<Modal
					shouldCloseOnOverlayClick
					isOpen={isOpen}
					onRequestClose={(e) => {
						e.stopPropagation();
						setIsOpen(false);
					}}
				>
					<S.ModalWrapper>
						<S.TitleWrapper>
							<Typography tag="h1" weight="bold" center>
								Mark Document Reviewed
							</Typography>
							<Typography tag="h6" weight="bold" center>
								Are you sure you want to mark this document as reviewed?
							</Typography>
						</S.TitleWrapper>
						<S.Form>
							{/*<Controller
								name="status"
								control={control}
								rules={{ required: required('Status') }}
								render={({ field: { onChange, value } }) => <Select label="Status" id="status" error={errors.status} options={statusOptions} value={statusOptions.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
							/>*/}
							<S.FormButtonWrapper>
								<Button
									variant="outline"
									variation="secondary"
									type="button"
									onClick={(e) => {
										e.stopPropagation();
										setIsOpen(false);
									}}
								>
									<Typography tag="span" variation="1" weight="extrablack">
										Cancel
									</Typography>
								</Button>
								<Button
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleSubmit(onSubmit)();
									}}
								>
									<Typography tag="span" variation="1" weight="extrablack">
										{isSubmitting ? 'Loading...' : 'Update'}
									</Typography>
								</Button>
							</S.FormButtonWrapper>
						</S.Form>
					</S.ModalWrapper>
				</Modal>
			</>
		)
	);
};
EditCell.propTypes = {
	row: PropTypes.shape(),
};

export const AdminRequestDocuments = ({ className, request, loadRequest }) => {
	const { Documents } = request;

	const [columns] = useState([
		{
			Header: 'Document Type',
			accessor: 'type',
		},
		{
			Header: 'Uploaded Date',
			accessor: 'uploadDate',
		},
		{
			Header: 'Reviewed Date',
			accessor: 'reviewedDate',
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: BadgeCell,
			CellName: 'BadgeCell',
		},
		{
			hideHeader: true,
			accessor: '[editButton]',
			Cell: EditCell,
			CellName: 'EditCell',
		},
	]);

	const [data] = useState(
		Documents.map((document) => {
			return {
				...document,
				id: document.DocumentKey,
				type: request.documentTypes.find((type) => type.DocumentTypeID.toString() === document.DocumentType.toString())?.DocumentType || 'Document',
				uploadDate: formatDate(document.UploadDate),
				reviewedDate: document.ReviewedOn ? formatDate(document.ReviewedOn) : 'N/A',
				status: document.Reviewed ? 'complete' : 'inprogress',
				loadRequest,
			};
		})
	);

	const openDocument = async (document) => {
		try {
			const data = await adminRetrieveDoc({ documentKey: document.DocumentKey || document.MessageKey });
			openBase64Document(data);
		} catch (e) {
			toast.error(e.message);
		}
	};

	return (
		<Card className={className} title="Documents">
			<S.Wrapper>{Documents.length > 0 ? <Table columns={columns} data={data} withoutSearch handleClick={openDocument} /> : <EmptyComponent icon={['fal', 'file-alt']} title="No Request Documents" description="Check back to view documents pertaining to this request." />}</S.Wrapper>
		</Card>
	);
};
AdminRequestDocuments.propTypes = {
	className: PropTypes.string,
	request: PropTypes.shape(),
	loadRequest: PropTypes.func,
};
