export const required = (label) => {
	return {
		value: true,
		message: `${label} is required`,
	};
};

export const pattern = (label, value) => {
	return {
		value,
		message: `${label} is invalid`,
	};
};
