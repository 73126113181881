import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const CardInner = styled.div`
	width: 100%;
	max-width: 600px;
	padding-top: 10px;

	.inputContainer {
		align-items: flex-start;
		width: 100%;
		margin-bottom: 0px;

		label {
			margin-left: 0;
			font-weight: 600;
			margin-bottom: 0.5rem;
		}
		input {
			width: 100%;
			margin-bottom: 1.375rem;
		}
	}
`;

export const AmountInputContainer = styled.div`
	position: relative;
	& > svg {
		color: ${colors.neutralButton};
		font-size: 1.5rem;
		position: absolute;
		left: 7px;
		top: 50%;
		z-index: 2;
		transform: translateY(-50%);
	}

	.amountInput {
		display: inline-block;

		& > input {
			max-width: 15.25rem;
			padding-left: 1.5rem;
			@media screen and (max-width: 576px) {
				max-width: auto;
				width: 100%;
			}
		}
	}
`;

export const CardFooter = styled.div`
	margin-top: 40px;
	text-align: right;
	width: 100%;
	display: flex;
`;

export const Form = styled.form``;

export const SubscriberInfo = styled.div`
	border: 1px solid ${colors.neutralForm};
	padding: 16px;
	display: flex;
	gap: 12px;
	flex-direction: column;
	border-radius: 6px;
`;
