import styled from 'styled-components';

import colors from '../../styles/colors';

export const VARIATION = {
	square: '0.375em',
	pill: '9999px',
};

export const TYPE = {
	primary: colors.brandPrimary,
	secondary: colors.brandSecondary,
	danger: colors.statusBad,
	warning: colors.statusOkay,
	success: colors.statusGood,
};

export const Badge = styled.span`
	display: inline-flex;
	background-color: ${({ type }) => TYPE[type]};
	color: white;
	padding: 0.5em 1em;
	border-radius: ${({ variation }) => VARIATION[variation]};
	user-select: none;
	cursor: default;
	white-space: nowrap;

	svg {
		margin-right: 0.25rem;
	}
`;
