import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const PhoneNumber = styled.div`
	max-width: 20rem;
	margin: 0.75rem 0;
	.phone-number-input > h4 {
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 0.4rem;
	}
`;

export const CardParaContainer = styled.div`
	margin-top: 1.125rem;
	margin-bottom: 0.75rem;
`;

export const CardHeadingWrapper = styled.div`
	margin-top: 0.85rem;
`;

export const RadioContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 0.6rem;
	& > div {
		display: flex;
		margin-right: 5rem;
		align-items: center;
		label {
			margin-bottom: 0;
			cursor: pointer;
		}
	}

	& > span {
		margin-left: 1.5rem;
		margin-top: 6px;
	}
`;

export const ErrorMessage = styled.div`
	color: ${colors.statusBad};
	line-height: 1;
	margin-bottom: -0.3rem;
`;

export const CallPreference = styled.div`
	background: #f4f6f8;
	padding: 0.9rem;
	margin-top: 1.5rem;
`;

export const Card2ParaContainer = styled.div`
	margin-top: 1.125rem;
	margin-bottom: 0.75rem;
`;
export const DatePickerContainer = styled.div`
	max-width: 20rem;
	margin-bottom: 2.365rem;
	margin-top: 1.25rem;

	label {
		font-weight: 600;
		color: ${colors.formLabel};
	}

	@media screen and (max-width: 350px) {
		max-width: 15rem;
		input {
			padding-right: 0;
		}
	}
`;

// export const ChoicesWrapper = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	@media screen and (max-width: 768px) {
// 		flex-direction: column;
// 	}
// `;
// export const ChoiceBox = styled.div`
// 	width: 49%;
// 	@media screen and (max-width: 768px) {
// 		width: 100%;
// 		&:first-child {
// 			margin-bottom: 1rem;
// 		}
// 	}
// `;

export const PhoneLockWrapper = styled.div`
	padding: 1rem 1.5rem;
	border: 1px solid ${colors.neutralForm};
	button {
		width: 100%;
		border-radius: 0;
		margin-top: 1.25rem;
	}
`;

export const PhoneLockHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	svg {
		font-size: 2.5rem;
		color: ${colors.neutralButton};
	}
`;

export const PhoneLockHeaderHeadings = styled.div``;

export const BoxMarginWrapper = styled.div`
	margin: 0.85rem 0;
`;

export const ChoicesBoxesContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.25rem;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const PackagesContainer = styled.div`
	margin-top: 2.25rem;
	margin-bottom: 0.75rem;
`;

export const ServiceWrapper = styled.div`
	padding: 1.5rem 1.7rem;
	display: flex;
	justify-content: space-between;
	border: 1px solid ${colors.neutralText};
	border-radius: 4px;
	align-items: center;
`;

export const ServiceContentContainer = styled.div``;
export const AddSymbol = styled.div`
	display: inline-flex;
	font-size: 2.5rem;
	cursor: pointer;

	.checkCircleIcon {
		color: ${colors.brandPrimary};
	}
`;
