import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const GridWrapper = styled.div`
	display: grid;
	gap: 1.25rem;

	grid-template-areas:
		'a1'
		'a2'
		'a3';

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}
`;
