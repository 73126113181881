import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../../services/authenticationService';
import { retrieveAccountPaymentSummary, retrieveAccountSummary } from '../../../services/userService';
import {
	Current_Cus_Onb_Conf_Acc,
	Manage_Greenlight,
	Manage_Greenlight_Settings,
	Manage_Utility,
	Manage_Utility_Settings,
	New_Cust_Com_Onb_App,
	New_Cust_Com_Onb_Greenlight,
	New_Cust_Com_Onb_Utils,
	New_Cust_Res_Onb_App,
	New_Cust_Res_Onb_Greenlight,
	New_Cust_Res_Onb_Utils,
} from '../../../utils/constants';

export const fetchUser = createAsyncThunk('user/fetchUser', async ({ isAdmin, placeholderUser }) => {
	// Get items from storage
	const localStorageItem = window.localStorage.getItem('wilsonnc_user');
	const token = window.localStorage.getItem('wilsonnc_token');
	const role = window.localStorage.getItem('wilsonnc_role') || 'customer';
	let user;
	try {
		user = JSON.parse(localStorageItem);
	} catch (error) {
		window.localStorage.removeItem('wilsonnc_user');
		user = null;
	}

	// Create placeholder user
	const placeholder = process.env.REACT_APP_ENV === 'development' ? placeholderUser : {};

	// Validate token
	if (token) {
		// Fetch objects if necessary
		if (user != null && role !== 'customer') {
			// Return user
			return user;
		} else {
			let userObj = null;
			try {
				userObj = await fetchCurrentUser(token, true, placeholder, false);
				const locations = await retrieveAccountSummary(false);
				const accounts = (await retrieveAccountPaymentSummary(false)) || [];
				userObj = {
					...userObj,
					locations: (locations || []).map((location) => {
						const matchingUtilityAccount = accounts.find((account) => account.LocationID === location.LocationID && account.AccountKey === location.UtilityAccount?.AccountKey);
						const matchingGreenlightAccount = accounts.find((account) => account.LocationID === location.LocationID && account.AccountKey === location.GreenlightAccount?.AccountKey);

						return {
							...location,
							...(location.UtilityAccount != null
								? {
										UtilityAccount: {
											...location.UtilityAccount,
											...(matchingUtilityAccount != null && matchingUtilityAccount.AccountKey === location.UtilityAccount.AccountKey
												? {
														AutoPay: matchingUtilityAccount.AutoPay,
														WalletAccountKey: matchingUtilityAccount.WalletAccountKey,
														BankDraftEnabled: matchingUtilityAccount.BankDraft,
												  }
												: undefined),
											Services: (location.UtilityAccount.Services || [])
												.map((service) => {
													const s = service.toUpperCase();
													if (s === 'GAS' || s === 'ELECTRIC' || s === 'WATER' || s === 'SPRINKLER') return s;
													return false;
												})
												.filter(Boolean),
										},
								  }
								: undefined),
							...(location.GreenlightAccount != null
								? {
										GreenlightAccount: {
											...location.GreenlightAccount,
											...(matchingGreenlightAccount != null && matchingGreenlightAccount.AccountKey === location.GreenlightAccount.AccountKey
												? {
														AutoPay: matchingGreenlightAccount.AutoPay,
														WalletAccountKey: matchingGreenlightAccount.WalletAccountKey,
														BankDraftEnabled: matchingGreenlightAccount.BankDraft,
												  }
												: undefined),
										},
								  }
								: undefined),
						};
					}),
					onboardingComplete: role === 'staff' || (locations != null && locations.length > 0),
					isAdmin: user?.IsAdmin || isAdmin || placeholder.isAdmin === true,
					role: role,
				};
			} catch (e) {}

			// Parse user
			try {
				// Initialize user object
				const strippedUserObj = JSON.parse(JSON.stringify(userObj));

				// Filter out PDFs
				if (strippedUserObj.locations) {
					for (let i = 0; i < strippedUserObj.locations.length; i += 1) {
						if (strippedUserObj.locations[i].UtilityAccount) {
							delete strippedUserObj.locations[i].UtilityAccount.LastBillPDF;
						}
						if (strippedUserObj.locations[i].GreenlightAccount) {
							delete strippedUserObj.locations[i].GreenlightAccount.LastBillPDF;
						}
					}
				}

				// Store data
				window.localStorage.setItem('wilsonnc_token', token);
				window.localStorage.setItem('wilsonnc_role', 'customer');
				window.localStorage.setItem('wilsonnc_user', JSON.stringify(strippedUserObj));
			} catch (e) {}

			// Return user
			return userObj;
		}
	} else {
		window.localStorage.removeItem('wilsonnc_user');
		window.localStorage.removeItem('wilsonnc_token');
		window.localStorage.removeItem('wilsonnc_role');
		window.localStorage.removeItem('wilsonnc_auth_milestone');
		window.localStorage.removeItem('auth_modal_open');
		window.localStorage.removeItem('auth_modal_target');
		window.localStorage.removeItem(New_Cust_Com_Onb_Greenlight);
		window.localStorage.removeItem(New_Cust_Com_Onb_Utils);
		window.localStorage.removeItem(New_Cust_Res_Onb_App);
		window.localStorage.removeItem(New_Cust_Res_Onb_Greenlight);
		window.localStorage.removeItem(New_Cust_Res_Onb_Utils);
		window.localStorage.removeItem(New_Cust_Com_Onb_App);
		window.localStorage.removeItem(Current_Cus_Onb_Conf_Acc);
		window.localStorage.removeItem(Manage_Greenlight);
		window.localStorage.removeItem(Manage_Greenlight_Settings);
		window.localStorage.removeItem(Manage_Utility);
		window.localStorage.removeItem(Manage_Utility_Settings);
	}
	return null;
});

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		status: 'idle',
		error: null,
		value: null,
	},
	reducers: {
		updateUser: (state, action) => {
			state.value = action.payload;
		},
		clearUser: (state) => {
			state.value = null;
		},
	},
	extraReducers: {
		[fetchUser.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchUser.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchUser.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});

export const { updateUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
