import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import colors from '../../styles/colors';

const getBorderColor = (props) => {
	if (props.isDragAccept) {
		return colors.statusGood;
	}
	if (props.isDragReject) {
		return colors.statusBad;
	}
	return colors.neutralForm;
};

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;
	position: relative;
	grid-template-areas:
		'info'
		'dropzone'
		'files';

	.info-area {
		grid-area: info;
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'info -'
			'dropzone files';
	}
`;

export const DropAreaWrapper = styled.div`
	grid-area: dropzone;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	place-content: center;
	text-align: center;
	border-radius: 0.25rem;
	border: 1px dashed ${(props) => getBorderColor(props)};
	padding: 2rem 1rem;

	.dropfile-message {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		button {
			appearance: none;
			border: none;
			background: none;
			font-weight: inherit;
			color: ${colors.brandPrimary};

			&:hover {
				opacity: 0.8;
			}
		}
	}

	svg {
		height: 3rem !important;
		width: 3rem !important;
		align-self: center;
		margin-bottom: 0.75rem;
		color: ${colors.brandPrimary};
		cursor: pointer;
	}
	span {
		margin-top: 6px;
		color: ${colors.neutralText};
	}
`;

export const FilesWrapper = styled.div`
	grid-area: files;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.files-wrapper-title {
		padding-bottom: 0.5rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid ${colors.neutralForm};
	}
`;

export const File = styled.div`
	display: flex;
	border-bottom: 1px solid ${colors.neutralForm};
	align-items: center;
	padding: 0.5rem 0;

	svg {
		height: 1.125rem !important;
		width: 1.125rem !important;
	}

	.file-info {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		.filename-text {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.small-text {
			color: ${colors.neutralText};
		}
	}

	& > * + * {
		margin-left: 0.5rem;
	}

	&:first-of-type {
		padding-top: 0;
	}
`;

export const EmptyWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	padding: 1rem;
	align-items: center;
	color: ${colors.neutralText};
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: 0.05em;
	color: ${colors.statusBad};
`;

export const Warning = styled(FontAwesomeIcon)`
	color: ${colors.statusBad};
	margin: 0px 0px 0px 8px;
`;
