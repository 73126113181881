import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const FullWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: white;
	margin: -1rem;
	padding: 1rem;

	& > button {
		padding: 0;
		text-align: left;
		margin-bottom: 0.5rem;

		svg {
			width: 0.625rem !important;
		}
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-self: stretch;
	max-width: 32rem;
	flex-wrap: wrap;

	div:first-child {
		margin-right: 0.5rem;
	}
`;

export const InfoItem = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;
`;

export const GridWrapper = styled.div`
	display: grid;
	gap: 1.25rem;

	grid-template-areas:
		'a1'
		'a2'
		'a3';

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'a1 a2'
			'a3 a3';
	}
`;

export const PageHeader = styled.div`
	display: flex;
	align-items: center;
`;

export const UtilityIcon = styled.div`
	background-image: ${(props) => `url(${props.image})`};
	background-position: center;
	background-size: auto 100%;
	background-repeat: no-repeat;
	width: 45px;
	height: 45px;
	margin-right: 5px;
`;
