import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Card, Table, Spinner, EmptyComponent } from '../../components';
import { BadgeCell, DateTimeCell } from '../../components/Table';
import { retrieveAdminUserRequests } from '../../services/adminService';
import { getRequestTypes } from '../../services/allUsers';
import { META_TITLE } from '../../utils/constants';
import * as S from './AdminRequests.styles';
import { tableSorterDate } from '../../components/Table/Table';

export default function AdminRequests() {
	const history = useHistory();
	const [status, setStatus] = useState('loading');
	const [requests, setRequests] = useState([]);

	const [columns] = useState([
		{
			Header: 'Username',
			accessor: 'username',
		},
		{
			Header: 'Request Type',
			accessor: 'requestType',
		},
		{
			Header: 'Date Requested',
			accessor: 'dateRequested',
			Cell: DateTimeCell,
			CellName: 'DateTimeCell',
			sortType: tableSorterDate,
		},
		{
			Header: 'Assigned To',
			accessor: 'assignedTo',
		},
		{
			Header: 'Source',
			accessor: 'source',
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: BadgeCell,
			CellName: 'BadgeCell',
		},
	]);

	function navigateTo(request) {
		history.push(`requests/${request.RequestKey}`);
	}

	async function init() {
		try {
			const types = await getRequestTypes();
			const requestObjs = await retrieveAdminUserRequests({});
			setRequests(
				requestObjs
					.sort((a, b) => {
						return new Date(b.RequestDate) - new Date(a.RequestDate);
					})
					.filter((request) => {
						return request.IsOpen !== true;
					})
					.map((request) => {
						let status = 'incomplete';
						if (request.IsOpen === true) {
							status = 'new';
							if (request.AssignedTo) {
								status = 'inprogress';
							}
						} else if (request.ClosedDate != null) {
							status = 'closed';
						}
						return {
							id: request.RequestKey,
							RequestKey: request.RequestKey,
							username: request.PortalUser,
							requestType: types.find((type) => type.RequestTypeID === request.RequestType)?.RequestType,
							dateRequested: request.RequestDate,
							assignedTo: request.AssignedTo || '',
							status,
						};
					})
			);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Requests | ${META_TITLE}`;

		// Init data
		init();
	}, []);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (requests.length === 0) {
			return <EmptyComponent icon={['fal', 'file-alt']} title="No Closed Requests" description="Check back to view closed service requests." />;
		}
		return <Table columns={columns} data={requests} handleClick={navigateTo} />;
	};

	return (
		<S.Wrapper>
			<PageHeader>Requests</PageHeader>
			<Card title="Closed Requests">{renderContent()}</Card>
		</S.Wrapper>
	);
}
