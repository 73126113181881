import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3.5rem 1rem;
	height: 100%;

	.login-message {
		margin-top: 1.5rem;
	}
`;
