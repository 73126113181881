import React, { useEffect } from 'react';

import { PageHeader, PersonalDetails, ServiceAddresses, NotificationsPreferences } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './Profile.styles';

export default function Profile() {
	useEffect(() => {
		// Set document title
		document.title = `My Profile | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<PageHeader>Profile</PageHeader>
			<PersonalDetails className="a1" />
			<ServiceAddresses className="a2" />
			<NotificationsPreferences className="a3" />
		</S.Wrapper>
	);
}
