import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

import * as S from './Navigation.styles';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { AdminTopbar } from './AdminTopbar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Navigation = ({ children, links, modules, isAdmin, showAnnouncementBar = false }) => {
	const [barHeight, setBarHeight] = useState(0);
	const configuration = useSelector((state) => state.configuration.value);
	const announcementBarRef = useRef(null);

	// Handle actions on component load
	useEffect(() => {
		setBarHeight(announcementBarRef?.current?.clientHeight);
	}, []);

	// Handle actions on window resize
	useWindowResize(() => {
		setBarHeight(announcementBarRef.current?.clientHeight);
	});

	const renderAnnouncementBar = () =>
		configuration.announcementBar.url ? (
			<a href={configuration.announcementBar.url} target="_blank" rel="noreferrer">
				<S.AnnouncementBar ref={announcementBarRef}>{configuration.announcementBar.content}</S.AnnouncementBar>
			</a>
		) : (
			<S.AnnouncementBar ref={announcementBarRef}>{configuration.announcementBar.content}</S.AnnouncementBar>
		);

	return (
		<>
			{showAnnouncementBar && configuration?.showAnnouncementBar && renderAnnouncementBar()}
			<S.Navigation>
				<Sidebar links={links} modules={modules} />
				<S.Wrapper>
					{isAdmin ? <AdminTopbar /> : <Topbar />}
					<S.Content>{children}</S.Content>
					{showAnnouncementBar && configuration?.showAnnouncementBar && <S.AnnouncementBarSpacer $height={barHeight} />}
				</S.Wrapper>
			</S.Navigation>
		</>
	);
};

Navigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	links: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			to: PropTypes.string,
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			label: PropTypes.string,
		})
	),
	modules: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			Module: PropTypes.func,
		})
	),
	isAdmin: PropTypes.bool,
	showAnnouncementBar: PropTypes.bool,
};
