import React, { useEffect, useState } from 'react';

import { PageHeader, ScheduledPayments, PastPayments, Spinner } from '../../components';
import * as S from './PaymentHistory.styles';
import { PaymentHistoryFilterModal } from '../PaymentHistoryFilterModal';
import { FILTER_MAXIMUM_RANGE, META_TITLE, PAYMENT_TYPES } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { walletRetrieveAccounts } from '../../services/walletService';

const TYPES = {
	PAST: 'Past',
	SCHEDULED: 'Scheduled',
};

export default function PaymentHistory() {
	const currentLocation = useSelector((state) => state.location.value);
	const history = useHistory();
	const [status, setStatus] = useState('loading');
	const [paymentFilterModal, setPaymentFilterModal] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [filterSelectedOptionsPast, setFilterSelectedOptionsPast] = useState({
		paymentMethods: { card: false, bankDraft: false, eCheck: false },
		billTypes: { utility: false, greenlight: false },
		paymentTypes: {
			autopay: false,
			manual: false,
		},
		startDate: null,
		endDate: null,
		amount: {
			min: -1,
			max: -1,
		},
	});
	const [filterSelectedOptionsScheduled, setFilterSelectedOptionsScheduled] = useState({
		paymentMethods: { card: false, bankDraft: false, eCheck: false },
		billTypes: { utility: false, greenlight: false },
		paymentTypes: {
			autopay: false,
			manual: false,
		},
		startDate: null,
		endDate: null,
		amount: {
			min: -1,
			max: -1,
		},
	});
	const [filterModalType, setFilterModalType] = useState('');

	const onFilterButtonClick = (type) => {
		setFilterModalType(type);
		setPaymentFilterModal(true);
	};

	const appliedFiltersCount = (type) => {
		let filterCount = 0;
		const { paymentMethods: methods, billTypes, paymentTypes, startDate, endDate } = type === TYPES.PAST ? filterSelectedOptionsPast : filterSelectedOptionsScheduled;
		if (startDate) {
			filterCount++;
		}
		if (endDate) {
			filterCount++;
		}
		if (Object.keys(methods).some((k) => methods[k])) {
			filterCount++;
		}
		if (Object.keys(billTypes).some((k) => billTypes[k])) {
			filterCount++;
		}
		if (Object.keys(paymentTypes).some((k) => paymentTypes[k])) {
			filterCount++;
		}
		return filterCount;
	};

	async function fetchPaymentMethods() {
		try {
			const itemArray = await walletRetrieveAccounts();
			setPaymentMethods(itemArray);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Payment History | ${META_TITLE}`;

		// Fetch payment methods
		fetchPaymentMethods();
	}, []);

	const renderComponent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		return (
			<>
				<PaymentHistoryFilterModal
					isOpen={paymentFilterModal}
					setIsOpen={setPaymentFilterModal}
					filterModalType={filterModalType}
					selectedOptions={filterModalType === TYPES.PAST ? filterSelectedOptionsPast : filterSelectedOptionsScheduled}
					setFilterSelectedOptions={filterModalType === TYPES.PAST ? setFilterSelectedOptionsPast : setFilterSelectedOptionsScheduled}
				/>
				<S.Wrapper showScheduled={currentLocation?.IsPrePay !== true}>
					<PageHeader action={[{ id: 1, onClick: () => history.push('/settings'), label: 'Manage Wallet' }]}>Payment History</PageHeader>
					{currentLocation?.IsPrePay !== true && (
						<ScheduledPayments className="a1" isUserDashboard filterCount={appliedFiltersCount(TYPES.SCHEDULED)} onFilterButtonClick={() => onFilterButtonClick(TYPES.SCHEDULED)} filterSelectedOptions={filterSelectedOptionsScheduled} wallet={paymentMethods} />
					)}
					<PastPayments className="a2" isUserDashboard filterCount={appliedFiltersCount(TYPES.PAST)} onFilterButtonClick={() => onFilterButtonClick(TYPES.PAST)} filterSelectedOptions={filterSelectedOptionsPast} wallet={paymentMethods} />
				</S.Wrapper>
			</>
		);
	};

	return renderComponent();
}
