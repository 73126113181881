import toast from 'react-hot-toast';

import api from './api';
import { getUserProfile } from './userService';

export const errorHandler = (error) => {
	toast.error(error.message || 'We are currently unable to process your request, please try again later.');
};

const PORTAL_USER = 'Portal_User_';
const STAFF_USER = 'Staff_User_';

// ================= Helpers ====================

export const fetchCurrentUser = async (token, usePlaceholder = false, placeholderUser, shouldRedirect = true) => {
	// Set user
	const localStorageItem = window.localStorage.getItem('wilsonnc_user');
	let user;
	try {
		user = JSON.parse(localStorageItem);
	} catch (error) {
		user = null;
	}

	// Fetch user if necessary
	if (token != null) {
		const userInfo = await getUserProfile(token, shouldRedirect);
		user = userInfo;
	}

	// Set placeholder content if necessary
	if (placeholderUser != null && usePlaceholder === true && process.env.REACT_APP_ENV === 'development') {
		if (user) {
			user = {
				...user,
				...placeholderUser,
			};
		} else {
			user = placeholderUser;
		}
	}

	// Return user
	return user;
};

// ================= User Authentication ====================

export async function login({ email, password }) {
	const {
		data: { LoginExists, LoginSuccess, IsValidated, IsEnabled, LoginToken, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Login`, { UserEmail: email, Password: password });

	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (LoginSuccess === false) {
		throw new Error('The password entered is incorrect. Please try again.');
	} else if (LoginExists === false) {
		throw new Error('An account with this email does not exist.');
	} else if (!LoginToken) {
		throw new Error('We are currently unable to process your login request, please try again later.');
	} else {
		return LoginToken;
	}
}

export async function forgotPassword({ email }) {
	const {
		data: { LoginExists, ResetEmailSent, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Forgot_Password`, { UserEmail: email });

	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!LoginExists) {
		throw new Error('This email does not exist.');
	} else if (!ResetEmailSent) {
		throw new Error("We're having trouble sending your reset link. Please try again.");
	}
}

export async function validateResetPassword({ token }) {
	const {
		data: { TokenValid, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Validate_Reset_Password`, { PasswordResetToken: token });
	if (TokenValid) {
		return true;
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		throw new Error("We can't find a reset password request for this url. Please try again.");
	}
}

export async function setPassword({ newPassword, resetToken }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		if (resetToken) {
			const {
				data: { Success, TokenValid, HasErrors, ErrorMsg },
			} = await api.post(`${PORTAL_USER}Set_Password`, { NewPassword: newPassword, PasswordResetToken: resetToken });

			if (HasErrors) {
				throw new Error(ErrorMsg);
			} else if (!TokenValid) {
				throw new Error("We can't find a reset password request for this url. Please try again.");
			}
			return Success;
		} else if (token) {
			const {
				data: { Success, TokenValid, HasErrors, ErrorMsg },
			} = await api.post(`${PORTAL_USER}Set_Password`, { LoginToken: token, NewPassword: newPassword });

			if (HasErrors) {
				throw new Error(ErrorMsg);
			} else if (!TokenValid) {
				throw new Error("We can't find a reset password request for this url. Please try again.");
			}
			return Success;
		}
	} catch (error) {
		throw error;
	}
}

export async function createUser({ email, password }) {
	const {
		data: { UserCreated, UserEmailTaken, ValidationEmailSent, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Create`, { UserEmail: email, Password: password });

	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (UserEmailTaken) {
		throw new Error('This email is already signed up for MyWilson. Please enter a different email or return to the Login page and use the Forgot Password link.');
	} else if (UserCreated) {
		return true;
	}
}

export async function userValidationEmail({ email, forceEmail = false }) {
	const {
		data: { ValidationEmailSent, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Send_Validation_Email`, { UserEmail: email, ForceEmail: forceEmail });

	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (ValidationEmailSent) {
		return true;
	}
}

export async function userValidateLogin({ token1, token2 }) {
	const {
		data: { TokensValid, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Validate_Login`, { ValidationToken1: token1, ValidationToken2: token2 });

	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!TokensValid) {
		throw new Error("We can't find a verification request for this url. Please try again.");
	}
	return TokensValid;
}

export async function userExtendLoginSession() {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		if (token != null) {
			const {
				data: { TokenValid, TokenExtended, HasErrors, ErrorMsg },
			} = await api.get(`${PORTAL_USER}Extend_Login_Token/${token}`);
			if (TokenValid === false) {
				throw new Error('Your token is invalid.');
			} else if (HasErrors) {
				throw new Error(ErrorMsg);
			} else if (!TokenExtended) {
				throw new Error('Your account session was not extended.');
			} else {
				return TokenExtended;
			}
		}
		return false;
	} catch (error) {
		throw error;
	}
}

export async function acceptInvite({ inviteToken, password }) {
	try {
		const {
			data: { InviteTokenValid, InviteAccepted, IsEnabled, IsValidated, PasswordAge, LoginToken, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Invite_Accept`, { InviteToken: inviteToken, Password: password });

		if (InviteTokenValid === false) {
			throw new Error('Your invite token is invalid.');
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return {
				inviteAccepted: InviteAccepted,
				loginEnabled: IsEnabled,
				userValidated: IsValidated,
				passwordAge: PasswordAge,
				loginToken: LoginToken,
			};
		}
	} catch (error) {
		throw error;
	}
}

// ===============Staff Authentication====================

export async function loginStaff({ email, password }) {
	const {
		data: { LoginExists, LoginSuccess, IsAdmin, IsEnabled, LoginToken, HasErrors, ErrorMsg },
	} = await api.post(`${STAFF_USER}Login`, { UserEmail: email, Password: password });

	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (LoginSuccess === false) {
		throw new Error('The password entered is incorrect. Please try again.');
	} else if (LoginExists === false) {
		throw new Error('An account with this email does not exist.');
	} else if (!LoginToken) {
		throw new Error('We are currently unable to process your login request, please try again later.');
	} else {
		return { LoginToken, IsAdmin, role: 'staff', email: email, altEmail: email, isEnabled: IsEnabled };
	}
}

export async function staffForgotPassword({ email }) {
	const {
		data: { LoginExists, ResetEmailSent, HasErrors, ErrorMsg },
	} = await api.post(`${STAFF_USER}Forgot_Password`, { UserEmail: email });

	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!LoginExists) {
		throw new Error('This email does not exist.');
	} else if (!ResetEmailSent) {
		throw new Error("We're having trouble sending your reset link. Please try again.");
	}
}

export async function staffValidateResetPassword({ resetToken }) {
	const {
		data: { TokenValid, HasErrors, ErrorMsg },
	} = await api.post(`${STAFF_USER}Validate_Reset_Password`, { PasswordResetToken: resetToken });
	if (TokenValid) {
		return true;
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		throw new Error("We're having trouble resetting your password. Please try again.");
	}
}

export async function staffSetPassword({ newPassword, resetToken }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		if (resetToken) {
			const {
				data: { Success, TokenValid, HasErrors, ErrorMsg },
			} = await api.post(`${STAFF_USER}Set_Password`, { NewPassword: newPassword, PasswordResetToken: resetToken });
			if (HasErrors) {
				throw new Error(ErrorMsg);
			} else if (Success) {
				return true;
			}
		} else if (token) {
			const {
				data: { Success, TokenValid, HasErrors, ErrorMsg },
			} = await api.post(`${STAFF_USER}Set_Password`, { LoginToken: token, NewPassword: newPassword });
			if (HasErrors) {
				throw new Error(ErrorMsg);
			} else if (Success) {
				return true;
			}
		}
	} catch (error) {
		toast.error(error.message || 'Something went wrong, try again later!');
	}
}

export async function staffExtendLoginSession() {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		if (token != null) {
			const {
				data: { TokenValid, TokenExtended, HasErrors, ErrorMsg },
			} = await api.get(`${STAFF_USER}Extend_Login_Token/${token}`);

			if (TokenValid === false) {
				throw new Error('Your token is invalid.');
			} else if (HasErrors) {
				throw new Error(ErrorMsg);
			} else if (!TokenExtended) {
				throw new Error('Your account session was not extended.');
			} else {
				return TokenExtended;
			}
		}
	} catch (error) {
		throw error;
	}
}
