import styled from 'styled-components';
import colors from '../../styles/colors';

export const Navigation = styled.div`
	display: flex;
	height: inherit;
	position: relative;
	width: 100% !important;
`;

export const Wrapper = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	width: 100% !important;
`;

export const Content = styled.div`
	display: block;
	padding: 1rem 1rem 2.5rem;
	height: 100%;
	overflow: auto;
	width: 100% !important;
`;

export const AnnouncementBar = styled.div`
	width: 100% !important;
	padding: 20px 25px;
	color: white;
	background-color: ${colors.statusGreat};
	text-align: center;
	font-weight: 600;

	a,
	a:link,
	a:visited {
		text-decoration: underline;
		color: white;
	}
`;

export const AnnouncementBarSpacer = styled.div`
	width: 100%;
	height: ${({ $height }) => $height}px;
`;
