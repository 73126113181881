import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: ${colors.sidebarShadow};
	max-height: 100%;
	pointer-events: all;
	min-width: 340px;
	position: relative;
	z-index: 9999999999;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${colors.brandPrimary};
	color: white;
	padding: 1rem;

	h5 {
		pointer-events: none;
	}
`;

export const NotificationList = styled.ul`
	display: flex;
	flex-direction: column;
	list-style: none;
	max-height: 100%;
	margin: 0;
	overflow-x: auto;
	background-color: white;

	li {
		border-top: 1px solid ${colors.neutralForm};

		&:first-of-type {
			border: none;
		}
	}
`;

export const Notification = styled.button.attrs({ type: 'button' })`
	display: flex;
	width: 100%;
	background-color: ${({ isRead }) => (isRead ? 'white' : colors.neutralBackground)};
	color: black;
	border: none;
	padding: 1rem;

	&:hover {
		opacity: 0.8;
	}
`;

export const IconWrapper = styled.div`
	display: grid;
	place-content: center;
	background-color: ${colors.brandPrimary};
	border-radius: 100%;
	height: 1.625rem;
	width: 1.625rem;
	color: white;

	svg {
		width: 0.75rem !important;
		height: 0.75rem !important;
	}
`;

export const ContentWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 1rem;

	p {
		line-height: 1.125rem;
	}

	.time-ago {
		margin-top: 0.25rem;
		color: ${colors.neutralText};
	}
`;

export const EmptyWrapper = styled.div`
	height: 24rem; /* 384px */
	display: grid;
	place-content: center;
	color: ${colors.neutralText};

	h5 {
		margin-bottom: 0.25rem;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;

	svg {
		width: 5rem !important;
		height: 5rem !important;
	}
`;
