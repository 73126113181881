import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: white;
	box-shadow: ${colors.sidebarShadow};
	padding: 1.25em;

	& > h6 {
		margin: 0.25em auto 2em;
		max-width: 25em; /* 400px */
	}

	@media screen and (min-width: 375px) {
		padding: 1.5em;
	}

	@media screen and (min-width: 768px) {
		height: inherit;
		min-height: 41.25em; /* 660px */
		justify-content: flex-start;
	}
`;

export const MainWrapper = styled.main`
	display: grid;
	align-content: center;
	height: inherit;
	overflow: scroll;
	min-height: 32.5em; /* 520px */
	margin: 0 1em;
	padding: 1em 0;

	@media screen and (min-width: 768px) {
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
	}
`;

export const PictureWrapper = styled.div`
	display: flex;
	justify-content: center;
	height: 3.75em;
	min-height: 3.75em;
	background-color: ${colors.brandPrimary};

	@media screen and (min-width: 768px) {
		height: 8em;
		min-height: 8em;
	}
`;

export const Picture = styled.picture`
	display: flex;
	max-width: 4.25em;

	@media screen and (min-width: 768px) {
		max-width: 9.5em;
	}

	img {
		width: 100%;
		object-fit: contain;
	}
`;
export const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 5em;
	width: 5em;
	background-color: ${colors.neutralBackground};
	border-radius: 50%;
	margin: 0 auto;
	margin-bottom: 21px;
	img {
		height: 3.125em;
		width: 3.125em;
	}
	@media screen and (min-width: 768px) {
		height: 10.625em;
		width: 10.625em;

		img {
			height: 6.25em;
			width: 6.25em;
		}
	}
`;

export const ParaContainer = styled.div`
	max-width: 35.375em;
	margin: 0 auto;
	margin-top: 0.6875em;
`;

export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: white;
	padding: 1.25em;
	margin-top: 2rem;

	@media screen and (min-width: 375px) {
		padding: 1.5em;
	}

	@media screen and (min-width: 768px) {
		height: inherit;
		min-height: 41.25em; /* 660px */
		padding: 4em;
		justify-content: flex-start;
	}
`;

export const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 3em 0;

	& > * + * {
		margin-top: 1.5em;
	}

	button {
		align-self: center;
	}

	@media screen and (min-width: 768px) {
		max-width: 25em; /* 400px */
		width: 100%;
		margin: 3em auto;
	}
`;
