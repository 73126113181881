import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Card, Typography } from '../../components';

import * as S from './StatementTable.styles';
import { openBase64PDF } from '../../utils/pdf';
import { formatToUSD } from '../../utils/currency-formatter';

export const StatementTable = ({ className, invoice, accountData }) => {
	const meteredUtilities = [
		{ id: 1, name: 'Electric', consumption: ' 11.2 kWh', cost: '$100' },
		{ id: 2, name: 'Gas', consumption: ' 11.2 kWh', cost: '$100' },
		{ id: 3, name: 'Water', consumption: ' 11.2 kWh', cost: '$100' },
		{ id: 4, name: 'Sprinklers', consumption: ' 11.2 kWh', cost: '$100' },
	];

	const nonMeteredUtilities = [
		{ id: 1, name: 'Wastewater', cost: '$100' },
		{ id: 2, name: 'Stormwater', cost: '$100' },
		{ id: 3, name: 'Solid Waste', cost: '$100' },
	];

	return (
		<S.StatementSection>
			<Card
				className={className}
				title="Statement"
				action={[
					invoice.pdfData != null && {
						id: 1,
						label: 'View Statement',
						icon: ['fal', 'external-link'],
						type: 'outline',
						onClick: () => {
							openBase64PDF(invoice.pdfData);
						},
					},
				].filter(Boolean)}
			>
				{/*<S.TableHeader>
					<Row>
						<Col>
							<Typography tag="h6" weight="bold">
								Utility
							</Typography>
						</Col>
						<Col>
							<Typography tag="h6" weight="bold">
								Consumption
							</Typography>
						</Col>
						<Col>
							<Typography tag="h6" weight="bold">
								Cost
							</Typography>
						</Col>
					</Row>
				</S.TableHeader>
				<S.TableHeading>
					<Typography tag="p" variation="1" weight="bold">
						Metered Utilities
					</Typography>
				</S.TableHeading>
				<S.TableBody>
					{meteredUtilities.map((utility) => (
						<S.TableRow key={utility.id}>
							<Row>
								<Col>
									<Typography tag="p" variation="1" weight="regular">
										{utility.name}
									</Typography>
								</Col>
								<Col>
									<S.ConsumptionCol>
										<Typography tag="p" variation="1" weight="regular">
											{utility.consumption}
										</Typography>
									</S.ConsumptionCol>
								</Col>
								<Col>
									<Typography tag="p" variation="1" weight="regular">
										{utility.cost}
									</Typography>
								</Col>
							</Row>
						</S.TableRow>
					))}
				</S.TableBody>
				<S.TableHeading1>
					<Typography tag="p" variation="1" weight="bold">
						Non-Metered Utilities
					</Typography>
				</S.TableHeading1>
				<S.TableBody>
					{nonMeteredUtilities.map((utility) => (
						<S.TableRow key={utility.id}>
							<Row>
								<Col>
									<Typography tag="p" variation="1" weight="regular">
										{utility.name}
									</Typography>
								</Col>

								<Col md={{ span: 4, offset: 4 }}>
									<Typography tag="p" variation="1" weight="regular">
										{utility.cost}
									</Typography>
								</Col>
							</Row>
						</S.TableRow>
					))}
				</S.TableBody>
				<S.TableRow1>
					<Row>
						<Col md={{ span: 4, offset: 8 }}>
							<Row>
								<S.SubTotal>
									<Typography tag="p" variation="1" weight="bold">
										Subtotal
									</Typography>
								</S.SubTotal>
								<Typography tag="p" variation="1" weight="regular">
									{formatToUSD(invoice.AmountDue)}
								</Typography>
							</Row>
						</Col>
					</Row>
				</S.TableRow1>
				<S.TableRow1>
					<Row>
						<Col md={{ span: 4, offset: 8 }}>
							<S.TaxWrapper>
								<S.Tax>
									<Typography tag="p" variation="1" weight="bold">
										Tax
									</Typography>
								</S.Tax>
								<Typography tag="p" variation="1" weight="regular">
									$0.00
								</Typography>
							</S.TaxWrapper>
						</Col>
					</Row>
				</S.TableRow1>
				<S.TableRow1>
					<Row>
						<Col md={{ span: 4, offset: 8 }}>
							<Row>
								<S.Total>
									<Typography tag="p" variation="1" weight="bold">
										Total
									</Typography>
								</S.Total>
								<Typography tag="p" variation="1" weight="regular">
									{formatToUSD(invoice.AmountDue)}
								</Typography>
							</Row>
						</Col>
					</Row>
					</S.TableRow1>*/}
			</Card>
		</S.StatementSection>
	);
};
StatementTable.propTypes = {
	className: PropTypes.string,
	invoice: PropTypes.shape(),
	accountData: PropTypes.shape(),
};
