import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { PageHeader, Card, Typography, TextInput, Button } from '../../components';
import { META_TITLE } from '../../utils/constants';
import { required } from '../../utils/form-default-errors';
import * as S from './AdminMakePayment.styles';
import { errorHandler, otcAccountLookup, otcLogTransaction } from '../../services/adminService';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../styles/colors';
import axios from 'axios';
import { RadioInput } from '../../components/RadioInput/RadioInput';

// Create tokenpay instances
const greenlightCardTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_GREENLIGHT_PUBLISH_KEY);
const utilitiesCardTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_UTILITIES_PUBLISH_KEY);

const generalStyleContent = `
	#cardElement,
	#ACH {
		font-family: 'Source Sans Pro', sans-serif;
		height: auto;
		padding: 10px 12px;
		border-radius: 5px;
		border: 1px solid ${colors.neutralForm};
		box-sizing: border-box;
		line-height: 0;
		margin: 0px 0px 15px;
		width: 100%;
	}
	@media screen and (min-width: 768px) {
		#cardElement,
		#ACH {
			height: 100px;
		}
	}
	#cardErrorMessage,
	#ACHErrorMessage {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.statusBad};
		font-size: 0.8rem;
		position: absolute;
		margin-top: -12px;
	}
`;

const customStyleContent = `
	.input-style {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.black};
	}
	.input-hint {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.neutralButton};
		border-color: ${colors.neutralForm};
	}
	/* All fields in form */
	.form-wrapper {
	}
	/* Credit Card Number */
	.input-wrapper {
	}
	/* Expiry Date */
	.sub-wrapper {
	}
	.valid-class {
		color: ${colors.black};
	}
	.invalid-class {
		color: ${colors.statusBad};
	}
`;

export default function AdminMakePayment() {
	const [currentStep, setCurrentStep] = useState(1);
	const [subscriberIDLoading, setSubscriberIDLoading] = useState(false);
	const [paymentSubmitting, setPaymentSubmitting] = useState(false);
	const [paymentLogging, setPaymentLogging] = useState(false);
	const [subscriberInfo, setSubscriberInfo] = useState(null);
	const [paymentAmount, setPaymentAmount] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [confirmationInfo, setConfirmationInfo] = useState(null);

	const bottomRef = useRef();

	const {
		register: registerAccountType,
		formState: { errors: accountTypeErrors },
		reset: resetAccountType,
		setValue: setAccountTypeValue,
		watch: watchAccountType,
	} = useForm();

	const {
		register: registerPaymentType,
		formState: { errors: paymentTypeErrors },
		reset: resetPaymentType,
		setValue: setPaymentTypeValue,
		watch: watchPaymentType,
	} = useForm();

	const {
		handleSubmit: handleSubscriberSubmit,
		register: registerSubscriber,
		formState: { errors: subscriberErrors },
		reset: resetSubscriber,
	} = useForm();

	const {
		handleSubmit: handleAmountSubmit,
		register: registerAmount,
		formState: { errors: amountErrors },
		reset: resetAmount,
		setValue: setAmountValue,
		watch: watchAmount,
	} = useForm();

	const {
		handleSubmit: handleEmailSubmit,
		register: registerEmail,
		formState: { errors: emailErrors },
		reset: resetEmail,
	} = useForm();

	const formPaymentAmount = watchAmount('paymentAmount');
	const accountType = watchAccountType('accountType');
	const paymentType = watchPaymentType('paymentType');

	async function submitSubscriberId(data) {
		// Update state
		setSubscriberIDLoading(true);

		// Get parameters
		const { subscriberId } = data;

		// Attempt account lookup
		try {
			const subscriber = await otcAccountLookup({ type: accountType, id: subscriberId });

			// Update state
			setSubscriberInfo(subscriber);
			setSubscriberIDLoading(false);
		} catch (e) {
			// Display error
			errorHandler(e);

			// Update state
			setSubscriberIDLoading(false);
		}
	}

	function confirmSubscriber() {
		if (accountType === 'greenlight' && subscriberInfo.IsDepositDue === true) {
			setCurrentStep(2.5);
		} else {
			setCurrentStep(3);
		}
	}

	function resetSubscriberSearch() {
		setSubscriberInfo(null);
		setCurrentStep(2);
		resetSubscriber();
		resetPaymentType();
		resetAmount();
		resetEmail();
	}

	function submitAmount(data) {
		setPaymentAmount(data.paymentAmount);
		setCurrentStep(4);
	}

	function resetPaymentAmount() {
		resetAmount();
		setCurrentStep(3);
		resetEmail();
	}

	function resetForm() {
		setSubscriberInfo(null);
		setPaymentAmount(null);
		setPaymentMethod(null);
		resetAccountType();
		resetPaymentType();
		resetSubscriber();
		resetAmount();
		resetEmail();
		setCurrentStep(1);
	}

	async function submitPayment(paymentToken) {
		// Get login token
		const loginToken = window.localStorage.getItem('wilsonnc_token');

		// Attempt payment logic
		try {
			// Exchange payment token
			const { data: tokenData } = await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/payment/token`, {
				type: 'Credit Card',
				...(accountType === 'greenlight'
					? {
							greenlightToken: paymentToken,
					  }
					: {
							utilityToken: paymentToken,
					  }),
			});

			// Validate data
			if (!tokenData || !tokenData.accountToken) {
				throw new Error('There was an issue verifying your payment method. Please try again.');
			}
			if (tokenData.brand != null && tokenData.brand !== 'visa' && tokenData.brand !== 'mastercard') {
				throw new Error('MyWilson currently only supports Visa and Mastercard cards. Please enter another payment method.');
			}

			// Get parameters
			const { accountToken, expirationDate, last4, brand } = tokenData;

			// Set payment method
			setPaymentMethod(`${brand.toUpperCase()} ****${last4}`);

			// Complete payment
			const { data: paymentData } = await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/payment`, {
				loginToken: loginToken,
				token: accountToken,
				tokenSource: 'card',
				accountHolderName: subscriberInfo.SubscriberName,
				expirationDate: expirationDate,
				payments: [
					accountType === 'greenlight' && {
						type: 'greenlight',
						amount: paymentAmount,
						locationId: subscriberInfo.LocationID,
						accountKey: subscriberInfo.SubscriberID,
						accountNumber: subscriberInfo.SubscriberID,
						dateType: 'immediately',
					},
					accountType === 'utilities' && {
						type: 'utilities',
						amount: paymentAmount,
						locationId: subscriberInfo.LocationID,
						accountKey: subscriberInfo.CustomerID,
						accountNumber: subscriberInfo.CustomerID,
						dateType: 'immediately',
					},
				].filter(Boolean),
			});
			if (!paymentData || !paymentData.success) {
				throw new Error('There was an issue completing your payment. Please try again.');
			} else {
				// Update state
				setPaymentSubmitting(false);
				setCurrentStep(5);
				setConfirmationInfo(`${paymentData.authorizationCode}`);
			}
		} catch (e) {
			setPaymentSubmitting(false);
			toast.error(e.message);
			return;
		}
	}

	async function logPayment(data) {
		// Update state
		setPaymentLogging(true);

		// Get parameters
		const { email } = data;

		// Attempt payment log
		try {
			const paymentLogged = await otcLogTransaction({
				type: accountType,
				...(accountType === 'greenlight'
					? {
							subscriberId: subscriberInfo.SubscriberID,
							isDepositPayment: paymentType === 'deposit',
							...(paymentType === 'deposit'
								? {
										depositDocumentNumber: subscriberInfo.DepositDocumentNumber,
								  }
								: undefined),
					  }
					: {
							customerId: subscriberInfo.CustomerID,
					  }),
				customerNotificationEmail: email,
				locationId: subscriberInfo.LocationID,
				paymentAmount: paymentAmount,
				confirmationInfo: confirmationInfo,
			});

			// Update state
			setPaymentLogging(false);

			// Check state
			if (paymentLogged) {
				setCurrentStep(6);
			} else {
				toast.error('There was an issue logging your payment. Please notify an administrator.');
			}
		} catch (e) {
			// Display error
			errorHandler(e);

			// Update state
			setPaymentLogging(false);
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `New Payment | ${META_TITLE}`;
	}, []);

	useEffect(() => {
		if (accountType) {
			resetSubscriberSearch();
		}
	}, [accountType]);

	useEffect(() => {
		if (paymentType) {
			setCurrentStep(3);
			resetAmount();
			resetEmail();
		}
	}, [paymentType]);

	useEffect(() => {
		if (currentStep === 4) {
			setTimeout(() => {
				// Initialize card elements
				let cardTokenpay = accountType === 'greenlight' ? greenlightCardTokenpay : utilitiesCardTokenpay;
				try {
					cardTokenpay.initialize({
						dataElement: 'cardElement',
						errorElement: 'cardErrorMessage',
						iframeId: 'paymentCardFormFrame',
						useACH: false,
						useStyles: true,
						disableZip: false,
						disableCvv: false,
					});
				} catch (e) {
					console.log(e);
				}

				// Create listener for form submission
				const cardForm = document.getElementById('paymentCardForm');
				if (cardForm != null) {
					cardForm.addEventListener('submit', async function (event) {
						event.preventDefault();
						setPaymentSubmitting(true);
						try {
							cardTokenpay.createToken(
								function (result) {
									if (!result.token) {
										setPaymentSubmitting(false);
										toast.error('There was an error verifying your card. Please try again.');
										return;
									}
									submitPayment(result.token);
								},
								function () {
									setPaymentSubmitting(false);
									toast.error('There was an error verifying your card. Please try again.');
								}
							);
						} catch (e) {
							setPaymentSubmitting(false);
							toast.error('There was an error verifying your card. Please try again.');
						}
						return false;
					});
				}
			}, 100);
		}

		// Scroll to bottom of page
		setTimeout(() => {
			bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
		}, 100);
	}, [currentStep]);

	return (
		<S.Wrapper>
			{/* Title */}
			<PageHeader>New Payment</PageHeader>

			{/* Step 1 - Greenlight / Utilities Selection */}
			{currentStep >= 1 && (
				<Card title="Account Type">
					<S.CardInner>
						<S.Form>
							<RadioInput
								label="Select an account type to make a payment"
								id="accountType"
								options={[
									{
										label: 'Greenlight',
										value: 'greenlight',
									},
									{
										label: 'Utilities',
										value: 'utilities',
									},
								]}
								{...registerAccountType('accountType', {
									required: required('Account type'),
								})}
								disabled={currentStep > 4}
								stateValue={accountType}
								error={accountTypeErrors.accountType}
								onChangeFunc={(id, value) => setAccountTypeValue(id, value)}
							/>
						</S.Form>
					</S.CardInner>
				</Card>
			)}

			{/* Step 2 - Account Lookup */}
			{currentStep >= 2 && (
				<Card title={`${accountType === 'greenlight' ? 'Greenlight' : 'Utilities'} Account Lookup`}>
					<S.CardInner>
						<S.Form onSubmit={handleSubscriberSubmit(submitSubscriberId)}>
							{!subscriberInfo ? (
								<TextInput
									label={accountType === 'greenlight' ? 'Subscriber ID' : 'Location ID'}
									id="subscriberId"
									type="text"
									error={subscriberErrors.subscriberId}
									{...registerSubscriber('subscriberId', {
										required: required(accountType === 'greenlight' ? 'Subscriber ID' : 'Location ID'),
									})}
								/>
							) : (
								<S.SubscriberInfo>
									{accountType === 'greenlight' ? (
										<>
											{subscriberInfo.SubscriberName && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Subscriber Name
													</Typography>
													<Typography tag="p">{subscriberInfo.SubscriberName}</Typography>
												</div>
											)}
											{subscriberInfo.SubscriberID && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Subscriber ID
													</Typography>
													<Typography tag="p">{subscriberInfo.SubscriberID}</Typography>
												</div>
											)}
											{subscriberInfo.SubscriberAddress && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Subscriber Address
													</Typography>
													<Typography tag="p">{subscriberInfo.SubscriberAddress}</Typography>
												</div>
											)}
											<div>
												<Typography tag="span" weight="bold" variation="3">
													Service Enabled
												</Typography>
												<Typography tag="p">{subscriberInfo.ServiceEnabled === true ? 'Yes' : 'No'}</Typography>
											</div>
											<div>
												<Typography tag="span" weight="bold" variation="3">
													Deposit Due
												</Typography>
												<Typography tag="p">{subscriberInfo.IsDepositDue === true ? 'Yes' : 'No'}</Typography>
											</div>
											{subscriberInfo.DepositDocumentNumber && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Deposit Document Number
													</Typography>
													<Typography tag="p">{subscriberInfo.DepositDocumentNumber}</Typography>
												</div>
											)}
										</>
									) : (
										<>
											{subscriberInfo.CustomerName && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Customer Name
													</Typography>
													<Typography tag="p">{subscriberInfo.CustomerName}</Typography>
												</div>
											)}
											{subscriberInfo.CustomerID && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Customer ID
													</Typography>
													<Typography tag="p">{subscriberInfo.CustomerID}</Typography>
												</div>
											)}
											{subscriberInfo.LocationID && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Location ID
													</Typography>
													<Typography tag="p">{subscriberInfo.LocationID}</Typography>
												</div>
											)}
											{subscriberInfo.CustomerAddress && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Customer Address
													</Typography>
													<Typography tag="p">{subscriberInfo.CustomerAddress}</Typography>
												</div>
											)}
											{subscriberInfo.LocationStatus && (
												<div>
													<Typography tag="span" weight="bold" variation="3">
														Location Status
													</Typography>
													<Typography tag="p">{subscriberInfo.LocationStatus}</Typography>
												</div>
											)}
										</>
									)}
								</S.SubscriberInfo>
							)}
							<S.CardFooter>
								{!subscriberInfo && (
									<Button disabled={subscriberIDLoading} type="submit">
										<Typography tag="span" variation="2" weight="extrablack">
											{subscriberIDLoading ? 'Loading...' : 'Search'}
										</Typography>
									</Button>
								)}
								{subscriberInfo && (
									<Button type="button" disabled={currentStep > 2}>
										<Typography tag="span" variation="2" weight="extrablack" onClick={confirmSubscriber}>
											Confirm & Continue
										</Typography>
									</Button>
								)}
								{subscriberInfo && (
									<Button variant="text" type="button" onClick={resetSubscriberSearch} disabled={currentStep > 4}>
										<Typography tag="span" variation="2" weight="extrablack">
											Update
										</Typography>
									</Button>
								)}
							</S.CardFooter>
						</S.Form>
					</S.CardInner>
				</Card>
			)}

			{/* Step 2.5 - Payment Type */}
			{currentStep >= 2.5 && accountType === 'greenlight' && subscriberInfo.IsDepositDue === true && (
				<Card title="Payment Type">
					<S.CardInner>
						<S.Form>
							<RadioInput
								label="Do you want to make a deposit payment or an account payment?"
								id="paymentType"
								options={[
									{
										label: 'Deposit Payment',
										value: 'deposit',
									},
									{
										label: 'Account Payment',
										value: 'account',
									},
								]}
								{...registerPaymentType('paymentType', {
									required: required('Account type'),
								})}
								disabled={currentStep > 4}
								stateValue={paymentType}
								error={paymentTypeErrors.paymentType}
								onChangeFunc={(id, value) => setPaymentTypeValue(id, value)}
							/>
						</S.Form>
					</S.CardInner>
				</Card>
			)}

			{/* Step 3 - Payment Amount */}
			{currentStep >= 3 && (
				<Card title="Payment Amount">
					<S.CardInner>
						<S.Form onSubmit={handleAmountSubmit(submitAmount)}>
							<S.AmountInputContainer>
								<FontAwesomeIcon icon={['fal', 'dollar-sign']} />
								<TextInput
									disabled={currentStep > 3}
									containerClassName="amountInput"
									id="paymentAmount"
									error={amountErrors.paymentAmount}
									{...registerAmount('paymentAmount', {
										required: required('Payment amount'),
									})}
									type="text"
									maxLength="7"
									value={formPaymentAmount}
									onChange={(e) => {
										let val = e.target.value.replace(/[^0-9]/g, '');
										if (val != null && val.length >= 3) {
											let value = val.split('');
											if (value.length > 6) value.length = 6;
											value.splice(value.length - 2, 0, '.');
											val = value.join('');
										}
										setAmountValue('paymentAmount', val);
									}}
								/>
							</S.AmountInputContainer>
							<S.CardFooter>
								<Button type="submit" disabled={currentStep > 3}>
									<Typography tag="span" variation="2" weight="extrablack">
										Continue
									</Typography>
								</Button>
								{currentStep > 3 && (
									<Button variant="text" type="button" onClick={resetPaymentAmount} disabled={currentStep > 4}>
										<Typography tag="span" variation="2" weight="extrablack">
											Update
										</Typography>
									</Button>
								)}
							</S.CardFooter>
						</S.Form>
					</S.CardInner>
				</Card>
			)}

			{/* Step 4 - Payment Info */}
			{currentStep >= 4 && (
				<Card title="Payment Method">
					<S.CardInner>
						<style>{generalStyleContent}</style>
						<style id="customStyles">{customStyleContent}</style>
						<div className="inputContainer">
							<Typography tag="label">Card Details</Typography>
						</div>
						<form id="paymentCardForm" action={`${process.env.REACT_APP_BRIDGEPAY_URL}/WebSecurity/echo.aspx`} method="post">
							<div id="cardElement" />
							<div id="cardErrorMessage" />
						</form>
						<S.CardFooter>
							<Button
								type="button"
								disabled={currentStep > 4 || paymentSubmitting}
								onClick={() => {
									document.getElementById('paymentCardForm').dispatchEvent(new Event('submit', { cancelable: true }));
								}}
							>
								<Typography tag="span" variation="2" weight="extrablack">
									{paymentSubmitting ? 'Loading...' : 'Save & Submit Payment'}
								</Typography>
							</Button>
						</S.CardFooter>
					</S.CardInner>
				</Card>
			)}

			{/* Step 5 - Confirmation Email */}
			{currentStep >= 5 && (
				<Card title="Confirmation Email">
					<S.CardInner>
						<S.Form onSubmit={handleEmailSubmit(logPayment)}>
							<TextInput label="Email" id="email" type="text" error={emailErrors.email} {...registerEmail('email')} disabled={currentStep > 5} />
							<S.CardFooter>
								<Button type="submit" disabled={currentStep > 5 || paymentLogging}>
									<Typography tag="span" variation="2" weight="extrablack">
										{paymentLogging ? 'Loading...' : 'Continue'}
									</Typography>
								</Button>
							</S.CardFooter>
						</S.Form>
					</S.CardInner>
				</Card>
			)}

			{/* Step 6 - Payment Confirmation */}
			{currentStep >= 6 && (
				<Card
					title="Payment Confirmation"
					action={[
						{
							id: 1,
							onClick: () => {
								const mywindow = window.open('', 'PRINT', 'height=400,width=600');
								mywindow.document.write('<html><head><title>' + document.title + '</title>');
								mywindow.document.write('</head><body >');
								mywindow.document.write(document.getElementById('printArea').innerHTML);
								mywindow.document.write('</body></html>');
								mywindow.document.close(); // necessary for IE >= 10
								mywindow.focus(); // necessary for IE >= 10*/
								mywindow.print();
								mywindow.close();
							},
							icon: ['fal', 'print'],
							label: 'Print',
							type: 'outline',
						},
					]}
				>
					<S.CardInner>
						<S.SubscriberInfo id="printArea">
							{accountType === 'greenlight' ? (
								<>
									{subscriberInfo.SubscriberName && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Subscriber Name
											</Typography>
											<Typography tag="p">{subscriberInfo.SubscriberName}</Typography>
										</div>
									)}
									{subscriberInfo.SubscriberID && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Subscriber ID
											</Typography>
											<Typography tag="p">{subscriberInfo.SubscriberID}</Typography>
										</div>
									)}
									{subscriberInfo.SubscriberAddress && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Subscriber Address
											</Typography>
											<Typography tag="p">{subscriberInfo.SubscriberAddress}</Typography>
										</div>
									)}
								</>
							) : (
								<>
									{subscriberInfo.CustomerName && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Customer Name
											</Typography>
											<Typography tag="p">{subscriberInfo.CustomerName}</Typography>
										</div>
									)}
									{subscriberInfo.CustomerID && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Customer ID
											</Typography>
											<Typography tag="p">{subscriberInfo.CustomerID}</Typography>
										</div>
									)}
									{subscriberInfo.LocationID && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Location ID
											</Typography>
											<Typography tag="p">{subscriberInfo.LocationID}</Typography>
										</div>
									)}
									{subscriberInfo.CustomerAddress && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Customer Address
											</Typography>
											<Typography tag="p">{subscriberInfo.CustomerAddress}</Typography>
										</div>
									)}
									{subscriberInfo.LocationStatus && (
										<div>
											<Typography tag="span" weight="bold" variation="3">
												Location Status
											</Typography>
											<Typography tag="p">{subscriberInfo.LocationStatus}</Typography>
										</div>
									)}
								</>
							)}
							<div>
								<Typography tag="span" weight="bold" variation="3">
									Payment Amount
								</Typography>
								<Typography tag="p">${paymentAmount}</Typography>
							</div>
							<div>
								<Typography tag="span" weight="bold" variation="3">
									Payment Method
								</Typography>
								<Typography tag="p">{paymentMethod}</Typography>
							</div>
							<div>
								<Typography tag="span" weight="bold" variation="3">
									Authorization Code
								</Typography>
								<Typography tag="p">{confirmationInfo}</Typography>
							</div>
						</S.SubscriberInfo>
						<S.CardFooter>
							<Button type="button" onClick={resetForm}>
								<Typography tag="span" variation="2" weight="extrablack">
									Start Over
								</Typography>
							</Button>
						</S.CardFooter>
					</S.CardInner>
				</Card>
			)}
			<div ref={bottomRef} />
		</S.Wrapper>
	);
}
