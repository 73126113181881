import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.statusBad : 'inherit')};
	}
`;

export const LabelContainer = styled.div`
	margin-top: 1.125rem;
	margin-bottom: 0.4rem;
	svg {
		color: ${colors.brandPrimary};
		margin-left: 0.25rem;
	}
`;

export const RadioContainer = styled.div`
	display: flex;
	margin-bottom: 0.6rem;
	align-items: start;
	gap: 5px;
	flex-direction: column;

	@media screen and (min-width: 768px) {
		align-items: center;
		gap: 0px;
		flex-direction: row;
	}

	& > div {
		display: flex;
		margin-right: 5rem;
		align-items: center;
		label {
			margin-bottom: 0;
			cursor: pointer;
		}
	}

	& > span {
		margin-left: 1.5rem;
		margin-top: 6px;
	}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1em;
	color: ${colors.statusBad};
`;
