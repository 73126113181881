import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../../Typography';
import * as S from './MobileTable.styles';
import { useEffect } from 'react';
import { formatToUSD } from '../../../utils/currency-formatter';
import { AvailableStatementCell } from '../Table';

function MobileItem({ originalId, originalObj, values, handleClick }) {
	const [first, ...remainingValues] = values;

	function onClick(item) {
		if (handleClick) {
			handleClick(item);
		}
	}

	return (
		<S.ItemButton className="table-item--mobile" withHoverEffect={!!handleClick} onClick={() => onClick(originalObj)}>
			<div className="item-head">
				<Typography tag="h4" weight="bold">
					{first.value}
				</Typography>
			</div>
			<div className="item-content">
				{remainingValues.map(({ accessor, label, value, cellName }) => {
					if (cellName === 'AvailableStatementCell') {
						return <AvailableStatementCell key={accessor} row={{ original: { ...originalObj } }} />;
					}
					return (
						<div key={accessor}>
							<Typography tag="h6" weight="bold">
								{label}
							</Typography>
							<Typography tag="p">{value}</Typography>
						</div>
					);
				})}
			</div>
		</S.ItemButton>
	);
}
MobileItem.propTypes = {
	values: PropTypes.array,
	handleClick: PropTypes.func,
	originalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	originalObj: PropTypes.shape(),
};

export function MobileTable({ columns, data, handleClick }) {
	const [items, setItems] = useState([]);

	useEffect(() => {
		if (columns && data) {
			setItems(mapListObjects());
		}
	}, [columns, data]);

	function mapListObjects() {
		return Object.values(data).map((dataItem, index) => {
			const values = Object.entries(dataItem)
				.map((item) => {
					const [accessor, value] = item;
					const column = columns.find((column) => column.accessor === accessor);
					if (column != null && value != null && value !== '') {
						if (column.Cell != null && column.CellName === 'CurrencyCell') {
							return { accessor, value: isNaN(value) ? '--' : formatToUSD(value), label: column?.Header, cell: column.Cell, cellName: column.CellName };
						}
						return { accessor, value: typeof value === 'string' ? value.charAt(0).toUpperCase() + value.substr(1) : value, label: column?.Header, cell: column.Cell, cellName: column.CellName };
					}
					return null;
				})
				.filter(Boolean)
				.sort((a, b) => {
					if (a.accessor && a.accessor) {
						const aColumnIndex = columns.findIndex((column) => column.accessor === a.accessor);
						const bColumnIndex = columns.findIndex((column) => column.accessor === b.accessor);
						if (aColumnIndex > -1 && bColumnIndex > -1) {
							return aColumnIndex - bColumnIndex;
						}
					}
					return 1;
				});
			return { id: index, originalId: dataItem?.id, originalObj: dataItem, values };
		});
	}

	return (
		<S.MobileWrapper>
			{items.map(({ id, values, originalObj, originalId }) => (
				<MobileItem key={id} originalId={originalId} originalObj={originalObj} values={values} handleClick={handleClick} />
			))}
		</S.MobileWrapper>
	);
}
MobileTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	handleClick: PropTypes.func,
};
