import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchConfiguration = createAsyncThunk('configuration/fetchConfiguration', async () => {
	try {
		// Fetch current configuration
		const { data } = await axios.get(`${process.env.REACT_APP_INTERNAL_API_URL}/configuration`);

		// Return configuration
		return data;
	} catch (e) {
		// Return null
		return null;
	}
});

export const configurationSlice = createSlice({
	name: 'configuration',
	initialState: {
		status: 'idle',
		isLoaded: false,
		enableMaintenanceMode: false,
		error: null,
		value: null,
	},
	reducers: {
		updateConfiguration: (state, action) => {
			state.value = action.payload;
		},
		clearConfiguration: (state) => {
			state.value = null;
		},
	},
	extraReducers: {
		[fetchConfiguration.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchConfiguration.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
			state.enableMaintenanceMode = action.payload?.enableMaintenanceMode;
			state.isLoaded = true;
		},
		[fetchConfiguration.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});

export const { updateConfiguration, clearConfiguration } = configurationSlice.actions;

export default configurationSlice.reducer;
