import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { newCustomerResidentialApplication } from '../../app/slices/onboarding/onboardingSlice';
import { Button, OnboardingFooter, OnboardingHeader, TextInput, Typography, Stepper, Card, DropFile, ButtonToggle, AddressInput, DatePicker } from '../../components';
import { META_TITLE, New_Cust_Res_Onb_App } from '../../utils/constants';
import { required } from '../../utils/form-default-errors';
import * as S from './OnboardingNewCustomerResidentialApplication.styles';

export default function OnboardingNewCustomerResidentialApplication({ showDashboard }) {
	const [photoId, setPhotoId] = useState(null);
	const [socialSecurityCard, setSocialSecurityCard] = useState(null);
	const [lawfulOccupancy, setLawfulOccupancy] = useState(null);

	const [applicationOptions, setApplicationOptions] = useState([
		{ id: 'individual', label: 'Individual', selected: true },
		{ id: 'organization', label: 'Organization', selected: false },
	]);

	const stepData = useSelector((state) => state.onboarding);
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		control,
		reset,
		watch,
	} = useForm({ defaultValues: { ...stepData.newCustomer.residential.application } });

	const movingDate = watch('movingDate');

	const onBack = () => {
		history.push('/onboarding/new-customer/building-type');
	};

	const onNext = (data) => {
		const tempData = { ...data };

		// Set file content
		tempData.photoId = photoId;
		tempData.socialSecurityCard = socialSecurityCard;
		tempData.lawfulOccupancy = lawfulOccupancy;

		// Update application
		dispatch(newCustomerResidentialApplication(tempData));
		const stringifyData = JSON.stringify(data);
		localStorage.setItem(New_Cust_Res_Onb_App, stringifyData);
		history.push('/onboarding/new-customer/residential/utilities');
	};

	useEffect(() => {
		// Set document title
		document.title = `New Customer | ${META_TITLE}`;

		const data = localStorage.getItem(New_Cust_Res_Onb_App);
		let tempData;

		if (data) {
			tempData = JSON.parse(data);
		}
		if (tempData) {
			setValue('firstName', tempData?.firstName);
			setValue('lastName', tempData?.lastName);
			setValue('serviceAddressToConnect', tempData?.serviceAddressToConnect);
			setValue('driverLicense', tempData?.driverLicense);
			setValue('ssn', tempData?.ssn);
			setValue('homePhone', tempData?.homePhone);
			setValue('workPhone', tempData?.workPhone);
			setValue('cellPhone', tempData?.cellPhone);
			setValue('movingFromAddress', tempData?.movingFromAddress);
			setValue('movingDate', tempData?.movingDate);
			setValue('coApplicantFirstName', tempData?.coApplicantFirstName);
			setValue('coApplicantLastName', tempData?.coApplicantLastName);
			setValue('coApplicantDriverLicense', tempData?.coApplicantDriverLicense);
			setValue('coApplicantSsn', tempData?.coApplicantSsn);
			setValue('busFederalTaxId', tempData?.busFederalTaxId);
			setValue('businessName', tempData?.businessName);
			setValue('mailingAddress', tempData?.mailingAddress);
			setValue('nameOfResponsibleParty', tempData?.nameOfResponsibleParty);
			setValue('phone', tempData?.phone);
			setValue('relationship', tempData?.relationship);
		}
		const { photoId = [], socialSecurityCard = [], lawfulOccupancy = [] } = stepData?.newCustomer.residential.application;

		setPhotoId(photoId);
		setSocialSecurityCard(socialSecurityCard);
		setLawfulOccupancy(lawfulOccupancy);
	}, []);

	useEffect(() => {
		reset({ ...stepData.newCustomer.residential.application });
	}, [applicationOptions]);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<OnboardingHeader showDashboard={showDashboard} />
			<Stepper
				activeStep={1}
				steps={[
					{ id: 1, label: 'Location' },
					{ id: 2, label: 'Application' },
					{ id: 3, label: 'Utilities' },
					{ id: 4, label: 'Greenlight' },
					{ id: 5, label: 'Review' },
				]}
			/>
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag="h1" weight="bold" center>
						Apply for Services
					</Typography>

					<Card title="Application">
						<S.ApplicationTypeSection>
							<Typography tag="p" variation="1">
								I am applying as an
							</Typography>
							<ButtonToggle options={applicationOptions} setOptions={setApplicationOptions} size="small" key="toggle" />
						</S.ApplicationTypeSection>

						<Typography tag="h4" weight="bold">
							Applicant Information
						</Typography>
						<Typography tag="p" variation="2">
							*Indicates a Required Field
						</Typography>

						{applicationOptions.find((option) => option.selected === true).id === 'individual' ? (
							<>
								<S.ApplicationGrid>
									<TextInput
										containerClassName="item-firstName"
										label="First Name *"
										id="firstName"
										error={errors.firstName}
										{...register('firstName', {
											required: required('First Name'),
										})}
									/>
									<TextInput
										containerClassName="item-lastName"
										label="Last Name *"
										id="lastName"
										error={errors.lastName}
										{...register('lastName', {
											required: required('Last Name'),
										})}
									/>
									<Controller
										name="item-serviceAddressToConnect"
										control={control}
										rules={{ required: required('Service Address to Connect') }}
										render={({ field: { onChange, value }, fieldState }) => <AddressInput containerClassName="item-serviceAddressToConnect" label="Service Address to Connect *" id="serviceAddressToConnect" error={fieldState.error} onChange={onChange} value={value} />}
									/>
									<TextInput containerClassName="item-mailingAddress" label="Mailing Address (if different)" id="mailingAddress" error={errors.mailingAddress} {...register('mailingAddress')} />
									<TextInput
										containerClassName="item-driverLicense"
										label="Driver's License State/Number *"
										id="driverLicense"
										error={errors.driverLicense}
										{...register('driverLicense', {
											required: required("Driver's License Number"),
										})}
									/>
									<TextInput containerClassName="item-ssn" label="SSN" showWarning={true} warningText="Optional: Failure to submit a Social Security number will require a High Risk Deposit." id="ssn" error={errors.ssn} {...register('ssn', {})} />
									{/*<TextInput containerClassName="item-employer" label="Employer" id="employer" error={errors.employer} {...register('employer', {})} />*/}
									<TextInput containerClassName="item-homePhone" label="Home Phone" id="homePhone" error={errors.homePhone} {...register('homePhone', {})} />
									<TextInput containerClassName="item-workPhone" label="Work Phone" id="workPhone" error={errors.workPhone} {...register('workPhone', {})} />
									<TextInput containerClassName="item-cellPhone" label="Cell Phone" id="cellPhone" error={errors.cellPhone} {...register('cellPhone', {})} />
									<TextInput containerClassName="item-movingFromAddress" label="Address Moving From" id="movingFromAddress" error={errors.movingFromAddress} {...register('movingFromAddress')} />
									<div className="item-movingDate" style={{ marginTop: '-2px' }}>
										<DatePicker label="Moving Date" selected={movingDate} icon="angle-down" {...register('movingDate')} onChange={(date) => setValue('movingDate', date)} error={!movingDate && errors.movingDate} id="movingDatePicker" minDate={new Date()} />
									</div>
								</S.ApplicationGrid>
								<Typography tag="h4" weight="bold">
									Co-Applicant Information
								</Typography>
								<S.CoApplicationGrid>
									<TextInput containerClassName="item-coApplicantFirstName" label="First Name" id="coApplicantFirstName" error={errors.coApplicantFirstName} {...register('coApplicantFirstName')} />
									<TextInput containerClassName="item-coApplicantLastName" label="Last Name" id="coApplicantLastName" error={errors.coApplicantLastName} {...register('coApplicantLastName')} />
									<TextInput containerClassName="item-coApplicantDriverLicense" label="Driver's License State/Number" id="coApplicantDriverLicense" error={errors.coApplicantDriverLicense} {...register('coApplicantDriverLicense')} />
									<TextInput
										containerClassName="item-coApplicantSsn"
										label="SSN"
										showWarning={true}
										warningText="Optional: Failure to submit a Social Security number will require a High Risk Deposit."
										id="coApplicantSsn"
										error={errors.coApplicantSsn}
										{...register('coApplicantSsn')}
									/>

									{/*<TextInput containerClassName="item-coApplicantEmployer" label="Employer" id="coApplicantEmployer" error={errors.coApplicantEmployer} {...register('coApplicantEmployer')} />*/}
								</S.CoApplicationGrid>
							</>
						) : (
							<>
								<S.ApplicationGrid1>
									<TextInput containerClassName="item-firstName" label="First Name" id="firstName" error={errors.firstName} {...register('firstName')} />
									<TextInput containerClassName="item-lastName" label="Last Name" id="lastName" error={errors.lastName} {...register('lastName')} />
									<TextInput
										containerClassName="item-businessName"
										label="Business Name *"
										id="businessName"
										error={errors.businessName}
										{...register('businessName', {
											required: required('Business Name'),
										})}
									/>
								</S.ApplicationGrid1>
								<S.ApplicationGrid>
									<Controller
										name="item-serviceAddressToConnect"
										control={control}
										rules={{ required: required('Service Address to Connect') }}
										render={({ field: { onChange, value }, fieldState }) => <AddressInput containerClassName="item-serviceAddressToConnect" label="Service Address to Connect *" id="serviceAddressToConnect" error={fieldState.error} onChange={onChange} value={value} />}
									/>
									<TextInput containerClassName="item-mailingAddress" label="Mailing Address (if different)" id="mailingAddress" error={errors.mailingAddress} {...register('mailingAddress')} />
									<TextInput containerClassName="item-driverLicense" label="Business’s Federal Tax ID" id="busFederalTaxId" error={errors.busFederalTaxId} {...register('busFederalTaxId', {})} />
									<TextInput
										containerClassName="item-ssn"
										label="Applicant Phone *"
										id="phone"
										error={errors.phone}
										{...register('phone', {
											required: required('Phone number'),
										})}
									/>
									<TextInput containerClassName="item-movingFromAddress" label="Address Moving From" id="movingFromAddress" error={errors.movingFromAddress} {...register('movingFromAddress')} />
									<div className="item-movingDate" style={{ marginTop: '-2px' }}>
										<DatePicker label="Moving Date" selected={movingDate} icon="angle-down" {...register('movingDate')} onChange={(date) => setValue('movingDate', date)} error={!movingDate && errors.movingDate} id="movingDatePicker" minDate={new Date()} />
									</div>
								</S.ApplicationGrid>
								<S.ApplicationGrid>
									<TextInput
										containerClassName="item-serviceAddressToConnect "
										label="Name of Responsible Party"
										id="nameOfResponsibleParty"
										showWarning={true}
										warningText="This person will be individually responsible for payment of bills. Deposit may be waived following credit determination."
										error={errors.nameOfResponsibleParty}
										{...register('nameOfResponsibleParty')}
									/>
									<TextInput containerClassName="item-mailingAddress" label="Relationship" id="relationship" error={errors.relationship} {...register('relationship')} />
									<TextInput
										containerClassName="item-driverLicense"
										label="Driver's License State/Number *"
										id="driverLicense"
										error={errors.driverLicense}
										{...register('driverLicense', {
											required: required("Driver's License Number"),
										})}
									/>
									<TextInput containerClassName="item-ssn" label="SSN" id="ssn" showWarning={true} warningText="Optional: Failure to submit a Social Security number will require a High Risk Deposit." error={errors.ssn} {...register('ssn', {})} />
								</S.ApplicationGrid>
							</>
						)}
					</Card>

					<Card title="Documentation">
						<Typography tag="p" weight="bold" style={{ marginBottom: '5px' }}>
							Reliable. Safe. Secure.
						</Typography>
						<Typography tag="p">Words that describe our Electric, Gas and Water utilities - and how we treat your personal information. All required documentation can be securely submitted using the file upload features below.</Typography>

						<S.DocsWrapper>
							<DropFile
								label="Proof of Identity"
								description="Can be a Driver's License, Passport, or ID Card"
								onChangeFile={(files) => {
									setPhotoId(files);
								}}
								files={photoId}
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
							<DropFile
								label="Social Security Card"
								description="Optional: Failure to submit a Social Security number will require a High Risk Deposit"
								showWarning={true}
								warningText="Optional: Failure to submit a Social Security number will require a High Risk Deposit."
								onChangeFile={(files) => {
									setSocialSecurityCard(files);
								}}
								files={socialSecurityCard}
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
							<DropFile
								label="Proof of Lawful Occupancy"
								description="Can be a Deed, Closing Statement, Offer to Purchase, or Tax Bill"
								onChangeFile={(files) => {
									setLawfulOccupancy(files);
								}}
								files={lawfulOccupancy}
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
						</S.DocsWrapper>
					</Card>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}

OnboardingNewCustomerResidentialApplication.propTypes = {
	showDashboard: PropTypes.bool,
};
