import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Button, OnboardingFooter, Typography, Card } from '..';
import * as S from './CustomerCommercialGreenlight.styles';
import { required } from '../../utils/form-default-errors';
import { RadioInput } from '../RadioInput/RadioInput';
import toast from 'react-hot-toast';

export const CustomerCommercialGreenlight = ({ onboarding = false, pageHeading = '', getDataFromLocalStorage, onBackFunc, onNextFunc, selectionCard = () => null, allowNo = true }) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
	} = useForm({ defaultValues: {} });

	const onBack = () => {
		onBackFunc();
	};

	const isGreenlightInternetService = watch('isGreenlightInternetService');

	const onNext = (data) => {
		if (allowNo === true || (allowNo === false && isGreenlightInternetService === 'Yes')) {
			onNextFunc(data);
		} else {
			toast.error('Please add at least one Greenlight service to continue.');
		}
	};

	useEffect(() => {
		const tempData = getDataFromLocalStorage();
		if (tempData) {
			const { isGreenlightInternetService = '' } = tempData;
			setValue('isGreenlightInternetService', isGreenlightInternetService);
		}
	}, []);

	const selectionCardRender = useMemo(() => {
		return selectionCard;
	}, [selectionCard]);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<S.FormContentWrapper onboarding={onboarding}>
				<S.FormContent>
					<Typography tag="h1" weight="bold" center>
						{pageHeading}
					</Typography>
					{!onboarding && selectionCardRender()}
					<Card title="Greenlight Community Broadband">
						<RadioInput
							label="Do you want to add Greenlight internet as a service?"
							id="isGreenlightInternetService"
							options={[
								{
									label: 'Yes',
									value: 'Yes',
								},
								{
									label: 'No',
									value: 'No',
								},
							]}
							{...register('isGreenlightInternetService', {
								required: required('Selection'),
							})}
							stateValue={isGreenlightInternetService}
							error={errors.isGreenlightInternetService}
							onChangeFunc={(id, value) => setValue(id, value)}
						/>

						<S.CallPreference>
							<Typography tag="p">
								Prefer to do this over the phone? Call our team at <a href="tel:252-399-2200">252-399-2200</a>
							</Typography>
						</S.CallPreference>
					</Card>
					{isGreenlightInternetService === 'Yes' && (
						<>
							<Card>
								<Typography>You’ve made a great choice! A Sales Executive will reach out to you shortly on the phone number you provided.</Typography>
							</Card>
						</>
					)}
				</S.FormContent>
			</S.FormContentWrapper>
			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
};

CustomerCommercialGreenlight.displayName = 'Customer Commercial Greenlight';
CustomerCommercialGreenlight.propTypes = {
	onboarding: PropTypes.bool,
	disableSettingsCard: PropTypes.bool,
	pageHeading: PropTypes.string,
	getDataFromLocalStorage: PropTypes.func,
	onBackFunc: PropTypes.func,
	onNextFunc: PropTypes.func,
	selectionCard: PropTypes.func,
	allowNo: PropTypes.bool,
};
