import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Card } from '../Card';
import { Table, DateCell, BadgeCell } from '../Table';
import { Spinner } from '../Spinner';
import { EmptyComponent } from '../EmptyComponent';
import { adminSearchPortalUsers } from '../../services/adminService';
import { formatDate } from '../../utils/time-formatter';
import { tableSorterDate } from '../Table/Table';
import * as S from './CurrentUsers.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsyncDebounce } from 'react-table';

export const CurrentUsers = ({ className }) => {
	const history = useHistory();
	const [status, setStatus] = useState('success');
	const [users, setUsers] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const [columns] = useState([
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Account Enabled',
			accessor: 'accountEnabled',
		},
		{
			Header: 'Account Validated',
			accessor: 'accountValidated',
		},
		{
			Header: 'Invite Status',
			accessor: 'inviteStatus',
			Cell: BadgeCell,
			CellName: 'BadgeCell',
		},
		{
			Header: 'Created At',
			accessor: 'createdAt',
			Cell: DateCell,
			CellName: 'DateCell',
			sortType: tableSorterDate,
		},
	]);

	function navigateTo(item) {
		history.push(`/users/${encodeURIComponent(item.email)}`);
	}

	async function fetchPortalUsers({ searchText = '' }) {
		try {
			if (searchText) {
				const userObjs = await adminSearchPortalUsers({ searchText });
				setUsers(
					(userObjs || []).map((user) => {
						let inviteStatus = '';
						if (user.InviteToken) {
							if (user.IsInviteAccepted) {
								inviteStatus = 'inviteAccepted';
							} else {
								inviteStatus = 'invitePending';
							}
						} else if (!user.IsAccountValidated) {
							inviteStatus = 'pendingOnboarding';
						}
						return {
							id: user.UserID,
							UserID: user.UserID,
							email: user.Email || user.PrimaryEmail,
							accountEnabled: user.IsAccountEnabled ? 'Yes' : 'No',
							accountValidated: user.IsAccountValidated ? 'Yes' : 'No',
							createdAt: formatDate(user.LoginCreated),
							inviteStatus,
						};
					})
				);
			}
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	const onSearchChange = useAsyncDebounce((value) => {
		if (value.length >= 3) {
			fetchPortalUsers({ searchText: value });
		}
	}, 500);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		return (
			<>
				<S.SearchWrapper>
					<FontAwesomeIcon icon={['fal', 'search']} />
					<S.FilterInput
						value={searchValue || ''}
						onChange={(e) => {
							setSearchValue(e.target.value);
							onSearchChange(e.target.value);
						}}
						placeholder="Email address or account ID..."
					/>
				</S.SearchWrapper>
				<Table columns={columns} data={users} handleClick={navigateTo} withoutSearch />
				{users.length === 0 && <EmptyComponent icon={['fal', 'user-circle']} title="No matching users" description="Search for portal users by email address or account ID" />}
			</>
		);
	};

	return (
		<Card className={className} title="Current Users">
			{renderContent()}
		</Card>
	);
};
CurrentUsers.propTypes = {
	className: PropTypes.string,
};
