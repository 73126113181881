import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

const SIZES = {
	small: css`
		padding: 0.375em 0.875em;
	`,
	medium: css`
		padding: 0.625em 1.5em;
	`,
};

const VARIATIONS = {
	default: {
		solid: css`
			background-color: ${colors.brandPrimary};
			border-color: ${colors.brandPrimary};
			color: white;
		`,
		outline: css`
			background-color: white;
			border-color: ${colors.brandPrimary};
			color: ${colors.brandPrimary};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${colors.brandPrimary};
		`,
	},
	secondary: {
		solid: css`
			background-color: ${colors.neutralButton};
			border-color: ${colors.neutralButton};
			color: white;
		`,
		outline: css`
			background-color: white;
			border-color: ${colors.neutralButton};
			color: ${colors.neutralButton};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${colors.neutralButton};
		`,
	},
	warning: {
		solid: css`
			background-color: ${colors.statusBad};
			border-color: ${colors.statusBad};
			color: white;
		`,
		outline: css`
			background-color: white;
			border-color: ${colors.statusBad};
			color: ${colors.statusBad};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${colors.statusBad};
		`,
	},
	tertiary: {
		solid: css`
			background-color: white;
			border-color: white;
			color: ${colors.brandPrimary};
		`,
		outline: css`
			background-color: ${colors.brandPrimary};
			border-color: white;
			color: white;
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: white;
		`,
	},
	light: {
		solid: css`
			background-color: ${colors.neutralBackground};
			border-color: ${colors.neutralBackground};
			color: ${colors.neutralText};
		`,
		outline: css`
			background-color: ${colors.neutralBackground};
			border-color: ${colors.neutralText};
			color: ${colors.neutralText};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${colors.neutralText};
		`,
	},
};

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border-radius: 9999px;
	letter-spacing: 1.5px;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	white-space: nowrap;

	& > svg {
		margin-right: 0.625em;
	}

	${({ size, variant, variation }) => css`
		${SIZES[size]}
		${VARIATIONS[variation][variant]}
	`}

	&:hover {
		opacity: 0.8;
	}

	&:disabled {
		opacity: 0.8;
		cursor: not-allowed;

		&:hover {
			opacity: 0.8;
		}
	}
`;
