import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	paymentAmount: {},
	newMethodAdded: {},
	paymentMethod: {},
	paymentDate: {},
};

export const makePaymentSlice = createSlice({
	name: 'makePayment',
	initialState,
	reducers: {
		paymentAmount: (state, action) => {
			state.paymentAmount = action.payload;
		},
		newMethodAdded: (state, action) => {
			state.newMethodAdded = action.payload;
		},
		paymentMethod: (state, action) => {
			state.paymentMethod = action.payload;
		},
		paymentDate: (state, action) => {
			state.paymentDate = action.payload;
		},
		clearPayment: (state) => {
			state.paymentAmount = initialState.paymentAmount;
			state.newMethodAdded = initialState.newMethodAdded;
			state.paymentMethod = initialState.paymentMethod;
			state.paymentDate = initialState.paymentDate;
		},
	},
});

export const { paymentAmount, paymentMethod, paymentDate, newMethodAdded, clearPayment } = makePaymentSlice.actions;

export default makePaymentSlice.reducer;
