import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0;
	border-bottom: 1px solid ${colors.neutralForm};

	&:first-child {
		padding-top: 0.5rem;
	}

	&:last-child {
		border: none;
		padding-bottom: 0;
	}

	@media screen and (min-width: 768px) {
		padding: 1rem 0;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	${({ expired }) =>
		expired &&
		css`
			opacity: 0.5;
		`}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	margin-top: 1rem;

	@media screen and (min-width: 768px) {
		margin-top: 0;
	}
`;

export const Link = styled.a`
	align-self: flex-start;
	cursor: pointer;
	color: ${colors.brandSecondary};
	font-size: 1.125rem;
	font-weight: 600;

	&:hover {
		opacity: 0.8;
	}
`;

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5.625rem 1rem 2rem 1rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 29.75rem;
	margin-top: 2rem;

	& > * + * {
		margin-top: 1rem;
	}
	.react-datepicker__tab-loop {
		margin: 0px;
	}
`;

export const FormButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
