import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import { Badge } from '../Badge';
import { Button } from '../Button';
import * as S from './PageHeader.styles';

export function PageHeader({ children, action = [], badge }) {
	return (
		<S.Wrapper className="page-header">
			<Typography tag="h1" weight="bold">
				{children}
			</Typography>
			{!!badge && (
				<S.BadgeWrapper>
					<Badge type={badge.type} variation={badge.variation}>
						{badge.icon && <FontAwesomeIcon icon={badge.icon} />}
						{badge.label}
					</Badge>
				</S.BadgeWrapper>
			)}
			{!!action.length && (
				<S.ButtonWrapper>
					{action.map((item) => (
						<Button key={item.id} onClick={item.onClick} variant={item.type} variation={item.variation} size={item.size}>
							{item.icon && <FontAwesomeIcon icon={item.icon} />}
							<Typography tag="span" variation={item.type === 'text' ? '1' : '2'} weight="extrablack">
								{item.label}
							</Typography>
						</Button>
					))}
				</S.ButtonWrapper>
			)}
		</S.Wrapper>
	);
}

PageHeader.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	action: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			onClick: PropTypes.func,
			label: PropTypes.string,
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			type: PropTypes.oneOf(['solid', 'outline', 'text']),
			variation: PropTypes.oneOf(['default', 'secondary', 'warning']),
			size: PropTypes.oneOf(['medium', 'small']),
		})
	),
	badge: PropTypes.shape({
		type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'warning', 'success']),
		variation: PropTypes.oneOf(['square', 'pill']),
		label: PropTypes.string,
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	}),
};
