import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { PageHeader, Tab, Card, Typography, Button, Modal, TextInput, Spinner, EmptyComponent, DatePicker } from '../../components';
import { TextArea } from '../../components/TextArea';
import { adminAddArticle, adminAddResource, adminRemoveArticle, adminRemoveResource, adminRetrieveArticles, adminRetrieveResources, adminUpdateArticle, adminUpdateResource } from '../../services/adminService';
import { META_TITLE } from '../../utils/constants';
import { required } from '../../utils/form-default-errors';
import moment from 'moment';
import * as S from './AdminLinks.styles';
import { renderArticleDateStatus } from '../../utils/time-formatter';
import { sortResourcesEvents } from '../../utils/sorting';

const Resources = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [removeIsOpen, setRemoveIsOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);
	const [activeResource, setActiveResource] = useState(null);
	const [status, setStatus] = useState('loading');
	const [resources, setResources] = useState([]);

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		watch,
		formState: { errors, isSubmitting },
	} = useForm();

	const startDate = watch('startDate');
	const endDate = watch('endDate');

	async function onSubmit(data) {
		try {
			if (isEditing) {
				const success = await adminUpdateResource({ resourceKey: activeResource.ResourceKey, ...data });
				if (success) {
					toast.success('This resource has successfully been updated.');
					init();
					setIsOpen(false);
					setActiveResource(null);
				} else {
					toast.error('There was an issue updating this resource. Please try again.');
					reset(undefined, { keepValues: true });
				}
			} else {
				const success = await adminAddResource({ ...data });
				if (success) {
					toast.success('This resource has successfully been created.');
					init();
					setIsOpen(false);
					setActiveResource(null);
				} else {
					toast.error('There was an issue creating this resource. Please try again.');
					reset(undefined, { keepValues: true });
				}
			}
		} catch (e) {
			toast.error(e.message);
			reset(undefined, { keepValues: true });
		}
	}

	const removeResource = async () => {
		setIsRemoving(true);
		try {
			const success = await adminRemoveResource({ resourceKey: activeResource.ResourceKey });
			setIsRemoving(false);
			if (success) {
				toast.success('This resource has successfully been removed.');
				const newResources = resources.filter((resource) => resource.ResourceKey !== activeResource.ResourceKey);
				setResources(newResources);
				setRemoveIsOpen(false);
			} else {
				toast.error('There was an issue removing this resource. Please try again.');
			}
		} catch (e) {
			setIsRemoving(false);
			toast.error(e.message);
		}
	};

	const handleRemoveResource = (resource) => {
		setActiveResource(resource);
		setRemoveIsOpen(true);
	};

	const handleEditResource = (resource) => {
		reset({ title: resource.Title, description: resource.Description, url: resource.URL, startDate: new Date(resource.StartDate), endDate: new Date(resource.EndDate) });
		setActiveResource(resource);
		setIsEditing(true);
		setIsOpen(true);
	};

	const handleAddResource = () => {
		reset({});
		setActiveResource(null);
		setIsEditing(false);
		setIsOpen(true);
	};

	async function init() {
		try {
			const resourceObjs = await adminRetrieveResources({ returnExpired: true, returnCurrent: true, returnFuture: true });
			const now = new Date();
			resourceObjs.sort(sortResourcesEvents);
			const fullResources = resourceObjs.map((resource) => {
				const endDate = new Date(resource.EndDate);
				if (endDate.getTime() < now.getTime()) resource.expired = true;
				return resource;
			});
			setResources(fullResources);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, []);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (resources.length === 0) {
			return <EmptyComponent icon={['fal', 'link']} title="No Available Resources" description="Create a new resource to get started." />;
		}
		return resources.map((resource) => (
			<S.Item key={resource.ResourceKey}>
				<S.InfoWrapper expired={resource.expired}>
					<Typography tag="h4" weight="bold">
						{resource.Title}
					</Typography>
					<Typography tag="p">{resource.Description}</Typography>
					<S.Link href={resource.URL} target="_blank">
						{resource.URL}
					</S.Link>
					<Typography tag="p" variation="2" style={{ marginTop: '10px' }}>
						{renderArticleDateStatus(resource)}
					</Typography>
				</S.InfoWrapper>
				<S.ButtonWrapper>
					<Button variant="text" variation="warning" size="small" onClick={() => handleRemoveResource(resource)}>
						<Typography tag="span" variation="2" weight="extrablack">
							Remove
						</Typography>
					</Button>
					<Button variant="outline" size="small" onClick={() => handleEditResource(resource)}>
						<FontAwesomeIcon icon={['fal', 'edit']} />
						<Typography tag="span" variation="2" weight="extrablack">
							Edit
						</Typography>
					</Button>
				</S.ButtonWrapper>
			</S.Item>
		));
	};

	return (
		<>
			<Card title="All Resources" action={[{ id: 1, onClick: () => handleAddResource(), icon: ['fal', 'plus'], label: 'Add New Resource' }]}>
				<S.ContentWrapper>{renderContent()}</S.ContentWrapper>
			</Card>
			<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
				<S.ModalWrapper>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold" center>
							{isEditing ? 'Update Resource' : 'Add New Resource'}
						</Typography>
					</S.TitleWrapper>
					<S.Form onSubmit={handleSubmit(onSubmit)}>
						<TextInput
							label="Title"
							id="title"
							error={errors.title}
							{...register('title', {
								required: required('Title'),
							})}
						/>
						<TextInput
							label="Link"
							id="url"
							error={errors.url}
							{...register('url', {
								required: required('Link'),
							})}
						/>
						<TextArea
							label="Description"
							id="description"
							error={errors.description}
							{...register('description', {
								required: required('Description'),
							})}
						/>
						<DatePicker
							{...register('startDate', {
								required: true,
							})}
							error={errors.startDate}
							label="Open Date"
							placeholderText="Select a Date"
							id="startDate"
							selected={startDate}
							onChange={(date) => setValue('startDate', date)}
						/>
						<DatePicker
							{...register('endDate', {
								required: true,
							})}
							error={errors.endDate}
							label="Close Date"
							placeholderText="Select a Date"
							id="endDate"
							selected={endDate}
							onChange={(date) => setValue('endDate', date)}
							minDate={startDate || new Date()}
						/>
						<S.FormButtonWrapper>
							<Button variant="outline" variation="secondary" type="button" onClick={() => setIsOpen(false)}>
								<Typography tag="span" variation="1" weight="extrablack">
									Cancel
								</Typography>
							</Button>
							<Button>
								<Typography tag="span" variation="1" weight="extrablack">
									{isSubmitting ? 'Loading...' : `${isEditing ? 'Update Resource' : 'Add Resource'}`}
								</Typography>
							</Button>
						</S.FormButtonWrapper>
					</S.Form>
				</S.ModalWrapper>
			</Modal>
			<Modal
				shouldCloseOnOverlayClick
				isOpen={removeIsOpen}
				onRequestClose={() => {
					setRemoveIsOpen(false);
				}}
			>
				<S.ModalWrapper>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold" center>
							Remove Resource
						</Typography>
						<Typography tag="h3" center>
							Are you sure you want to remove this resource?
						</Typography>
					</S.TitleWrapper>
					<S.FormButtonWrapper>
						<Button
							variant="outline"
							variation="secondary"
							type="button"
							onClick={() => {
								setRemoveIsOpen(false);
							}}
						>
							<Typography tag="span" variation="1" weight="extrablack">
								Cancel
							</Typography>
						</Button>
						<Button variation="warning" onClick={removeResource}>
							<Typography tag="span" variation="1" weight="extrablack">
								{isRemoving ? 'Loading...' : 'Remove Resource'}
							</Typography>
						</Button>
					</S.FormButtonWrapper>
				</S.ModalWrapper>
			</Modal>
		</>
	);
};

const Articles = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [removeIsOpen, setRemoveIsOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);
	const [activeArticle, setActiveArticle] = useState(null);
	const [status, setStatus] = useState('loading');
	const [articles, setArticles] = useState([]);

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		reset,
		watch,
		setValue,
	} = useForm();

	const startDate = watch('startDate');
	const endDate = watch('endDate');

	async function onSubmit(data) {
		try {
			if (isEditing) {
				const success = await adminUpdateArticle({ articleKey: activeArticle.ArticleKey, ...data });
				if (success) {
					toast.success('This article has successfully been updated.');
					init();
					setIsOpen(false);
					setActiveArticle(null);
				} else {
					toast.error('There was an issue updating this article. Please try again.');
				}
			} else {
				const success = await adminAddArticle({ ...data });
				if (success) {
					toast.success('This article has successfully been created.');
					init();
					setIsOpen(false);
					setActiveArticle(null);
				} else {
					toast.error('There was an issue creating this article. Please try again.');
				}
			}
		} catch (e) {
			toast.error(e.message);
		}
	}

	const removeArticle = async () => {
		setIsRemoving(true);
		try {
			const success = await adminRemoveArticle({ articleKey: activeArticle.ArticleKey });
			setIsRemoving(false);
			if (success) {
				toast.success('This article has successfully been removed.');
				const newArticles = articles.filter((article) => article.ArticleKey !== activeArticle.ArticleKey);
				setArticles(newArticles);
				setRemoveIsOpen(false);
			} else {
				toast.error('There was an issue removing this article. Please try again.');
			}
		} catch (e) {
			setIsRemoving(false);
			toast.error(e.message);
		}
	};

	const handleRemoveArticle = (article) => {
		setActiveArticle(article);
		setRemoveIsOpen(true);
	};

	const handleEditArticle = (article) => {
		reset({ title: article.Title, description: article.Description, url: article.URL, startDate: new Date(article.StartDate), endDate: new Date(article.EndDate) });
		setActiveArticle(article);
		setIsEditing(true);
		setIsOpen(true);
	};

	const handleAddArticle = () => {
		reset({});
		setActiveArticle(null);
		setIsEditing(false);
		setIsOpen(true);
	};

	async function init() {
		try {
			const articleObjs = await adminRetrieveArticles({ returnExpired: true, returnCurrent: true, returnFuture: true });
			const now = new Date();
			articleObjs.sort(sortResourcesEvents);
			const fullArticles = articleObjs.map((article) => {
				const endDate = new Date(article.EndDate);
				if (endDate.getTime() < now.getTime()) article.expired = true;
				return article;
			});
			setArticles(fullArticles);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, []);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (articles.length === 0) {
			return <EmptyComponent icon={['fal', 'link']} title="No Available Articles" description="Create a new article to get started." />;
		}
		return articles.map((article) => (
			<S.Item key={article.ArticleKey}>
				<S.InfoWrapper expired={article.expired}>
					<Typography tag="h4" weight="bold">
						{article.Title}
					</Typography>
					<Typography tag="p">{article.Description}</Typography>
					<S.Link href={article.URL} target="_blank">
						{article.URL}
					</S.Link>
					<Typography tag="p" variation="2" style={{ marginTop: '10px' }}>
						{renderArticleDateStatus(article)}
					</Typography>
				</S.InfoWrapper>
				<S.ButtonWrapper>
					<Button variant="text" variation="warning" size="small" onClick={() => handleRemoveArticle(article)}>
						<Typography tag="span" variation="2" weight="extrablack">
							Remove
						</Typography>
					</Button>
					<Button variant="outline" size="small" onClick={() => handleEditArticle(article)}>
						<FontAwesomeIcon icon={['fal', 'edit']} />
						<Typography tag="span" variation="2" weight="extrablack">
							Edit
						</Typography>
					</Button>
				</S.ButtonWrapper>
			</S.Item>
		));
	};

	return (
		<>
			<Card title="All Articles" action={[{ id: 1, onClick: () => handleAddArticle(), icon: ['fal', 'plus'], label: 'Add New Article' }]}>
				<S.ContentWrapper>{renderContent()}</S.ContentWrapper>
			</Card>
			<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
				<S.ModalWrapper>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold" center>
							{isEditing ? 'Update Article' : 'Add New Article'}
						</Typography>
					</S.TitleWrapper>
					<S.Form onSubmit={handleSubmit(onSubmit)}>
						<TextInput
							label="Title"
							id="title"
							error={errors.title}
							{...register('title', {
								required: required('Title'),
							})}
						/>
						<TextInput
							label="Link"
							id="url"
							error={errors.url}
							{...register('url', {
								required: required('Link'),
							})}
						/>
						<TextArea
							label="Description"
							id="description"
							error={errors.description}
							{...register('description', {
								required: required('Description'),
							})}
						/>
						<DatePicker
							{...register('startDate', {
								required: true,
							})}
							error={errors.startDate}
							label="Open Date"
							placeholderText="Select a Date"
							id="startDate"
							selected={startDate}
							onChange={(date) => setValue('startDate', date)}
						/>
						<DatePicker
							{...register('endDate', {
								required: true,
							})}
							error={errors.endDate}
							label="Close Date"
							placeholderText="Select a Date"
							id="endDate"
							selected={endDate}
							onChange={(date) => setValue('endDate', date)}
							minDate={startDate || new Date()}
						/>
						<S.FormButtonWrapper>
							<Button variant="outline" variation="secondary" type="button" onClick={() => setIsOpen(false)}>
								<Typography tag="span" variation="1" weight="extrablack">
									Cancel
								</Typography>
							</Button>
							<Button>
								<Typography tag="span" variation="1" weight="extrablack">
									{isSubmitting ? 'Loading...' : `${isEditing ? 'Update Article' : 'Add Article'}`}
								</Typography>
							</Button>
						</S.FormButtonWrapper>
					</S.Form>
				</S.ModalWrapper>
			</Modal>
			<Modal
				shouldCloseOnOverlayClick
				isOpen={removeIsOpen}
				onRequestClose={() => {
					setRemoveIsOpen(false);
				}}
			>
				<S.ModalWrapper>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold" center>
							Remove Article
						</Typography>
						<Typography tag="h3" center>
							Are you sure you want to remove this article?
						</Typography>
					</S.TitleWrapper>
					<S.FormButtonWrapper>
						<Button
							variant="outline"
							variation="secondary"
							type="button"
							onClick={() => {
								setRemoveIsOpen(false);
							}}
						>
							<Typography tag="span" variation="1" weight="extrablack">
								Cancel
							</Typography>
						</Button>
						<Button variation="warning" onClick={removeArticle}>
							<Typography tag="span" variation="1" weight="extrablack">
								{isRemoving ? 'Loading...' : 'Remove Article'}
							</Typography>
						</Button>
					</S.FormButtonWrapper>
				</S.ModalWrapper>
			</Modal>
		</>
	);
};

export default function AdminLinks() {
	const [tabs, setTabs] = useState([
		{ id: 1, label: 'Articles', component: <Articles /> },
		{ id: 2, label: 'Resources', component: <Resources /> },
	]);

	useEffect(() => {
		// Set document title
		document.title = `Links | ${META_TITLE}`;
	}, []);

	return (
		<>
			<S.Wrapper>
				<PageHeader>Links</PageHeader>
				<Tab tabs={tabs} setTabs={setTabs} transparent />
			</S.Wrapper>
		</>
	);
}
