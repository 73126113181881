import styled from 'styled-components';

import colors from '../../styles/colors';

export const EmptyWrapper = styled.div`
	height: 18rem; /* 384px */
	display: grid;
	place-content: center;
	color: ${colors.neutralText};

	h5 {
		margin-bottom: 0.25rem;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;

	svg {
		width: 4rem !important;
		height: 4rem !important;
	}
`;
