import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { Typography } from '../Typography';
import * as S from './RangeSlider.styles';

export const RangeSlider = forwardRef(({ label, ...rest }, ref) => {
	return (
		<S.Wrapper>
			{label && <Typography tag="label">{label}</Typography>}
			<Slider valueLabelFormat={(value) => `$${value}`} {...rest} getAriaLabel={() => 'Temperature range'} valueLabelDisplay="on" />
		</S.Wrapper>
	);
});

RangeSlider.displayName = 'RangeSlider';
RangeSlider.propTypes = {
	label: PropTypes.string,
};
