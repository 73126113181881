import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { addGreenlight } from '../../app/slices/manageUtility/manageUtilitySlice';
import { Card, MakePaymentHeader } from '../../components';
import * as S from './AddGreenlight.styles';

import { RadioInput } from '../../components/RadioInput/RadioInput';

import { Manage_Greenlight, Manage_Greenlight_Settings, META_TITLE } from '../../utils/constants';
import { CustomerCommercialGreenlight } from '../../components/CustomerCommercialGreenlight';
import { CustomerResidentialGreenlight } from '../../components/CustomerResidentialGreenlight';

export default function AddGreenlight() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [type, setType] = useState('residential');

	const onBack = () => {
		localStorage.removeItem(Manage_Greenlight_Settings);
		localStorage.removeItem(Manage_Greenlight);
		history.push(location.state?.backUrl || '/services');
	};

	const onNext = (data) => {
		data.type = type;
		dispatch(addGreenlight(data));
		localStorage.setItem(Manage_Greenlight, JSON.stringify(data));
		history.push('/manage-greenlight/settings');
	};

	useEffect(() => {
		// Set document title
		document.title = `Manage Greenlight | ${META_TITLE}`;
	}, []);

	const getDataFromLocalStorage = () => {
		let tempGreenlight = localStorage.getItem(Manage_Greenlight);
		if (tempGreenlight) {
			tempGreenlight = JSON.parse(tempGreenlight);
		}
		if (tempGreenlight) {
			if (tempGreenlight?.type) {
				setType(tempGreenlight.type);
			}
			return tempGreenlight;
		}
	};

	const onChangeType = (value) => {
		localStorage.removeItem(Manage_Greenlight);
		setType(value);
	};

	const selectionCard = () => {
		return (
			<Card title="Greenlight Service">
				<RadioInput
					label="Is this for residential or commercial use?"
					options={[
						{
							label: 'Residential',
							value: 'residential',
						},
						{
							label: 'Commercial',
							value: 'commercial',
						},
					]}
					stateValue={type}
					onChangeFunc={(_id, value) => onChangeType(value)}
				/>
			</Card>
		);
	};

	return (
		<S.Wrapper>
			<MakePaymentHeader />
			{type === 'residential' ? (
				<CustomerResidentialGreenlight pageHeading="Manage Greenlight" disableSettingsCard getDataFromLocalStorage={getDataFromLocalStorage} onBackFunc={onBack} onNextFunc={onNext} selectionCard={selectionCard} allowNo={false} />
			) : (
				<CustomerCommercialGreenlight pageHeading="Manage Greenlight" disableSettingsCard getDataFromLocalStorage={getDataFromLocalStorage} onBackFunc={onBack} onNextFunc={onNext} selectionCard={selectionCard} allowNo={false} />
			)}
		</S.Wrapper>
	);
}
