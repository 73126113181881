import styled, { css } from 'styled-components';
import colors from '../../../styles/colors';

export const MobileWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const ItemButton = styled.div`
	cursor: auto !important;
	${({ withHoverEffect }) =>
		withHoverEffect &&
		css`
			&:hover {
				cursor: pointer !important;
				opacity: 0.8;
			}
		`}

	* {
		color: ${colors.black};
	}
	button {
		* {
			color: ${colors.brandPrimary};
		}
	}
`;
