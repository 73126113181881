import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'beautiful-react-hooks';
import PropTypes from 'prop-types';

import { Button, OnboardingFooter, OnboardingHeader, Typography, Stepper, Card, DropFile } from '../../components';
import * as S from './CurrentCustomerConfirmAccount.styles';
import { Current_Cus_Onb_Conf_Acc, META_TITLE } from '../../utils/constants';
import { addAccount } from '../../services/userService';

export default function CurrentCustomerConfirmAccount({ isCommercial = false, showDashboard }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const stepData = useSelector((state) => state.onboarding);

	const [selectedAccounts, setSelectedAccounts] = useState({});
	const [apiLoading, setAPILoading] = useState(false);

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues: { ...stepData.newCustomer.commercial.application } });

	const onBack = () => {
		if (isCommercial) {
			history.push('/onboarding/current-customer/commercial/search');
		} else {
			history.push('/onboarding/current-customer/residential/search');
		}
	};

	const onNext = async () => {
		setAPILoading(true);
		const { unassignedUtilityAccounts, unassignedGreenlightAccounts } = selectedAccounts;
		const allAPIRequests = [];
		try {
			if (unassignedUtilityAccounts.length > 0) {
				unassignedUtilityAccounts.forEach(async (account) => {
					const { AccountNumber, AccountName, LocationID } = account;
					allAPIRequests.push(addAccount({ locationId: LocationID, accountType: 1, accountNumber: AccountNumber }));
				});
			}
			if (unassignedGreenlightAccounts.length > 0) {
				unassignedGreenlightAccounts.forEach(async (account) => {
					const { AccountNumber, AccountName, LocationID } = account;
					allAPIRequests.push(addAccount({ locationId: LocationID, accountType: 2, accountNumber: AccountNumber }));
				});
			}
			await Promise.all(allAPIRequests);
		} catch (e) {
		} finally {
			setAPILoading(false);
			if (isCommercial) {
				history.push('/onboarding/current-customer/commercial/billing');
			} else {
				history.push('/onboarding/current-customer/residential/billing');
			}
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `Confirm Account | ${META_TITLE}`;
		const selectedAccountsTemp = localStorage.getItem(Current_Cus_Onb_Conf_Acc) && JSON.parse(localStorage.getItem(Current_Cus_Onb_Conf_Acc));

		if (!selectedAccountsTemp) {
			const { unassignedUtilityAccounts = [], unassignedGreenlightAccounts = [] } = selectedAccountsTemp || {};
			let totalAccounts = 0;
			if (unassignedUtilityAccounts?.length) {
				totalAccounts = unassignedUtilityAccounts?.length;
			}

			if (unassignedGreenlightAccounts?.length) {
				totalAccounts = totalAccounts + unassignedGreenlightAccounts?.length;
			}
			if (totalAccounts === 0) {
				if (isCommercial) {
					history.push('/onboarding/current-customer/commercial/search');
				} else {
					history.push('/onboarding/current-customer/residential/search');
				}
			}
		} else {
			setSelectedAccounts(selectedAccountsTemp);
		}
	}, []);

	return (
		<>
			<S.Form>
				<OnboardingHeader showDashboard={showDashboard} />
				<Stepper
					activeStep={2}
					steps={[
						{ id: 1, label: 'Location' },
						{ id: 2, label: 'Profile' },
						{ id: 3, label: 'Account' },
						{ id: 4, label: 'Billing' },
					]}
				/>
				<S.FormContentWrapper>
					<S.FormContent>
						<Typography tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold" center>
							Confirm Account
						</Typography>
						{selectedAccounts?.unassignedUtilityAccounts?.length > 0 && (
							<Card title="Utilities">
								{selectedAccounts?.unassignedUtilityAccounts?.map((account) => (
									<S.Card1ContentWrapper key={account.AccountNumber}>
										<Typography tag="h5" weight="bold">
											Account Address: {account?.Address}
										</Typography>
										<Typography tag="p" variation="1">
											Account Number: {account?.AccountNumber}
										</Typography>
										<Typography tag="p" variation="1">
											Account Name: {account?.AccountName}
										</Typography>
									</S.Card1ContentWrapper>
								))}
							</Card>
						)}
						{selectedAccounts?.unassignedGreenlightAccounts?.length > 0 && (
							<Card title="Greenlight">
								{selectedAccounts?.unassignedGreenlightAccounts?.map((account) => (
									<S.Card1ContentWrapper key={account.AccountNumber}>
										<Typography tag="h5" weight="bold">
											Account Address: {account?.Address}
										</Typography>
										<Typography tag="p" variation="1">
											Account Number: {account?.AccountNumber}
										</Typography>
										<Typography tag="p" variation="1">
											Account Name: {account?.AccountName}
										</Typography>
									</S.Card1ContentWrapper>
								))}
							</Card>
						)}
					</S.FormContent>
				</S.FormContentWrapper>

				<OnboardingFooter>
					<Button variant="outline" variation="tertiary" type="button" onClick={onBack} disabled={apiLoading}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Back
						</Typography>
					</Button>
					<Button
						variant="outline"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleSubmit(onNext)();
						}}
						disabled={apiLoading}
					>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							{apiLoading ? 'Proceeding ...' : 'Confirm and Proceed'}
						</Typography>
					</Button>
				</OnboardingFooter>
			</S.Form>
		</>
	);
}

CurrentCustomerConfirmAccount.propTypes = {
	isCommercial: PropTypes.bool,
	showDashboard: PropTypes.bool,
};
