import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './ManageUtilityRequestEquipmentModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Button, Modal, Typography, Select } from '../../components';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { requestEquipment } from '../../app/slices/manageUtility/manageUtilitySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { META_TITLE } from '../../utils/constants';
import { submitUserRequest } from '../../services/userService';
import toast from 'react-hot-toast';

const EquipmentTypes = [
	{
		label: 'Remote',
		value: 'Remote',
	},
	{
		label: 'Greenlight Router',
		value: 'Greenlight Router',
	},
];

const RequestReasons = [
	{
		label: 'Damaged/Malfunction',
		value: 'Damaged/Malfunction',
	},
	{
		label: 'Lost',
		value: 'Lost',
	},
];

const ManageUtilityRequestEquipmentModal = ({ isOpen, setIsOpen }) => {
	const currentLocation = useSelector((state) => state.location.value);

	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const stepData = useSelector((state) => state.manageUtility);

	const [requestedEquipment, setRequestedEquipment] = useState([
		{
			type: EquipmentTypes[0],
			reason: RequestReasons[0],
		},
	]);

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const { handleSubmit, setValue } = useForm({
		defaultValues: {
			...stepData.requestEquipment,
			requestedEquipment,
		},
	});

	const onAddEquipment = () => {
		const tempReqEquipments = [...requestedEquipment];
		tempReqEquipments.push({
			type: EquipmentTypes[0],
			reason: RequestReasons[0],
		});
		setRequestedEquipment(tempReqEquipments);
		setValue('requestedEquipment', tempReqEquipments);
	};

	const onTypeChange = (value, index) => {
		const tempReqEquipments = [...requestedEquipment];
		tempReqEquipments[index] = { ...tempReqEquipments[index], type: value };
		setRequestedEquipment(tempReqEquipments);
		setValue('requestedEquipment', tempReqEquipments);
	};

	const onReasonChange = (value, index) => {
		const tempReqEquipments = [...requestedEquipment];
		tempReqEquipments[index] = { ...tempReqEquipments[index], reason: value };
		setRequestedEquipment(tempReqEquipments);
		setValue('requestedEquipment', tempReqEquipments);
	};

	const finalApiCalls = async (data) => {
		try {
			const { requestKey } = await submitUserRequest({
				requestType: 4,
				requestDescription: JSON.stringify({ ...data, locationId: currentLocation?.LocationID }),
			});
			toast.success('Your equipment request has been submitted.');
		} catch (e) {
			toast.success('There was an issue submitting your request. Please try again.');
		}
	};

	const onSubmit = async (data) => {
		dispatch(requestEquipment(data));
		setLoading(true);
		await finalApiCalls(data);
		setLoading(false);
		setIsOpen(false);
	};

	const onModalClose = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		// Set document title
		document.title = `Manage Utilities | ${META_TITLE}`;
	}, []);

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onModalClose}>
			<S.ModalContainer onSubmit={handleSubmit(onSubmit)}>
				<S.HeadingContainer>
					<Typography center tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold">
						Request Equipment
					</Typography>
				</S.HeadingContainer>
				<S.MainWrapper>
					{requestedEquipment?.map((req, index) => (
						<S.Main key={index}>
							<S.NumberContainer>
								<Typography tag="h4" weight="semibold">
									{index + 1}.
								</Typography>
							</S.NumberContainer>
							<S.SelectsContainer>
								<Select label="Equipment Type" value={req?.type} options={EquipmentTypes} onChange={(value) => onTypeChange(value, index)} />
								<Select label="Reason for Request" value={req?.reason} options={RequestReasons} onChange={(value) => onReasonChange(value, index)} />
							</S.SelectsContainer>
						</S.Main>
					))}
				</S.MainWrapper>
				<S.AddButtonContainer>
					<S.AddButton onClick={onAddEquipment}>
						<FontAwesomeIcon icon={['fal', 'plus-circle']} />
						<Typography tag="span" variation="1" weight="extrablack">
							Add Equipment
						</Typography>
					</S.AddButton>
				</S.AddButtonContainer>
				<S.ButtonsContainer>
					<Button type="button" variant="outline" variation="secondary" onClick={onModalClose}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button variant="solid" type="submit" disabled={loading}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Submit Request
						</Typography>
					</Button>
				</S.ButtonsContainer>
			</S.ModalContainer>
		</Modal>
	);
};
export default ManageUtilityRequestEquipmentModal;

ManageUtilityRequestEquipmentModal.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	onClickButton: PropTypes.func,
};
