import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';

import { addNotification, fetchNotifications } from '../../app/slices/notifications/notificationsSlice';
import { formatToRelativeTime } from '../../utils/time-formatter';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { EmptyComponent } from '../EmptyComponent';
import { Spinner } from '../Spinner';
import * as S from './NotificationPanel.styles';

export const NotificationPanel = () => {
	const notifications = useSelector((state) => state.notifications.value);
	const status = useSelector((state) => state.notifications.status);
	const error = useSelector((state) => state.notifications.error);
	const dispatch = useDispatch();

	function handleNotificationActions() {
		dispatch(addNotification({ id: Math.random(), message: 'This is a random notification', icon: ['fal', 'list'], createdAt: Date.now(), isRead: false }));
	}

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchNotifications());
		}
		if (status === 'failed' && error) {
			toast.error(error);
		}
	}, [error, status, dispatch]);

	function renderNotificationContent() {
		if (status === 'loading') {
			return (
				<S.EmptyWrapper>
					<Spinner />
				</S.EmptyWrapper>
			);
		}

		if (status === 'succeeded') {
			if (notifications.length) {
				return notifications.map(({ id, icon, message, createdAt, isRead }) => (
					<li key={id}>
						<S.Notification isRead={isRead}>
							<S.IconWrapper>
								<FontAwesomeIcon icon={icon} />
							</S.IconWrapper>
							<S.ContentWrapper>
								<Typography tag="p" variation="2" dangerouslySetInnerHTML={{ __html: message }} />
								<Typography tag="p" variation="2" weight="semibold" className="time-ago">
									{formatToRelativeTime(createdAt)}
								</Typography>
							</S.ContentWrapper>
						</S.Notification>
					</li>
				));
			}

			return <EmptyComponent icon={['fal', 'bell']} title="No Notifications" description="We will notify you when something arrives." />;
		}

		if (status === 'failed') {
			return (
				<S.EmptyWrapper>
					<S.InfoWrapper>
						<FontAwesomeIcon icon={['fal', 'tools']} />
					</S.InfoWrapper>
					<Typography tag="h5" weight="semibold" center>
						Error
					</Typography>
					<Typography tag="p" variation="2" center>
						Something is wrong, refresh your page to try again.
					</Typography>
				</S.EmptyWrapper>
			);
		}
	}

	return (
		<S.Wrapper>
			<S.Header>
				<Typography tag="h5" weight="bold">
					Notifications
				</Typography>
				{/*<IconButton size={1} icon="ellipsis-h" onClick={handleNotificationActions} />*/}
			</S.Header>
			<S.NotificationList>{renderNotificationContent()}</S.NotificationList>
		</S.Wrapper>
	);
};
