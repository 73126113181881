import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Toggle } from '../Toggle';
import { Typography } from '../Typography';
import * as S from './NotificationsPreferences.styles';
import toast from 'react-hot-toast';
import { getUserNotificationSettings, updateUserNotificationSettings } from '../../services/userService';

export const NotificationsPreferences = ({ className }) => {
	const [dailyConsumption, setDailyConsumption] = useState(false);
	const [highConsumption, setHighConsumption] = useState(false);
	const [possibleWaterLeak, setPossibleWaterLeak] = useState(false);

	const updateDailyConsumption = async (checked) => {
		setDailyConsumption(checked);
		try {
			const isUpdated = await updateUserNotificationSettings({ dailyConsumption: checked, highUsage: highConsumption, waterLeak: possibleWaterLeak });
			if (isUpdated !== true) {
				toast.error('There was an issue updating your notification settings. Please try again.');
				setDailyConsumption(!checked);
			}
		} catch (e) {
			toast.error(e.message);
			setDailyConsumption(!checked);
		}
	};

	const updateHighConsumption = async (checked) => {
		setHighConsumption(checked);
		try {
			const isUpdated = await updateUserNotificationSettings({ dailyConsumption: dailyConsumption, highUsage: checked, waterLeak: possibleWaterLeak });
			if (isUpdated !== true) {
				toast.error('There was an issue updating your notification settings. Please try again.');
				setHighConsumption(!checked);
			}
		} catch (e) {
			toast.error(e.message);
			setHighConsumption(!checked);
		}
	};

	const updatePossibleWaterLeak = async (checked) => {
		setPossibleWaterLeak(checked);
		try {
			const isUpdated = await updateUserNotificationSettings({ dailyConsumption: dailyConsumption, highUsage: highConsumption, waterLeak: checked });
			if (isUpdated !== true) {
				toast.error('There was an issue updating your notification settings. Please try again.');
				setPossibleWaterLeak(!checked);
			}
		} catch (e) {
			toast.error(e.message);
			setPossibleWaterLeak(!checked);
		}
	};

	async function init() {
		try {
			const { DailyConsumption, HighUsage, WaterLeak } = await getUserNotificationSettings();
			setDailyConsumption(DailyConsumption);
			setHighConsumption(HighUsage);
			setPossibleWaterLeak(WaterLeak);
		} catch (e) {}
	}

	useEffect(() => {
		init();
	}, []);

	return (
		<Card className={className} title="Notifications">
			<S.Wrapper>
				<Typography tag="h5" weight="bold">
					Email Notifications
				</Typography>
				<Toggle label="High Consumption" id="high-consumption" checked={highConsumption} onChange={(e) => updateHighConsumption(e.target.checked)} />
				<Toggle label="Possible Water Leak" id="possible-water-leak" checked={possibleWaterLeak} onChange={(e) => updatePossibleWaterLeak(e.target.checked)} />
			</S.Wrapper>
		</Card>
	);
};

NotificationsPreferences.propTypes = {
	className: PropTypes.string,
};
