import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MessageWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
	border-bottom: 1px solid ${colors.neutralForm};

	.time-detail {
		color: ${colors.neutralButton};
	}

	&:first-child {
		padding-top: 0.5rem;
	}
	&:last-child {
		border: none;
		padding-bottom: 0;
	}
`;
