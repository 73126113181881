import styled from 'styled-components';

import colors from '../../styles/colors';

export const Head = styled.div`
	display: flex;
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.75rem;
`;

export const MobileItem = styled.div`
	appearance: none;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: left;
	padding: 0.75rem;
	border: 1px solid ${colors.neutralForm};
	color: black;
	margin: 0.625rem 0;

	${Head} {
		justify-content: space-between;
		align-items: center;
	}

	&:hover {
		opacity: 0.8;
	}
`;

export const DesktopWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: -0.625rem;

	${Head} {
		padding: 0.5rem;
		background-color: ${colors.neutralBackground};
		color: ${colors.neutralText};
		border: 1px solid ${colors.neutralForm};
		border-left: none;
		border-right: none;

		& > * {
			flex: 1;
		}
	}
`;

export const DesktopItem = styled.div`
	appearance: none;
	background-color: transparent;
	border: none;
	display: flex;
	align-items: center;
	text-align: left;
	padding: 0.5rem;
	border-bottom: 1px solid ${colors.neutralForm};
	color: black;

	& > * {
		flex: 1;
	}

	&:hover {
		opacity: 0.8;
	}
`;
