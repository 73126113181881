import styled from 'styled-components';

import { Link as LithiosLink } from '../../components';
import colors from '../../styles/colors';

export const MainWrapper = styled.main`
	display: grid;
	align-content: center;
	height: inherit;
	overflow: scroll;
	min-height: 32.5em; /* 520px */
	margin: 0 1em;
	padding: 1em 0;

	@media screen and (min-width: 768px) {
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
	}
`;

export const PictureWrapper = styled.div`
	display: flex;
	justify-content: center;
	height: 3.75em;
	min-height: 3.75em;
	background-color: ${colors.brandPrimary};

	@media screen and (min-width: 768px) {
		height: 8em;
		min-height: 8em;
	}
`;

export const Picture = styled.picture`
	display: flex;
	max-width: 4.25em;

	@media screen and (min-width: 768px) {
		max-width: 9.5em;
	}

	img {
		width: 100%;
		object-fit: contain;
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: white;
	box-shadow: ${colors.sidebarShadow};
	padding: 1.25em;

	@media screen and (min-width: 375px) {
		padding: 1.5em;
	}

	@media screen and (min-width: 768px) {
		height: inherit;
		min-height: 41.25em; /* 660px */
		padding: 4em;
		justify-content: flex-start;
	}
`;

export const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 1.5em 0;

	& > * + * {
		margin-top: 1.5em;
	}

	button {
		align-self: center;
	}

	@media screen and (min-width: 768px) {
		max-width: 25em; /* 400px */
		width: 100%;
		margin: 1.5em auto;

		button {
			margin-top: 3em;
		}
	}
`;

export const Link = styled(LithiosLink)`
	display: inline-block;
	font-size: 0.875em;
	margin-top: 1.5em;
	font-weight: 400;
`;
