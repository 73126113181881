import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	height: inherit;
	align-content: center;
	color: ${colors.neutralText};
`;
