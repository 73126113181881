/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import envelopeOpenText from '../../assets/envelope-open-text.svg';
import logo from '../../assets/wilson-logo.png';
import { Button, Typography, Spinner } from '../../components';
import * as S from './VerifyEmail.styles';
import { useHistory, useParams } from 'react-router-dom';
import { userValidateLogin } from '../../services/authenticationService';
import { META_TITLE } from '../../utils/constants';

export default function VerifyEmail() {
	const { aToken, bToken } = useParams();
	const [status, setStatus] = useState('loading');
	const history = useHistory();

	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	async function init() {
		try {
			await userValidateLogin({ token1: aToken, token2: bToken });
			setStatus('valid');
		} catch (e) {
			setStatus({ value: 'error', message: e.message });
		}
	}

	const handleGoBackToLogin = () => {
		history.push('/login');
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		// Set document title
		document.title = `Verification | ${META_TITLE}`;
	}, []);

	// Return component
	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			{status === 'loading' ? (
				<Spinner />
			) : status === 'valid' ? (
				<S.MainContainer>
					<S.LogoContainer>
						<img src={envelopeOpenText} alt="" />
					</S.LogoContainer>
					<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
						Your Email Has Been Verified
					</Typography>
					<S.ParaContainer>
						<Typography tag="h6" variation="2" center>
							We've successfully verified your email address. Continue to the Log In page to set up your accounts.
						</Typography>
					</S.ParaContainer>
					<S.InputsWrapper>
						<Button size={isHigherThan768 ? 'medium' : 'small'} onClick={handleGoBackToLogin}>
							<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
								Continue to Log In
							</Typography>
						</Button>
					</S.InputsWrapper>
				</S.MainContainer>
			) : (
				<S.MainContainer>
					<S.LogoContainer>
						<img src={envelopeOpenText} alt="" />
					</S.LogoContainer>
					<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
						This Verification Link Is No Longer Valid
					</Typography>
					<S.ParaContainer>
						<Typography tag="h6" variation="2" center>
							{status.message}
						</Typography>
					</S.ParaContainer>
					<S.InputsWrapper>
						<Button size={isHigherThan768 ? 'medium' : 'small'} type="button" onClick={handleGoBackToLogin}>
							<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
								Back to Login
							</Typography>
						</Button>
					</S.InputsWrapper>
				</S.MainContainer>
			)}
		</S.MainWrapper>
	);
}
