import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { notAuthenticatedLinks } from '../NotAuthenticatedRouter';
import {
	Page404,
	OnboardingWelcome,
	OnboardingNewCustomerBuildingType,
	OnboardingNewCustomerResidentialApplication,
	OnboardingNewCustomerCommercialApplication,
	OnboardingNewCustomerCommercialGreenlight,
	OnboardingNewCustomerCommercialReview,
	OnboardingNewCustomerCommercialUtilities,
	OnboardingNewCustomerResidentialUtilities,
	OnboardingNewCustomerResidentialGreenlight,
	CurrentCustomerProfileSearch,
	CurrentCustomerConfirmAccount,
	CurrentCustomerBillingSetup,
	OnboardingNewCustomerResidentialReview,
	Logout,
} from '../../pages';
import { MaintenanceModeWrapper } from '../../components';

export function OnboardingRoutes({ showDashboard = false }) {
	return [
		{
			path: '/onboarding',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingWelcome showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{ path: '/onboarding/new-customer', render: () => <Redirect to="/onboarding/new-customer/building-type" /> },
		{
			path: '/onboarding/new-customer/building-type',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerBuildingType showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/new-customer/residential/application',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerResidentialApplication showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/new-customer/residential/utilities',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerResidentialUtilities showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/new-customer/residential/greenlight',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerResidentialGreenlight showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/new-customer/residential/review',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerResidentialReview showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},

		{
			path: '/onboarding/new-customer/commercial/application',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerCommercialApplication showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/new-customer/commercial/utilities',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerCommercialUtilities showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/new-customer/commercial/greenlight',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerCommercialGreenlight showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/new-customer/commercial/review',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerCommercialReview showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{ path: '/onboarding/current-customer', render: () => <Redirect to="/onboarding/current-customer/building-type" /> },
		{
			path: '/onboarding/current-customer/building-type',
			render: () => (
				<MaintenanceModeWrapper>
					<OnboardingNewCustomerBuildingType showDashboard={showDashboard} isCurrentCustomer />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/current-customer/residential/search',
			render: () => (
				<MaintenanceModeWrapper>
					<CurrentCustomerProfileSearch showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/current-customer/residential/confirm',
			render: () => (
				<MaintenanceModeWrapper>
					<CurrentCustomerConfirmAccount showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/current-customer/residential/billing',
			render: () => (
				<MaintenanceModeWrapper>
					<CurrentCustomerBillingSetup showDashboard={showDashboard} />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/current-customer/commercial/search',
			render: () => (
				<MaintenanceModeWrapper>
					<CurrentCustomerProfileSearch showDashboard={showDashboard} isCommercial />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/current-customer/commercial/confirm',
			render: () => (
				<MaintenanceModeWrapper>
					<CurrentCustomerConfirmAccount showDashboard={showDashboard} isCommercial />
				</MaintenanceModeWrapper>
			),
		},
		{
			path: '/onboarding/current-customer/commercial/billing',
			render: () => (
				<MaintenanceModeWrapper>
					<CurrentCustomerBillingSetup showDashboard={showDashboard} isCommercial />
				</MaintenanceModeWrapper>
			),
		},
	];
}

export default function OnboardingRouter({ showDashboard = false }) {
	return (
		<Switch>
			{/* General */}
			<Route exact path="/" component={() => <Redirect to="/onboarding" />} />
			<Route exact path="/logout" component={Logout} />

			{/* Redirects */}
			<Route path="/login" component={() => <Redirect to="/onboarding" />} />

			{/* General Routes */}
			{OnboardingRoutes({ showDashboard }).map((route) => (
				<Route exact key={route.path} path={route.path} render={route.render} />
			))}

			{/* Redirects */}
			{notAuthenticatedLinks.map(({ id, to }) => (
				<Route key={id} path={to} component={() => <Redirect to="/onboarding" />} />
			))}
			<Route path="*" component={() => <Redirect to="/onboarding" />} />
		</Switch>
	);
}

OnboardingRoutes.propTypes = {
	showDashboard: PropTypes.bool,
};

OnboardingRouter.propTypes = {
	showDashboard: PropTypes.bool,
};
