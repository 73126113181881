import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.form`
	max-width: 36rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	background: ${colors.white};
	padding: 2.5rem 1rem;

	@media screen and (min-width: 768px) {
	}
`;

export const HeadingContainer = styled.div`
	margin-top: 3rem;
	margin-bottom: 3rem;
`;

export const MainHeadingWrapper = styled.div`
	margin-top: 0rem;
	margin-bottom: 1rem;
`;

export const Main = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
`;

export const SelectsContainer = styled.div`
	width: 100%;
	margin-left: auto;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.125rem;

	& > div {
		width: 100%;
	}

	label {
		font-weight: 600;
		color: ${colors.black};
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		& > div {
			width: 100%;
			margin-left: auto;
			margin-bottom: 0.75rem;
		}
	}
`;

export const MainWrapper = styled.div`
	width: 90%;
	margin-bottom: 1.125rem;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	margin-top: 2.5rem;
	& > button {
		margin-right: 1rem;
	}

	@media screen and (max-width: 400px) {
		flex-direction: column;
		& > button {
			margin-right: 0rem;
			margin-bottom: 1rem;
		}
	}
`;

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5.625rem 1rem 2rem 1rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 29.75rem;
	margin-top: 2rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const FormButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
