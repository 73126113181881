import React, { useEffect } from 'react';

import { PageHeader, ResourcesPanel } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './Resources.styles';

export default function Resources() {
	useEffect(() => {
		// Set document title
		document.title = `Resources | ${META_TITLE}`;
	}, []);

	return (
		<>
			<S.Wrapper>
				<PageHeader>Resources</PageHeader>
				<ResourcesPanel type="resources" />
			</S.Wrapper>
		</>
	);
}
