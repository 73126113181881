import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { Card } from '../Card';
import * as S from './AdminRequestDescription.styles';
import { Typography } from '../Typography';

export const AdminRequestDescription = ({ className, request }) => {
	const splitCamelCase = (word) => {
		var output,
			i,
			l,
			capRe = /[A-Z]/;
		if (typeof word !== 'string') {
			throw new Error('The "word" parameter must be a string.');
		}
		output = [];
		for (i = 0, l = word.length; i < l; i += 1) {
			if (i === 0) {
				output.push(word[i].toUpperCase());
			} else {
				if (i > 0 && capRe.test(word[i])) {
					output.push(' ');
				}
				output.push(word[i]);
			}
		}
		let joined = output.join('');
		if (joined.endsWith('Ssn')) {
			joined = joined.replace('Ssn', 'SSN');
		}
		return joined;
	};

	const processContent = (content) => {
		let newContent = content;
		if (content.startsWith('item-')) {
			newContent = newContent.replace('item-', '');
		}
		return newContent;
	};

	const renderJSON = (content, level) => {
		const renderArray = [];

		// Render for type
		if (content != null) {
			if (content.constructor == Object) {
				// Organize keys
				const keys = Object.keys(content).sort();

				// Check if key/value
				if (keys.join('') === 'labelvalue') {
					renderArray.push(<Typography tag="p">{`${content.label}`}</Typography>);
				} else {
					// Change order of specific keys
					const lastNameIndex = keys.indexOf('lastName');
					if (lastNameIndex > -1) {
						keys.splice(lastNameIndex, 1);
						keys.unshift('lastName');
					}
					const firstNameIndex = keys.indexOf('firstName');
					if (firstNameIndex > -1) {
						keys.splice(firstNameIndex, 1);
						keys.unshift('firstName');
					}

					// Iterate through keys
					keys.forEach((key) => {
						// Get parameters
						let entity = content[key];

						// Check if entity is not a file path
						let isFilePath = false;
						if (entity) {
							if (Array.isArray(entity) && entity.length > 0) {
								const innerKeys = Object.keys(entity[0]);
								if (innerKeys.length === 1 && innerKeys[0] === 'path') {
									isFilePath = true;
								}
							} else if (entity.constructor == Object) {
								const innerKeys = Object.keys(entity);
								if (innerKeys.length === 1 && innerKeys[0] === 'path') {
									isFilePath = true;
								}
							}
						}

						// Validate key
						if (!isFilePath && key.toLowerCase() !== 'icon' && key.toLowerCase() !== 'id') {
							if (key === 'meters') {
								entity = entity.filter((meter) => meter.added);
							}
							const innerContent = renderJSON(entity, level + 1);
							if (innerContent != null && innerContent.length > 0) {
								renderArray.push(
									<S.ContentSection key={key}>
										<Typography tag={level === 0 ? 'h4' : 'p'} weight={level === 0 ? 'bold' : 'semibold'} className="upper">
											{splitCamelCase(processContent(key))}
										</Typography>
										{innerContent}
									</S.ContentSection>
								);
							}
						}
					});
				}
			} else if (Array.isArray(content)) {
				content.forEach((entity) => {
					const innerContent = renderJSON(entity, level + 1);
					if (innerContent != null && innerContent.length > 0) {
						renderArray.push(
							<S.ContentSection>
								<div className="background">{innerContent}</div>
							</S.ContentSection>
						);
					}
				});
			} else if (typeof content == 'boolean') {
				if (content === true) {
					renderArray.push(<Typography tag="p">{content === true ? 'Yes' : 'No'}</Typography>);
				}
			} else if (typeof content === 'number') {
				renderArray.push(<Typography tag="p">{`${content}`}</Typography>);
			} else {
				const date = moment(content, true);
				if (date.isValid()) {
					renderArray.push(<Typography tag="p">{date.format('LLLL')}</Typography>);
				} else {
					const innerContent = processContent(content);
					if (innerContent && innerContent.toLowerCase() !== 'no') {
						renderArray.push(<Typography tag="p">{innerContent}</Typography>);
					}
				}
			}
		}
		return renderArray;
	};

	const renderDescription = () => {
		// Format description
		let isJSON = true;
		let formattedDescription = request.Description.replace(/\\t/g, '')
			.replace(/\\n/g, '')
			.replace(/\\"/g, '"')
			.replace(/},}/g, '}}')
			.replace(/(^"|"$)/g, '');

		// Parse content
		let descriptionContent = formattedDescription;
		try {
			descriptionContent = JSON.parse(formattedDescription);
		} catch (e) {
			isJSON = false;
		}

		// Return text content
		if (!isJSON) return <Typography tag="p">{descriptionContent}</Typography>;

		// Render JSON content
		return renderJSON(descriptionContent, 0);
	};

	const exportApplicationPDF = () => {
		const input = document.getElementById('printArea');
		const pdf = jsPDF({ orientation: 'p', format: 'letter' });
		const filename = `Application ${request.RequestKey} ${new Date().toJSON().slice(0, 10)}.pdf`;
		pdf.setProperties({
			title: filename,
		});
		pdf.html(input, {
			callback: function (doc) {
				window.open(doc.output('bloburl', { filename }));
			},
			x: 0,
			y: 0,
			margin: [4, 4, 4, 4], // mm
			width: 208, // 216 = letter paper width in mm, 208 = less the 8mm margin
			windowWidth: 786, // 816 = letter paper pixel width at 96dpi (web), 786 = less the 30px margin
			html2canvas: {
				logging: false,
				windowWidth: 786, // 816 = letter paper pixel width at 96dpi (web), 786 = less the 30px margin
			},
		});
	};

	return (
		<Card
			className={className}
			title="Request"
			action={[
				{
					id: 1,
					onClick: () => exportApplicationPDF(),
					icon: ['fal', 'print'],
					label: 'Print',
					type: 'outline',
				},
			]}
		>
			<S.Wrapper id="printArea">{renderDescription()}</S.Wrapper>
		</Card>
	);
};
AdminRequestDescription.propTypes = {
	className: PropTypes.string,
	request: PropTypes.shape(),
};
