import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'beautiful-react-hooks';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { settings } from '../../app/slices/manageUtility/manageUtilitySlice';

import { Button, OnboardingFooter, Typography, DatePicker, Select, TextInput, Card, Radio, Checkbox, MakePaymentHeader } from '../../components';
import { Manage_Utility, Manage_Utility_Settings, META_TITLE, State_Options } from '../../utils/constants';
import { required } from '../../utils/form-default-errors';
import * as S from './ManageUtilitySettings.styles';

const canellationsReasons = [
	{
		value: 'Moving',
		label: 'Moving',
	},
	{
		value: 'Other Reason',
		label: 'Other Reason',
	},
];

export default function ManageUtilitySettings() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');

	const [utilitiesData, setUtilitiesData] = useState([]);

	const stepData = useSelector((state) => state.manageUtility);
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		getValues,
	} = useForm({ defaultValues: { ...stepData.settings, utilityStartDate: new Date(), utilityEndDate: new Date(), reasonForCancellation: canellationsReasons[0], state: State_Options[0] } });

	const utilityStartDate = watch('utilityStartDate');
	const utilityEndDate = watch('utilityEndDate');
	const reasonForCancellation = watch('reasonForCancellation');
	const state = watch('state');

	const hasAddedUtilities = utilitiesData?.some((utility) => utility.changeStatus === 'added');
	const hasRemovedUtilities = utilitiesData?.some((utility) => utility.changeStatus === 'removed');

	const onBack = () => {
		history.push('/manage-utility/edit');
	};

	const onNext = (data) => {
		dispatch(settings(data));
		localStorage.setItem(Manage_Utility_Settings, JSON.stringify(data));
		history.push('/manage-utility/review');
	};

	useEffect(() => {
		// Set document title
		document.title = `Manage Utilities | ${META_TITLE}`;

		let tempSettings = localStorage.getItem(Manage_Utility_Settings);
		let tempServices = localStorage.getItem(Manage_Utility);
		if (tempSettings) {
			tempSettings = JSON.parse(tempSettings);
		}
		if (tempServices) {
			tempServices = JSON.parse(tempServices);
		}
		if (tempServices) {
			setUtilitiesData(tempServices);
		}
		if (tempSettings) {
			setValue('utilityStartDate', new Date(tempSettings?.utilityStartDate));
			setValue('utilityEndDate', new Date(tempSettings?.utilityEndDate));
			setValue('reasonForCancellation', tempSettings?.reasonForCancellation);
			setValue('state', tempSettings?.state);
			setValue('city', tempSettings?.city);
			setValue('zipcode', tempSettings?.zipcode);
			setValue('address', tempSettings?.address);
		}
	}, []);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<MakePaymentHeader />
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Utility Settings
					</Typography>
					{hasAddedUtilities && (
						<Card title="Added Utilities">
							<S.Card1Date>
								<DatePicker
									{...register('utilityStartDate', {
										required: true,
									})}
									error={errors?.hasOwnProperty('utilityStartDate')}
									label="Utility Start Date"
									id="utilityStartDate"
									selected={utilityStartDate}
									onChange={(date) => setValue('utilityStartDate', date)}
									minDate={new Date()}
								/>
							</S.Card1Date>
						</Card>
					)}
					{hasRemovedUtilities && (
						<Card title="Removed Utilities">
							<S.Card2Container>
								<S.Card2InputsContainer>
									<S.Card2Date>
										<DatePicker
											{...register('utilityEndDate', {
												required: true,
											})}
											error={errors?.hasOwnProperty('utilityEndDate')}
											label="Utility End Date"
											id="utilityEndDate"
											selected={utilityEndDate}
											onChange={(date) => setValue('utilityEndDate', date)}
											minDate={new Date()}
										/>
									</S.Card2Date>
									<S.InputContainer>
										<Select
											{...register('reasonForCancellation', {
												required: true,
											})}
											error={errors?.reasonForCancellation}
											label="Reason for Cancellation"
											id="reasonForCancellation"
											value={reasonForCancellation}
											options={canellationsReasons}
											onChange={(value) => setValue('reasonForCancellation', value)}
										/>
									</S.InputContainer>
								</S.Card2InputsContainer>
								<S.Card2AddressContainer>
									<TextInput
										label="Address to Send Final Bill"
										id="address"
										error={errors?.address}
										{...register('address', {
											required: required('Address'),
										})}
									/>
								</S.Card2AddressContainer>
								<S.Card2InputsContainer2>
									<S.InputContainer>
										<TextInput
											containerClassName="cityName"
											label="City"
											id="city"
											error={errors?.city}
											{...register('city', {
												required: required('City'),
											})}
										/>
									</S.InputContainer>
									<S.Card2StateContainer>
										<Select
											{...register('state', {
												required: true,
											})}
											error={errors?.state}
											label="State"
											id="state"
											value={state}
											options={State_Options}
											onChange={(value) => setValue('state', value)}
										/>
									</S.Card2StateContainer>
									<S.InputContainer>
										<TextInput
											containerClassName="zipcode"
											label="Zipcode"
											id="zipcode"
											error={errors?.zipcode}
											{...register('zipcode', {
												required: required('Zipcode'),
											})}
										/>
									</S.InputContainer>
								</S.Card2InputsContainer2>
							</S.Card2Container>
						</Card>
					)}
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Review Changes
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
