import { createSlice } from '@reduxjs/toolkit';

export const locationSlice = createSlice({
	name: 'location',
	initialState: {
		status: 'idle',
		error: null,
		value: null,
	},
	reducers: {
		updateCurrentLocation: (state, action) => {
			const payload = { ...action.payload };
			if (payload) {
				let IsBudgetBilling = false;
				let IsPrePay = false;
				let IsCreditCardOnly = false;
				if (payload.UtilityAccount) {
					if (payload.UtilityAccount.IsBudgetBilling === true) IsBudgetBilling = true;
					if (payload.UtilityAccount.IsPrePay === true) IsPrePay = true;
					if (payload.UtilityAccount.IsCreditCardOnly === true) IsCreditCardOnly = true;
				}
				if (payload.GreenlightAccount) {
					if (payload.GreenlightAccount.IsBudgetBilling === true) IsBudgetBilling = true;
					if (payload.GreenlightAccount.IsPrePay === true) IsPrePay = true;
					if (payload.GreenlightAccount.IsCreditCardOnly === true) IsCreditCardOnly = true;
				}
				payload.IsBudgetBilling = IsBudgetBilling;
				payload.IsPrePay = IsPrePay;
				payload.IsCreditCardOnly = IsCreditCardOnly;
			}
			state.value = payload;
		},
		clearCurrentLocation: (state) => {
			state.value = null;
		},
	},
	extraReducers: {},
});

export const { updateCurrentLocation, clearCurrentLocation } = locationSlice.actions;

export default locationSlice.reducer;
