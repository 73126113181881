/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Card } from '..';
import * as S from './CustomerResidentialGreenlightReviewCard.styles';
import { ChoicePackage } from '../ChoicePackage/ChoicePackage';
import { useSelector } from 'react-redux';

export default function CustomerResidentialGreenlightReviewCard({ greenLightData }) {
	const { internetPackages, bundles } = useSelector((state) => state.greenlight.value);

	const [greenlight, setGreenlight] = useState({});

	useEffect(() => {
		setGreenlight(greenLightData);
	}, [greenLightData]);

	const renderServices = () => {
		switch (greenlight?.selectedServices) {
			case 'internet':
				return 'Internet';
			case 'internet-and-cable':
				return 'Internet & Cable';
			case 'internet-and-phone':
				return 'Internet & Phone';
			case 'internet-cable-and-phone':
				return 'Internet, Cable, & Phone';
			default:
				return 'None Selected';
		}
	};

	const getInternetPackage = () => {
		return internetPackages.find((pkg) => pkg.id === greenlight?.internetSelection);
	};

	const getBundle = () => {
		return bundles.find((bund) => bund.id === greenlight?.internetCableBundleSelection);
	};

	return (
		<Card title="Greenlight">
			<S.Card2ContentWrapper>
				<Typography tag="h5" weight="bold">
					Services
				</Typography>
				<Typography tag="p" variation="1" className="adjustTop">
					{renderServices()}
				</Typography>
				{greenlight?.internetSelection && (
					<S.Card2ContentSubWrapper>
						<Typography tag="h6" weight="semibold">
							Internet Package
						</Typography>
						<ChoicePackage
							key={getInternetPackage().id}
							icon={getInternetPackage().icon}
							title={getInternetPackage().title}
							price={getInternetPackage().price}
							subTitle={getInternetPackage().subTitle}
							details={getInternetPackage().details}
							value={getInternetPackage()}
							showToggle={false}
						/>
					</S.Card2ContentSubWrapper>
				)}
				{greenlight?.internetCableBundleSelection && (
					<S.Card2ContentSubWrapper>
						<Typography tag="h5" weight="semibold">
							Internet & Cable Bundle
						</Typography>
						<ChoicePackage icon={getBundle().icon} title={getBundle().title} price={getBundle().price} subTitle={getBundle().subTitle} details={getBundle().details} details2={getBundle().details2} subHeadings={getBundle().subHeadings} showToggle={false} value={getBundle()} />
					</S.Card2ContentSubWrapper>
				)}
				{(greenlight?.selectedServices === 'internet-and-phone' || greenlight?.selectedServices === 'internet-cable-and-phone') && (
					<S.Card2ContentSubWrapper>
						<Typography tag="h5" weight="semibold">
							Home Phone
						</Typography>
						<Typography tag="h6" weight="semibold" variation="1">
							Keep Phone Number
						</Typography>
						<Typography tag="p" className="adjustTop">
							{greenlight?.keepPhoneNumber}
						</Typography>
						{greenlight?.phoneNumber && (
							<>
								<Typography tag="h6" weight="semibold" variation="1">
									Current Phone Number
								</Typography>
								<Typography tag="p" className="adjustTop">
									{greenlight?.phoneNumber}
								</Typography>
							</>
						)}
						{greenlight?.phoneProvider && (
							<>
								<Typography tag="h6" weight="semibold" variation="1">
									Current Provider
								</Typography>
								<Typography tag="p" className="adjustTop">
									{greenlight?.phoneProvider}
								</Typography>
							</>
						)}
						{greenlight?.phoneProviderAccountNumber && (
							<>
								<Typography tag="h6" weight="semibold" variation="1">
									Current Provider Account Number
								</Typography>
								<Typography tag="p" className="adjustTop">
									{greenlight?.phoneProviderAccountNumber}
								</Typography>
							</>
						)}
					</S.Card2ContentSubWrapper>
				)}
			</S.Card2ContentWrapper>
			<S.CallPreference>
				<Typography tag="p" variation="2">
					You&apos;ve made a great choice! A Sales Executive will reach out to you shorty on the phone number you provided.
				</Typography>
			</S.CallPreference>
			{/*<S.Card1ContentWrapper2>
				<Typography tag="h5" weight="bold">
					Settings
				</Typography>
				<S.Card1DetailsWrapper>
					<Typography tag="h6" weight="semibold">
						How do you want to pay for Greenlight services?
					</Typography>
					<Typography tag="h6">{greenlight?.utilityPay}</Typography>
				</S.Card1DetailsWrapper>
				<Typography tag="h6" weight="semibold">
					Greenlight Start Date
				</Typography>
				<Typography tag="span" variation="2">
					Subject to schedule availability. An Advisor will confirm an appointment date and time shortly.
				</Typography>
				<Typography tag="h6">{moment(greenlight?.utilityStartDate)?.format('MM/DD/YYYY')}</Typography>
			</S.Card1ContentWrapper2>*/}
		</Card>
	);
}

CustomerResidentialGreenlightReviewCard.displayName = 'Customer Residential Greenlight Review Card';
CustomerResidentialGreenlightReviewCard.propTypes = {
	greenLightData: PropTypes.object,
};
