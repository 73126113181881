export function formatInitials(user) {
	let names = user?.name?.split(' ');
	if (names == null && user?.email != null) names = [user?.email];
	let initials = names && names.length > 0 ? '' : 'W';
	for (let i = 0; i < 2; i += 1) {
		if (names?.length > i) {
			if (names[i]) {
				initials += names?.[i]?.[0];
			}
		}
	}
	return initials.toUpperCase();
}
