import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import { PageHeader, Tab, CurrentUsers, UnassociatedAccounts, Modal, Typography, TextInput, Button, Checkbox } from '../../components';
import * as S from './AdminUsers.styles';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import { META_TITLE } from '../../utils/constants';
import { inviteCreateUser, inviteFindAccounts } from '../../services/adminService';
import { SearchSearchingModal } from '../SearchSearchingModal';
import { SearchMatchFound } from '../SearchMatchFound';
import { SearchNoMatchesModal } from '../SearchNoMatchesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AdminUsers() {
	const [isSearchingModalOpen, setIsSearchingModalOpen] = useState(false);
	const [isResultFoundModalOpen, setIsResultFoundModalOpen] = useState(false);
	const [isResultNotFoundModalOpen, setIsResultNotFoundModalOpen] = useState(false);
	const [isSearchCustomerModalOpen, setIsSearchCustomerModalOpen] = useState(false);
	const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

	const [accounts, setAccounts] = useState({ unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] });
	const [searchedAccounts, setSearchedAccounts] = useState({ unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] });

	const [tabs, setTabs] = useState([
		{ id: 1, label: 'Current Users', component: <CurrentUsers /> },
		{ id: 2, label: 'Unassociated Accounts', component: <UnassociatedAccounts /> },
	]);

	const {
		handleSubmit: handleSubmitCreate,
		register: registerCreate,
		formState: { errors: errorsCreate, isSubmitting: isSubmittingCreate },
		reset: resetCreate,
	} = useForm();

	const {
		handleSubmit: handleSubmitSearch,
		register: registerSearch,
		formState: { errors: errorsSearch, isSubmitting: isSubmittingSearch },
		reset: resetSearch,
	} = useForm();

	async function onSubmit(data) {
		if (accounts.unassignedUtilityAccounts.length === 0 && accounts.unassignedGreenlightAccounts.length === 0) {
			toast.error('Please select at least one account to attach to this invite.');
			resetCreate(undefined, { keepValues: true });
			return;
		}
		try {
			const success = await inviteCreateUser({
				userEmail: data.email,
				accounts: [
					...(accounts.unassignedUtilityAccounts || []).map((account) => ({
						AccountType: '1',
						AccountNumber: account.AccountNumber,
						LocationID: account.LocationID,
					})),
					...(accounts.unassignedGreenlightAccounts || []).map((account) => ({
						AccountType: '2',
						AccountNumber: account.AccountNumber,
						LocationID: account.LocationID,
					})),
				],
			});
			if (success) {
				setIsCreateUserModalOpen(false);
				toast.success('A new user has been successfully invited.');
			} else {
				toast.error('There was an issue inviting this user. Please try again.');
				resetCreate(undefined, { keepValues: true });
			}
		} catch (e) {
			toast.error(e.message);
			resetCreate(undefined, { keepValues: true });
		}
	}

	async function onSubmitSearchForm(data) {
		const { customerAccountId } = data;
		setIsSearchingModalOpen(true);
		try {
			const data = await inviteFindAccounts({ customerAccountId });
			const { unassignedUtilityAccounts, unassignedGreenlightAccounts } = data;
			let tempAccounts = { unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] };
			let totalAccounts = 0;
			if (unassignedUtilityAccounts?.length) {
				totalAccounts = unassignedUtilityAccounts?.length;
				tempAccounts = { ...tempAccounts, unassignedUtilityAccounts };
			}
			if (unassignedGreenlightAccounts?.length) {
				totalAccounts = totalAccounts + unassignedGreenlightAccounts?.length;
				tempAccounts = { ...tempAccounts, unassignedGreenlightAccounts };
			}
			if (totalAccounts) {
				setIsResultFoundModalOpen(true);
			} else {
				setIsResultNotFoundModalOpen(true);
			}
			setSearchedAccounts(tempAccounts);
		} catch (e) {
			resetSearch(undefined, { keepValues: true });
		}
		setIsSearchingModalOpen(false);
	}

	const handleResultSelection = (selections) => {
		const newAccounts = { ...accounts };
		if (selections.unassignedUtilityAccounts) {
			selections.unassignedUtilityAccounts.forEach((account) => {
				const exists = newAccounts.unassignedUtilityAccounts.some((acct) => acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber);
				if (!exists) {
					newAccounts.unassignedUtilityAccounts.push(account);
				}
			});
		}
		if (selections.unassignedGreenlightAccounts) {
			selections.unassignedGreenlightAccounts.forEach((account) => {
				const exists = newAccounts.unassignedGreenlightAccounts.some((acct) => acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber);
				if (!exists) {
					newAccounts.unassignedGreenlightAccounts.push(account);
				}
			});
		}
		setAccounts(newAccounts);
		setIsResultFoundModalOpen(false);
	};

	const moveToCreateCustomer = () => {
		if (accounts.unassignedUtilityAccounts.length === 0 && accounts.unassignedGreenlightAccounts.length === 0) {
			toast.error('Please select at least one account to attach to this invite.');
			return;
		}
		setIsSearchCustomerModalOpen(false);
		setIsCreateUserModalOpen(true);
	};

	const removeSelectedAccount = (account, type) => {
		const newAccounts = { ...accounts };
		if (type === 'utility') {
			newAccounts.unassignedUtilityAccounts = newAccounts.unassignedUtilityAccounts.filter((acct) => !(acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber));
		} else {
			newAccounts.unassignedGreenlightAccounts = newAccounts.unassignedGreenlightAccounts.filter((acct) => !(acct.LocationID === account.LocationID && acct.AccountNumber === account.AccountNumber));
		}
		setAccounts(newAccounts);
	};

	useEffect(() => {
		// Set document title
		document.title = `Users | ${META_TITLE}`;
	}, []);

	return (
		<>
			{/* Page Content */}
			<S.Wrapper>
				<PageHeader
					action={[
						{
							id: 1,
							onClick: () => {
								setAccounts({ unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] });
								resetSearch();
								resetCreate();
								setIsSearchCustomerModalOpen(true);
							},
							icon: ['fal', 'envelope'],
							label: 'New Customer Invite',
						},
					]}
				>
					Users
				</PageHeader>
				{/*<Tab tabs={tabs} setTabs={setTabs} transparent />*/}
				<CurrentUsers />
			</S.Wrapper>

			{/* Customer Search Modal */}
			<Modal shouldCloseOnOverlayClick allowScroll isOpen={isSearchCustomerModalOpen} onRequestClose={() => setIsSearchCustomerModalOpen(false)}>
				<S.ModalWrapper>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold" center>
							New Customer Invite
						</Typography>
						<Typography tag="p" center>
							Search for unassociated customer accounts to add to this invite.
						</Typography>
					</S.TitleWrapper>
					<S.CheckboxesContainer>
						{accounts?.unassignedUtilityAccounts?.map((item, index) => (
							<S.CheckboxWrapper key={index}>
								<S.CheckboxContentContainer>
									<Typography tag="h5" weight="bold">
										{item?.Address} (Utility Account)
									</Typography>
									<Typography tag="p" variation="2">
										Account Name: {item?.AccountName}
									</Typography>
								</S.CheckboxContentContainer>
								<S.CheckboxRemove>
									<FontAwesomeIcon
										icon={['fal', 'times']}
										onClick={() => {
											removeSelectedAccount(item, 'utility');
										}}
									/>
								</S.CheckboxRemove>
							</S.CheckboxWrapper>
						))}
						{accounts?.unassignedGreenlightAccounts?.map((item, index) => (
							<S.CheckboxWrapper key={index}>
								<S.CheckboxContentContainer>
									<Typography tag="h5" weight="bold">
										{item?.Address} (Greenlight Account)
									</Typography>
									<Typography tag="p" variation="2">
										Account Name: {item?.AccountName}
									</Typography>
								</S.CheckboxContentContainer>
								<S.CheckboxRemove>
									<FontAwesomeIcon
										icon={['fal', 'times']}
										onClick={() => {
											removeSelectedAccount(item, 'greenlight');
										}}
									/>
								</S.CheckboxRemove>
							</S.CheckboxWrapper>
						))}
					</S.CheckboxesContainer>
					<S.Form onSubmit={handleSubmitSearch(onSubmitSearchForm)}>
						<TextInput
							label="Customer Account ID"
							id="customerAccountId"
							error={errorsSearch.customerAccountId}
							{...registerSearch('customerAccountId', {
								required: required('Customer Account ID'),
							})}
						/>
						<Button type="submit">
							<Typography tag="span" variation="1" weight="extrablack">
								{isSubmittingSearch ? 'Loading...' : 'Search'}
							</Typography>
						</Button>
					</S.Form>
					<S.FormButtonWrapper>
						<Button variant="outline" variation="secondary" type="button" onClick={() => setIsSearchCustomerModalOpen(false)}>
							<Typography tag="span" variation="1" weight="extrablack">
								Cancel
							</Typography>
						</Button>
						<Button>
							<Typography tag="span" variation="1" weight="extrablack" onClick={moveToCreateCustomer}>
								Continue
							</Typography>
						</Button>
					</S.FormButtonWrapper>
				</S.ModalWrapper>
			</Modal>

			{/* Customer Invite Modal */}
			<Modal shouldCloseOnOverlayClick allowScroll isOpen={isCreateUserModalOpen} onRequestClose={() => setIsCreateUserModalOpen(false)}>
				<S.ModalWrapper>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold" center>
							New Customer Invite
						</Typography>
					</S.TitleWrapper>
					<S.Form onSubmit={handleSubmitCreate(onSubmit)}>
						<TextInput
							label="Email"
							id="email"
							error={errorsCreate.email}
							{...registerCreate('email', {
								required: required('Email'),
								pattern: pattern('Email', EMAIL),
							})}
						/>
						<S.CheckboxesContainer>
							{accounts?.unassignedUtilityAccounts?.map((item, index) => (
								<S.CheckboxWrapper key={index}>
									<S.CheckboxContentContainer>
										<Typography tag="h5" weight="bold">
											{item?.Address} (Utility Account)
										</Typography>
										<Typography tag="p" variation="2">
											Account Name: {item?.AccountName}
										</Typography>
									</S.CheckboxContentContainer>
								</S.CheckboxWrapper>
							))}
							{accounts?.unassignedGreenlightAccounts?.map((item, index) => (
								<S.CheckboxWrapper key={index}>
									<S.CheckboxContentContainer>
										<Typography tag="h5" weight="bold">
											{item?.Address} (Greenlight Account)
										</Typography>
										<Typography tag="p" variation="2">
											Account Name: {item?.AccountName}
										</Typography>
									</S.CheckboxContentContainer>
								</S.CheckboxWrapper>
							))}
						</S.CheckboxesContainer>
						<S.FormButtonWrapper>
							<Button
								variant="outline"
								variation="secondary"
								type="button"
								onClick={() => {
									setIsCreateUserModalOpen(false);
									setIsSearchCustomerModalOpen(true);
								}}
							>
								<Typography tag="span" variation="1" weight="extrablack">
									Back
								</Typography>
							</Button>
							<Button>
								<Typography tag="span" variation="1" weight="extrablack">
									{isSubmittingCreate ? 'Loading...' : 'Send Invite'}
								</Typography>
							</Button>
						</S.FormButtonWrapper>
					</S.Form>
				</S.ModalWrapper>
			</Modal>

			{/* Modals */}
			<SearchSearchingModal isOpen={isSearchingModalOpen} setIsOpen={setIsSearchingModalOpen} />
			<SearchMatchFound isOpen={isResultFoundModalOpen} setIsOpen={setIsResultFoundModalOpen} data={searchedAccounts} onContinue={handleResultSelection} storeResults={false} />
			<SearchNoMatchesModal isOpen={isResultNotFoundModalOpen} setIsOpen={setIsResultNotFoundModalOpen} showContinue={false} />
		</>
	);
}
