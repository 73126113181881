import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.div`
	max-widht: 72.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	padding: 4.5rem 1rem;

	svg {
		font-size: 2.5rem;
		color: ${colors.brandPrimary};
		margin-bottom: 0.75rem;
	}

	@media screen and (min-width: 768px) {
		padding: 8.4rem 0;
	}
`;

export const ParaContainer = styled.div`
	max-width: 23.375rem;
	margin: 0 auto;
	margin-top: 1rem;
`;
