import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useForm } from 'react-hook-form';

import { staffForgotPassword, errorHandler } from '../../services/authenticationService';
import logo from '../../assets/wilson-logo.png';
import { TextInput, Button, Typography, Link } from '../../components';
import * as S from './StaffForgotPassword.styles';
import { EMAIL } from '../../utils/common-regex';
import { pattern, required } from '../../utils/form-default-errors';
import { META_TITLE } from '../../utils/constants';

export default function StaffForgotPassword() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm({
		defaultValues: {},
	});
	const history = useHistory();
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	function onSubmit(e) {
		handleSubmit(staffForgotPassword)(e).catch((error) => {
			reset(undefined, { keepValues: true });
			throw error;
		});
	}

	function handleGoBackToLogin() {
		history.push('/staff/login');
	}

	useEffect(() => {
		// Set document title
		document.title = `Forgot Password | ${META_TITLE}`;
	}, []);

	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			<S.Form onSubmit={onSubmit}>
				<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
					Forgot Password?
				</Typography>
				<Typography tag={'h6'} weight="semibold" center>
					{!isSubmitSuccessful ? 'Enter the email associated with your account to reset your password.' : 'Check your email for next steps.'}
				</Typography>
				<S.InputsWrapper>
					{!isSubmitSuccessful ? (
						<>
							<TextInput
								label="Email"
								id="email"
								type="email"
								error={errors.email}
								{...register('email', {
									required: required('Email'),
									pattern: pattern('Email', EMAIL),
								})}
							/>
							<Button size={isHigherThan768 ? 'medium' : 'small'}>
								<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
									{isSubmitting ? 'Loading...' : 'Next'}
								</Typography>
							</Button>
							<Typography tag="span" variation="2" center>
								<Link to="/staff/login">Back to Log In</Link>
							</Typography>
						</>
					) : (
						<Button size={isHigherThan768 ? 'medium' : 'small'} type="button" onClick={handleGoBackToLogin}>
							<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
								Back to Login
							</Typography>
						</Button>
					)}
				</S.InputsWrapper>
			</S.Form>
		</S.MainWrapper>
	);
}
