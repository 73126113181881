import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as S from './UpdatePaymentMethodModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Button, Modal, DatePicker, Typography, TextInput } from '../../components';
import { useForm } from 'react-hook-form';
import { required } from '../../utils/form-default-errors';
import { useEffect } from 'react';
import { walletUpdateAccount } from '../../services/walletService';
import toast from 'react-hot-toast';

const UpdatePaymentMethodModal = ({ isOpen, afterSubmit, onRequestClose, account }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const {
		register,
		formState: { errors, isSubmitting },
		handleSubmit,
		setValue,
		watch,
		clearErrors,
		reset,
	} = useForm({
		defaultValues: {
			accountName: account?.AccountName,
			cardHolderName: account?.CardCardholderName,
			ACHRoutingNumber: account?.ACHRoutingNumber,
			ACHAccountNumber: account?.ACHAccountNumber,
			...(account?.CardExpirationDate != null
				? {
						expiryMonth: moment(account?.CardExpirationDate, 'MM/YYYY').isValid() ? moment(account?.CardExpirationDate, 'MM/YYYY').toDate() : new Date(),
						expiryYear: moment(account?.CardExpirationDate, 'MM/YYYY').isValid() ? moment(account?.CardExpirationDate, 'MM/YYYY').toDate() : new Date(),
				  }
				: undefined),
		},
	});

	const expiryMonth = watch('expiryMonth');
	const expiryYear = watch('expiryYear');

	const onSubmit = async (data) => {
		// Get parameters
		const { accountName, cardHolderName, expiryMonth, expiryYear, ACHRoutingNumber, ACHAccountNumber } = data;

		// Create expiration date
		let cardExpirationDate;
		if (account?.AccountType === 'Card') {
			const cardExpirationDateObj = new Date(expiryYear);
			cardExpirationDateObj.setMonth(expiryMonth.getMonth());
			cardExpirationDate = moment(cardExpirationDateObj)?.format('MM/YYYY');
		}
		try {
			const accountUpdated = await walletUpdateAccount({
				walletAccountKey: account?.WalletAccountKey,
				accountName,
				accountToken: account?.AccountToken,
				...(account?.AccountType === 'Card'
					? {
							cardCardholderName: cardHolderName,
							cardExpirationDate,
							cardLast4: account?.CardLast4,
							cardModifier: account?.CardModifier,
							cardClass: account?.CardClass,
							cardBrand: account?.CardBrand,
					  }
					: undefined),
				...(account?.AccountType === 'ACH'
					? {
							ACHRoutingNumber,
							ACHAccountNumber,
					  }
					: undefined),
			});
			if (accountUpdated) {
				if (afterSubmit) afterSubmit();
				toast.success('Your payment method has successfully been updated.');
				onRequestClose();
			} else {
				toast.error('There was an issue updating your payment method. Please try again.');
				reset(undefined, { keepValues: true });
			}
		} catch (e) {
			toast.error(e.message);
			reset(undefined, { keepValues: true });
		}
	};

	const onModalClose = () => {
		clearErrors(['accountName', 'cardHolderName', 'expiryMonth', 'expiryYear', 'ACHRoutingNumber', 'ACHAccountNumber']);
		onRequestClose();
	};

	useEffect(() => {
		if (isOpen && account != null) {
			reset({
				accountName: account?.AccountName,
				cardHolderName: account?.CardCardholderName,
				ACHRoutingNumber: account?.ACHRoutingNumber,
				ACHAccountNumber: account?.ACHAccountNumber,
				...(account?.CardExpirationDate != null
					? {
							expiryMonth: moment(account?.CardExpirationDate, 'MM/YYYY').isValid() ? moment(account?.CardExpirationDate, 'MM/YYYY').toDate() : null,
							expiryYear: moment(account?.CardExpirationDate, 'MM/YYYY').isValid() ? moment(account?.CardExpirationDate, 'MM/YYYY').toDate() : null,
					  }
					: undefined),
			});
		}
	}, [isOpen, account]);

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onModalClose}>
			<S.ModalContainer onSubmit={handleSubmit(onSubmit)}>
				<S.HeadingContainer>
					<Typography center tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold">
						Update Payment Method
					</Typography>
				</S.HeadingContainer>
				<S.ModalMainWrapper>
					<S.SubFormContainer>
						<TextInput
							containerClassName="inputContainer"
							label="Account Nickname"
							placeholder="My Card"
							id="accountName"
							error={errors.accountName}
							{...register('accountName', {
								required: required('Account Nickname'),
							})}
						/>
						{account?.AccountType === 'Card' && (
							<>
								<TextInput
									containerClassName="inputContainer"
									label="Cardholder Name"
									placeholder="Justin Case"
									id="cardHolderName"
									error={errors.cardHolderName}
									{...register('cardHolderName', {
										required: account?.AccountType === 'Card' && required('Last Name'),
									})}
								/>
								<S.CreditCardDateContainer>
									<S.DatePickerContainer>
										<DatePicker
											label="Expiration Date"
											selected={expiryMonth}
											icon="angle-down"
											{...register('expiryMonth', {
												required: account?.AccountType === 'Card' && required('Month'),
											})}
											onChange={(date) => setValue('expiryMonth', date)}
											error={!expiryMonth && errors.expiryMonth}
											dateFormat="MM"
											showMonthYearPicker
											showFullMonthYearPicker
											showTwoColumnMonthYearPicker
											placeholderText="MM"
											id="expiryMonthPicker"
											minDate={new Date()}
										/>
									</S.DatePickerContainer>
									<S.DatePickerContainer>
										<DatePicker
											label="  "
											selected={expiryYear}
											{...register('expiryYear', {
												required: account?.AccountType === 'Card' && required('Year'),
											})}
											error={!expiryYear && errors.expiryYear}
											icon="angle-down"
											onChange={(date) => setValue('expiryYear', date)}
											showYearPicker
											dateFormat="yyyy"
											placeholderText="YYYY"
											id="expiryYearPicker"
											minDate={new Date()}
										/>
									</S.DatePickerContainer>
								</S.CreditCardDateContainer>
							</>
						)}
						{account?.AccountType === 'ACH' && (
							<>
								<TextInput
									containerClassName="inputContainer"
									label="Routing Number"
									placeholder="xxxxxxxx"
									type="number"
									id="ACHRoutingNumber"
									error={errors.ACHRoutingNumber}
									{...register('ACHRoutingNumber', {
										required: account?.AccountType === 'ACH' && required('Bank Routing Number'),
									})}
								/>
								<TextInput
									containerClassName="inputContainer"
									label="Account Number"
									placeholder="xxxxxxxx"
									type="number"
									id="ACHAccountNumber"
									error={errors.ACHAccountNumber}
									{...register('ACHAccountNumber', {
										required: account?.AccountType === 'ACH' && required('Bank Account Number'),
									})}
								/>
							</>
						)}
					</S.SubFormContainer>
				</S.ModalMainWrapper>
				<S.ButtonsContainer>
					<Button type="button" variant="outline" variation="secondary" onClick={onModalClose}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button variant="solid" type="submit" disabled={isSubmitting}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Update Method
						</Typography>
					</Button>
				</S.ButtonsContainer>
			</S.ModalContainer>
		</Modal>
	);
};
export default UpdatePaymentMethodModal;

UpdatePaymentMethodModal.propTypes = {
	isOpen: PropTypes.bool,
	afterSubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
	account: PropTypes.shape(),
};
