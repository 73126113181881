import styled from 'styled-components';

import colors from '../../styles/colors';

export const ItemContainer = styled.div`
	max-height: 18rem;
	overflow: scroll;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${colors.neutralForm};
	align-items: flex-start;
	padding: 0.5rem 0;
	cursor: pointer;
	text-align: left;

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		border: none;
		padding-bottom: 0;
	}
`;
