import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	background-color: ${colors.neutralBackground};
`;

export const ContentWrapper = styled.div`
	flex: 1;
	overflow: auto;
	padding: 1rem;
`;

export const ContentInnerWrapper = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 1rem;
`;

export const OptionsCard = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const OptionButton = styled.button`
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	appearance: none;
	border-radius: 0.25rem;
	background-color: transparent;
	border: 1px solid ${colors.neutralText};
	align-items: center;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		text-align: left;
		padding: 1.5rem 1rem;

		& > * + * {
			margin-left: 1rem;
		}
	}

	.circle {
		width: 1.25rem;
		height: 1.25rem;
		border: 1px solid ${colors.neutralText};
		border-radius: 9999px;
		margin-bottom: 0.5rem;

		&.checked {
			position: relative;
			overflow: hidden;
			&::after {
				position: absolute;
				border-radius: 9999px;
				inset: 2px;
				content: '';
				background-color: ${colors.brandPrimary};
			}
		}

		@media screen and (min-width: 768px) {
			margin-bottom: 0;
		}
	}
	.icon {
		color: ${colors.brandPrimary};
		width: 2.625rem;
		height: 2.625rem;
	}
	.content-info {
	}
`;
