import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './ResourcesPanel.styles';
import { Spinner } from '../Spinner';
import { getCurrentArticles, getCurrentResources } from '../../services/userService';
import { Link } from '../Link';
import { EmptyComponent } from '../EmptyComponent';
import { sortResourcesEvents } from '../../utils/sorting';

export const ResourcesPanel = ({ title, type, limit, action, className }) => {
	const [status, setStatus] = useState('loading');
	const [items, setItems] = useState([]);

	const isMounted = useRef(true);

	async function init() {
		try {
			const itemArray = type === 'articles' ? await getCurrentArticles() : await getCurrentResources();
			itemArray.sort(sortResourcesEvents);
			if (limit != null && itemArray.length > limit) {
				itemArray.length = limit;
			}
			if (isMounted.current) {
				setItems(itemArray);
				setStatus('success');
			}
		} catch (e) {
			toast.error(e.message);
			if (isMounted.current) {
				setStatus('error');
			}
		}
	}

	function handleOnClick(item) {
		setTimeout(() => {
			window.open(item.URL, '_blank');
		});
	}

	useEffect(() => {
		// Set state
		isMounted.current = true;

		// Run init
		init();

		// Handle actions on dismount
		return () => {
			isMounted.current = false;
		};
	}, []);

	if (status === 'loading') {
		return <Spinner />;
	}
	return (
		<Card title={title} action={action} className={className}>
			{items.length > 0 ? (
				items.map((item) => (
					<S.Item key={item.ArticleKey || item.ResourceKey} onClick={() => handleOnClick(item)}>
						<Typography tag="h5" weight="bold">
							{item.Title}
						</Typography>
						<Typography tag="p" variation="2">
							{item.Description}
						</Typography>
						{limit == null && (
							<Typography tag="p" variation="2">
								<Link to={item.URL}>{item.URL}</Link>
							</Typography>
						)}
					</S.Item>
				))
			) : (
				<EmptyComponent icon={['fal', 'list']} title={`No current ${type}`} description={`Check back later for ${type} from the City of Wilson`} />
			)}
		</Card>
	);
};

ResourcesPanel.propTypes = {
	title: PropTypes.string,
	type: PropTypes.oneOf(['articles', 'resources']),
	limit: PropTypes.number,
	action: PropTypes.array,
	className: PropTypes.string,
};
