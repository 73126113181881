import styled from 'styled-components';

import colors from '../../styles/colors';

export const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${colors.neutralForm};
	padding-bottom: 1.5rem;
	margin-top: 0.5em;

	&:first-of-type {
		margin-top: 0;
	}

	&:last-of-type {
		border: none;
		padding-bottom: 0;
	}
`;

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: start;
	gap: 10px;
	flex-direction: column;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		gap: 20px;
		align-items: center;
	}
`;

export const HeaderCenter = styled.div`
	flex-grow: 1;
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;

	h2 {
		margin-top: 0.25rem;
		color: ${({ warning }) => (warning ? colors.statusBad : 'black')};
	}
`;

export const InfoWrapper = styled.div`
	display: flex;

	.container-fluid {
		padding: 0;

		${Item} {
			margin-top: 0.5rem;
		}
	}
`;
