import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 0.5rem;

	& > * + * {
		margin-top: 1rem;
	}
`;
