import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Card } from '../Card';
import { Select } from '../Select';
import { TextArea } from '../TextArea';
import { Typography } from '../Typography';
import { Button } from '../Button';
import * as S from './SupportRequestForm.styles';
import toast from 'react-hot-toast';
import { required } from '../../utils/form-default-errors';
import { getRequestTypes } from '../../services/allUsers';
import { Spinner } from '../Spinner';
import { submitUserRequest } from '../../services/userService';
import { useSelector } from 'react-redux';

export const SupportRequestForm = ({ className }) => {
	const currentLocation = useSelector((state) => state.location.value);

	const [status, setStatus] = useState('loading');
	const [supportTypes, setSupportTypes] = useState([]);
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	const history = useHistory();

	async function init() {
		try {
			const types = await getRequestTypes();
			setSupportTypes(
				types.map((type) => {
					return { value: type.RequestTypeID.toString(), label: type.RequestType };
				})
			);
			setStatus('success');
		} catch (e) {
			toast.error(e.message);
			setStatus('error');
		}
	}

	function handleReset() {
		reset();
		history.push('/');
	}

	async function onSubmit(data) {
		try {
			const { requestCreated } = await submitUserRequest({
				requestType: data.reason,
				requestDescription: JSON.stringify({
					locationId: currentLocation?.LocationID,
					message: data.message,
				}),
			});
			if (requestCreated) {
				toast.success('We have successfully sent your support request. Check your email for updates.');
				handleReset();
			} else {
				toast.error('There was an issue creating your request. Please try again.');
				reset(undefined, { keepValues: true });
			}
		} catch (e) {
			toast.error(e.message);
			reset(undefined, { keepValues: true });
		}
	}

	useEffect(() => {
		init();
	}, []);

	if (status === 'loading') {
		return <Spinner />;
	}
	return (
		<Card className={className} title="Support Request Form">
			<S.Form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="reason"
					control={control}
					rules={{ required: required('Reason') }}
					render={({ field: { onChange, value } }) => <Select label="Reason" id="reason" error={errors.reason} options={supportTypes} value={supportTypes.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
				/>
				<TextArea
					label="Message"
					id="message"
					error={errors.message}
					{...register('message', {
						required: required('Message'),
					})}
				/>
				<S.ButtonWrapper>
					<Button type="button" variant="outline" variation="secondary" onClick={handleReset}>
						<Typography tag="span" weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button>
						<Typography tag="span" weight="extrablack">
							{isSubmitting ? 'Loading...' : 'Send'}
						</Typography>
					</Button>
				</S.ButtonWrapper>
			</S.Form>
		</Card>
	);
};

SupportRequestForm.propTypes = {
	className: PropTypes.string,
};
