import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';

import { Card, Typography } from '../../components';

import * as S from './StatementOverview.styles';
import { formatDate } from '../../utils/time-formatter';

export const StatementOverview = ({ className, invoice, accountData }) => {
	return (
		<Card className={className} title="Overview">
			<S.ItemWrapper>
				<S.InfoWrapper>
					<Container fluid>
						<Row>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Bill Date
									</Typography>
									<Typography tag="h2" weight="bold">
										{formatDate(invoice.BillDate)}
									</Typography>
								</S.Item>
							</Col>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Payment Due Date
									</Typography>
									<Typography tag="h2" weight="bold">
										{formatDate(invoice.DueDate)}
									</Typography>
								</S.Item>
							</Col>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Type
									</Typography>
									<Typography tag="h2" weight="bold">
										{accountData?.type}
									</Typography>
								</S.Item>
							</Col>
						</Row>
					</Container>
				</S.InfoWrapper>
			</S.ItemWrapper>
		</Card>
	);
};
StatementOverview.propTypes = {
	className: PropTypes.string,
	invoice: PropTypes.shape(),
	accountData: PropTypes.shape(),
};
