import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import { Button } from '../Button';
import * as S from './Card.styles';

export const Card = ({ title = '', action = [], children, className }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	return (
		<S.Card className={className}>
			{title && (
				<S.TitleWrapper clear={action?.clear}>
					<Typography tag={isHigherThan768 ? 'h3' : 'h4'} weight="bold">
						{title}
					</Typography>
					{isHigherThan768 && !!action.length && (
						<S.ButtonWrapper>
							{action.map((item) =>
								item.component != null ? (
									item.component
								) : (
									<Button key={item.id} onClick={item.onClick} variant={item.type} size="small">
										{item.icon && <FontAwesomeIcon icon={item.icon} />}
										<Typography tag="span" variation={item.type === 'text' ? '1' : '2'} weight="extrablack">
											{item.label}
										</Typography>
									</Button>
								)
							)}
						</S.ButtonWrapper>
					)}
				</S.TitleWrapper>
			)}
			<S.ContentWrapper>
				{children}
				{!isHigherThan768 && !!action.length && (
					<S.ButtonWrapper>
						{action.map((item) =>
							item.component != null ? (
								item.component
							) : (
								<Button key={item.id} onClick={item.onClick} variant={item.type}>
									{item.icon && <FontAwesomeIcon icon={item.icon} />}
									<Typography tag="span" variation={item.type === 'text' ? '1' : '2'} weight="extrablack">
										{item.label}
									</Typography>
								</Button>
							)
						)}
					</S.ButtonWrapper>
				)}
			</S.ContentWrapper>
		</S.Card>
	);
};

Card.propTypes = {
	title: PropTypes.string,
	action: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			onClick: PropTypes.func,
			label: PropTypes.string,
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			type: PropTypes.oneOf(['solid', 'outline', 'text']),
		})
	),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
};
