import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './RquestPlanChangeModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Button, Modal, Typography, Select, DatePicker } from '../../components';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { submitUserRequest } from '../../services/userService';
import { useSelector } from 'react-redux';

const PlanTypes = (accountType) =>
	[
		{
			label: 'Standard',
			value: 'Standard',
		},
		{
			label: 'Prepaid',
			value: 'Prepaid',
		},
		accountType === 'Utility' && {
			label: 'Budget',
			value: 'Budget',
		},
	].filter(Boolean);

const RequestReasons = (accountType) =>
	[
		{
			label: 'Financial Conflict',
			value: 'Financial Conflict',
		},
		accountType === 'Utility' && {
			label: 'Due Date Extension',
			value: 'Due Date Extension',
		},
		{
			label: 'Other Reason',
			value: 'Other Reason',
		},
	].filter(Boolean);

const RquestPlanChangeModal = ({ accountType, accountKey, isOpen, setIsOpen }) => {
	const currentLocation = useSelector((state) => state.location.value);

	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		reset,
	} = useForm({
		defaultValues: {
			planType: PlanTypes(accountType)[0],
			requestReason: RequestReasons(accountType)[0],
			effectDate: new Date(),
		},
	});

	const onSubmit = async (data) => {
		// Format data
		const requestData = {
			accountType,
			accountKey,
			...data,
		};

		// Submit request
		try {
			const { requestCreated } = await submitUserRequest({
				requestType: 2,
				requestDescription: JSON.stringify({ ...requestData, locationId: currentLocation?.LocationID }),
			});
			if (requestCreated) {
				toast.success('We have successfully sent your support request. Check your email for updates.');
				setIsOpen(false);
			} else {
				toast.error('There was an issue creating your request. Please try again.');
			}
		} catch (e) {
			toast.error(e.message);
		}
	};
	const onModalClose = () => {
		setIsOpen(false);
	};
	const planType = watch('planType');
	const requestReason = watch('requestReason');
	const effectDate = watch('effectDate');

	useEffect(() => {
		if (isOpen) {
			reset();
		}
	}, [isOpen]);

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onModalClose}>
			<S.ModalContainer onSubmit={handleSubmit(onSubmit)}>
				<S.HeadingContainer>
					<Typography center tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold">
						Request Plan Change
					</Typography>
				</S.HeadingContainer>
				<S.MainWrapper>
					<S.Main>
						<S.SelectsContainer>
							<Select label="Plan Type" value={planType} options={PlanTypes(accountType)} onChange={(value) => setValue('planType', value)} />
							<Select label="Reason for Request" value={requestReason} options={RequestReasons(accountType)} onChange={(value) => setValue('requestReason', value)} />
						</S.SelectsContainer>
					</S.Main>
					<S.SelectsContainer>
						<DatePicker
							label="Date to go into Effect"
							{...register('effectDate', {
								required: true,
							})}
							selected={effectDate}
							onChange={(date) => setValue('effectDate', date)}
							error={errors?.hasOwnProperty('effectDate')}
							id="effectDate"
							minDate={new Date()}
						/>
					</S.SelectsContainer>
				</S.MainWrapper>

				<S.ButtonsContainer>
					<Button type="button" variant="outline" variation="secondary" onClick={onModalClose}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button variant="solid" type="submit">
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Submit Request
						</Typography>
					</Button>
				</S.ButtonsContainer>
			</S.ModalContainer>
		</Modal>
	);
};

export default RquestPlanChangeModal;

RquestPlanChangeModal.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	onClickButton: PropTypes.func,
	accountType: PropTypes.string,
	accountKey: PropTypes.string,
};
