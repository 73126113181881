import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Button, OnboardingFooter, OnboardingHeader, Typography, Stepper, Card, DropFile } from '../../components';
import * as S from './OnboardingNewCustomerCommercialReview.styles';
import { NewCustomerAccountPendingPopup } from '../NewCustomerAccountPendingPopup';
import { META_TITLE, New_Cust_Com_Onb_App, New_Cust_Com_Onb_Greenlight, New_Cust_Com_Onb_Utils } from '../../utils/constants';
import moment from 'moment';
import { submitUserRequest, userSubmitRequestDoc } from '../../services/userService';
import { getDocumentTypes } from '../../services/allUsers';
import { getBase64List } from '../../utils/file-controller';
import CustomerCommercialGreenlightReviewCard from '../../components/CustomerCommercialGreenlightReviewCard/CustomerCommercialGreenlightReviewCard';
import toast from 'react-hot-toast';
import { clearOnboarding } from '../../app/slices/onboarding/onboardingSlice';

export default function OnboardingNewCustomerCommercialReview({ showDashboard }) {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const data = useSelector((state) => state.onboarding.newCustomer.commercial);
	const dispatch = useDispatch();

	const [popupShow, setPopupShow] = useState(false);
	const [greenLightData, setGreenLightData] = useState({});
	const [utilitiesData, setUtilitiesData] = useState({});
	const [applicationData, setApplicationData] = useState({});
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const onBack = () => {
		history.push('/onboarding/new-customer/commercial/greenlight');
	};

	const finalApiCalls = async () => {
		const allAPIRequests = [];
		const ourFiles = [];
		const { dcc, fireInsp, propInsp, docOrPhotoId, taxIdOrSecCard, securityDep, occupancyProof, privilLicense } = data?.application;

		try {
			const response = await getDocumentTypes();
			const { requestKey } = await submitUserRequest({
				requestType: 1,
				requestDescription: JSON.stringify(`{
					"utilities": ${JSON.stringify(utilitiesData)},
					"application": ${JSON.stringify({
						...applicationData,
						locationType: 'Commercial',
					})},
					"greenLight": ${JSON.stringify(greenLightData)},
				}`),
			});
			if (dcc?.length) {
				const tempFiles = await getBase64List(dcc);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 5 })));
			}
			if (fireInsp?.length) {
				const tempFiles = await getBase64List(fireInsp);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 6 })));
			}
			if (propInsp?.length) {
				const tempFiles = await getBase64List(propInsp);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 7 })));
			}
			if (docOrPhotoId?.length) {
				const tempFiles = await getBase64List(docOrPhotoId);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 1 })));
			}
			if (taxIdOrSecCard?.length) {
				const tempFiles = await getBase64List(taxIdOrSecCard);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 8 })));
			}
			if (securityDep?.length) {
				const tempFiles = await getBase64List(securityDep);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 9 })));
			}
			if (occupancyProof?.length) {
				const tempFiles = await getBase64List(occupancyProof);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 4 })));
			}
			if (privilLicense?.length) {
				const tempFiles = await getBase64List(privilLicense);
				ourFiles.push(...tempFiles.map((file) => ({ ...file, documentType: 10 })));
			}
			for (let i = 0; i < ourFiles.length; i++) {
				allAPIRequests.push(userSubmitRequestDoc({ requestKey, documentType: ourFiles?.[i]?.documentType, mimeType: ourFiles?.[i]?.mimeType, document: ourFiles?.[i]?.base64File }));
			}
			await Promise.all(allAPIRequests);
			toast.success('Your account request has been submitted successfully. You will receive a confirmation email shortly.');
		} catch (e) {
			toast.error("We're having an issue submitting your account request. Please try again.");
			console.log('error', e);
		}
	};

	const onNext = async () => {
		setLoading(true);
		await finalApiCalls();
		setLoading(false);
		setPopupShow(true);
		localStorage.removeItem(New_Cust_Com_Onb_Greenlight);
		localStorage.removeItem(New_Cust_Com_Onb_Utils);
		localStorage.removeItem(New_Cust_Com_Onb_App);
		dispatch(clearOnboarding());
	};

	const handlePopupClose = () => {
		history.push('/onboarding');
	};

	useEffect(() => {
		// Set document title
		document.title = `New Customer | ${META_TITLE}`;

		let tempGreenLight = localStorage.getItem(New_Cust_Com_Onb_Greenlight);
		let tempUtilities = localStorage.getItem(New_Cust_Com_Onb_Utils);
		let tempApplication = localStorage.getItem(New_Cust_Com_Onb_App);
		tempGreenLight = tempGreenLight && JSON.parse(tempGreenLight);
		tempUtilities = tempUtilities && JSON.parse(tempUtilities);
		tempApplication = tempApplication && JSON.parse(tempApplication);

		if (tempGreenLight) {
			setGreenLightData(tempGreenLight);
		}

		if (tempUtilities) {
			setUtilitiesData(tempUtilities);
		}

		if (tempApplication) {
			setApplicationData(tempApplication);
		}
	}, []);

	return (
		<>
			<NewCustomerAccountPendingPopup onClickButton={handlePopupClose} isOpen={popupShow} setIsOpen={setPopupShow} />
			<S.Form>
				<OnboardingHeader showDashboard={showDashboard} />
				<Stepper
					activeStep={4}
					steps={[
						{ id: 1, label: 'Location' },
						{ id: 2, label: 'Application' },
						{ id: 3, label: 'Utilities' },
						{ id: 4, label: 'Greenlight' },
						{ id: 5, label: 'Review' },
					]}
				/>
				<S.FormContentWrapper>
					<S.FormContent>
						<Typography tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold" center>
							Review Changes
						</Typography>
						{utilitiesData?.enableUtilitiesForProperty === 'Yes' && (
							<Card title="Utilities">
								<S.Card1ContentWrapper>
									<Typography tag="h5" weight="bold">
										Services
									</Typography>
									{utilitiesData?.meters?.map(
										(meter) =>
											meter?.added && (
												<Typography key={meter?.name} tag="p" variation="1">
													{meter?.name}
												</Typography>
											)
									)}
								</S.Card1ContentWrapper>
								<S.Card1ContentWrapper2>
									<Typography tag="h5" weight="bold">
										Settings
									</Typography>
									{/*<S.Card1DetailsWrapper>
										<Typography tag="h6" weight="semibold">
											How do you want to pay for your utilities?
										</Typography>
										<Typography tag="h6">{utilitiesData?.utilityPay}</Typography>
									</S.Card1DetailsWrapper>*/}
									<S.Card1DetailsWrapper>
										<Typography tag="h6" weight="semibold">
											Utility Start Date
										</Typography>
										<Typography tag="h6">{moment(utilitiesData?.utilityStartDate).format('MM/DD/YYYY')}</Typography>
									</S.Card1DetailsWrapper>
									<Typography tag="h6" weight="semibold">
										Email Notifications
									</Typography>
									<Typography tag="h6">{utilitiesData?.emailNotifications?.highConsumption && 'High Consumption'}</Typography>
									<Typography tag="h6">{utilitiesData?.emailNotifications?.possibleWaterLeak && 'Possible Water Leak'}</Typography>
								</S.Card1ContentWrapper2>
							</Card>
						)}
						{greenLightData?.isGreenlightInternetService === 'Yes' && <CustomerCommercialGreenlightReviewCard greenLightData={greenLightData} />}
					</S.FormContent>
				</S.FormContentWrapper>

				<OnboardingFooter>
					<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Back
						</Typography>
					</Button>
					<Button variant="outline" onClick={onNext} disabled={loading}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Confirm and Proceed
						</Typography>
					</Button>
				</OnboardingFooter>
			</S.Form>
		</>
	);
}

OnboardingNewCustomerCommercialReview.propTypes = {
	showDashboard: PropTypes.bool,
};
