export const openBase64PDF = (pdf) => {
	const splitBinary = pdf.replace(/\s/g, '').split('base64,');
	const binary = atob(splitBinary[splitBinary.length - 1]);
	const len = binary.length;
	const buffer = new ArrayBuffer(len);
	const view = new Uint8Array(buffer);
	for (let i = 0; i < len; i++) {
		view[i] = binary.charCodeAt(i);
	}
	const blob = new Blob([view], { type: 'application/pdf' });
	const url = URL.createObjectURL(blob);

	setTimeout(() => {
		window.open(url, '_blank');
	});
};

export const openBase64Document = (pdf) => {
	const splitBinary = pdf.replace(/\s/g, '').split('base64,');
	const binary = atob(splitBinary[splitBinary.length - 1]);
	const len = binary.length;
	const buffer = new ArrayBuffer(len);
	const view = new Uint8Array(buffer);
	for (let i = 0; i < len; i++) {
		view[i] = binary.charCodeAt(i);
	}
	const blob = new Blob([view], { type: splitBinary[0].replace('data:', '').replace(';', '') });
	const url = URL.createObjectURL(blob);

	setTimeout(() => {
		window.open(url, '_blank');
	});
};
