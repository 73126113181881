export default {
	brandPrimary: '#005da8',
	brandSecondary: '#71bee4',
	brandTertiary: '#8db930',
	brandFourth: '#092a37',
	black: '#000000',
	white: '#ffffff',

	darkOverlay: 'rgba(0, 0, 0, 0.5)',
	defaultShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.15)',
	sidebarShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
	focusShadow: 'inset 0 0 0 2px #71bee4',

	neutralBackground: '#f4f6f8',
	neutralForm: '#cdd5de',
	neutralButton: '#707781',
	neutralText: '#aeb5be',

	accentGas: '#71e4a6',
	accentElectric: '#9671e4',
	accentSprinkler: '#e471b6',
	accentWater: '#005da8',

	statusGreat: '#2CA58D',
	statusGood: '#8db930',
	statusOkay: '#e1cf28',
	statusBad: '#d12c2c',
	statusBadLight: '#fcdede',

	formLabel: '#212529',
};
