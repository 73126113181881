/**
 * General
 */

export const META_TITLE = 'MyWilson';

/**
 * Payment Types
 */

export const PAYMENT_TYPES = {
	NORMAL: 'normal',
	PREPAID: 'prepaid',
	BUDGET: 'budget',
};

/**
 * Filter Range
 */

export const FILTER_MAXIMUM_RANGE = 10000;

/**
 * User
 */

export const PLACEHOLDER_USER_DETAILS = { paymentType: PAYMENT_TYPES.NORMAL };
export const PLACEHOLDER_STAFF_DETAILS = {};

/**
 * Resources
 */

export const RESOURCES = [
	{ ResourceKey: 1, Title: 'Fix It Wilson', Description: 'For Reporting of Non Emergency Issues Only', URL: 'https://iframe.publicstuff.com/#?client_id=1000026' },
	{ ResourceKey: 2, Title: 'Code Red', Description: 'Emergency Notification System', URL: 'https://www.wilsonnc.org/residents/city-services/all-departments/communication-marketing/emergency-notification-system' },
	{ ResourceKey: 3, Title: 'RIDE', Description: 'Public Transit Service', URL: 'https://www.wilsonnc.org/residents/all-departments/public-works/wilson-transit-ride-wilson-industrial-air-center/ride' },
	{ ResourceKey: 4, Title: 'Police to Citizen Portal', Description: 'Access and view incident reports and accident reports', URL: 'https://wilsonnc.policetocitizen.com/Home' },
];

/**
 * Badge Variants
 */

export const BADGE_LABELS = {
	'': { label: '', type: '' },
	unpaid: { label: 'Unpaid', type: 'danger' },
	paid: { label: 'Paid', type: 'success' },
	active: { label: 'Active', type: 'success' },
	pending: { label: 'Pending', type: 'warning' },
	new: { label: 'New', type: 'secondary' },
	complete: { label: 'Complete', type: 'success', icon: ['fal', 'check'] },
	incomplete: { label: 'Incomplete', type: 'danger', icon: ['fal', 'times'] },
	canceled: { label: 'Canceled', type: 'danger', icon: ['fal', 'times'] },
	inprogress: { label: 'In Progress', type: 'warning', icon: ['fal', 'clock'] },
	scheduled: { label: 'Scheduled', type: 'warning', icon: ['fal', 'clock'] },
	closed: { label: 'Closed', type: 'success', icon: ['fal', 'check'] },
	inviteAccepted: { label: 'Invite Accepted', type: 'success', icon: ['fal', 'check'] },
	notInvited: { label: 'Not Invited', type: 'danger', icon: ['fal', 'times'] },
	invitePending: { label: 'Invite Pending', type: 'warning', icon: ['fal', 'clock'] },
	pendingOnboarding: { label: 'Onboarding Pending', type: 'warning', icon: ['fal', 'clock'] },
};

/**
 * Onboarding
 */

//Current Customer Onboarding Account Confirm Account Constant for local storage
export const Current_Cus_Onb_Conf_Acc = 'wilsonnc_onboarding_current_customer_confirm_account';

//Commercial User Onboarding LocalStorage Constants
export const New_Cust_Com_Onb_App = 'new-customer-commercial-application';
export const New_Cust_Com_Onb_Greenlight = 'new-customer-commercial-greenlight';
export const New_Cust_Com_Onb_Utils = 'new-customer-commercial-utilities';

//Residential User Onboarding LocalStorage Constants
export const New_Cust_Res_Onb_App = 'new-customer-residential-application';
export const New_Cust_Res_Onb_Greenlight = 'new-customer-residential-greenlight';
export const New_Cust_Res_Onb_Utils = 'new-customer-residential-utilities';

//Manage Utilities LocalStorage Constants
export const Manage_Utility = 'manage-utility';
export const Manage_Utility_Settings = 'manage-utility-settings';

//Manage Greenlight LocalStorage Constants
export const Manage_Greenlight = 'manage-greenlight';
export const Manage_Greenlight_Settings = 'manage-greenlight-settings';

export const State_Options = [
	{
		label: 'Alabama',
		value: 'AL',
	},
	{
		label: 'Alaska',
		value: 'AK',
	},
	{
		label: 'American Samoa',
		value: 'AS',
	},
	{
		label: 'Arizona',
		value: 'AZ',
	},
	{
		label: 'Arkansas',
		value: 'AR',
	},
	{
		label: 'California',
		value: 'CA',
	},
	{
		label: 'Colorado',
		value: 'CO',
	},
	{
		label: 'Connecticut',
		value: 'CT',
	},
	{
		label: 'Delaware',
		value: 'DE',
	},
	{
		label: 'District Of Columbia',
		value: 'DC',
	},
	{
		label: 'Federated States Of Micronesia',
		value: 'FM',
	},
	{
		label: 'Florida',
		value: 'FL',
	},
	{
		label: 'Georgia',
		value: 'GA',
	},
	{
		label: 'Guam',
		value: 'GU',
	},
	{
		label: 'Hawaii',
		value: 'HI',
	},
	{
		label: 'Idaho',
		value: 'ID',
	},
	{
		label: 'Illinois',
		value: 'IL',
	},
	{
		label: 'Indiana',
		value: 'IN',
	},
	{
		label: 'Iowa',
		value: 'IA',
	},
	{
		label: 'Kansas',
		value: 'KS',
	},
	{
		label: 'Kentucky',
		value: 'KY',
	},
	{
		label: 'Louisiana',
		value: 'LA',
	},
	{
		label: 'Maine',
		value: 'ME',
	},
	{
		label: 'Marshall Islands',
		value: 'MH',
	},
	{
		label: 'Maryland',
		value: 'MD',
	},
	{
		label: 'Massachusetts',
		value: 'MA',
	},
	{
		label: 'Michigan',
		value: 'MI',
	},
	{
		label: 'Minnesota',
		value: 'MN',
	},
	{
		label: 'Mississippi',
		value: 'MS',
	},
	{
		label: 'Missouri',
		value: 'MO',
	},
	{
		label: 'Montana',
		value: 'MT',
	},
	{
		label: 'Nebraska',
		value: 'NE',
	},
	{
		label: 'Nevada',
		value: 'NV',
	},
	{
		label: 'New Hampshire',
		value: 'NH',
	},
	{
		label: 'New Jersey',
		value: 'NJ',
	},
	{
		label: 'New Mexico',
		value: 'NM',
	},
	{
		label: 'New York',
		value: 'NY',
	},
	{
		label: 'North Carolina',
		value: 'NC',
	},
	{
		label: 'North Dakota',
		value: 'ND',
	},
	{
		label: 'Northern Mariana Islands',
		value: 'MP',
	},
	{
		label: 'Ohio',
		value: 'OH',
	},
	{
		label: 'Oklahoma',
		value: 'OK',
	},
	{
		label: 'Oregon',
		value: 'OR',
	},
	{
		label: 'Palau',
		value: 'PW',
	},
	{
		label: 'Pennsylvania',
		value: 'PA',
	},
	{
		label: 'Puerto Rico',
		value: 'PR',
	},
	{
		label: 'Rhode Island',
		value: 'RI',
	},
	{
		label: 'South Carolina',
		value: 'SC',
	},
	{
		label: 'South Dakota',
		value: 'SD',
	},
	{
		label: 'Tennessee',
		value: 'TN',
	},
	{
		label: 'Texas',
		value: 'TX',
	},
	{
		label: 'Utah',
		value: 'UT',
	},
	{
		label: 'Vermont',
		value: 'VT',
	},
	{
		label: 'Virgin Islands',
		value: 'VI',
	},
	{
		label: 'Virginia',
		value: 'VA',
	},
	{
		label: 'Washington',
		value: 'WA',
	},
	{
		label: 'West Virginia',
		value: 'WV',
	},
	{
		label: 'Wisconsin',
		value: 'WI',
	},
	{
		label: 'Wyoming',
		value: 'WY',
	},
];
