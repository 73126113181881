import React from 'react';

import { Typography } from '../../../Typography';
import { Button } from '../../../Button';
import * as S from './OTCPayment.styles';
import { useHistory } from 'react-router-dom';

export const OTCPayment = () => {
	const history = useHistory();

	const action = () => {
		history.push('/payment/manual');
	};

	return (
		<S.Wrapper>
			<S.ButtonWrapper>
				<Button type="button" size="small" variant="outline" onClick={action}>
					<Typography tag="span" variation="2" weight="extrablack">
						Make payment
					</Typography>
				</Button>
			</S.ButtonWrapper>
		</S.Wrapper>
	);
};
OTCPayment.propTypes = {};
