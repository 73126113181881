import React, { useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { errorHandler } from '../../services/authenticationService';
import logo from '../../assets/wilson-logo.png';
import { TextInput, Button, Typography, Select } from '../../components';
import * as S from './CreateAccountProfile.styles';
import { required, pattern } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import {
	META_TITLE,
	PLACEHOLDER_USER_DETAILS,
	State_Options,
	Current_Cus_Onb_Conf_Acc,
	Manage_Greenlight,
	Manage_Greenlight_Settings,
	Manage_Utility,
	Manage_Utility_Settings,
	New_Cust_Com_Onb_App,
	New_Cust_Com_Onb_Greenlight,
	New_Cust_Com_Onb_Utils,
	New_Cust_Res_Onb_App,
	New_Cust_Res_Onb_Greenlight,
	New_Cust_Res_Onb_Utils,
} from '../../utils/constants';
import { updateUserProfile } from '../../services/userService';
import toast from 'react-hot-toast';
import { getAllUrlParams } from '../../utils/state';
import { fetchUser } from '../../app/slices/user/userSlice';
import { useDispatch } from 'react-redux';

export default function CreateAccountProfile() {
	const location = useLocation();
	const history = useHistory();

	// Get email from url
	const queryParameters = getAllUrlParams(location.search);

	// Format email
	const email = queryParameters.e ? decodeURIComponent(queryParameters.e) : null;

	// Get if verification needed
	const verificationNeeded = (queryParameters.v ? decodeURIComponent(queryParameters.v) : '1') !== '0';

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
		reset,
	} = useForm({
		defaultValues: {
			altEmail: email,
		},
	});
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const dispatch = useDispatch();

	async function createUserProfileCaller(data) {
		try {
			const updated = await updateUserProfile({
				name: data.name,
				altEmail: data.altEmail,
				phone: data.phone,
				mailingAddress: data.mailingAddress,
				mailingCity: data.mailingCity,
				mailingState: data.mailingState,
				mailingZip: data.mailingZip,
			});
			if (updated) {
				if (verificationNeeded) {
					window.localStorage.removeItem('wilsonnc_token');
					window.localStorage.removeItem('wilsonnc_role');
					history.push(`/verify-your-email?e=${encodeURIComponent(email || data.altEmail)}`);
				} else {
					window.localStorage.removeItem('wilsonnc_auth_milestone');
					window.localStorage.removeItem(Manage_Greenlight);
					window.localStorage.removeItem(Manage_Greenlight_Settings);
					window.localStorage.removeItem(Manage_Utility);
					window.localStorage.removeItem(Manage_Utility_Settings);
					window.localStorage.removeItem(New_Cust_Com_Onb_Greenlight);
					window.localStorage.removeItem(New_Cust_Com_Onb_Utils);
					window.localStorage.removeItem(New_Cust_Res_Onb_App);
					window.localStorage.removeItem(New_Cust_Res_Onb_Greenlight);
					window.localStorage.removeItem(New_Cust_Res_Onb_Utils);
					window.localStorage.removeItem(New_Cust_Com_Onb_App);
					window.localStorage.removeItem(Current_Cus_Onb_Conf_Acc);
					dispatch(fetchUser({ placeholderUser: PLACEHOLDER_USER_DETAILS }));
				}
			} else {
				toast.error('There was an issue updating your profile. Please try again.');
				reset(undefined, { keepValues: true });
			}
		} catch (e) {
			reset(undefined, { keepValues: true });
			throw e;
		}
	}

	function onSubmit(e) {
		handleSubmit(createUserProfileCaller)(e).catch(errorHandler);
	}

	useEffect(() => {
		// Set document title
		document.title = `Sign Up | ${META_TITLE}`;
	}, []);

	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			<S.Form onSubmit={onSubmit}>
				<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
					Create Your Profile
				</Typography>
				<S.InputsWrapper>
					<TextInput
						label="Name"
						id="name"
						placeholder="e.g. Justin Case"
						error={errors.name}
						{...register('name', {
							required: required('Name'),
						})}
					/>
					<TextInput
						label="Alternative Email"
						id="altEmail"
						type="email"
						placeholder="e.g. justin@gmail.com"
						error={errors.altEmail}
						{...register('altEmail', {
							pattern: pattern('Alternative Email', EMAIL),
						})}
					/>
					<TextInput
						label="Phone Number"
						id="phone"
						placeholder="e.g. 444-444-4444"
						error={errors.phone}
						{...register('phone', {
							required: required('Phone Number'),
						})}
					/>
					<TextInput
						label="Mailing Street Address"
						id="mailingAddress"
						placeholder="e.g. 123 Acme Ave"
						error={errors.mailingAddress}
						{...register('mailingAddress', {
							required: required('Mailing Street Address'),
						})}
					/>
					<TextInput
						label="Mailing Address City"
						id="mailingCity"
						placeholder="e.g. Wilson"
						error={errors.mailingCity}
						{...register('mailingCity', {
							required: required('Mailing Address City'),
						})}
					/>
					<Controller
						name="mailingState"
						control={control}
						rules={{ required: required('Mailing Address State') }}
						render={({ field: { onChange, value } }) => <Select label="Mailing Address State" id="mailingState" error={errors.mailingState} options={State_Options} value={State_Options.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
					/>
					<TextInput
						label="Mailing Address Zipcode"
						id="mailingZip"
						placeholder="e.g. 27822"
						error={errors.mailingZip}
						{...register('mailingZip', {
							required: required('Mailing Address Zipcode'),
						})}
					/>
					<Button size={isHigherThan768 ? 'medium' : 'small'}>
						<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
							{isSubmitting ? 'Loading...' : 'Continue'}
						</Typography>
					</Button>
				</S.InputsWrapper>
			</S.Form>
		</S.MainWrapper>
	);
}
