import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/wilson-logo.png';
import * as S from './MakePaymentHeader.styles';
import { useSelector } from 'react-redux';
import { formatInitials } from '../../utils/user-formatter';

export const MakePaymentHeader = () => {
	const user = useSelector((state) => state.user.value);
	const history = useHistory();

	return (
		<S.Wrapper>
			<S.Image onClick={() => history.push('/')} src={logo} alt="" />
			<S.User onClick={() => history.push('/profile')}>{formatInitials(user)}</S.User>
		</S.Wrapper>
	);
};

MakePaymentHeader.propTypes = {};
