import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { notAuthenticatedLinks } from '../NotAuthenticatedRouter';
import { Navigation, MaintenanceModeWrapper } from '../../components';
import { Home, Page404, Services, PaymentHistory, ServicesElectric, ServicesGas, ServicesSprinkler, Statements, Articles, Resources, Settings, Profile, Support, StatementInvoiceDetail, Logout, ServicesWater, OnboardingWelcome } from '../../pages';
import { MakePaymentRouter } from '../MakePaymentRouter';
import { ManageUtilityRouter } from '../ManageUtilityRouter';
import { BalanceDue } from '../../components/Navigation/Modules';
import { useSelector } from 'react-redux';
import { PAYMENT_TYPES } from '../../utils/constants';
import { OnboardingRoutes } from '../OnboardingRouter/OnboardingRouter';

export const authenticatedLinks = [
	{ id: 1, to: '/', icon: ['fal', 'tachometer'], label: 'Dashboard' },
	{ id: 2, to: '/services', icon: ['fal', 'tools'], label: 'Services' },
	{ id: 3, to: '/payment-history', icon: ['fal', 'history'], label: 'Payment History' },
	{ id: 4, to: '/statements', icon: ['fal', 'file-invoice-dollar'], label: 'Statements' },
	{ id: 5, to: '/settings', icon: ['fal', 'credit-card'], label: 'Wallet' },
];

const componentWithNavigation = (Component) => {
	const history = useHistory();
	const currentLocation = useSelector((state) => state.location.value);

	// Calculate balances due
	const totalUtilities = currentLocation?.UtilityAccount?.CurrentBalance || 0;
	const totalGreenlight = currentLocation?.GreenlightAccount?.CurrentBalance || 0;
	const total = (totalUtilities > 0 ? totalUtilities : 0) + (totalGreenlight > 0 ? totalGreenlight : 0);

	return (
		<Navigation
			links={currentLocation?.IsPrePay === true ? [authenticatedLinks[0], authenticatedLinks[2]] : authenticatedLinks}
			modules={[
				{
					id: 1,
					Module: BalanceDue,
					props: {
						total: total,
						utilities: totalUtilities,
						greenlight: totalGreenlight,
						hideBalance: currentLocation?.IsPrePay === true,
						actions: [
							currentLocation?.IsPrePay === true
								? { id: 1, title: 'Pay Now', action: () => history.push({ pathname: '/make-payment/amount', state: { backUrl: window.location.pathname } }) }
								: { id: 1, title: 'Make Payment', action: () => history.push({ pathname: '/make-payment/amount', state: { backUrl: window.location.pathname } }) },
						].filter(Boolean),
					},
				},
			]}
			showAnnouncementBar={true}
		>
			<Component />
		</Navigation>
	);
};

export default function AuthenticatedRouter() {
	const currentLocation = useSelector((state) => state.location.value);

	// Return component
	return (
		<Switch>
			{/* General */}
			<Route exact path="/" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Home)}</MaintenanceModeWrapper>} />
			<Route exact path="/resources" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Resources)}</MaintenanceModeWrapper>} />
			<Route exact path="/articles" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Articles)}</MaintenanceModeWrapper>} />
			<Route exact path="/logout" component={Logout} />

			{/* Redirects */}
			<Route path="/login" component={() => <Redirect to="/" />} />

			{/* Services */}
			{currentLocation?.IsPrePay !== true && <Route exact path="/services" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Services)}</MaintenanceModeWrapper>} />}
			{currentLocation?.IsPrePay !== true && <Route path="/services/electric/:meterNumber" component={() => <MaintenanceModeWrapper>{componentWithNavigation(ServicesElectric)}</MaintenanceModeWrapper>} />}
			{currentLocation?.IsPrePay !== true && <Route path="/services/gas/:meterNumber" component={() => <MaintenanceModeWrapper>{componentWithNavigation(ServicesGas)}</MaintenanceModeWrapper>} />}
			{currentLocation?.IsPrePay !== true && <Route path="/services/sprinkler/:meterNumber" component={() => <MaintenanceModeWrapper>{componentWithNavigation(ServicesSprinkler)}</MaintenanceModeWrapper>} />}
			{currentLocation?.IsPrePay !== true && <Route path="/services/water/:meterNumber" component={() => <MaintenanceModeWrapper>{componentWithNavigation(ServicesWater)}</MaintenanceModeWrapper>} />}

			{/* Payment History */}
			<Route path="/payment-history" component={() => <MaintenanceModeWrapper>{componentWithNavigation(PaymentHistory)}</MaintenanceModeWrapper>} />

			{/* Statements */}
			{currentLocation?.IsPrePay !== true && <Route path="/statements" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Statements)}</MaintenanceModeWrapper>} />}
			{currentLocation?.IsPrePay !== true && <Route path="/statement/:accountKey/:id" component={() => <MaintenanceModeWrapper>{componentWithNavigation(StatementInvoiceDetail)}</MaintenanceModeWrapper>} />}

			{/* Profile */}
			<Route path="/profile" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Profile)}</MaintenanceModeWrapper>} />

			{/* Settings */}
			<Route path="/settings" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Settings)}</MaintenanceModeWrapper>} />
			<Route path="/support" component={() => <MaintenanceModeWrapper>{componentWithNavigation(Support)}</MaintenanceModeWrapper>} />

			{/* Make Payment */}
			<Route
				path="/make-payment"
				component={() => (
					<MaintenanceModeWrapper>
						<MakePaymentRouter />
					</MaintenanceModeWrapper>
				)}
			/>

			{/* Manage Utilities */}
			{currentLocation?.IsPrePay !== true && (
				<Route
					path="/manage-utility"
					component={() => (
						<MaintenanceModeWrapper>
							<ManageUtilityRouter />
						</MaintenanceModeWrapper>
					)}
				/>
			)}
			{currentLocation?.IsPrePay !== true && (
				<Route
					path="/manage-greenlight"
					component={() => (
						<MaintenanceModeWrapper>
							<ManageUtilityRouter />
						</MaintenanceModeWrapper>
					)}
				/>
			)}

			{/* Redirects */}
			{notAuthenticatedLinks.map(({ id, to }) => (
				<Route key={id} path={to} component={() => <Redirect to="/" />} />
			))}

			{/* Onboarding */}
			{OnboardingRoutes({ showDashboard: true }).map((route) => (
				<Route exact key={route.path} path={route.path} render={route.render} />
			))}

			{/* Redirects */}
			<Route path="/onboarding" component={() => <Redirect to="/" />} />
			<Route path="*" component={Page404} />
		</Switch>
	);
}
