import styled from 'styled-components';
import { NavLink as ReactNavLink } from 'react-router-dom';

import colors from '../../../../styles/colors';

export const LinkWrapper = styled.li`
	list-style: none;
`;

export const NavLink = styled(ReactNavLink)`
	display: flex;
	align-items: center;
	color: white;
	background-color: ${colors.brandPrimary};
	text-decoration: none;
	padding: 0.625em 1em;

	& > h6 {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	& > svg {
		margin-right: 0.4375em;
		width: 1.125em !important;
		height: 1.125em !important;
	}
	&:hover {
		color: white;
	}
	&:not(.active):hover {
		opacity: 0.8;
	}
	&.active {
		background-color: ${colors.brandSecondary};
	}
`;
