import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Controller, useForm } from 'react-hook-form';

import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { Button } from '../Button';
import { Card } from '../Card';
import { Modal } from '../Modal';
import { Select } from '../Select';
import { required } from '../../utils/form-default-errors';
import * as S from './ServiceAddresses.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RemoveAddressModal } from '../../pages';
import { useDispatch, useSelector } from 'react-redux';
import { PAYMENT_TYPES, PLACEHOLDER_USER_DETAILS } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { updateCurrentLocation } from '../../app/slices/location/locationSlice';
import { removeAccount } from '../../services/userService';
import { fetchUser } from '../../app/slices/user/userSlice';

export const ServiceAddresses = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const history = useHistory();
	const dispatch = useDispatch();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [utilityKeyForRemoval, setUtilityKeyForRemoval] = useState(null);
	const [greenlightKeyForRemoval, setGreenlightKeyForRemoval] = useState(null);
	const [locationIDForRemoval, setLocationIDForRemoval] = useState(null);

	const {
		handleSubmit,
		control,
		formState: { errors, isSubmitting },
		reset,
	} = useForm();

	async function onSubmit(data) {
		// Format keys
		const accountKeys = [data.accountKeys.utility, data.accountKeys.greenlight].filter(Boolean);

		// Initialize success
		let isSuccess = true;
		try {
			for (let i = 0; i < accountKeys.length; i += 1) {
				const { Success } = await removeAccount({ locationID: data.locationID, accountKey: accountKeys[i], reason: data.reasonForRemoval });
				if (!Success) isSuccess = false;
			}
			if (isSuccess) {
				setUtilityKeyForRemoval(null);
				setGreenlightKeyForRemoval(null);
				setLocationIDForRemoval(null);
				setIsDeleteModalOpen(false);
				dispatch(fetchUser({ placeholderUser: PLACEHOLDER_USER_DETAILS }));
				toast.success('Successfully removed account from your profile.');
			} else {
				toast.error('There was an issue removing this account. Please try again.');
				reset(undefined, { keepValues: true });
			}
		} catch (e) {
			toast.error(e.message);
			reset(undefined, { keepValues: true });
		}
	}

	const formatLocationName = (location) => {
		let name = '';
		if (location.UtilityAccount != null) {
			name += location.UtilityAccount?.AccountName;
		}
		if (location.GreenlightAccount != null) {
			if (name !== '') name += ' / ';
			name += location.GreenlightAccount?.AccountName;
		}
		return name || 'Location';
	};

	const showRemoveConfirmation = ({ utilityKey, greenlightKey, locationId }) => {
		setUtilityKeyForRemoval(utilityKey);
		setGreenlightKeyForRemoval(greenlightKey);
		setLocationIDForRemoval(locationId);
		setIsDeleteModalOpen(true);
	};

	return (
		<>
			<Card
				className={className}
				title="Service Addresses"
				action={[
					{
						id: 1,
						onClick: () => {
							history.push('/onboarding');
						},
						icon: ['fal', 'plus'],
						label: 'Add New Address',
					},
				]}
			>
				<S.Wrapper>
					{user?.locations?.map((location) => (
						<S.ItemWrapper key={location.LocationID}>
							<div className="item-wrapper">
								<Typography tag="h5" weight="bold">
									{formatLocationName(location)}
								</Typography>
								<div className="value-info">
									<Typography tag="h6" weight="semibold">
										Address
									</Typography>
									<Typography tag="p">{location.Address}</Typography>
								</div>
								{location.UtilityAccount != null && (
									<div className="value-info">
										<Typography tag="h6" weight="semibold" className="editable-heading">
											<span>Utilities</span>
											{location.UtilityAccount.IsPrePay !== true && (
												<IconButton
													size={1.15}
													icon={['fal', 'edit']}
													onClick={() => {
														dispatch(updateCurrentLocation(location));
														history.push({ pathname: '/manage-utility/edit', state: { backUrl: window.location.pathname } });
													}}
												/>
											)}
										</Typography>
										<div className="badge-wrapper">
											{location.UtilityAccount?.Services?.map((service) => (
												<S.UtilityBadge key={service}>
													<FontAwesomeIcon icon={['fal', 'check']} />
													<Typography tag="p" variation="2" weight="bold">
														{service.toLowerCase()}
													</Typography>
												</S.UtilityBadge>
											))}
										</div>
									</div>
								)}
							</div>
							<div className="item-wrapper">
								<Button
									className="self-end desktop-only"
									variant="text"
									variation="warning"
									onClick={() => {
										showRemoveConfirmation({
											utilityKey: location.UtilityAccount?.AccountNumber,
											greenlightKey: location.GreenlightAccount?.AccountNumber,
											locationId: location.LocationID,
										});
									}}
								>
									<Typography tag="span" variation="2" weight="extrablack">
										Remove
									</Typography>
								</Button>
								<div className="value-info-wrapper">
									<div className="value-info">
										<Typography tag="h6" weight="semibold" className="editable-heading">
											<span>Account Name</span>
											{/*<IconButton
												size={1.15}
												icon={['fal', 'edit']}
												onClick={() => { }}
											/>*/}
										</Typography>
										<Typography tag="p">{location.UtilityAccount?.AccountName || location.GreenlightAccount?.AccountName}</Typography>
									</div>
									{location.GreenlightAccount != null && (
										<div className="value-info o3">
											<Typography tag="h6" weight="semibold" className="editable-heading">
												<span>Greenlight</span>
												{location.GreenlightAccount.IsPrePay !== true && (
													<IconButton
														size={1.15}
														icon={['fal', 'edit']}
														onClick={() => {
															dispatch(updateCurrentLocation(location));
															history.push({ pathname: '/support', state: { backUrl: window.location.pathname } });
														}}
													/>
												)}
											</Typography>
											<div className="badge-wrapper">
												{location.GreenlightAccount?.Services?.map((service) => (
													<S.UtilityBadge key={service}>
														<FontAwesomeIcon icon={['fal', 'check']} />
														<Typography tag="p" variation="2" weight="bold">
															{service.toLowerCase()}
														</Typography>
													</S.UtilityBadge>
												))}
												<S.UtilityBadge key="accountType">
													<FontAwesomeIcon icon={['fal', 'check']} />
													<Typography tag="p" variation="2" weight="bold">
														{location.GreenlightAccount?.IsCommercial ? 'Commerical' : 'Residential'}
													</Typography>
												</S.UtilityBadge>
											</div>
										</div>
									)}
									<div className="value-info">
										<Typography tag="h6" weight="semibold" className="editable-heading">
											<span>Billing Plan</span>
											{/*<IconButton
												size={1.15}
												icon={['fal', 'edit']}
												onClick={() => {}}
											/>*/}
										</Typography>
										<Typography tag="p">Standard</Typography>
									</div>
								</div>
								<Button
									className="w-full mobile-only"
									variant="outline"
									variation="warning"
									onClick={() => {
										showRemoveConfirmation({
											utilityKey: location.UtilityAccount?.AccountNumber,
											greenlightKey: location.GreenlightAccount?.AccountNumber,
											locationId: location.LocationID,
										});
									}}
								>
									<Typography tag="span" variation="2" weight="extrablack">
										Remove
									</Typography>
								</Button>
							</div>
						</S.ItemWrapper>
					))}
				</S.Wrapper>
			</Card>
			<RemoveAddressModal isOpen={isDeleteModalOpen} onSubmit={onSubmit} onRequestClose={() => setIsDeleteModalOpen(false)} isSubmitting={isSubmitting} accountKeys={{ utility: utilityKeyForRemoval, greenlight: greenlightKeyForRemoval }} locationID={locationIDForRemoval} />
		</>
	);
};

ServiceAddresses.propTypes = {
	className: PropTypes.string,
};
