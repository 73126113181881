import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	margin-top: 0.75rem;
`;

export const Card1ContentWrapper = styled.div`
	margin-bottom: 0.5rem;
	h5 {
		line-height: 2.375rem;
		margin-bottom: 0.4rem;
	}
	p {
		margin-bottom: 1rem;
	}
	border-bottom: 1px solid ${colors.neutralForm};
`;

export const Card1DetailsWrapper = styled.div`
	margin-bottom: 1rem;
`;

export const Card1ContentWrapper2 = styled.div`
	padding-bottom: 0.5rem;
	h5 {
		line-height: 2.375rem;
		margin-bottom: 0.75rem;
	}

	h6 {
		line-height: 1.4;
	}
`;

export const Card2PackageDetails = styled.div`
	margin-top: 0.5rem;
	margin-bottom: 1.25rem;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
`;

export const Card2ContentWrapper = styled.div`
	margin-bottom: 0.5rem;
	padding-bottom: 1rem;
	h5 {
		line-height: 2.375rem;
		margin-bottom: 0.4rem;
	}
	border-bottom: 1px solid ${colors.neutralForm};
`;

export const Card2ContentSubWrapper = styled.div`
	margin-bottom: 0.75rem;
	h6 {
		line-height: 2.375rem;
	}
`;

export const Card2ContentFlex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const PackageBundleDetailsHeading = styled.div`
	margin-bottom: 0.5rem;
`;

export const PackageBundleDetails = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;
export const PackageDescription = styled.div`
	& > h6 {
		line-height: 1.4;
	}
`;
export const FormContent = styled.div`
	width: 100%;
	max-width: 44.375rem;
	margin: 0 auto;
	margin-bottom: 3.5rem;
	display: grid;
	gap: 2.625rem;
	h1 {
		padding-bottom: 0.8rem;
	}
	@media screen and (max-width: 768px) {
		h3 {
			font-size: 1.5rem;
		}
	}
`;
