import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	margin-top: 0.75rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 74rem;
	margin: 0 auto;
	margin-bottom: 3.5rem;
	display: grid;
	gap: 1.75rem;

	@media screen and (max-width: 768px) {
		h3 {
			font-size: 1.5rem;
		}
	}
`;

export const ContentType1Container = styled.div`
	padding-bottom: 1.5rem;
	h5 {
		margin-top: 0.5rem;
	}
`;

export const RadioInputContainer = styled.div`
	max-width: 12rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;
	div {
		align-items: center;
	}
	label {
		margin-bottom: 0;
		margin-left: 0.7rem;
		cursor: pointer;
	}
`;

export const ContentType1ParaContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.8rem;
	svg {
		color: ${colors.brandPrimary};
		margin-left: 0.25rem;
	}
`;

export const HorizontalLine = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.neutralForm};
	margin-bottom: 1rem;
`;

export const RadioInputsContainer2 = styled.div`
	margin-top: 0.75rem;
	margin-bottom: 1.0625rem;
	display: flex;
	flex-direction: column;
	div {
		align-items: flex-start;
		margin-bottom: 1.0625rem;
	}
	label {
		margin-bottom: 0;
		margin-left: 0.7rem;
		cursor: pointer;
	}
`;

export const SubFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
	margin-left: 2rem;
	max-width: 34.875rem;

	${({ isVisible }) =>
		isVisible
			? css`
					display: block;
			  `
			: css`
					display: none;
			  `}

	.inputContainer {
		align-items: flex-start;
		width: 100%;
		margin-bottom: 0px;

		label {
			margin-left: 0;
			font-weight: 600;
			margin-bottom: 0.5rem;
		}
		input {
			width: 100%;
			margin-bottom: 1.375rem;
		}
	}
	@media screen and (max-width: 576px) {
		margin-left: 1rem;
	}
	@media screen and (max-width: 768px) {
		max-width: 100%;
		width: 100%;
	}
`;

export const BillingSection = styled.div`
	${({ isVisible }) =>
		isVisible
			? css`
					display: block;
			  `
			: css`
					display: none;
			  `}
`;

export const DatePickerContainer = styled.div`
	width: 100%;

	.inputContainer {
		align-items: flex-start;
		width: 100%;

		label {
			margin-left: 0;
			font-weight: 600;
			margin-bottom: 0.5rem;
		}
		input {
			width: 100%;
		}
	}

	label {
		margin-left: 0;
		font-weight: 600;
		margin-bottom: 0.5rem;
		color: ${colors.formLabel};
		height: 20px !important;
		min-width: 10px;
	}

	div {
		margin-bottom: 0;
		div {
			div {
				align-items: flex-start;
				div {
					align-items: center;
					width: 100%;
				}
			}
		}
	}

	svg {
		left: auto;
		right: 0.5em;
	}

	input {
		padding-left: 0.75rem;
		padding-right: 2em;
		width: 100%;
	}
`;

export const CreditCardDateContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1.625rem;
	margin-bottom: 0 !important;

	@media screen and (max-width: 576px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 0.8rem;
		align-items: flex-start;

		.inputContainer {
			margin-top: -0.5rem;
		}
	}
`;
