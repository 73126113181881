import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Table, DateCell, CurrencyCell, BadgeCell } from '../Table';
import * as S from './InvoiceHistory.styles';
import { AvailableStatementCell, tableSorterDate } from '../Table/Table';
import { useSelector } from 'react-redux';
import { Spinner } from '../Spinner';
import { EmptyComponent } from '../EmptyComponent';
import { formatDate } from '../../utils/time-formatter';
import { useHistory } from 'react-router-dom';
import { retrieveAccountBillHistory } from '../../services/userService';

export const InvoiceHistory = ({ className, filterCount, onFilterButtonClick, filterSelectedOptions }) => {
	const [status, setStatus] = useState('loading');
	const [invoices, setInvoices] = useState([]);
	const currentLocation = useSelector((state) => state.location.value);

	const [columns] = useState(
		[
			{
				Header: 'Bill Date',
				accessor: 'billDate',
				Cell: DateCell,
				CellName: 'DateCell',
				sortType: tableSorterDate,
			},
			{
				Header: 'Due Date',
				accessor: 'dueDate',
				Cell: DateCell,
				CellName: 'DateCell',
				sortType: tableSorterDate,
			},
			/*currentLocation?.IsBudgetBilling === true && {
				Header: 'Deferred Balance',
				accessor: 'deferredBalance',
				Cell: CurrencyCell,
			},*/
			currentLocation?.IsBudgetBilling !== true && {
				Header: 'Balance Forward',
				accessor: 'DelinquentBalance',
				Cell: CurrencyCell,
				CellName: 'CurrencyCell',
			},
			{
				Header: 'Current Charges',
				accessor: 'CurrentCharges',
				Cell: CurrencyCell,
				CellName: 'CurrencyCell',
			},
			{
				Header: 'Amount Due',
				accessor: 'amountDue',
				Cell: CurrencyCell,
				CellName: 'CurrencyCell',
			},
			{
				Header: 'Type',
				accessor: 'type',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: BadgeCell,
				CellName: 'BadgeCell',
			},
			{
				Header: '',
				accessor: 'IsBillPDFAvailable',
				Cell: AvailableStatementCell,
				CellName: 'AvailableStatementCell',
				hideHeader: true,
			},
		].filter(Boolean)
	);

	async function init() {
		try {
			let bills = [];
			if (currentLocation?.UtilityAccount != null) {
				let utilityBills = await retrieveAccountBillHistory({ accountKey: currentLocation?.UtilityAccount?.AccountKey });
				utilityBills = utilityBills.map((bill) => {
					return {
						...bill,
						type: 'Utilities',
						status: bill.PaidStatus && bill.PaidStatus.toLowerCase() !== 'paid' ? bill.PaidStatus : '',
						AccountKey: currentLocation?.UtilityAccount?.AccountKey,
					};
				});
				bills.push(...utilityBills);
			}
			if (currentLocation?.GreenlightAccount != null) {
				let greenlightBills = await retrieveAccountBillHistory({ accountKey: currentLocation?.GreenlightAccount?.AccountKey });
				greenlightBills = greenlightBills.map((bill) => {
					return {
						...bill,
						type: 'Greenlight',
						status: bill.PaidStatus && bill.PaidStatus.toLowerCase() !== 'paid' ? bill.PaidStatus : '',
						AccountKey: currentLocation?.GreenlightAccount?.AccountKey,
					};
				});
				bills.push(...greenlightBills);
			}
			bills.sort((a, b) => new Date(b.BillDate).getTime() - new Date(a.BillDate).getTime());
			bills = bills.filter((bill) => {
				let validBill = true;
				if (filterSelectedOptions.startDate != null) {
					if (new Date(bill.BillDate).getTime() < filterSelectedOptions.startDate) validBill = false;
				}
				if (filterSelectedOptions.endDate != null) {
					if (new Date(bill.BillDate).getTime() > filterSelectedOptions.endDate) validBill = false;
				}
				if (filterSelectedOptions.amount.min != null && filterSelectedOptions.amount.max != null) {
					if (bill.AmountDue < filterSelectedOptions.amount.min || bill.AmountDue > filterSelectedOptions.amount.max) validBill = false;
				}
				if (filterSelectedOptions.isUtilities != null) {
					if (filterSelectedOptions.isUtilities !== true && bill.type === 'Utilities') validBill = false;
				}
				if (filterSelectedOptions.isGreenlight != null) {
					if (filterSelectedOptions.isGreenlight !== true && bill.type === 'Greenlight') validBill = false;
				}
				return validBill;
			});
			setInvoices(
				bills.map((bill) => {
					return {
						...bill,
						id: bill.BillID,
						BillID: bill.BillID,
						billDate: formatDate(bill.BillDate),
						dueDate: formatDate(bill.DueDate),
						deferredBalance: 0,
						amountDue: bill.PaidStatus ? bill.CurrentBalance : '--',
					};
				})
			);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, [filterSelectedOptions]);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (invoices.length === 0 && filterCount === 0) {
			return <EmptyComponent icon={['fal', 'credit-card']} title="No Recent Invoices" description="Check back to view your recent Utilities and Greenlight invoices." />;
		}
		return <Table columns={columns} data={invoices} isFilterButton filterCount={filterCount} onFilterButtonClick={onFilterButtonClick} />;
	};

	return (
		<Card className={className} title="Invoice History">
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</Card>
	);
};
InvoiceHistory.propTypes = {
	className: PropTypes.string,
	filterCount: PropTypes.number,
	onFilterButtonClick: PropTypes.func,
	filterSelectedOptions: PropTypes.shape(),
};
