import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 9999999;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.statusBad : 'inherit')};
		line-height: normal;
		font-size: 1em;
		line-height: normal;
		margin-bottom: 0.375em;
		display: inline-block;
	}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1.125em;
	color: ${colors.statusBad};
`;
