import styled from 'styled-components';

import colors from '../../styles/colors';
import { hideScrollbar } from '../../styles/utils';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	margin: -0.25rem -1rem 0;
`;

export const Head = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	white-space: nowrap;
	background-color: ${(props) => (props.transparent ? 'transparent' : 'white')};
	position: relative;
	box-shadow: ${(props) => (props.transparent ? 'none' : colors.sidebarShadow)};
	border-bottom: ${(props) => (props.transparent ? `1px solid ${colors.neutralForm}` : 'none')};

	${hideScrollbar}
`;

export const TabButton = styled.button`
	display: flex;
	justify-content: center;
	text-align: center;
	appearance: none;
	border: none;
	background-color: transparent;
	padding: 0.5rem 1rem;
	flex: 1;
	color: ${({ active }) => (active ? colors.brandPrimary : colors.neutralText)};

	& > *:first-child {
		position: relative;
	}

	@media screen and (min-width: 768px) {
		flex: 0;
	}
`;

export const ActiveIndicator = styled.div`
	position: absolute;
	bottom: -0.5rem;
	left: 0;
	right: 0;
	height: 0.125rem;
	background-color: ${colors.brandPrimary};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;
