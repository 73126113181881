import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/wilson-logo.png';
import * as S from './OnboardingHeader.styles';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OnboardingHeader = ({ showExit = true, showDashboard = false }) => {
	const history = useHistory();

	return (
		<S.Wrapper>
			<S.LeftContainer>
				{showExit && (
					<Button
						variant="outline"
						type="button"
						onClick={() => {
							history.push('/onboarding');
						}}
					>
						<Typography tag="span" variation="2" weight="extrablack">
							Save &amp; Exit
						</Typography>
					</Button>
				)}
				{!showExit && showDashboard && (
					<Button
						variant="outline"
						onClick={() => {
							history.push('/');
						}}
					>
						<Typography tag="span" variation="2" weight="extrablack">
							Back to Dashboard
						</Typography>
					</Button>
				)}
			</S.LeftContainer>
			<S.CenterContainer>
				<S.Image src={logo} alt="" />
			</S.CenterContainer>
			<S.RightContainer>
				<Button
					variant="outline"
					type="button"
					onClick={() => {
						history.push('/logout');
					}}
				>
					<Typography tag="span" variation="2" weight="extrablack">
						Log out
					</Typography>
				</Button>
			</S.RightContainer>
		</S.Wrapper>
	);
};

OnboardingHeader.propTypes = {
	showExit: PropTypes.bool,
	showDashboard: PropTypes.bool,
};
