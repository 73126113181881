import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.div`
	max-widht: 72.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	padding: 3rem 1rem;

	svg {
		font-size: 2.5rem;
		color: ${colors.brandPrimary};
		margin-bottom: 0.75rem;
	}

	@media screen and (min-width: 768px) {
		padding: 5rem 0;
	}
`;
export const ParaContainer = styled.div`
	margin-top: 3px;
	max-width: 34.125rem;
	margin-bottom: 2.25rem;

	@media screen and (min-width: 768px) {
		margin-bottom: 3.25rem;
	}
`;
export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const ButtonWrapper = styled.div`
	margin-left: 1.125rem;
`;
