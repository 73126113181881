import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { ErrorComponent, PageHeader, Spinner } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './AdminConfiguration.styles';
import { AnnouncementBar } from './AnnouncementBar';
import { MaintenanceMode } from './MaintenanceMode';

export default function AdminConfiguration() {
	const [pageStatus, setPageStatus] = useState('idle');
	const [configuration, setConfiguration] = useState(null);

	async function initializeData() {
		setPageStatus('loading');
		try {
			const { data } = await axios.get(`${process.env.REACT_APP_INTERNAL_API_URL}/configuration`);
			setConfiguration(data);
			setPageStatus('succeeded');
		} catch (e) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		// Set document title
		document.title = `Configuration | ${META_TITLE}`;
	}, []);

	const renderContent = () => {
		if (pageStatus === 'loading') {
			return <Spinner />;
		} else if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		if (pageStatus === 'succeeded') {
			return (
				<>
					<PageHeader>Configuration</PageHeader>
					<AnnouncementBar className="a1" configuration={configuration} />
					<MaintenanceMode className="a2" configuration={configuration} />
				</>
			);
		}
		return null;
	};

	return <S.Wrapper>{renderContent()}</S.Wrapper>;
}
