import React, { useMemo } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { PaymentAddNewMethodModal } from '../../components';
import { paymentMethod } from '../../app/slices/makePayment/makePaymentSlice';

import { Button, OnboardingFooter, Typography, Stepper, Card, Radio, MakePaymentHeader } from '../../components';
import * as S from './MakePaymentPaymentMethod.styles';
import { useEffect } from 'react';
import { META_TITLE } from '../../utils/constants';
import { walletRetrieveAccounts } from '../../services/walletService';
import toast from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import colors from '../../styles/colors';

export default function MakePaymentPaymentMethod() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');

	const currentLocation = useSelector((state) => state.location.value);

	const [addMethodModal, setAddMethodModal] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [fullPaymentMethods, setFullPaymentMethods] = useState([]);

	const stepData = useSelector((state) => state.makePayment);
	const dispatch = useDispatch();
	const history = useHistory();

	const { handleSubmit, setValue, watch } = useForm({
		defaultValues: {
			...stepData.paymentMethod,
			WalletAccountKey: fullPaymentMethods?.[0]?.WalletAccountKey,
			AccountName: fullPaymentMethods?.[0]?.AccountName,
			AccountToken: fullPaymentMethods?.[0]?.AccountToken,
			CardLast4: fullPaymentMethods?.[0]?.CardLast4,
			AccountType: fullPaymentMethods?.[0]?.AccountType,
			CardExpirationDate: fullPaymentMethods?.[0]?.CardExpirationDate,
			ACHRoutingNumber: fullPaymentMethods?.[0]?.ACHRoutingNumber,
			ACHAccountNumber: fullPaymentMethods?.[0]?.ACHAccountNumber,
			CardCardholderName: fullPaymentMethods?.[0]?.CardCardholderName,
		},
	});

	const WalletAccountKey = watch('WalletAccountKey');

	const onBack = () => {
		history.push('/make-payment/amount');
	};

	const onNext = (data) => {
		if (!data.AccountToken) {
			toast.error('Please select a payment method to continue.');
			return;
		}
		dispatch(paymentMethod(data));
		history.push('/make-payment/date');
	};

	const addMethod = (method) => {
		let methods = [...paymentMethods];
		methods.push(method);
		methods = methods.filter((v, i, a) => a.findIndex((v2) => v2.WalletAccountKey === v.WalletAccountKey) === i);
		setFullPaymentMethods(methods);
		setValue('paymentMethods', methods);
		setValue('WalletAccountKey', method?.WalletAccountKey);
		setValue('AccountName', method?.AccountName);
		setValue('AccountToken', method?.AccountToken);
		setValue('CardLast4', method?.CardLast4);
		setValue('AccountType', method?.AccountType);
		setValue('CardExpirationDate', method?.CardExpirationDate);
		setValue('ACHRoutingNumber', method?.ACHRoutingNumber);
		setValue('ACHAccountNumber', method?.ACHAccountNumber);
		setValue('CardCardholderName', method?.CardCardholderName);
	};

	const onMethodChange = (payMethod) => {
		setValue('WalletAccountKey', payMethod?.WalletAccountKey);
		setValue('AccountName', payMethod?.AccountName);
		setValue('AccountToken', payMethod?.AccountToken);
		setValue('CardLast4', payMethod?.CardLast4);
		setValue('AccountType', payMethod?.AccountType);
		setValue('CardExpirationDate', payMethod?.CardExpirationDate);
		setValue('ACHRoutingNumber', payMethod?.ACHRoutingNumber);
		setValue('ACHAccountNumber', payMethod?.ACHAccountNumber);
		setValue('CardCardholderName', payMethod?.CardCardholderName);
	};

	async function fetchPaymentMethods() {
		try {
			let itemArray = await walletRetrieveAccounts();
			setPaymentMethods(itemArray);
			if (stepData?.newMethodAdded && Object.keys(stepData?.newMethodAdded).length > 0) {
				itemArray = [...itemArray, stepData?.newMethodAdded];
			}
			itemArray = itemArray.filter((v, i, a) => a.findIndex((v2) => v2.WalletAccountKey === v.WalletAccountKey) === i);
			setFullPaymentMethods(itemArray);
			setValue('paymentMethods', itemArray);
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Make Payment | ${META_TITLE}`;

		// Fetch payment methods
		fetchPaymentMethods();

		// Redirect if necessary
		if (Object.keys(stepData.paymentAmount).length === 0) {
			history.push('/');
		}

		// Set current payment method
		const s = stepData.paymentMethod;
		if (s?.WalletAccountKey) {
			setValue('WalletAccountKey', s?.WalletAccountKey);
		}
		if (s?.AccountName) {
			setValue('AccountName', s?.AccountName);
		}
		if (s?.AccountToken) {
			setValue('AccountToken', s?.AccountToken);
		}
		if (s?.CardLast4) {
			setValue('CardLast4', s?.CardLast4);
		}
		if (s?.AccountType) {
			setValue('AccountType', s?.AccountType);
		}
		if (s?.paymentMethods) {
			setFullPaymentMethods(s?.paymentMethods);
			setValue('paymentMethods', s?.paymentMethods);
		}
	}, []);

	const renderExpirationIndicator = (method) => {
		const expirationDate = method.CardExpirationDate;
		const currentDate = moment(moment().format('MM/YYYY'), 'MM/YYYY');
		const dateObj = moment(expirationDate, 'MM/YYYY').isValid() ? moment(expirationDate, 'MM/YYYY') : currentDate.add(1, 'month');
		if (dateObj.isSameOrBefore(currentDate)) {
			return (
				<S.ExpirationContainer>
					<S.InfoIcon icon={['fal', 'exclamation-triangle']} data-for={`expiration${method.WalletAccountKey}`} data-tip />
					<ReactTooltip id={`expiration${method.WalletAccountKey}`} backgroundColor={colors.statusBad} effect="solid">
						<span>This payment method has expired. Please update your card information or add a new card.</span>
					</ReactTooltip>
				</S.ExpirationContainer>
			);
		}
		return null;
	};

	return (
		<>
			<PaymentAddNewMethodModal afterSubmit={addMethod} isOpen={addMethodModal} onRequestClose={() => setAddMethodModal(false)} showSaveOption={true} defaultSave={false} stepData={stepData} enableACHOption={true} />
			<S.Form onSubmit={handleSubmit(onNext)}>
				<MakePaymentHeader />
				<Stepper
					activeStep={1}
					steps={[
						{ id: 1, label: 'Amount' },
						{ id: 2, label: 'Payment' },
						{ id: 3, label: 'Date' },
						{ id: 4, label: 'Review' },
						{ id: 5, label: 'Confirmation' },
					]}
				/>
				<S.FormContentWrapper>
					<S.FormContent>
						<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
							Payment Method
						</Typography>
						<Card title="Select Payment Method">
							<S.RadioContainers>
								{fullPaymentMethods
									?.map(
										(payMethod, index) =>
											(payMethod?.AccountType === 'Card' || !currentLocation?.IsCreditCardOnly) && (
												<Radio
													key={payMethod?.WalletAccountKey}
													id={'paymentMethod' + index}
													label={
														<S.RadioContent>
															{payMethod?.AccountType === 'Card' ? `${payMethod?.AccountName || 'Card'} (**** **** **** ${payMethod?.CardLast4})` : `${payMethod?.AccountName || 'Bank Account'} (ACH)`}
															{payMethod?.CardExpirationDate && renderExpirationIndicator(payMethod)}
														</S.RadioContent>
													}
													value={payMethod?.WalletAccountKey}
													checked={WalletAccountKey === payMethod?.WalletAccountKey}
													onChange={() => onMethodChange(payMethod)}
												/>
											)
									)
									.filter(Boolean)}
							</S.RadioContainers>
							<S.AddNewMethod onClick={() => setAddMethodModal(true)}>
								<S.AddSymbol>
									<Typography tag="span" variation="3" weight="light">
										+
									</Typography>
								</S.AddSymbol>
								<Typography tag="span" variation="1" weight="extrablack">
									Add New Payment Method
								</Typography>
							</S.AddNewMethod>
						</Card>
					</S.FormContent>
				</S.FormContentWrapper>

				<OnboardingFooter>
					<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
						<Typography tag="span" variation="1" weight="extrablack">
							Back
						</Typography>
					</Button>
					<Button variant="outline" type="submit">
						<Typography tag="span" variation="1" weight="extrablack">
							Next
						</Typography>
					</Button>
				</OnboardingFooter>
			</S.Form>
		</>
	);
}
