import styled from 'styled-components';

import colors from '../../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const RadioContainers = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	& > div {
		margin-bottom: 0.6rem;
	}
`;

export const AddNewMethod = styled.div`
	cursor: pointer;
	margin-top: 1rem;
	color: ${colors.brandPrimary};
	display: flex;
`;

export const AddSymbol = styled.div`
	margin-right: 10px;
`;

export const ExpirationContainer = styled.div`
	svg {
		width: 1.2rem !important;
		height: 1.2rem !important;
		position: relative;
		top: 2px;
		color: ${colors.statusBad};
	}
`;

export const InfoIcon = styled(FontAwesomeIcon)`
	margin-left: 10px;
	cursor: pointer;
`;

export const RadioContent = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
	position: relative;
	top: -2px;
`;
