import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 0.5rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const ToggleItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-top: 0.125rem;
	}
`;

export const SectionWrapper = styled.div``;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	& > * + * {
		margin-top: 1rem;
	}
`;
