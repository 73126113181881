import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.25rem;
	}
`;

export const ActivityItem = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${colors.neutralForm};
	padding-bottom: 0.5rem;

	.small-text {
		margin-top: 0.25rem;
		color: ${colors.neutralText};
	}

	&:last-child {
		border: none;
		padding-bottom: 0;
	}
`;
