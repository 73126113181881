import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Modal, Button } from '../../components';
import * as S from './ProfileSearchSetupCompleteModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

const ProfileSearchSetupCompleteModal = ({ isOpen, setIsOpen, onClickButton }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<S.ModalContainer>
				<Typography tag={isHigherThan768 ? 'h1' : 'h3'} weight="bold" center>
					Your Account Setup is Complete
				</Typography>
				<S.ParaContainer>
					<Typography tag={isHigherThan768 ? 'p' : 'h6'} variation="1" center>
						Continue to your dashboard to view more account details{' '}
					</Typography>
				</S.ParaContainer>
				<Button variant="solid" onClick={onClickButton}>
					<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
						Continue to Dashboard
					</Typography>
				</Button>
			</S.ModalContainer>
		</Modal>
	);
};
export default ProfileSearchSetupCompleteModal;

ProfileSearchSetupCompleteModal.propTypes = {
	isOpen: PropTypes.bool?.isRequired,
	setIsOpen: PropTypes.func?.isRequired,
	onClickButton: PropTypes.func?.isRequired,
};
