import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Card = styled.div`
	background-color: white;
	padding: 1.25em;
	box-shadow: ${colors.sidebarShadow};
	border-radius: 0.25em;
`;

export const TitleWrapper = styled.div`
	border-bottom: 1px solid ${colors.neutralForm};
	padding-bottom: 0.5em;
	margin-bottom: 0.5em;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${({ clear }) =>
		clear &&
		css`
			button {
				padding: 0;
			}
		`}
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ButtonWrapper = styled.section`
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;
	padding-top: 0.5rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-top: 0.5rem;
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;
		margin: 0;
		padding: 0;
		border: none;

		& > * + * {
			margin-top: 0;
			margin-left: 0.75rem;
		}
	}
`;
