import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.25rem;
	}
`;
