import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Card } from '../Card';
import { Typography } from '../Typography';
import { formatToUSD } from '../../utils/currency-formatter';
import * as S from './GreenlightPanel.styles';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/time-formatter';

export const GreenlightPanel = ({ className, setReqEquipmentModal }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const history = useHistory();
	const currentLocation = useSelector((state) => state.location.value);

	// Get utilities account
	const GreenlightAccount = currentLocation?.GreenlightAccount;

	function onManageGreenLight() {
		history.push('/support');
	}

	function renderCards() {
		if (isHigherThan768) {
			return (
				<>
					<Typography tag="h5" weight="bold">
						Current Plan
					</Typography>
					<S.DesktopWrapper>
						<S.Head>
							<Typography tag="h6" weight="bold">
								Service
							</Typography>
							<Typography tag="h6" weight="bold">
								Account Number
							</Typography>
							{/*<Typography tag="h6" weight="bold">
								Amount
							</Typography>*/}
						</S.Head>
						{GreenlightAccount?.Services?.map((item) => (
							<S.DesktopItem key={item}>
								<Typography tag="p" className="titlecase">
									{item}
								</Typography>
								<Typography tag="p">{GreenlightAccount?.AccountNumber}</Typography>
								{/*<Typography tag="p">{formatToUSD(item.amount)}</Typography>*/}
							</S.DesktopItem>
						))}
					</S.DesktopWrapper>
				</>
			);
		}
		return (
			<>
				<Typography tag="h5" weight="bold">
					Current Plan
				</Typography>
				{GreenlightAccount?.Services?.map((item) => (
					<S.MobileItem key={item}>
						<S.Head>
							<Typography tag="h4" weight="bold" className="titlecase">
								{item}
							</Typography>
						</S.Head>
						<Row>
							<Col xs={6}>
								<S.ItemTable>
									<Typography tag="h6" weight="bold">
										Account Number
									</Typography>
									<Typography tag="p">{GreenlightAccount?.AccountNumber}</Typography>
								</S.ItemTable>
							</Col>
							{/*<Col xs={6}>
								<S.ItemTable>
									<Typography tag="h6" weight="bold">
										Amount
									</Typography>
									<Typography tag="p">{formatToUSD(item.amount)}</Typography>
								</S.ItemTable>
							</Col>*/}
						</Row>
					</S.MobileItem>
				))}
			</>
		);
	}

	return (
		<Card
			className={className}
			title="Greenlight"
			action={[
				{ id: 1, onClick: onManageGreenLight, label: 'Manage', icon: ['fal', 'cog'] },
				{ id: 2, onClick: () => setReqEquipmentModal(true), label: 'Request Equipment', icon: ['fal', 'tools'], type: 'outline' },
			]}
		>
			<S.ItemWrapper>
				<Typography tag="h5" weight="bold">
					Greenlight
				</Typography>
				<S.InfoWrapper>
					<Container fluid>
						<Row>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Current Balance
									</Typography>
									<Typography tag="h2" weight="bold">
										{formatToUSD(GreenlightAccount?.CurrentBalance || 0)}
									</Typography>
								</S.Item>
							</Col>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Bill Date
									</Typography>
									<Typography tag="h2" weight="bold">
										{GreenlightAccount?.LastBillDate ? formatDate(GreenlightAccount?.LastBillDate) : null}
									</Typography>
								</S.Item>
							</Col>
							<Col xs={12} sm={6} md={3}>
								<S.Item>
									<Typography tag="h6" weight="semibold">
										Payment Due Date
									</Typography>
									<Typography tag="h2" weight="bold">
										{GreenlightAccount?.CurrentDueDate ? formatDate(GreenlightAccount?.CurrentDueDate) : null}
									</Typography>
								</S.Item>
							</Col>
						</Row>
					</Container>
				</S.InfoWrapper>
			</S.ItemWrapper>
			{!isHigherThan768 && (
				<Typography tag="h3" weight="bold">
					Greenlight Active Services
				</Typography>
			)}
			<S.CardWrapper>{renderCards()}</S.CardWrapper>
		</Card>
	);
};

GreenlightPanel.propTypes = {
	className: PropTypes.string,
	setReqEquipmentModal: PropTypes.func,
};
