import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './IconButton.styles';

export const IconButton = forwardRef(({ size = 1.5, icon, ...rest }, ref) => {
	return (
		<S.Button ref={ref} size={size} {...rest}>
			<FontAwesomeIcon icon={icon} />
		</S.Button>
	);
});

IconButton.displayName = 'IconButton';
IconButton.propTypes = {
	size: PropTypes.number,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
