import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { required } from '../../utils/form-default-errors';

import * as S from './RemoveAddressModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Button, Modal, Typography, Select } from '../../components';
import { useForm, Controller } from 'react-hook-form';

const reasonForRemovalOptions = [
	{ value: 'moving', label: "I'm Moving" },
	{ value: 'technical-difficulty', label: 'Technical Difficulty' },
];

const RemoveAddressModal = ({ isOpen, onSubmit, onRequestClose, isSubmitting = false, accountKeys, locationID }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const {
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		control,
	} = useForm({
		defaultValues: {
			reasonForRemoval: reasonForRemovalOptions[1],
		},
	});

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onRequestClose}>
			<S.ModalWrapper>
				<S.TitleWrapper>
					<Typography tag="h1" weight="bold" center>
						Remove Address
					</Typography>
					<Typography tag="h3" center>
						Are you sure you want to remove this address?
					</Typography>
				</S.TitleWrapper>
				<S.Form
					onSubmit={handleSubmit((data) => {
						onSubmit({ ...data, accountKeys, locationID });
					})}
				>
					<Controller
						name="reasonForRemoval"
						control={control}
						rules={{ required: required('Reason for Removal') }}
						render={({ field: { onChange, value } }) => (
							<Select label="Reason for Removal" id="reason-for-removal" error={errors.reasonForRemoval} options={reasonForRemovalOptions} value={reasonForRemovalOptions.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />
						)}
					/>
					<Typography tag="h6" weight="semibold">
						Note: If you want to terminate the account, terminate all services.
					</Typography>
					<S.FormButtonWrapper>
						<Button variant="outline" variation="secondary" type="button" onClick={onRequestClose}>
							<Typography tag="span" variation="1" weight="extrablack">
								Cancel
							</Typography>
						</Button>
						<Button variation="warning">
							<Typography tag="span" variation="1" weight="extrablack">
								{isSubmitting ? 'Loading...' : 'Remove Address'}
							</Typography>
						</Button>
					</S.FormButtonWrapper>
				</S.Form>
			</S.ModalWrapper>
		</Modal>
	);
};

export default RemoveAddressModal;

RemoveAddressModal.propTypes = {
	isOpen: PropTypes.bool,
	onSubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
	isSubmitting: PropTypes.bool,
	accountKeys: PropTypes.shape({
		utility: PropTypes.string,
		greenlight: PropTypes.string,
	}),
	locationID: PropTypes.string,
};
