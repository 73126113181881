import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;

	grid-template-columns: repeat(2, 1fr);

	.page-header {
		grid-area: header;
	}
	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}
	.a4 {
		grid-area: a4;
	}
	.a5 {
		grid-area: a5;
	}
	.a6 {
		grid-area: a6;
	}

	/*grid-template-areas:
		'header header'
		'a1 a2'
		'a3 a4'
		'a5 a5'
		'a6 a6';*/

	grid-template-areas:
		'header header'
		'a1 a2'
		'a5 a5'
		'a6 a6';

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);
		/*grid-template-areas:
			'header header header header'
			'a1 a2 a3 a4'
			'a5 a5 a5 a6';*/
		grid-template-areas:
			'header header header header'
			'a1 a2 . .'
			'a5 a5 a5 a5'
			'a6 a6 a6 a6';
	}
`;
