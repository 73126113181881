import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, OnboardingFooter, Typography, Stepper, Card, MakePaymentHeader } from '../../components';
import * as S from './MakePaymentReview.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { META_TITLE } from '../../utils/constants';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { updateAccountAutopay } from '../../services/userService';

export default function MakePaymentReview() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const paymentIsProcessing = useRef(false);

	const user = useSelector((state) => state.user.value);
	const stepData = useSelector((state) => state.makePayment);
	const payMethod = stepData?.paymentMethod;
	const history = useHistory();

	const onBack = () => {
		history.push('/make-payment/date');
	};

	const getUtilityAmount = () => {
		let totalBalance = 0;
		const formState = stepData?.paymentAmount;
		(user?.locations || []).forEach(({ UtilityAccount }) => {
			if (UtilityAccount) {
				const { AccountKey } = UtilityAccount;
				const { type, amount } = formState[AccountKey] || {};
				if (amount && type) {
					totalBalance += parseFloat(`${amount}`);
				}
			}
		});
		return totalBalance;
	};

	const getGreenlightAmount = () => {
		let totalBalance = 0;
		const formState = stepData?.paymentAmount;
		(user?.locations || []).forEach(({ GreenlightAccount }) => {
			if (GreenlightAccount) {
				const { AccountKey } = GreenlightAccount;
				const { type, amount } = formState[AccountKey] || {};
				if (amount && type) {
					totalBalance += parseFloat(`${amount}`);
				}
			}
		});
		return totalBalance;
	};

	const setupAutopayForAccounts = async (payments, walletAccountKey) => {
		await Promise.all(
			payments.map(async (payment) => {
				await updateAccountAutopay({
					accountKey: payment.accountKey,
					autopayStatus: true,
					walletAccount: walletAccountKey,
				});
			})
		);
	};

	const onNext = async (e) => {
		// If processing, return
		if (paymentIsProcessing.current) return;

		// Update state
		paymentIsProcessing.current = true;
		e.preventDefault();
		setIsSubmitting(true);

		// Get data
		const { paymentAmount, paymentDate, paymentMethod } = stepData;

		// Create payment objects
		const paymentsToPost = [];
		const paymentsToLog = [];
		(user?.locations || []).forEach(({ LocationID, UtilityAccount, GreenlightAccount }) => {
			if (UtilityAccount) {
				const { AccountKey, AccountNumber } = UtilityAccount;
				const { type, amount } = paymentAmount[AccountKey] || {};
				if (type && amount && parseFloat(`${amount}`) > 0) {
					paymentsToPost.push({
						type: 'utilities',
						amount: parseFloat(`${amount}`),
						locationId: LocationID,
						accountKey: AccountKey,
						accountNumber: AccountNumber,
					});
					paymentsToLog.push({
						AccountKey: AccountKey,
						PaymentAmount: parseFloat(`${amount}`),
						ConfirmationInfo: '',
					});
				}
			}
			if (GreenlightAccount) {
				const { AccountKey, AccountNumber } = GreenlightAccount;
				const { type, amount } = paymentAmount[AccountKey] || {};
				if (type && amount && parseFloat(`${amount}`) > 0) {
					paymentsToPost.push({
						type: 'greenlight',
						amount: parseFloat(`${amount}`),
						locationId: LocationID,
						accountKey: AccountKey,
						accountNumber: AccountNumber,
					});
					paymentsToLog.push({
						AccountKey: AccountKey,
						PaymentAmount: parseFloat(`${amount}`),
						ConfirmationInfo: '',
					});
				}
			}
		});

		// Add dates to payment objects
		for (let i = 0; i < paymentsToPost.length; i += 1) {
			paymentsToPost[i].dateType = paymentDate.date;
			if (paymentDate.date === 'anotherDate') {
				paymentsToPost[i].dateValue = paymentDate.otherDate;
			}
		}
		for (let i = 0; i < paymentsToLog.length; i += 1) {
			paymentsToLog[i].EffectivePaymentDate = paymentDate.date === 'anotherDate' ? paymentDate.otherDate : paymentDate.immediatelyDate;
		}

		// Make payment with BridgePay
		try {
			const token = window.localStorage.getItem('wilsonnc_token');
			const { data } = await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/payment`, {
				loginToken: token,
				token: paymentMethod.AccountToken,
				walletAccountKey: paymentMethod.WalletAccountKey,
				tokenSource: paymentMethod.AccountType === 'Card' ? 'card' : 'ach',
				expirationDate: paymentMethod.CardExpirationDate,
				routingNumber: paymentMethod.ACHRoutingNumber,
				accountHolderName: paymentMethod.CardCardholderName || user.name,
				payments: paymentsToPost,
			});
			if (data && data.success === true) {
				if (stepData?.paymentDate?.enableAutopay === true && paymentMethod.WalletAccountKey != null && paymentMethod.WalletAccountKey !== 'new') {
					// Set autopay for accounts
					await setupAutopayForAccounts(paymentsToPost, paymentMethod.WalletAccountKey);

					// Update state
					setIsSubmitting(false);
					paymentIsProcessing.current = false;

					// Move to confirmation
					history.push({ pathname: '/make-payment/confirmation', state: { authorizationCode: data.authorizationCode } });
				} else {
					// Update state
					setIsSubmitting(false);
					paymentIsProcessing.current = false;

					// Move to confirmation
					history.push({ pathname: '/make-payment/confirmation', state: { authorizationCode: data.authorizationCode } });
				}
			} else {
				setIsSubmitting(false);
				paymentIsProcessing.current = false;
				toast.error('There was an issue completing your payment. Please try again.');
				return;
			}
		} catch (e) {
			setIsSubmitting(false);
			paymentIsProcessing.current = false;
			if (e.response && e.response.data) {
				toast.error(e.response.data.message);
			} else {
				toast.error(e.message);
			}
			return;
		}
	};

	const getDate = () => {
		const s = stepData?.paymentDate;
		if (s?.date === 'anotherDate') {
			return moment(s?.otherDate).format('MM/DD/YY');
		}
		return moment(s?.immediatelyDate).format('MM/DD/YY');
	};

	useEffect(() => {
		// Set document title
		document.title = `Make Payment | ${META_TITLE}`;

		// Redirect if necessary
		if (Object.keys(stepData.paymentAmount).length === 0 || Object.keys(stepData.paymentMethod).length === 0 || Object.keys(stepData.paymentDate).length === 0) {
			history.push('/');
		}
	}, []);

	return (
		<S.Form onSubmit={onNext}>
			<MakePaymentHeader />
			<Stepper
				activeStep={3}
				steps={[
					{ id: 1, label: 'Amount' },
					{ id: 2, label: 'Payment' },
					{ id: 3, label: 'Date' },
					{ id: 4, label: 'Review' },
					{ id: 5, label: 'Confirmation' },
				]}
			/>
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Review
					</Typography>
					<S.CardsContainer>
						<Card title="">
							<S.CardInner>
								<S.CardHeader>
									<Typography tag="h5" weight="bold">
										Selected Payment Amount
									</Typography>
									<Link to="/make-payment/amount">
										<FontAwesomeIcon icon={['fal', 'edit']} />
									</Link>
								</S.CardHeader>
								<S.DivContainer>
									{getUtilityAmount() > 0 && (
										<S.AmountContainer>
											<Typography tag="h6">Utility Amount</Typography>
											<Typography tag="p" variation="1" weight="bold">
												${getUtilityAmount().toFixed(2)}
											</Typography>
										</S.AmountContainer>
									)}
									{getGreenlightAmount() > 0 && (
										<S.AmountContainer>
											<Typography tag="h6">Greenlight Amount</Typography>
											<Typography tag="p" variation="1" weight="bold">
												${getGreenlightAmount().toFixed(2)}
											</Typography>
										</S.AmountContainer>
									)}
								</S.DivContainer>
							</S.CardInner>
						</Card>

						<Card title="">
							<S.CardInner>
								<S.CardHeader>
									<Typography tag="h5" weight="bold">
										Selected Payment Method
									</Typography>
									<Link to="/make-payment/method">
										<FontAwesomeIcon icon={['fal', 'edit']} />
									</Link>
								</S.CardHeader>
								<S.DivContainer>
									<Typography tag="h6">{payMethod?.AccountName || payMethod?.AccountType}</Typography>
									{payMethod?.AccountType === 'Card' && (
										<Typography tag="p" variation="1">
											{`**** **** **** ${payMethod?.CardLast4}`}
										</Typography>
									)}
									{payMethod?.AccountType === 'ACH' && (
										<Typography tag="p" variation="1">
											ACH
										</Typography>
									)}
								</S.DivContainer>
							</S.CardInner>
						</Card>
						<Card title="">
							<S.CardInner>
								<S.CardHeader>
									<Typography tag="h5" weight="bold">
										Selected Date
									</Typography>
									<Link to="/make-payment/date">
										<FontAwesomeIcon icon={['fal', 'edit']} />
									</Link>
								</S.CardHeader>
								<S.DivContainer>
									<Typography tag="h6">Pay On</Typography>
									<Typography tag="p" variation="1" weight="bold">
										{getDate()}
									</Typography>
									{stepData?.paymentDate?.enableAutopay === true && (
										<Typography tag="p" variation="1" weight="bold" style={{ marginTop: '10px' }}>
											Enable Autopay
										</Typography>
									)}
								</S.DivContainer>
							</S.CardInner>
						</Card>
					</S.CardsContainer>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit" disabled={isSubmitting}>
					<Typography tag="span" variation="1" weight="extrablack">
						Complete Payment
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
