import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useHistory } from 'react-router-dom';

import logo from '../../../assets/wilson-logo.png';
import { openMenu, closeMenu } from '../../../app/slices/menu/menuSlice';
import { IconButton } from '../../IconButton';
import { NotificationPanel } from '../../NotificationPanel';
import * as S from './Topbar.styles';
import { AccountSwitcher } from './AccountSwitcher';
import { ProfileOptions } from '../ProfileOptions';

export const Topbar = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [notificationPanelOpen, setNotificationPanelOpen] = useState(false);
	const opened = useSelector((state) => state.menu.opened);
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	function handleNotificationPanelOpen() {
		setNotificationPanelOpen((prev) => !prev);
	}

	return (
		<S.Topbar>
			<S.NavigationWrapper>
				<S.Wrapper>
					{!isHigherThan768 && <IconButton size={1.25} icon={['fal', 'bars']} onClick={() => (opened ? dispatch(closeMenu()) : dispatch(openMenu()))} />}
					{isHigherThan768 && <AccountSwitcher />}
				</S.Wrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
				<S.Wrapper alignEnd>
					<IconButton
						size={1.3}
						icon={['fal', 'question-circle']}
						onClick={() => {
							history.push('/support');
						}}
					/>
					<IconButton size={isHigherThan768 ? 1.5 : 1.25} icon={['fal', 'bell']} onClick={handleNotificationPanelOpen} />
					<ProfileOptions />
				</S.Wrapper>

				<S.Modal opened={notificationPanelOpen}>
					<NotificationPanel />
				</S.Modal>
			</S.NavigationWrapper>
			{!isHigherThan768 && (
				<S.AccountBar>
					<AccountSwitcher />
				</S.AccountBar>
			)}
		</S.Topbar>
	);
};
