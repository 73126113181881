import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: white;
	box-shadow: ${colors.defaultShadow};
	width: 100%;
	z-index: 1;
	min-height: 5rem;
	height: 5rem;
	padding: 0 1rem;
`;

export const LeftContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: left;
`;

export const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const RightContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: right;
`;

export const Image = styled.img`
	width: 100%;
	max-width: 4.6875rem;
`;
