import toast from 'react-hot-toast';

import api from './api';

export const errorHandler = (error) => {
	toast.error(error.message || 'Something went wrong, try again later!');
};

const PORTAL_USER = 'Portal_User_';

//================Wallet CRUD====================

export async function walletAddAccount({ accountType, accountName, accountToken, cardCardholderName, cardExpirationDate, cardLast4, cardModifier, cardClass, cardBrand, ACHRoutingNumber, ACHAccountNumber }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, WalletAccountCreated, WalletAccountKey, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Wallet_Add_Account`, {
		AccountType: accountType,
		AccountName: accountName,
		AccountToken: accountToken,
		CardCardholderName: cardCardholderName,
		CardExpirationDate: cardExpirationDate,
		CardLast4: cardLast4,
		CardModifier: cardModifier,
		CardClass: cardClass,
		CardBrand: cardBrand,
		ACHRoutingNumber,
		ACHAccountNumber,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!WalletAccountCreated) {
		throw new Error('Error creating new account');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return WalletAccountKey;
	}
}

export async function walletUpdateAccount({ walletAccountKey, accountName, accountToken, cardCardholderName, cardExpirationDate, cardLast4, cardModifier, cardClass, cardBrand, ACHRoutingNumber, ACHAccountNumber }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, WalletAccountKeyValid, WalletAccountUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Wallet_Update_Account`, {
		WalletAccountKey: walletAccountKey,
		AccountName: accountName,
		AccountToken: accountToken,
		CardCardholderName: cardCardholderName,
		CardExpirationDate: cardExpirationDate,
		CardLast4: cardLast4,
		CardModifier: cardModifier,
		CardClass: cardClass,
		CardBrand: cardBrand,
		ACHRoutingNumber,
		ACHAccountNumber,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!WalletAccountKeyValid) {
		throw new Error('Invalid wallet account key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return WalletAccountUpdated;
	}
}

export async function walletRemoveAccount({ walletKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, WalletAccountKeyValid, WalletAccountRemoved, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Wallet_Remove_Account`, {
		WalletAccountKey: walletKey,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!WalletAccountKeyValid) {
		throw new Error('Invalid wallet account key');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return WalletAccountRemoved;
	}
}

export async function walletRetrieveAccounts() {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, WalletAccounts, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Wallet_Retrieve_Accounts`, {
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return WalletAccounts || [];
	}
}

export async function walletLogTransactions({ walletAccountKey, payments }) {
	const token = window.localStorage.getItem('wilsonnc_token');

	// Initialize state
	let paymentsLogged = false;

	// Log each payment
	for (let i = 0; i < payments.length; i += 1) {
		const { AccountKey, PaymentAmount, ConfirmationInfo, EffectivePaymentDate } = payments[i];
		const {
			data: { TokenValid, WalletAccountKeyValid, PaymentLogged, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Wallet_Log_Transaction`, {
			...(walletAccountKey != null
				? {
						WalletAccountKey: walletAccountKey,
				  }
				: undefined),
			AccountKey,
			PaymentAmount,
			ConfirmationInfo,
			EffectivePaymentDate,
			LoginToken: token,
		});
		if (TokenValid === false) {
			window.location = '/logout';
		} else if (!WalletAccountKeyValid) {
			throw new Error('Invalid wallet account key.');
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else if (paymentsLogged) {
			paymentsLogged = PaymentLogged;
		}
	}

	// Return state
	return paymentsLogged;
}

export async function walletTransactionLog({ startDate, endDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Payments, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Retrieve_Transaction_Log`, {
		StartDate: startDate || '1/1/1900',
		EndDate: endDate || '1/1/3000',
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Payments || [];
	}
}

export async function retrieveScheduledPayments() {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Payments, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Scheduled_Payments`, {
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Payments || [];
	}
}

export async function addScheduledPayment({ paymentType, walletAccountKey, accountKey, paymentAmount, scheduledPaymentDate }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, WalletAccountKeyValid, AccountKeyValid, PaymentScheduled, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Scheduled_Payment_Add`, {
		PaymentType: paymentType,
		WalletAccountKey: walletAccountKey,
		AccountKey: accountKey,
		PaymentAmount: paymentAmount,
		ScheduledPaymentDate: scheduledPaymentDate,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!WalletAccountKeyValid) {
		throw new Error('Invalid wallet account key.');
	} else if (!AccountKeyValid) {
		throw new Error('Invalid account key.');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return PaymentScheduled;
	}
}

export async function cancelScheduledPayment({ scheduledPaymentKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, ScheduledPaymentKeyValid, PaymentCanceled, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Scheduled_Payment_Cancel`, {
		ScheduledPaymentKey: scheduledPaymentKey,
		LoginToken: token,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (!ScheduledPaymentKeyValid) {
		throw new Error('Invalid scheduled payment key.');
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return PaymentCanceled;
	}
}
