import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Typography, Badge, Button, StatementOverview, StatementTable, Spinner, ErrorComponent } from '../../components';
import * as S from './StatementInvoiceDetail.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { META_TITLE } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { retrieveAccountBillHistory, retrieveAccountBillPdf } from '../../services/userService';
import { formatDate } from '../../utils/time-formatter';

const BADGE_LABELS = {
	unpaid: { label: 'Unpaid', type: 'danger' },
	paid: { label: 'Paid', type: 'success' },
	pending: { label: 'Pending', type: 'warning' },
};

export default function StatementInvoiceDetail() {
	const { accountKey, id } = useParams();
	const [status, setStatus] = useState('loading');
	const [invoice, setInvoice] = useState({});
	const currentLocation = useSelector((state) => state.location.value);
	const history = useHistory();

	// Get account data for key
	let accountData = null;
	if (currentLocation?.UtilityAccount != null && currentLocation?.UtilityAccount?.AccountKey === accountKey) {
		accountData = {
			...currentLocation?.UtilityAccount,
			type: 'Utilities',
		};
	}
	if (accountData == null && currentLocation?.GreenlightAccount != null && currentLocation?.GreenlightAccount?.AccountKey === accountKey) {
		accountData = {
			...currentLocation?.GreenlightAccount,
			type: 'Greenlight',
		};
	}

	// If no account data, return to statements
	if (accountData == null) {
		history.push('/statements');
	}

	async function init() {
		try {
			const bills = await retrieveAccountBillHistory({ accountKey });
			let matchingInvoice = bills.find((bill) => bill.BillID.toString() === id.toString());
			if (matchingInvoice != null) {
				matchingInvoice.status = matchingInvoice.PaidStatus ? matchingInvoice.PaidStatus : '';
				if (matchingInvoice.IsBillPDFAvailable === true) {
					try {
						const pdfData = await retrieveAccountBillPdf({ accountKey, billId: parseInt(id) });
						if (pdfData != null) {
							matchingInvoice.pdfData = pdfData;
						}
					} catch (e) {
						toast.error(e.message);
					}
				}
				setInvoice(matchingInvoice);
				setStatus('success');
			} else {
				setStatus('error');
			}
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Invoice Statement | ${META_TITLE}`;

		// Init data
		init();
	}, []);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		} else if (status === 'error') {
			return <ErrorComponent />;
		}
		return (
			<>
				<S.PageHeader>
					<S.BackLink>
						<Link to="/statements">
							<S.LinkText>
								<Button
									variant="text"
									onClick={() => {
										history.push('/statements');
									}}
								>
									<FontAwesomeIcon icon={['fal', 'arrow-left']} spacing="1" />
									<Typography variation="2" weight="extrablack">
										Return to Statements
									</Typography>
								</Button>
							</S.LinkText>
						</Link>
					</S.BackLink>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold">
							Invoice for {formatDate(invoice.BillDate)}
						</Typography>
					</S.TitleWrapper>
					{invoice.status && <Badge type={BADGE_LABELS[invoice.status].type}>{BADGE_LABELS[invoice.status].label}</Badge>}
				</S.PageHeader>
				<StatementOverview className="a1" invoice={invoice} accountData={accountData} />
				<StatementTable className="a1" invoice={invoice} accountData={accountData} />
			</>
		);
	};

	return (
		<>
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</>
	);
}
