import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const MainContainer = styled.div`
	margin-top: 2rem;
`;

export const Service = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.25rem 1.75rem;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.25rem;
	margin-bottom: 0.9rem;
`;

export const AddSymbol = styled.div`
	display: inline-flex;
	font-size: 2.5rem;
	cursor: pointer;

	.checkCircleIcon {
		color: ${colors.brandPrimary};
	}

	.minusCircleIcon {
		color: ${colors.statusBad};
	}
`;
