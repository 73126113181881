import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { Button, OnboardingFooter, Typography, MakePaymentHeader } from '../../components';
import * as S from './AddGreenlightReview.styles';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Manage_Greenlight, Manage_Greenlight_Settings, META_TITLE } from '../../utils/constants';
import CustomerResidentialGreenlightReviewCard from '../../components/CustomerResidentialGreenlightReviewCard/CustomerResidentialGreenlightReviewCard';
import CustomerCommercialGreenlightReviewCard from '../../components/CustomerCommercialGreenlightReviewCard/CustomerCommercialGreenlightReviewCard';
import { submitUserRequest } from '../../services/userService';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

export default function AddGreenlightReview() {
	const currentLocation = useSelector((state) => state.location.value);

	const isLowerThan768 = useMediaQuery('(max-width: 768px)');
	const history = useHistory();

	const [greenLightData, setGreenLightData] = useState({});
	const [loading, setLoading] = useState(false);

	const finalApiCalls = async () => {
		try {
			const { requestCreated } = await submitUserRequest({
				requestType: 2,
				requestDescription: JSON.stringify({
					...greenLightData,
					locationId: currentLocation?.LocationID,
				}),
			});
			if (requestCreated) {
				toast.success('Your Greenlight request has been submitted.');
			} else {
				toast.success('There was an issue submitting your request. Please try again.');
			}
		} catch (e) {
			toast.error(e.message);
		}
	};

	const onBack = () => {
		history.push('/manage-greenlight/settings');
	};

	const onNext = async () => {
		setLoading(true);
		await finalApiCalls();
		setLoading(false);
		localStorage.removeItem(Manage_Greenlight_Settings);
		localStorage.removeItem(Manage_Greenlight);
		history.push('/services');
	};

	useEffect(() => {
		// Set document title
		document.title = `Manage Greenlight | ${META_TITLE}`;

		let tempGreenSetting = localStorage.getItem(Manage_Greenlight_Settings);
		tempGreenSetting = tempGreenSetting && JSON.parse(tempGreenSetting);
		if (tempGreenSetting?.greenlightStartDate) {
			tempGreenSetting = tempGreenSetting?.greenlightStartDate;
		}
		let tempGreenLight = localStorage.getItem(Manage_Greenlight);
		tempGreenLight = tempGreenLight && JSON.parse(tempGreenLight);
		if (tempGreenLight) {
			tempGreenLight.utilityStartDate = tempGreenSetting;
			setGreenLightData(tempGreenLight);
		}
	}, []);

	return (
		<S.Form>
			<MakePaymentHeader />
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Review Changes
					</Typography>
					{greenLightData?.isGreenlightInternetService === 'Yes' && (greenLightData?.type === 'residential' ? <CustomerResidentialGreenlightReviewCard greenLightData={greenLightData} /> : <CustomerCommercialGreenlightReviewCard greenLightData={greenLightData} />)}
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit" disabled={loading} onClick={onNext}>
					<Typography tag="span" variation="1" weight="extrablack">
						Submit Changes
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
