import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Table, PhoneCell } from '../Table';
import { useHistory } from 'react-router-dom';
import { EmptyComponent } from '../EmptyComponent';
import { Spinner } from '../Spinner';

export const UnassociatedAccounts = ({ className }) => {
	const history = useHistory();
	const [status, setStatus] = useState('loading');
	const [accounts, setAccounts] = useState([]);

	const [columns] = useState([
		{
			Header: 'First Name',
			accessor: 'firstName',
		},
		{
			Header: 'Last Name',
			accessor: 'lastName',
		},
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Phone',
			accessor: 'phone',
			Cell: PhoneCell,
			CellName: 'PhoneCell',
		},
		{
			Header: 'Address Accounts',
			accessor: 'addressAccounts',
		},
	]);

	function navigateTo(item) {
		history.push(`/users/${encodeURIComponent(item.email)}/unassociated`);
	}

	async function init() {
		try {
			// NV - ADMIN - REQUIRES API CHANGE
			setAccounts([]);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, []);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (accounts.length === 0) {
			return <EmptyComponent icon={['fal', 'user-circle']} title="No Unassociated Accounts" description="Check back to view unassociated accounts." />;
		}
		return <Table columns={columns} data={accounts} handleClick={navigateTo} />;
	};

	return (
		<Card className={className} title="Unassociated Accounts">
			{renderContent()}
		</Card>
	);
};
UnassociatedAccounts.propTypes = {
	className: PropTypes.string,
};
