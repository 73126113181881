import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './PersonalDetails.styles';
import { formatToUSPhoneNumber } from '../../utils/phone-formatter';
import { Controller, useForm } from 'react-hook-form';
import { updateUserProfile } from '../../services/userService';
import toast from 'react-hot-toast';
import { Modal } from '../Modal';
import { TextInput } from '../TextInput';
import { Select } from '../Select';
import { Button } from '../Button';
import { PLACEHOLDER_USER_DETAILS, State_Options } from '../../utils/constants';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import { fetchUser } from '../../app/slices/user/userSlice';

export const PersonalDetails = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const [editIsOpen, setEditIsOpen] = useState(false);
	const dispatch = useDispatch();

	const {
		handleSubmit,
		register,
		control,
		formState: { errors, isSubmitting },
		reset,
	} = useForm({
		defaultValues: {
			name: user?.name,
			altEmail: user?.altEmail,
			phone: user?.phone,
			mailingAddress: user?.mailingAddress,
			mailingCity: user?.mailingCity,
			mailingState: user?.mailingState,
			mailingZip: user?.mailingZip,
		},
	});

	async function onSubmit(data) {
		try {
			const updated = await updateUserProfile({
				name: data.name,
				altEmail: data.altEmail,
				phone: data.phone,
				mailingAddress: data.mailingAddress,
				mailingCity: data.mailingCity,
				mailingState: data.mailingState,
				mailingZip: data.mailingZip,
			});
			if (updated) {
				dispatch(fetchUser({ placeholderUser: PLACEHOLDER_USER_DETAILS }));
				setEditIsOpen(false);
				toast.success('Successfully updated your profile.');
			} else {
				toast.error('There was an issue updating your profile. Please try again.');
				reset(undefined, { keepValues: true });
			}
		} catch (e) {
			toast.error(e.message);
			reset(undefined, { keepValues: true });
		}
	}

	useEffect(() => {
		if (editIsOpen) {
			reset();
		}
	}, [editIsOpen]);

	return (
		<>
			<Card
				className={className}
				title="Personal Details"
				action={[
					{
						id: 1,
						onClick: () => {
							setEditIsOpen(true);
						},
						icon: ['fal', 'edit'],
						label: 'Edit',
						type: 'outline',
					},
				]}
			>
				<S.Wrapper>
					{user?.name && (
						<S.ItemWrapper>
							<Typography tag="h6" weight="semibold">
								Name
							</Typography>
							<Typography tag="p">{user?.name}</Typography>
						</S.ItemWrapper>
					)}
					{(user?.email || user?.altEmail) && (
						<S.ItemWrapper>
							<Typography tag="h6" weight="semibold">
								Email
							</Typography>
							<Typography tag="p">{user?.email || user?.altEmail}</Typography>
						</S.ItemWrapper>
					)}
					{user?.phone && (
						<S.ItemWrapper>
							<Typography tag="h6" weight="semibold">
								Phone
							</Typography>
							<Typography tag="p">{formatToUSPhoneNumber(user?.phone)}</Typography>
						</S.ItemWrapper>
					)}
					{user?.mailingAddress && (
						<S.ItemWrapper>
							<Typography tag="h6" weight="semibold">
								Mailing Address
							</Typography>
							<Typography tag="p">{`${user?.mailingAddress}, ${user?.mailingCity}, ${user?.mailingState} ${user?.mailingZip}`}</Typography>
						</S.ItemWrapper>
					)}
				</S.Wrapper>
			</Card>

			{/* Edit Profile Modal */}
			<Modal shouldCloseOnOverlayClick allowScroll isOpen={editIsOpen} onRequestClose={() => setEditIsOpen(false)}>
				<S.ModalWrapper>
					<S.TitleWrapper>
						<Typography tag="h1" weight="bold" center>
							Update Profile
						</Typography>
					</S.TitleWrapper>
					<S.Form onSubmit={handleSubmit(onSubmit)}>
						<TextInput
							label="Name"
							id="name"
							placeholder="e.g. Justin Case"
							error={errors.name}
							{...register('name', {
								required: required('Name'),
							})}
						/>
						<TextInput
							label="Alternative Email"
							id="altEmail"
							type="email"
							placeholder="e.g. justin@gmail.com"
							error={errors.altEmail}
							{...register('altEmail', {
								pattern: pattern('Alternative Email', EMAIL),
							})}
						/>
						<TextInput
							label="Phone Number"
							id="phone"
							placeholder="e.g. 444-444-4444"
							error={errors.phone}
							{...register('phone', {
								required: required('Phone Number'),
							})}
						/>
						<TextInput
							label="Mailing Street Address"
							id="mailingAddress"
							placeholder="e.g. 123 Acme Ave"
							error={errors.mailingAddress}
							{...register('mailingAddress', {
								required: required('Mailing Street Address'),
							})}
						/>
						<TextInput
							label="Mailing Address City"
							id="mailingCity"
							placeholder="e.g. Wilson"
							error={errors.mailingCity}
							{...register('mailingCity', {
								required: required('Mailing Address City'),
							})}
						/>
						<Controller
							name="mailingState"
							control={control}
							rules={{ required: required('Mailing Address State') }}
							render={({ field: { onChange, value } }) => <Select label="Mailing Address State" id="mailingState" error={errors.mailingState} options={State_Options} value={State_Options.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
						/>
						<TextInput
							label="Mailing Address Zipcode"
							id="mailingZip"
							placeholder="e.g. 27822"
							error={errors.mailingZip}
							{...register('mailingZip', {
								required: required('Mailing Address Zipcode'),
							})}
						/>
						<S.FormButtonWrapper>
							<Button variant="outline" variation="secondary" type="button" onClick={() => setEditIsOpen(false)}>
								<Typography tag="span" variation="1" weight="extrablack">
									Cancel
								</Typography>
							</Button>
							<Button>
								<Typography tag="span" variation="1" weight="extrablack">
									{isSubmitting ? 'Loading...' : 'Update Profile'}
								</Typography>
							</Button>
						</S.FormButtonWrapper>
					</S.Form>
				</S.ModalWrapper>
			</Modal>
		</>
	);
};
PersonalDetails.propTypes = {
	className: PropTypes.string,
};
