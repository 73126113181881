import toast from 'react-hot-toast';

import api from './api';

export const errorHandler = (error) => {
	toast.error(error.message || 'Something went wrong, try again later!');
};

const PORTAL_USER = 'Portal_User_';

//================Accounts CRUD====================

export async function searchAccounts({ lastName = null, businessName = null, customerId = null, customerSsn = null }) {
	try {
		const {
			data: { UnassignedGreenlightAccounts, UnassignedUtilityAccounts, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Find_Accounts`, { LastName: lastName ? lastName : undefined, BusinessName: businessName ? businessName : undefined, CustomerAccountID: customerId ? customerId : undefined, CustomerSSN: customerSsn ? parseInt(`${customerSsn}`, 10) : undefined });
		if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return {
				unassignedUtilityAccounts: UnassignedUtilityAccounts,
				unassignedGreenlightAccounts: UnassignedGreenlightAccounts,
			};
		}
	} catch (error) {
		throw error;
	}
}

export async function addAccount({ locationId, accountType, accountNumber }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, AccountKey, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Add_Account`, { LoginToken: token, LocationID: parseInt(`${locationId}`, 10), AccountNumber: accountNumber, AccountType: parseInt(`${accountType}`, 10) });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return {
				accountKey: AccountKey,
			};
		}
	} catch (error) {
		throw error;
	}
}

export async function removeAccount({ locationID, accountKey, reason }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { Success, TokenValid, AccountKeyValid, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Remove_Account`, { LoginToken: token, LocationID: locationID, AccountNumber: accountKey, Reason: reason });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return {
				Success,
				accountKeyValid: AccountKeyValid,
			};
		}
	} catch (error) {
		throw error;
	}
}

export async function retrieveAccountSummary(shouldRedirect = true) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { HasErrors, ErrorMsg, TokenValid, Locations },
		} = await api.post(`${PORTAL_USER}Retrieve_Account_Summary`, { LoginToken: token });

		if (TokenValid === false) {
			if (shouldRedirect === true) {
				window.location = '/logout';
			} else {
				throw new Error('Authentication token is invalid');
			}
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return Locations;
		}
	} catch (error) {
		throw error;
	}
}

export async function retrieveAccountPaymentSummary(shouldRedirect = true) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { HasErrors, ErrorMsg, TokenValid, Payments },
		} = await api.post(`${PORTAL_USER}Retrieve_Accounts`, { LoginToken: token });

		if (TokenValid === false) {
			if (shouldRedirect === true) {
				window.location = '/logout';
			} else {
				throw new Error('Authentication token is invalid');
			}
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return Payments;
		}
	} catch (error) {
		throw error;
	}
}

export async function retrieveAccountBillHistory({ accountKey }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, HasErrors, ErrorMsg, Bills },
		} = await api.post(`${PORTAL_USER}Retrieve_Account_Bill_History`, { LoginToken: token, AccountKey: accountKey });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return Bills;
		}
	} catch (error) {
		throw error;
	}
}

export async function retrieveAccountBillPdf({ accountKey, billId }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, AccountKeyValid, BillIDValid, BillPDF, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Retrieve_Account_Bill_PDF`, { LoginToken: token, AccountKey: accountKey, BillID: parseInt(`${billId}`, 10) });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else if (!AccountKeyValid) {
			throw new Error('Your account key is invalid.');
		} else if (!BillIDValid) {
			throw new Error('Your bill id is invalid.');
		} else {
			return BillPDF ? BillPDF : null;
		}
	} catch (error) {
		throw error;
	}
}

export async function updateAccountAutopay({ accountKey, autopayStatus, walletAccount }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, AccountKeyValid, AutoPayUpdated, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Update_Auto_Pay`, { LoginToken: token, AccountKey: accountKey, AutoPayStatus: autopayStatus, WalletAccountKey: walletAccount });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else if (!AccountKeyValid) {
			throw new Error('Your account key is invalid.');
		} else {
			return AutoPayUpdated;
		}
	} catch (error) {
		throw error;
	}
}

export async function addressLookup({ searchFor, maxCandidates = 5 }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { AddressCandidates, TokenValid, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Address_Lookup`, { LoginToken: token, SearchFor: searchFor, MaxCandidates: maxCandidates });
		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else {
			return AddressCandidates;
		}
	} catch (error) {
		throw error;
	}
}

export async function getUserPaperlessSettings() {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, PaperlessStatus },
	} = await api.get(`${PORTAL_USER}Paperless/${token}`);

	if (TokenValid === false) {
		window.location = '/logout';
	} else {
		return {
			PaperlessStatus,
		};
	}
}

export async function updateUserPaperlessSettings({ status }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, PaperlessStatusChanged, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Paperless`, {
		LoginToken: token,
		PaperlessStatus: status,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return PaperlessStatusChanged;
	}
}

// ========================Usage History===================

export async function retrieveDailyUsageHistory({ accountKey, usageDays }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, AccountKeyValid, HasErrors, ErrorMsg, Meters },
		} = await api.post(`${PORTAL_USER}Retrieve_Daily_Usage_History`, { LoginToken: token, AccountKey: accountKey, UsageDays: usageDays });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else if (!AccountKeyValid) {
			throw new Error('Your account key is invalid.');
		} else {
			return Meters;
		}
	} catch (error) {
		throw error;
	}
}

export async function retrieveDailyUsageHistoryWithRange({ accountKey, fromDate, toDate }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, AccountKeyValid, HasErrors, ErrorMsg, Meters },
		} = await api.post(`${PORTAL_USER}Retrieve_Daily_Usage_History2`, { LoginToken: token, AccountKey: accountKey, FromDate: fromDate, ToDate: toDate });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else if (!AccountKeyValid) {
			throw new Error('Your account key is invalid.');
		} else {
			return Meters;
		}
	} catch (error) {
		throw error;
	}
}

export async function retrieveUsageDetail({ accountKey, usageDate, meterNumber }) {
	try {
		const token = window.localStorage.getItem('wilsonnc_token');
		const {
			data: { TokenValid, AccountKeyValid, MeterNumberValid, Interval, Units, Intervals, HasErrors, ErrorMsg },
		} = await api.post(`${PORTAL_USER}Retrieve_Usage_Detail`, { LoginToken: token, AccountKey: accountKey, UsageDate: usageDate, MeterNumber: meterNumber });

		if (TokenValid === false) {
			window.location = '/logout';
		} else if (HasErrors) {
			throw new Error(ErrorMsg);
		} else if (!AccountKeyValid) {
			throw new Error('Your account key is invalid.');
		} else if (!MeterNumberValid) {
			throw new Error('Your meter number is invalid.');
		} else {
			return {
				Interval,
				Units,
				Intervals,
			};
		}
	} catch (error) {
		throw error;
	}
}

// ===============Articles====================

export async function getCurrentArticles() {
	const {
		data: { Articles, HasErrors, ErrorMsg, ...rest },
	} = await api.get(`${PORTAL_USER}Current_Articles`);
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Articles || [];
	}
}

// ===============Resources====================

export async function getCurrentResources() {
	const {
		data: { Resources, HasErrors, ErrorMsg },
	} = await api.get(`${PORTAL_USER}Current_Resources`);
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Resources || [];
	}
}

// ===============Community Events====================

export async function getCurrentCommunityEvents() {
	const {
		data: { CommunityEvents, HasErrors, ErrorMsg },
	} = await api.get(`${PORTAL_USER}Current_Community_Events`);
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return CommunityEvents || [];
	}
}

// ==============Notifications====================

export async function getUserNotificationSettings() {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, DailyConsumption, HighUsage, WaterLeak, HasErrors, ErrorMsg },
	} = await api.get(`${PORTAL_USER}Notification_Settings/${token}`);

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return {
			DailyConsumption,
			HighUsage,
			WaterLeak,
		};
	}
}

export async function updateUserNotificationSettings({ dailyConsumption, highUsage, waterLeak }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Updated, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Notification_Settings`, {
		LoginToken: token,
		DailyConsumption: dailyConsumption,
		HighUsage: highUsage,
		WaterLeak: waterLeak,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Updated;
	}
}

// =================User Profile==================

export async function getUserProfile(tok, shouldRedirect = true) {
	const token = tok || window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Name, PrimaryEmail, AltEmail, Phone, MailingAddress, MailingCity, MailingState, MailingZip, HasErrors, ErrorMsg },
	} = await api.get(`${PORTAL_USER}Profile/${token}`);

	if (TokenValid === false) {
		if (shouldRedirect === true) {
			window.location = '/logout';
		} else {
			throw new Error('Authentication token is invalid');
		}
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return {
			name: Name,
			email: PrimaryEmail,
			primaryEmail: PrimaryEmail,
			altEmail: AltEmail,
			phone: Phone,
			mailingAddress: MailingAddress,
			mailingCity: MailingCity,
			mailingState: MailingState,
			mailingZip: MailingZip,
		};
	}
}

export async function updateUserProfile({ name, altEmail, phone, mailingAddress, mailingCity, mailingState, mailingZip }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Updated, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Profile`, {
		LoginToken: token,
		Name: name,
		AltEmail: altEmail,
		Phone: phone,
		MailingAddress: mailingAddress,
		MailingCity: mailingCity,
		MailingState: mailingState,
		MailingZip: mailingZip,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Updated;
	}
}

// ================User Requests================

export async function submitUserRequest({ requestType, requestDescription }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, RequestCreated, RequestKey, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Submit_Request`, {
		LoginToken: token,
		RequestType: parseInt(`${requestType}`, 10),
		RequestDescription: requestDescription,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return {
			requestCreated: RequestCreated,
			requestKey: RequestKey,
		};
	}
}

export async function retrieveUserRequests({ isOpen }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, Requests, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Retrieve_Requests`, {
		LoginToken: token,
		IsOpen: isOpen,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return Requests;
	}
}

// =================User Request Messages================

export async function userSubmitRequestMessage({ requestKey, messageText }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, RequestKeyValid, MessageSubmitted, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Submit_Request_Message`, {
		LoginToken: token,
		RequestKey: requestKey,
		MessageText: messageText,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return {
			requestKeyValid: RequestKeyValid,
			messageSubmitted: MessageSubmitted,
		};
	}
}

// =================User Mark Message Read================

export async function userMarkMessageRead({ messageKey }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, MessageKeyValid, ReadStatusUpdated, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Mark_Message_Read`, {
		LoginToken: token,
		MessageKey: messageKey,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!MessageKeyValid) {
		throw new Error('Invalid Message Key');
	} else {
		return ReadStatusUpdated;
	}
}

// =================User Submit Request Document================

export async function userSubmitRequestDoc({ requestKey, documentType, mimeType, document }) {
	const token = window.localStorage.getItem('wilsonnc_token');
	const {
		data: { TokenValid, RequestKeyValid, DocumentUploaded, HasErrors, ErrorMsg },
	} = await api.post(`${PORTAL_USER}Submit_Request_Document`, {
		LoginToken: token,
		RequestKey: requestKey,
		DocumentType: parseInt(`${documentType}`, 10),
		MimeType: mimeType,
		Document: document,
	});

	if (TokenValid === false) {
		window.location = '/logout';
	} else if (HasErrors) {
		throw new Error(ErrorMsg);
	} else if (!RequestKeyValid) {
		throw new Error('Invalid Request Key');
	} else {
		return DocumentUploaded;
	}
}
