import styled from 'styled-components';

import colors from '../../styles/colors';

export const Item = styled.button`
	appearance: none;
	background-color: transparent;
	border: none;
	color: black;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: 0.5rem 0;
	text-align: left;

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		border: none;
		padding-bottom: 0;
	}

	&:hover {
		opacity: 0.8;
	}
`;
