import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { required } from '../../utils/form-default-errors';

import * as S from './CancelScheduledPaymentModal.styles';

import { Button, Modal, Typography } from '..';
import { cancelScheduledPayment } from '../../services/walletService';
import toast from 'react-hot-toast';

const CancelScheduledPaymentModal = ({ isOpen, afterSubmit, onRequestClose, scheduledPayment }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const cancelPayment = async () => {
		setIsSubmitting(true);
		try {
			const paymentCanceled = await cancelScheduledPayment({ scheduledPaymentKey: scheduledPayment });
			setIsSubmitting(false);
			if (paymentCanceled) {
				if (afterSubmit) afterSubmit();
				toast.success('Your scheduled payment method has successfully been canceled.');
				onRequestClose();
			} else {
				toast.error('There was an issue canceling your scheduled payment. Please try again.');
			}
		} catch (e) {
			setIsSubmitting(false);
			toast.error(e.message);
		}
	};

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onRequestClose}>
			<S.ModalWrapper>
				<S.TitleWrapper>
					<Typography tag="h1" weight="bold" center>
						Cancel Scheduled Payment
					</Typography>
					<Typography tag="p" center>
						Are you sure you want to cancel this scheduled payment? This cannot be undone.
					</Typography>
				</S.TitleWrapper>
				<S.FormButtonWrapper>
					<Button variant="outline" variation="secondary" type="button" onClick={onRequestClose}>
						<Typography tag="span" variation="1" weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button variation="warning" onClick={cancelPayment}>
						<Typography tag="span" variation="1" weight="extrablack">
							{isSubmitting ? 'Loading...' : 'Cancel Payment'}
						</Typography>
					</Button>
				</S.FormButtonWrapper>
			</S.ModalWrapper>
		</Modal>
	);
};

export default CancelScheduledPaymentModal;

CancelScheduledPaymentModal.propTypes = {
	isOpen: PropTypes.bool,
	afterSubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
	scheduledPayment: PropTypes.string,
};
