import styled from 'styled-components';
import colors from '../../styles/colors';

export const StatementSection = styled.div`
	margin-top: 2.125rem;
`;

export const TableHeader = styled.div`
	height: 2.1875rem;
	background: ${colors.neutralBackground};
	color: ${colors.neutralText};
	padding: 0.5625rem;
`;

export const TableBody = styled.div`
	border-bottom: 0.0625rem solid ${colors.neutralForm};
`;

export const TableHeading = styled.div`
	margin-top: 0.875rem;
	margin-bottom: -0.8125rem;
`;

export const TableHeading1 = styled.div`
	margin-top: 0.875rem;
	margin-bottom: -0.8125rem;
`;

export const TableRow = styled.div`
	margin: 1.375rem 0;
`;
export const TableRow1 = styled.div`
	margin-top: 1.375rem;
`;

export const ConsumptionCol = styled.div`
	padding-left: 0.3125rem;
`;
export const SubTotal = styled.div`
	margin-left: -5.4375rem;
	padding-right: 2.0625rem;
`;

export const TaxWrapper = styled.div`
	display: flex;
	margin-left: -6.375rem;
	border-bottom: 0.0625rem solid ${colors.neutralForm};
	padding-bottom: 0.75rem;
`;

export const Tax = styled.div`
	padding-right: 4.5rem;
`;

export const Total = styled.div`
	margin-left: -5.4375rem;
	padding-right: 3.8125rem;
`;
