import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './Radio.styles';

export const Radio = forwardRef(({ id, checked, label, ...rest }, ref) => {
	return (
		<S.Wrapper>
			<S.Radio ref={ref} id={id} {...rest} checked={checked} type="radio" aria-labelledby={id ? `${id}-label` : null} />
			{label && (
				<label htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					<Typography>{label}</Typography>
				</label>
			)}
		</S.Wrapper>
	);
});

Radio.displayName = 'Radio';
Radio.propTypes = {
	checked: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	id: PropTypes.string,
};
