import React, { useEffect } from 'react';

import { PageHeader, ResourcesPanel } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './Articles.styles';

export default function Articles() {
	useEffect(() => {
		// Set document title
		document.title = `Articles | ${META_TITLE}`;
	}, []);

	return (
		<>
			<S.Wrapper>
				<PageHeader>Articles</PageHeader>
				<ResourcesPanel type="articles" />
			</S.Wrapper>
		</>
	);
}
