import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const MeterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.25rem 1.75rem;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.25rem;
	margin-top: 0.9rem;

	h3 {
		flex-grow: 1;
		margin-left: 8px;
	}
`;

export const AddSymbol = styled.div`
	display: inline-flex;
	font-size: 2.5rem;
	cursor: pointer;

	.checkCircleIcon {
		color: ${colors.brandPrimary};
	}
`;

export const NonMeteredUtilitiesContainer = styled.div`
	border-top: 1px solid ${colors.neutralForm};
	margin-top: 2rem;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
`;

export const NonMeteredUtility = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1.5rem;

	svg {
		font-size: 2.5rem;
		color: ${colors.statusGood};
		margin-right: 0.7rem;
	}
	h3 {
		margin-left: 8px;
	}
`;

export const Card2ParaContainer = styled.div`
	margin-top: 1.125rem;
	margin-bottom: 0.75rem;
`;

export const RadioContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 0.6rem;

	& > div {
		display: flex;
		align-items: center;

		label {
			margin-bottom: 0;
			cursor: pointer;
		}
	}

	& > span {
		margin-left: 1.5rem;
		margin-top: 6px;
	}
`;

export const DatePickerContainer = styled.div`
	max-width: 20rem;
	margin-bottom: 2.365rem;
	margin-top: 1.25rem;

	label {
		font-weight: 600;
		color: ${colors.formLabel};
	}

	@media screen and (max-width: 350px) {
		max-width: 15rem;
		input {
			padding-right: 0;
		}
	}
`;

export const CheckboxesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1.25rem;

	& > div {
		margin-top: 15px;
	}
`;

export const UtilityIcon = styled.div`
	background-image: ${(props) => `url(${props.image})`};
	background-position: center;
	background-size: auto 100%;
	background-repeat: no-repeat;
	width: 45px;
	height: 45px;
`;

export const ApplicationTypeSection = styled.div`
	margin: 5px 0 25px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
