import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const PictureWrapper = styled.button`
	cursor: pointer;
	appearance: none;
	background-color: transparent;
	border: none;
`;

export const Picture = styled.picture`
	display: none;
	padding: 1em 3em 0 3em;

	img {
		width: 100%;
		object-fit: contain;
	}

	@media screen and (min-width: 768px) {
		display: block;
	}
`;

export const Sidebar = styled.aside`
	display: flex;
	flex-direction: column;
	width: 0%;
	max-width: 12.5em;
	background-color: ${colors.brandPrimary};
	box-shadow: ${colors.sidebarShadow};
	overflow-x: auto;
	position: absolute;
	height: inherit;
	z-index: 3;
	transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
	padding: 110px 0px 0px;

	& > * + * {
		margin-top: 1em;
	}

	${({ opened }) =>
		opened &&
		css`
			width: 100%;
		`}

	@media screen and (min-width: 768px) {
		width: 100%;
		min-width: 12.5em;
		position: static;
		padding: 0px;
	}
`;

export const LinksWrapper = styled.nav`
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${colors.brandSecondary};
`;

export const Overlay = styled.div`
	position: absolute;
	inset: 0;
	background-color: ${colors.darkOverlay};
	display: none;
	cursor: pointer;
	z-index: 2;

	${({ opened }) =>
		opened &&
		css`
			display: block;
		`}

	@media screen and (min-width: 768px) {
		display: none;
	}
`;
