import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;

	& label {
		font-weight: 600;
		color: #000000;
	}
`;

export const Card1Date = styled.div`
	width: 20rem;
	margin-top: 0.5rem;
	margin-bottom: 1.25rem;

	@media screen and (max-width: 800px) {
		width: 100%;
	}
	@media screen and (max-width: 400px) {
		& > div {
			width: fit-content;
		}
		input {
			max-width: 15rem;
		}
	}
`;

export const Card2Date = styled.div`
	@media screen and (max-width: 400px) {
		& > div {
			width: fit-content;
		}
		input {
			max-width: 15rem;
		}
	}
`;

export const InputContainer = styled.div`
	margin-top: 0.1rem;
`;

export const Card2Container = styled.div`
	width: 767px;

	@media screen and (max-width: 800px) {
		width: 100%;
	}
`;

export const Card2InputsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;

	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`;

export const Card2StateContainer = styled.div`
	width: 16rem;
	margin-right: 2rem;
	margin-top: 0.1rem;

	@media screen and (max-width: 550px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 1.5rem;
	}
`;

export const Card2InputsContainer2 = styled.div`
	display: flex;
	flex-wrap: wrap;

	.cityName {
		width: 16rem;
		margin-right: 2rem;
		margin-bottom: 1rem;
	}
	.zipcode {
		width: 7.125rem;
		margin-bottom: 1rem;
	}

	@media screen and (max-width: 550px) {
		flex-direction: column;

		.cityName {
			width: 100%;
			margin-right: 0;
		}

		.zipcode {
			width: 100%;
		}
	}
`;

export const Card2AddressContainer = styled.div`
	margin-top: 1.7rem;
	margin-bottom: 1.25rem;
`;
