import React, { useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import envelopeOpenText from '../../assets/envelope-open-text.svg';
import logo from '../../assets/wilson-logo.png';
import { Button, Typography } from '../../components';
import * as S from './VerifyYourMail.styles';
import { useLocation } from 'react-router-dom';
import { getAllUrlParams } from '../../utils/state';
import { userValidationEmail } from '../../services/authenticationService';
import toast from 'react-hot-toast';
import { META_TITLE } from '../../utils/constants';

export default function VerifyYourMail() {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const location = useLocation();

	// Format email handler
	const formatEmail = (email) => {
		const [name, domain] = email.split('@');
		return `${name[0]}${new Array(name.length).join('*')}@${domain[0]}${new Array(domain.length).join('*')}`;
	};

	// Get email from url
	const queryParameters = getAllUrlParams(location.search);

	// Format email
	const email = queryParameters.e ? formatEmail(decodeURIComponent(queryParameters.e)) : 'your email';

	// Resend email handler
	const handleResendEmail = async () => {
		try {
			await userValidationEmail({ email: decodeURIComponent(queryParameters.e), forceEmail: true });
			toast.success("We've sent a new verification link to your email. Check your inbox for verification instructions.");
		} catch (e) {
			toast.error("We're having trouble sending a verification email. Please try again.");
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `Verify Your Email | ${META_TITLE}`;
	}, []);

	// Return component
	return (
		<S.MainWrapper>
			<S.PictureWrapper>
				<S.Picture>
					<img src={logo} alt="" />
				</S.Picture>
			</S.PictureWrapper>
			<S.MainContainer>
				<S.LogoContainer>
					<img src={envelopeOpenText} alt="" />
				</S.LogoContainer>
				<Typography tag={isHigherThan768 ? 'h1' : 'h4'} weight="bold" center>
					Verify Your Email
				</Typography>
				<S.ParaContainer>
					<Typography tag="h6" variation="2" center>
						Almost there! We’ve sent a verification email to{' '}
						<Typography tag="h6" as="span" weight="semibold" variation="3" center>
							{email}
						</Typography>
						. You need to verify your email address to continue signing up.
					</Typography>
				</S.ParaContainer>
				<S.InputsWrapper>
					<Button size={isHigherThan768 ? 'medium' : 'small'} onClick={handleResendEmail}>
						<Typography weight="extrablack" variation={isHigherThan768 ? '1' : '2'}>
							Resend Email
						</Typography>
					</Button>
				</S.InputsWrapper>
			</S.MainContainer>
		</S.MainWrapper>
	);
}
