import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const DatePickerContainer = styled.div`
	max-width: 20rem;
	margin-top: 4px;
`;
