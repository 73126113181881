import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './InfoCard.styles';

export const InfoCard = ({ className, title, value }) => {
	return (
		<Card className={className}>
			<S.Wrapper>
				<Typography tag="h6" weight="semibold">
					{title}
				</Typography>
				<Typography tag="h2" weight="bold">
					{value}
				</Typography>
			</S.Wrapper>
		</Card>
	);
};
InfoCard.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	value: PropTypes.number,
};
