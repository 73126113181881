import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div``;

export const PageHeader = styled.div`
	background: ${colors.white};
	box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
	border-radius: 0;
	margin-top: -1rem;
	margin-left: -1rem;
	width: calc(100% + 2rem);
	padding: 1.125rem 2.31rem;
	margin-bottom: 2.125rem;
	padding-top: 0;
`;

export const TitleWrapper = styled.div`
	margin-bottom: 0.4125rem;
	margin-top: 0rem;
`;
export const BackLink = styled.div`
	margin-left: -1.563rem;
`;

export const LinkText = styled.span`
	padding-left: 0.625rem;
	button {
		padding-top: 0;
	}
`;
