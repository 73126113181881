import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useHistory } from 'react-router-dom';

import logo from '../../../assets/wilson-logo.png';
import { openMenu } from '../../../app/slices/menu/menuSlice';
import { IconButton } from '../../IconButton';
import { NotificationPanel } from '../../NotificationPanel';
import * as S from './AdminTopbar.styles';
import { ProfileOptions } from '../ProfileOptions';

export const AdminTopbar = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.value);
	const history = useHistory();
	const [notificationPanelOpen, setNotificationPanelOpen] = useState(false);
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	function handleNotificationPanelOpen() {
		setNotificationPanelOpen((prev) => !prev);
	}

	return (
		<S.Topbar>
			<S.Wrapper>{!isHigherThan768 && <IconButton size={1.25} icon={['fal', 'bars']} onClick={() => dispatch(openMenu())} />}</S.Wrapper>
			<S.Picture>
				<img src={logo} alt="" />
			</S.Picture>
			<S.Wrapper alignEnd>
				<IconButton size={isHigherThan768 ? 1.5 : 1.25} icon={['fal', 'bell']} onClick={handleNotificationPanelOpen} />
				<ProfileOptions />
			</S.Wrapper>

			<S.Modal opened={notificationPanelOpen}>
				<NotificationPanel />
			</S.Modal>
		</S.Topbar>
	);
};
