import React from 'react';
import Lottie from 'react-lottie';

import animationData from './loading.json';
import { Typography } from '../Typography';
import * as S from './Spinner.styles';

export function Spinner() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
	};

	return (
		<S.Wrapper>
			<Lottie options={defaultOptions} width={192} height={108} />
			<Typography tag="h4">Loading</Typography>
		</S.Wrapper>
	);
}
