import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { closeMenu } from '../../../app/slices/menu/menuSlice';
import { NavLink } from './NavLink';
import logo from '../../../assets/wilson-logo.png';
import * as S from './Sidebar.styles';

export const Sidebar = ({ links = [], modules = [] }) => {
	const opened = useSelector((state) => state.menu.opened);
	const history = useHistory();
	const dispatch = useDispatch();

	return (
		<>
			<S.Overlay opened={opened} onClick={() => dispatch(closeMenu())} />
			<S.Sidebar opened={opened}>
				<S.PictureWrapper onClick={() => history.push('/')}>
					<S.Picture>
						<img src={logo} alt="" />
					</S.Picture>
				</S.PictureWrapper>
				{modules.map(({ id, Module, props }) => (
					<Module key={id} {...props} />
				))}
				<S.LinksWrapper>
					<ul>
						{links.map((link) => (
							<NavLink key={link.id} to={link.to} icon={link.icon} onClick={() => dispatch(closeMenu())}>
								{link.label}
							</NavLink>
						))}
					</ul>
				</S.LinksWrapper>
			</S.Sidebar>
		</>
	);
};

Sidebar.displayName = 'Sidebar';
Sidebar.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			to: PropTypes.string,
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			label: PropTypes.string,
		})
	),
	modules: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			Module: PropTypes.func,
			props: PropTypes.shape(),
		})
	),
};
