import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './OnboardingFooter.styles';

export const OnboardingFooter = ({ children }) => {
	const actionRef = useRef([]);

	useEffect(() => {
		const listener = (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				if (actionRef?.current?.length > 0) {
					actionRef.current[actionRef.current.length - 1]?.click();
				}
				event.preventDefault();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, []);

	return (
		<S.Wrapper>
			{React.Children.map(children, (child, index) =>
				React.cloneElement(child, {
					ref: (ref) => (actionRef.current[index] = ref),
				})
			)}
		</S.Wrapper>
	);
};

OnboardingFooter.propTypes = {
	children: PropTypes.node,
	enterRef: PropTypes.shape(),
};
