import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'beautiful-react-hooks';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { paymentAmount } from '../../app/slices/makePayment/makePaymentSlice';

import { Button, OnboardingFooter, Typography, Stepper, TextInput, Card, Radio, Checkbox, MakePaymentHeader, Badge } from '../../components';
import { META_TITLE } from '../../utils/constants';
import { required } from '../../utils/form-default-errors';
import { formatDate } from '../../utils/time-formatter';
import * as S from './MakePaymentPaymentAmount.styles';

export default function MakePaymentPaymentAmount() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');
	const user = useSelector((state) => state.user.value);
	const stepData = useSelector((state) => state.makePayment);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const defaultValues = (data) => {
		const values = {};
		Object.keys(data || {}).forEach((accountKey) => {
			const { type, amount } = (data || {})[accountKey];
			if (type != null && amount != null) {
				values[`${accountKey}-type`] = type;
				values[`${accountKey}-amount`] = amount;
			}
		});
		return values;
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
	} = useForm({ defaultValues: defaultValues(stepData.paymentAmount) });

	const formState = watch();

	useEffect(() => {
		const s = stepData?.paymentAmount;
		const values = defaultValues(s);
		Object.keys(values).forEach((key) => {
			setValue(key, values[key]);
		});
	}, []);

	const onBack = () => {
		history.push(location.state?.backUrl || '/payment-history');
	};

	const onNext = (data) => {
		if (getTotalBalance() <= 0) {
			toast.error('Please select an amount to make a payment.');
			return;
		}
		const formData = {};
		(user?.locations || []).forEach(({ UtilityAccount, GreenlightAccount }) => {
			if (UtilityAccount) {
				const { AccountKey } = UtilityAccount;
				const type = data[`${AccountKey}-type`];
				const amount = data[`${AccountKey}-amount`];
				if (type && amount) {
					formData[AccountKey] = { type, amount };
				}
			}
			if (GreenlightAccount) {
				const { AccountKey } = GreenlightAccount;
				const type = data[`${AccountKey}-type`];
				const amount = data[`${AccountKey}-amount`];
				if (type && amount) {
					formData[AccountKey] = { type, amount };
				}
			}
		});
		dispatch(paymentAmount(formData));
		history.push('/make-payment/method');
	};

	const getUtilityBalance = () => {
		let totalBalance = 0;
		(user?.locations || []).forEach(({ UtilityAccount }) => {
			if (UtilityAccount) {
				const { AccountKey } = UtilityAccount;
				const amount = formState[`${AccountKey}-amount`];
				if (amount) {
					totalBalance += parseFloat(`${amount}`);
				}
			}
		});
		return totalBalance;
	};

	const getGreenlightBalance = () => {
		let totalBalance = 0;
		(user?.locations || []).forEach(({ GreenlightAccount }) => {
			if (GreenlightAccount) {
				const { AccountKey } = GreenlightAccount;
				const amount = formState[`${AccountKey}-amount`];
				if (amount) {
					totalBalance += parseFloat(`${amount}`);
				}
			}
		});
		return totalBalance;
	};

	const getTotalBalance = () => {
		return getUtilityBalance() + getGreenlightBalance();
	};

	const onUtilitiesChange = (account) => {
		if (formState[`${account.AccountKey}-type`] != null) {
			setValue(`${account.AccountKey}-type`, undefined);
			setValue(`${account.AccountKey}-amount`, undefined);
		} else {
			setValue(`${account.AccountKey}-type`, 'currentAmount');
			setValue(`${account.AccountKey}-amount`, account.CurrentBalance);
		}
	};

	const onGreenLightChange = (account) => {
		if (formState[`${account.AccountKey}-type`] != null) {
			setValue(`${account.AccountKey}-type`, undefined);
			setValue(`${account.AccountKey}-amount`, undefined);
		} else {
			setValue(`${account.AccountKey}-type`, 'currentAmount');
			setValue(`${account.AccountKey}-amount`, account.CurrentBalance);
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `Make Payment | ${META_TITLE}`;
	}, []);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<MakePaymentHeader />
			<Stepper
				activeStep={0}
				steps={[
					{ id: 1, label: 'Amount' },
					{ id: 2, label: 'Payment' },
					{ id: 3, label: 'Date' },
					{ id: 4, label: 'Review' },
					{ id: 5, label: 'Confirmation' },
				]}
			/>
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Payment Amount
					</Typography>
					<S.CardsContainer>
						<Card title="Select Accounts">
							{user?.locations?.map((location) => (
								<S.AccountSection key={location.LocationID}>
									<Typography tag="h5">{location.Address}</Typography>
									{location?.UtilityAccount != null && (
										<>
											<S.CheckboxContainer>
												<Checkbox
													label={
														<S.CheckboxContent>
															{`Utilities - ${location?.UtilityAccount.AccountNumber} (Due ${formatDate(location?.UtilityAccount?.CurrentDueDate)})`}
															{location?.UtilityAccount?.AutoPay && (
																<Badge type="success" className="autopayBadge">
																	<Typography tag="p" variation="2">
																		Autopay
																	</Typography>
																</Badge>
															)}
														</S.CheckboxContent>
													}
													checked={formState[`${location?.UtilityAccount.AccountKey}-type`] != null}
													onChange={() => {
														onUtilitiesChange(location?.UtilityAccount);
													}}
												/>
											</S.CheckboxContainer>
											{formState[`${location?.UtilityAccount.AccountKey}-type`] != null && (
												<S.RadioContainers>
													<Radio
														label={`Current Amount - $${location?.UtilityAccount.CurrentBalance.toFixed(2)}`}
														checked={formState[`${location?.UtilityAccount.AccountKey}-type`] === 'currentAmount'}
														onChange={() => {
															setValue(`${location?.UtilityAccount.AccountKey}-type`, 'currentAmount');
															setValue(`${location?.UtilityAccount.AccountKey}-amount`, location?.UtilityAccount.CurrentBalance);
														}}
													/>
													{location?.UtilityAccount.DelinquentBalance > 0 && (
														<Radio
															label={`Delinquent Amount - $${location?.UtilityAccount.DelinquentBalance.toFixed(2)}`}
															checked={formState[`${location?.UtilityAccount.AccountKey}-type`] === 'delinquentAmount'}
															onChange={() => {
																setValue(`${location?.UtilityAccount.AccountKey}-type`, 'delinquentAmount');
																setValue(`${location?.UtilityAccount.AccountKey}-amount`, location?.UtilityAccount.DelinquentBalance);
															}}
														/>
													)}
													<Radio
														label="Other Amount"
														checked={formState[`${location?.UtilityAccount.AccountKey}-type`] === 'otherAmount'}
														onChange={() => {
															setValue(`${location?.UtilityAccount.AccountKey}-type`, 'otherAmount');
															setValue(`${location?.UtilityAccount.AccountKey}-amount`, 0);
														}}
													/>
													{formState[`${location?.UtilityAccount.AccountKey}-type`] === 'otherAmount' && (
														<S.AmountInputContainer>
															<FontAwesomeIcon icon={['fal', 'dollar-sign']} />
															<TextInput
																containerClassName="amountInput"
																id={`${location?.UtilityAccount.AccountKey}-amount`}
																error={errors[`${location?.UtilityAccount.AccountKey}-amount`]}
																{...register(`${location?.UtilityAccount.AccountKey}-amount`, {
																	required: required('Amount'),
																})}
																type="text"
																maxLength="7"
																value={formState[`${location?.UtilityAccount.AccountKey}-amount`]}
																onChange={(e) => {
																	let val = e.target.value.replace(/[^0-9]/g, '');
																	if (val != null && val.length >= 3) {
																		let value = val.split('');
																		if (value.length > 6) value.length = 6;
																		value.splice(value.length - 2, 0, '.');
																		val = value.join('');
																	}
																	setValue(`${location?.UtilityAccount.AccountKey}-amount`, val);
																}}
															/>
														</S.AmountInputContainer>
													)}
												</S.RadioContainers>
											)}
										</>
									)}
									{location?.GreenlightAccount != null && (
										<>
											<S.CheckboxContainer>
												<Checkbox
													label={
														<S.CheckboxContent>
															{`Greenlight - ${location?.GreenlightAccount.AccountNumber} (Due ${formatDate(location?.GreenlightAccount?.CurrentDueDate)})`}
															{location?.GreenlightAccount?.AutoPay && (
																<Badge type="success" className="autopayBadge">
																	<Typography tag="p" variation="2">
																		Autopay
																	</Typography>
																</Badge>
															)}
														</S.CheckboxContent>
													}
													checked={formState[`${location?.GreenlightAccount.AccountKey}-type`] != null}
													onChange={() => {
														onGreenLightChange(location?.GreenlightAccount);
													}}
												/>
											</S.CheckboxContainer>
											{formState[`${location?.GreenlightAccount.AccountKey}-type`] != null && (
												<S.RadioContainers>
													<Radio
														label={`Current Amount - $${location?.GreenlightAccount.CurrentBalance.toFixed(2)}`}
														checked={formState[`${location?.GreenlightAccount.AccountKey}-type`] === 'currentAmount'}
														onChange={() => {
															setValue(`${location?.GreenlightAccount.AccountKey}-type`, 'currentAmount');
															setValue(`${location?.GreenlightAccount.AccountKey}-amount`, location?.GreenlightAccount.CurrentBalance);
														}}
													/>
													{location?.GreenlightAccount.DelinquentBalance > 0 && (
														<Radio
															label={`Delinquent Amount - $${location?.GreenlightAccount.DelinquentBalance.toFixed(2)}`}
															checked={formState[`${location?.GreenlightAccount.AccountKey}-type`] === 'delinquentAmount'}
															onChange={() => {
																setValue(`${location?.GreenlightAccount.AccountKey}-type`, 'delinquentAmount');
																setValue(`${location?.GreenlightAccount.AccountKey}-amount`, location?.GreenlightAccount.DelinquentBalance);
															}}
														/>
													)}
													<Radio
														label="Other Amount"
														checked={formState[`${location?.GreenlightAccount.AccountKey}-type`] === 'otherAmount'}
														onChange={() => {
															setValue(`${location?.GreenlightAccount.AccountKey}-type`, 'otherAmount');
															setValue(`${location?.GreenlightAccount.AccountKey}-amount`, 0);
														}}
													/>
													{formState[`${location?.GreenlightAccount.AccountKey}-type`] === 'otherAmount' && (
														<S.AmountInputContainer>
															<FontAwesomeIcon icon={['fal', 'dollar-sign']} />
															<TextInput
																containerClassName="amountInput"
																id={`${location?.GreenlightAccount.AccountKey}-amount`}
																error={errors[`${location?.GreenlightAccount.AccountKey}-amount`]}
																{...register(`${location?.GreenlightAccount.AccountKey}-amount`, {
																	required: required('Amount'),
																})}
																type="text"
																maxLength="7"
																value={formState[`${location?.GreenlightAccount.AccountKey}-amount`]}
																onChange={(e) => {
																	let val = e.target.value.replace(/[^0-9]/g, '');
																	if (val != null && val.length >= 3) {
																		let value = val.split('');
																		if (value.length > 6) value.length = 6;
																		value.splice(value.length - 2, 0, '.');
																		val = value.join('');
																	}
																	setValue(`${location?.GreenlightAccount.AccountKey}-amount`, val);
																}}
															/>
														</S.AmountInputContainer>
													)}
												</S.RadioContainers>
											)}
										</>
									)}
									<S.HorizontalLine />
								</S.AccountSection>
							))}
						</Card>
						<Card>
							<S.Card2HeadingWrapper>
								<Typography tag="h5" weight="bold">
									Payment Total
								</Typography>
							</S.Card2HeadingWrapper>
							{getUtilityBalance() > 0 && (
								<S.Card2DetailsContainer>
									<Typography tag="p" variation="1">
										Utilities Subtotal
									</Typography>
									<Typography tag="p" variation="1" weight="bold">
										${getUtilityBalance().toFixed(2)}
									</Typography>
								</S.Card2DetailsContainer>
							)}
							{getGreenlightBalance() > 0 && (
								<S.Card2DetailsContainer>
									<Typography tag="p" variation="1">
										Greenlight Subtotal
									</Typography>
									<Typography tag="p" variation="1" weight="bold">
										${getGreenlightBalance().toFixed(2)}
									</Typography>
								</S.Card2DetailsContainer>
							)}

							<S.HorizontalLine2 />
							<S.Card2Total>
								<Typography tag="p" variation="1" weight="semibold">
									Total Balance Due
								</Typography>
								<Typography tag="p" variation="1" weight="bold">
									${getTotalBalance().toFixed(2)}
								</Typography>
							</S.Card2Total>
						</Card>
					</S.CardsContainer>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
