export const getBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
		reader.readAsDataURL(file);
		return reader;
	});
};

export const imageUpload = (file) => {
	return getBase64(file).then((base64) => {
		return base64;
	});
};

export const getBase64List = async (files) => {
	var ourImagesList = [];
	if (files?.length) {
		for (let i = 0; i < files.length; i++) {
			const base64File = await imageUpload(files[i]);

			ourImagesList.push({
				mimeType: files[i].type,
				base64File: base64File,
			});
		}
	}
	return ourImagesList;
};
