import { format } from 'date-fns';

export const calculateDailyAverage = (days, key) => {
	if (days == null) return null;
	let total = 0;
	let validDays = 0;
	days.forEach((day) => {
		const value = day[key];
		if (value != null) {
			total += value;
			validDays += 1;
		}
	});
	if (validDays === 0) return null;
	return total / validDays;
};

export const calculate30DayMax = (days, key) => {
	if (days == null) return null;
	let maxes = [];
	let items = [];
	let dayCount = 0;
	days.forEach((day, index) => {
		const value = day[key];
		if (value != null) {
			items.push(value);
		}
		dayCount += 1;
		if (dayCount === 30 || index === days.length - 1) {
			maxes.push(Math.max(...items));
			items = [];
			dayCount = 0;
		}
	});
	if (maxes.length === 0) return null;
	return Math.max(...maxes);
};

export const calculate30DayAverage = (days, key) => {
	if (days == null) return null;
	let totals = [];
	let currentTotal = 0;
	let dayCount = 0;
	days.forEach((day, index) => {
		const value = day[key];
		if (value != null) {
			currentTotal += value;
		}
		dayCount += 1;
		if (dayCount === 30 || index === days.length - 1) {
			totals.push(currentTotal);
			currentTotal = 0;
			dayCount = 0;
		}
	});
	if (totals.length === 0) return null;
	return totals.reduce((a, b) => a + b) / totals.length;
};

export const formatMeterData = (meter) => {
	if (meter == null) return meter;
	for (let i = 0; i < meter.UsageDays.length; i += 1) {
		meter.UsageDays[i].Usage = meter.UsageDays[i].Usage || 0;
		meter.UsageDays[i].MaxDemand = meter.UsageDays[i].MaxDemand || 0;
		meter.UsageDays[i].Cost = meter.UsageDays[i].Cost || 0;
		meter.UsageDays[i].TempHigh = meter.UsageDays[i].TempHigh || 0;
		meter.UsageDays[i].TempLow = meter.UsageDays[i].TempLow || 0;
	}
	meter.UsageDays.sort((a, b) => new Date(a.UsageDate).getTime() - new Date(b.UsageDate).getTime());
	const UsageMonths = [];
	const UsageYears = [];
	let possibleYears = [];

	// Format days
	meter.UsageDays.forEach((day) => {
		// Update month
		const month = format(new Date(day.UsageDate), 'MMMM yyyy');
		const index = UsageMonths.findIndex((m) => m.Month === month);
		if (index > -1) {
			UsageMonths[index].Days.push(day);
		} else {
			UsageMonths.push({
				Month: month,
				UsageDate: day.UsageDate,
				Days: [day],
			});
		}

		// Add possible year
		const year = format(new Date(day.UsageDate), 'yyyy');
		if (!possibleYears.includes(year)) possibleYears.push(year);
	});

	// Create year keys
	possibleYears.sort((a, b) => parseInt(a) - parseInt(b));
	const yearKeys = UsageMonths.length <= 13 ? [possibleYears.join(' - ')] : possibleYears;

	// Format months
	UsageMonths.forEach((month) => {
		const year = format(new Date(month.UsageDate), 'yyyy');
		const index = yearKeys.length === 1 && UsageYears.length > 0 ? 0 : UsageYears.findIndex((m) => m.Year === year);
		if (index > -1) {
			UsageYears[index].Months.push(month);
		} else {
			UsageYears.push({
				Year: yearKeys.length === 1 ? yearKeys[0] : year,
				UsageDate: month.UsageDate,
				Months: [month],
			});
		}
	});
	for (let i = 0; i < UsageMonths.length; i += 1) {
		const { Days } = UsageMonths[i];
		UsageMonths[i].Usage = Days.length > 0 ? Days.map((day) => day.Usage || 0).reduce((a, b) => a + b) : 0;
		UsageMonths[i].MaxDemand = Days.length > 0 ? Math.max(...Days.map((day) => day.MaxDemand || 0)) : 0;
		UsageMonths[i].Cost = Days.length > 0 ? Days.map((day) => day.Cost || 0).reduce((a, b) => a + b) : 0;
		UsageMonths[i].TempHigh = Days.length > 0 ? Days.map((day) => day.TempHigh || 0).reduce((a, b) => a + b) / Days.length : 0;
		UsageMonths[i].TempLow = Days.length > 0 ? Days.map((day) => day.TempLow || 0).reduce((a, b) => a + b) / Days.length : 0;
	}
	for (let i = 0; i < UsageYears.length; i += 1) {
		const { Months } = UsageYears[i];
		UsageYears[i].Usage = Months.length > 0 ? Months.map((day) => day.Usage || 0).reduce((a, b) => a + b) : 0;
		UsageYears[i].MaxDemand = Months.length > 0 ? Math.max(...Months.map((day) => day.MaxDemand || 0)) : 0;
		UsageYears[i].Cost = Months.length > 0 ? Months.map((day) => day.Cost || 0).reduce((a, b) => a + b) : 0;
		UsageYears[i].TempHigh = Months.length > 0 ? Months.map((day) => day.TempHigh || 0).reduce((a, b) => a + b) / Months.length : 0;
		UsageYears[i].TempLow = Months.length > 0 ? Months.map((day) => day.TempLow || 0).reduce((a, b) => a + b) / Months.length : 0;
	}
	return {
		...meter,
		UsageMonths,
		UsageYears,
	};
};
