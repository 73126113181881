import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-datepicker/dist/react-datepicker.css';

import { Typography } from '../Typography';
import * as S from './DatePicker.styles';

const CustomInput = forwardRef(({ id, label, subLabel, error, icon, ...rest }, ref) => (
	<S.Wrapper error={error}>
		{label && (
			<Typography tag="label" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
				{label}
			</Typography>
		)}
		{subLabel && (
			<Typography tag="span" variation="2" style={{ margin: '-3px 0 8px' }}>
				{subLabel}
			</Typography>
		)}
		<S.InputWrapper>
			<FontAwesomeIcon icon={['fal', icon]} />
			<S.Input ref={ref} id={id} error={error} {...rest} />
		</S.InputWrapper>
	</S.Wrapper>
));

CustomInput.displayName = 'CustomInput';
CustomInput.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	error: PropTypes.bool,
	icon: PropTypes.string,
};

export const DatePicker = forwardRef(({ id, label, subLabel, daysHighlighted = [], error, icon = 'calendar', ...rest }, ref) => {
	return <ReactDatePicker ref={ref} {...rest} style={{ display: 'block' }} highlightDates={daysHighlighted} customInput={<CustomInput id={id} label={label} subLabel={subLabel} error={error} icon={icon} />} />;
});

DatePicker.displayName = 'DatePicker';
DatePicker.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	daysHighlighted: PropTypes.array,
	error: PropTypes.bool,
	icon: PropTypes.string,
};
