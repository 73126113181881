import React, { useEffect, useState } from 'react';
import { useForm, useController, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { newCustomerCommercialApplication } from '../../app/slices/onboarding/onboardingSlice';
import { Button, OnboardingFooter, OnboardingHeader, TextInput, Typography, Stepper, Card, DropFile, ButtonToggle, AddressInput, DatePicker } from '../../components';
import { META_TITLE, New_Cust_Com_Onb_App } from '../../utils/constants';
import { required } from '../../utils/form-default-errors';
import PropTypes from 'prop-types';
import * as S from './OnboardingNewCustomerCommercialApplication.styles';

export default function OnboardingNewCustomerCommercialApplication({ showDashboard }) {
	const [dcc, setDcc] = useState(undefined);
	const [fireInsp, setFireInsp] = useState(null);
	const [propInsp, setPropInsp] = useState(null);
	const [docOrPhotoId, setDocOrPhotoId] = useState(null);
	const [taxIdOrSecCard, setTaxIdOrSecCard] = useState(null);
	const [securityDep, setSecurityDep] = useState(null);
	const [occupancyProof, setOccupancyProof] = useState(null);
	const [privilLicense, setPrivilLicense] = useState(null);

	const [applicationOptions, setApplicationOptions] = useState([
		{ id: 'individual', label: 'Individual', selected: true },
		{ id: 'organization', label: 'Organization', selected: false },
	]);

	const stepData = useSelector((state) => state.onboarding);
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		control,
		reset,
		watch,
	} = useForm({ defaultValues: { ...stepData.newCustomer.commercial.application } });

	const movingDate = watch('movingDate');

	const onBack = () => {
		history.push('/onboarding/new-customer/building-type');
	};

	const onNext = async (data) => {
		const tempData = { ...data };

		// Set file content
		tempData.dcc = dcc;
		tempData.fireInsp = fireInsp;
		tempData.propInsp = propInsp;
		tempData.docOrPhotoId = docOrPhotoId;
		tempData.taxIdOrSecCard = taxIdOrSecCard;
		tempData.securityDep = securityDep;
		tempData.occupancyProof = occupancyProof;
		tempData.privilLicense = privilLicense;

		// Update application
		dispatch(newCustomerCommercialApplication(tempData));
		const stringifyData = JSON.stringify(data);
		localStorage.setItem(New_Cust_Com_Onb_App, stringifyData);
		history.push('/onboarding/new-customer/commercial/utilities');
	};

	useEffect(async () => {
		// Set document title
		document.title = `New Customer | ${META_TITLE}`;
		const data = localStorage.getItem(New_Cust_Com_Onb_App);
		let tempData;

		/* storing file to LocalStorage Logic

		const dcc = localStorage.getItem('dcc');
		if (dcc) {
			dccFile = JSON.parse(dcc);
		}
		const fireInsp = localStorage.getItem('fireInsp');
		if (fireInsp) {
			fireInspFile = JSON.parse(fireInsp);
		}
		const propInsp = localStorage.getItem('propInsp');
		if (propInsp) {
			propInspFile = JSON.parse(propInsp);
		}
		const docOrPhotoId = localStorage.getItem('docOrPhotoId');
		if (docOrPhotoId) {
			docOrPhotoIdFile = JSON.parse(docOrPhotoId);
		}
		const taxIdOrSecCard = localStorage.getItem('taxIdOrSecCard');
		if (taxIdOrSecCard) {
			taxIdOrSecCardFile = JSON.parse(taxIdOrSecCard);
		}
		const securityDep = localStorage.getItem('securityDep');
		if (securityDep) {
			securityDepFile = JSON.parse(securityDep);
		}
		const occupancyProof = localStorage.getItem('occupancyProof');
		if (occupancyProof) {
			occupancyProofFile = JSON.parse(occupancyProof);
		}
		const privilLicense = localStorage.getItem('privilLicense');
		if (privilLicense) {
			privilLicenseFile = JSON.parse(privilLicense);
		}
	*/

		if (data) {
			tempData = JSON.parse(data);
		}
		if (tempData) {
			setValue('firstName', tempData?.firstName);
			setValue('lastName', tempData?.lastName);
			setValue('serviceAddressToConnect', tempData?.serviceAddressToConnect);
			setValue('driverLicense', tempData?.driverLicense);
			setValue('ssn', tempData?.ssn);
			//setValue('employer', tempData?.employer);
			setValue('homePhone', tempData?.homePhone);
			setValue('workPhone', tempData?.workPhone);
			setValue('cellPhone', tempData?.cellPhone);
			setValue('movingFromAddress', tempData?.movingFromAddress);
			setValue('movingDate', tempData?.movingDate);
			setValue('coApplicantFirstName', tempData?.coApplicantFirstName);
			setValue('coApplicantLastName', tempData?.coApplicantLastName);
			setValue('coApplicantDriverLicense', tempData?.coApplicantDriverLicense);
			setValue('coApplicantSsn', tempData?.coApplicantSsn);
			setValue('busFederalTaxId', tempData?.busFederalTaxId);
			setValue('businessName', tempData?.businessName);
			setValue('mailingAddress', tempData?.mailingAddress);
			setValue('nameOfResponsibleParty', tempData?.nameOfResponsibleParty);
			setValue('phone', tempData?.phone);
			setValue('relationship', tempData?.relationship);

			//setValue('coApplicantEmployer', tempData?.coApplicantEmployer);
		}

		const { dcc = [], fireInsp = [], propInsp = [], docOrPhotoId = [], taxIdOrSecCard = [], securityDep = [], occupancyProof = [], privilLicense = [] } = stepData?.newCustomer?.commercial?.application;

		setDcc(dcc);
		setFireInsp(fireInsp);
		setPropInsp(propInsp);
		setDocOrPhotoId(docOrPhotoId);
		setTaxIdOrSecCard(taxIdOrSecCard);
		setSecurityDep(securityDep);
		setOccupancyProof(occupancyProof);
		setPrivilLicense(privilLicense);
		/* Getting files from LocalStorage
		if (dccFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
		if (fireInspFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
		if (propInspFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
		if (docOrPhotoIdFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
		if (taxIdOrSecCardFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
		if (securityDepFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
		if (occupancyProofFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
		if (privilLicenseFile?.length) {
			const tempFiles = await getBase64ToFilesList(dccFile);
			setDcc(tempFiles);
		}
	*/

		// if (tempFiles) {
		// 	const ourImge = await base64ToFile(tempFiles.dcc[0]);

		// 	setDcc([ourImge]);
		// }
		// if (tempFiles) {
		// 	setPropInsp(tempFiles?.propInsp);
		// 	setFireInsp(tempFiles?.fireInsp);
		// }
	}, []);

	useEffect(() => {
		reset({ ...stepData.newCustomer.commercial.application });
	}, [applicationOptions]);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<OnboardingHeader showDashboard={showDashboard} />
			<Stepper
				activeStep={1}
				steps={[
					{ id: 1, label: 'Location' },
					{ id: 2, label: 'Application' },
					{ id: 3, label: 'Utilities' },
					{ id: 4, label: 'Greenlight' },
					{ id: 5, label: 'Review' },
				]}
			/>
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag="h1" weight="bold" center>
						Apply for Services
					</Typography>

					<Card title="Application">
						<S.ApplicationTypeSection>
							<Typography tag="p" variation="1">
								I am applying as an
							</Typography>
							<ButtonToggle options={applicationOptions} setOptions={setApplicationOptions} size="small" key="toggle" />
						</S.ApplicationTypeSection>

						<Typography tag="h4" weight="bold">
							Applicant Information
						</Typography>
						{/*<Typography tag="p" variation="2" className="red" style={{ margin: '10px 0px 5px' }}>
							You may apply as an individual or an organization. All organizations will pay a security deposit. Deposits for individuals will be determined based upon a credit evaluation.
						</Typography>*/}
						<Typography tag="p" variation="2" style={{ margin: '0px 0px 15px' }}>
							*Indicates a Required Field
						</Typography>

						{applicationOptions.find((option) => option.selected === true).id === 'individual' ? (
							<>
								<S.ApplicationGrid>
									<TextInput
										containerClassName="item-firstName"
										label="First Name *"
										id="firstName"
										error={errors.firstName}
										{...register('firstName', {
											required: required('First Name'),
										})}
									/>
									<TextInput
										containerClassName="item-lastName"
										label="Last Name *"
										id="lastName"
										error={errors.lastName}
										{...register('lastName', {
											required: required('Last Name'),
										})}
									/>
									<Controller
										name="item-serviceAddressToConnect"
										control={control}
										rules={{ required: required('Service Address to Connect') }}
										render={({ field: { onChange, value }, fieldState }) => <AddressInput containerClassName="item-serviceAddressToConnect" label="Service Address to Connect *" id="serviceAddressToConnect" error={fieldState.error} onChange={onChange} value={value} />}
									/>
									<TextInput containerClassName="item-mailingAddress" label="Mailing Address (if different)" id="mailingAddress" error={errors.mailingAddress} {...register('mailingAddress')} />
									<TextInput
										containerClassName="item-driverLicense"
										label="Driver's License State/Number *"
										id="driverLicense"
										error={errors.driverLicense}
										{...register('driverLicense', {
											required: required("Driver's License Number"),
										})}
									/>
									<TextInput containerClassName="item-ssn" label="SSN" showWarning={true} warningText="Optional: Failure to submit a Social Security number will require a High Risk Deposit." id="ssn" error={errors.ssn} {...register('ssn', {})} />
									{/*<TextInput containerClassName="item-employer" label="Employer" id="employer" error={errors.employer} {...register('employer', {})} />*/}
									<TextInput containerClassName="item-homePhone" label="Home Phone" id="homePhone" error={errors.homePhone} {...register('homePhone', {})} />
									<TextInput
										containerClassName="item-workPhone"
										label="Work Phone *"
										id="workPhone"
										showWarning={true}
										warningText="We will use this phone number to contact you about setting up Utilities or Greenlight service."
										error={errors.workPhone}
										{...register('workPhone', {
											required: required('Work Phone Number'),
										})}
									/>
									<TextInput containerClassName="item-cellPhone" label="Cell Phone" id="cellPhone" error={errors.cellPhone} {...register('cellPhone', {})} />
									<TextInput containerClassName="item-movingFromAddress" label="Address Moving From" id="movingFromAddress" error={errors.movingFromAddress} {...register('movingFromAddress')} />
									<div className="item-movingDate" style={{ marginTop: '-2px' }}>
										<DatePicker label="Moving Date" selected={movingDate} icon="angle-down" {...register('movingDate')} onChange={(date) => setValue('movingDate', date)} error={!movingDate && errors.movingDate} id="movingDatePicker" minDate={new Date()} />
									</div>
								</S.ApplicationGrid>
								<Typography tag="h4" weight="bold">
									Co-Applicant Information
								</Typography>
								<S.CoApplicationGrid>
									<TextInput containerClassName="item-coApplicantFirstName" label="First Name" id="coApplicantFirstName" error={errors.coApplicantFirstName} {...register('coApplicantFirstName')} />
									<TextInput containerClassName="item-coApplicantLastName" label="Last Name" id="coApplicantLastName" error={errors.coApplicantLastName} {...register('coApplicantLastName')} />
									<TextInput containerClassName="item-coApplicantDriverLicense" label="Driver's License State/Number" id="coApplicantDriverLicense" error={errors.coApplicantDriverLicense} {...register('coApplicantDriverLicense')} />
									<TextInput
										containerClassName="item-coApplicantSsn"
										label="SSN"
										showWarning={true}
										warningText="Optional: Failure to submit a Social Security number will require a High Risk Deposit."
										id="coApplicantSsn"
										error={errors.coApplicantSsn}
										{...register('coApplicantSsn')}
									/>

									{/*<TextInput containerClassName="item-coApplicantEmployer" label="Employer" id="coApplicantEmployer" error={errors.coApplicantEmployer} {...register('coApplicantEmployer')} />*/}
								</S.CoApplicationGrid>
							</>
						) : (
							<>
								<S.ApplicationGrid1>
									<TextInput containerClassName="item-firstName" label="First Name" id="firstName" error={errors.firstName} {...register('firstName')} />
									<TextInput containerClassName="item-lastName" label="Last Name" id="lastName" error={errors.lastName} {...register('lastName')} />
									<TextInput
										containerClassName="item-businessName"
										label="Business Name *"
										id="businessName"
										error={errors.businessName}
										{...register('businessName', {
											required: required('Business Name'),
										})}
									/>
								</S.ApplicationGrid1>
								<S.ApplicationGrid>
									<Controller
										name="item-serviceAddressToConnect"
										control={control}
										rules={{ required: required('Service Address to Connect') }}
										render={({ field: { onChange, value }, fieldState }) => <AddressInput containerClassName="item-serviceAddressToConnect" label="Service Address to Connect *" id="serviceAddressToConnect" error={fieldState.error} onChange={onChange} value={value} />}
									/>
									<TextInput containerClassName="item-mailingAddress" label="Mailing Address (if different)" id="mailingAddress" error={errors.mailingAddress} {...register('mailingAddress')} />
									<TextInput containerClassName="item-driverLicense" label="Business’s Federal Tax ID" id="busFederalTaxId" error={errors.busFederalTaxId} {...register('busFederalTaxId', {})} />
									<TextInput
										containerClassName="item-ssn"
										label="Applicant Phone *"
										id="phone"
										error={errors.phone}
										{...register('phone', {
											required: required('Phone number'),
										})}
									/>
									<TextInput containerClassName="item-movingFromAddress" label="Address Moving From" id="movingFromAddress" error={errors.movingFromAddress} {...register('movingFromAddress')} />
									<div className="item-movingDate" style={{ marginTop: '-2px' }}>
										<DatePicker label="Moving Date" selected={movingDate} icon="angle-down" {...register('movingDate')} onChange={(date) => setValue('movingDate', date)} error={!movingDate && errors.movingDate} id="movingDatePicker" minDate={new Date()} />
									</div>
								</S.ApplicationGrid>
								<S.ApplicationGrid>
									<TextInput
										containerClassName="item-serviceAddressToConnect "
										label="Name of Responsible Party"
										id="nameOfResponsibleParty"
										showWarning={true}
										warningText="This person will be individually responsible for payment of bills. Deposit may be waived following credit determination."
										error={errors.nameOfResponsibleParty}
										{...register('nameOfResponsibleParty')}
									/>
									<TextInput containerClassName="item-mailingAddress" label="Relationship" id="relationship" error={errors.relationship} {...register('relationship')} />
									<TextInput
										containerClassName="item-driverLicense"
										label="Driver's License State/Number *"
										id="driverLicense"
										error={errors.driverLicense}
										{...register('driverLicense', {
											...register('driverLicense', {
												required: required("Driver's License Number"),
											}),
										})}
									/>
									<TextInput containerClassName="item-ssn" label="SSN" id="ssn" showWarning={true} warningText="Optional: Failure to submit a Social Security number will require a High Risk Deposit." error={errors.ssn} {...register('ssn', {})} />
								</S.ApplicationGrid>
							</>
						)}
					</Card>

					<Card title="Documentation">
						<Typography tag="p" weight="bold" style={{ marginBottom: '5px' }}>
							Reliable. Safe. Secure.
						</Typography>
						<Typography tag="p">Words that describe our Electric, Gas and Water utilities - and how we treat your personal information. All required documentation can be securely submitted using the file upload features below.</Typography>

						<S.DocsWrapper>
							<DropFile
								label="DCC"
								description="If you have already obtained it."
								onChangeFile={(files) => {
									setDcc(files);
								}}
								files={dcc}
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
							<DropFile
								onChangeFile={(files) => {
									setFireInsp(files);
								}}
								label="Fire Inspection"
								files={fireInsp}
								description="If you have already obtained it. If not, contact Fire Services at (252) 399-2880"
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
							{/*<DropFile
								onChangeFile={(files) => {
									setPropInsp(files);
								}}
								errorMessage={propInsp?.length ? '' : 'Property Inspection is required'}
								label="Property Inspection *"
								files={propInsp}
								description="Required if utility services have been off for over a year. Contact Inspections at (252) 399-2220 or (252) 399-2219"
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>*/}
							<DropFile
								onChangeFile={(files) => {
									setDocOrPhotoId(files);
								}}
								files={docOrPhotoId}
								label="Business Organization Documentation / State or Federal Issued Photo ID"
								description=""
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
							<DropFile
								onChangeFile={(files) => {
									setTaxIdOrSecCard(files);
								}}
								files={taxIdOrSecCard}
								label="Federal Tax ID / Social Security Card"
								description="**Failure to submit a Social Security number will require a High Risk Deposit"
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
							{/*<DropFile
								onChangeFile={(files) => {
									setSecurityDep(files);
								}}
								errorMessage={!securityDep && 'Security Deposit is required'}
								label="Security Deposit"
								files={securityDep}
								description="The City of Wilson requires a deposit equal to two month’s average utility bill"
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>*/}
							<DropFile
								onChangeFile={(files) => {
									setOccupancyProof(files);
								}}
								label="Proof of Lawful Occupancy"
								files={occupancyProof}
								description="Renters:
									A signed copy of your Lease Agreement or updated Rent Receipt

									Owners:
									A copy of your Deed, Settlement Statement, Offer to Purchase or current Tax Bill"
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>
							{/*<DropFile
								onChangeFile={(files) => {
									setPrivilLicense(files);
								}}
								files={privilLicense}
								label="Privilege License (For Beer/Wine Sales and Taxi/Limo Services Only)"
								description="Contact Accounts Receivable at (252) 399-2163 or (252) 206-5271."
								options={{
									accept: {
										'image/png': ['.png'],
										'image/jpeg': ['.jpeg', '.jpg'],
										'image/heic': ['.heic'],
										'application/pdf': ['.pdf'],
									},
									maxSize: 20000000, // 20 MB
								}}
							/>*/}
						</S.DocsWrapper>

						{/* Footnotes */}
						<Typography tag="p" variation="2" style={{ marginTop: '25px' }}>
							* Property Inspection is required if utility services have been off for over a year. Contact Inspections at <a href="tel:2523992220">(252) 399-2220</a> or <a href="tel:2523992219">(252) 399-2219</a>.
						</Typography>
						<Typography tag="p" variation="2" style={{ marginBottom: '10px' }}>
							* Privilege License (For Beer/Wine Sales and Taxi/Limo Services Only). Contact Accounts Receivable at <a href="tel:2522963332">(252) 296-3332</a> or <a href="tel:2523992200">(252) 399-2200</a>.
						</Typography>
					</Card>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}

OnboardingNewCustomerCommercialApplication.propTypes = {
	showDashboard: PropTypes.bool,
};
