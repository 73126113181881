import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ConsumptionUsage, CurrentInvoices, ImportantDates, RecentBills, RecentPayments, ResourcesPanel, PageHeader, Card, ButtonToggle } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './Home.styles';

export default function Home() {
	const user = useSelector((state) => state.user.value);
	const currentLocation = useSelector((state) => state.location.value);
	const history = useHistory();

	// Check if has active utility account
	const hasUtilityAccount = currentLocation?.UtilityAccount != null;

	const [graphOptions, setGraphOptions] = useState([
		{ id: 'usage', label: 'Usage', selected: hasUtilityAccount },
		{ id: 'billing', label: 'Billing History', selected: !hasUtilityAccount },
	]);

	const recentPayments = [
		{ id: 1, date: '1/20/20', type: 'Utilities', amount: 0 },
		{ id: 2, date: '1/20/20', type: 'Greenlight', amount: 0 },
		{ id: 3, date: '1/20/20', type: 'Greenlight', amount: 0 },
		{ id: 4, date: '1/20/20', type: 'Utilities', amount: 0 },
	];
	const importantDates = [
		{ id: 1, date: '1/20', name: 'Greenlight Bill Day' },
		{ id: 2, date: '1/21', name: 'Trash/Yardwaste Day' },
		{ id: 3, date: '1/29', name: 'Utility Bill Day' },
		{ id: 4, date: '2/2', name: 'Recycling Day' },
		{ id: 5, date: '2/2', name: 'Greenlight Payment Due Day' },
		{ id: 6, date: '2/2', name: 'Utility Payment Due Day' },
	];

	const getActiveGraphOption = () => {
		return graphOptions.find((option) => {
			return option.selected === true;
		});
	};

	useEffect(() => {
		// Set document title
		document.title = `My Dashboard | ${META_TITLE}`;
	}, []);

	// Format utility account services
	const services = (currentLocation?.UtilityAccount?.Services || []).map((service) => service.toLowerCase());
	const sprinkerIndex = services.indexOf('sprinkler');
	const waterIndex = services.indexOf('water');
	if (sprinkerIndex > -1 && waterIndex > -1 && sprinkerIndex < waterIndex) {
		services[sprinkerIndex] = 'water';
		services[waterIndex] = 'sprinkler';
	}

	return (
		<S.Wrapper showInvoices={currentLocation?.IsPrePay !== true}>
			<PageHeader
				action={[
					currentLocation?.IsPrePay === true && {
						id: 1,
						onClick: () => {
							history.push({ pathname: '/make-payment/amount', state: { backUrl: window.location.pathname } });
						},
						label: 'Pay Now',
						type: 'solid',
						size: 'medium',
					},
				].filter(Boolean)}
			>
				Dashboard
			</PageHeader>
			{currentLocation?.IsPrePay !== true && <CurrentInvoices className="a1" />}
			<Card
				title={getActiveGraphOption().id === 'billing' ? 'Billing History' : 'Utility Consumption'}
				className="a2"
				action={[
					hasUtilityAccount && {
						id: 1,
						component: <ButtonToggle options={graphOptions} setOptions={setGraphOptions} size="small" key="toggle" />,
					},
				].filter(Boolean)}
			>
				<ConsumptionUsage
					billingMode={getActiveGraphOption().id === 'billing'}
					hidePeriodSelector={getActiveGraphOption().id === 'billing'}
					utilities={services.map((service) => {
						return service.charAt(0).toUpperCase() + service.substr(1).toLowerCase();
					})}
				/>
			</Card>
			{currentLocation?.IsPrePay !== true ? <RecentBills className="a3" /> : <RecentPayments recentPayments={recentPayments} className="a3" />}
			<ImportantDates importantDates={importantDates} className="a4" />
			<ResourcesPanel title="Resources" type="resources" limit={4} action={[{ id: 1, label: 'View All', onClick: () => history.push('/resources'), type: 'text' }]} className="a5" />
			<ResourcesPanel title="Articles" type="articles" limit={4} action={[{ id: 1, label: 'View All', onClick: () => history.push('/articles'), type: 'text' }]} className="a6" />
		</S.Wrapper>
	);
}
