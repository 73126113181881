import { createGlobalStyle } from 'styled-components';

import colors from './colors';

const GlobalStyles = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-size: 100%;
	}
	html,
	body,
	#root {
		min-height: 100%;
		height: 100%;
	}
	html,
	body {
		overflow: hidden;
	}
	body, button, input {
		font-family: 'Source Sans Pro', sans-serif;
	}
	body {
		background-color: #e5e5e5;
	}
	a:hover {
		text-decoration: none;
	}
	*:focus,
	input:focus,
	input[type=submit]:focus,
	a:focus {
		outline: none;
		&:focus-visible {
			box-shadow: ${colors.focusShadow};
		}
	}
	.titlecase {
		text-transform: capitalize;
	}
	.react-datepicker-wrapper {
		display: block;
		width: 100%;
	}
	.react-datepicker__triangle {
		transform: translateX(38px) !important;
	}
	.go4109123758 {
		z-index: 999999999;
	}
	.customToaster {
		z-index: 999999999 !important;
	}
	.fixedWidthBadge {
		width: 76px;
		height: 5px;
		display: inline-flex;
	}
	.ReactModal {
		&__Overlay {
			opacity: 0;
			transition: opacity 200ms ease-in-out;
			background-color: rgba(0, 0, 0, 0.6) !important;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 99999999;

			&--after-open {
				opacity: 1;
			}

			&--before-close {
				opacity: 0;
			}
		}
		&__Content {
			background-color: white !important;
			position: relative !important;
			inset: auto !important;
			cursor: auto;
			display: block;
			align-items: center;
			width: 100%;
			border: none !important;
			border-radius: 0.25rem !important;
			max-width: 72rem; /* 1152px */
			margin: 1rem;
    		max-height: calc(100% - 2rem);
			padding: 0 !important;
			box-shadow: ${colors.sidebarShadow};
			overflow: visible !important;

			&.allowScroll {
				overflow: scroll !important;
			}
		}
	}

	.mobile-only {
		display: block;

		@media screen and (min-width: 768px) {
			display: none;
		}
	}

	.desktop-only {
		display: none;

		@media screen and (min-width: 768px) {
			display: block;
		}
	}

	.flex {
		display: flex;
	}
	.flex-row {
		flex-direction: row;
	}
	.flex-col {
		flex-direction: column;
	}
	.justify-between {
		justify-content: space-between;
	}
	.items-center {
		align-items: center;
	}
	.self-end {
		align-self: flex-end;
	}
	.flex-1 {
		flex: 1 1 0%;
	}
	.w-full {
		width: 100%;
	}

	.mt-2 {
		margin-top: 0.5rem;
	}
	.mt-4 {
		margin-top: 1rem;
	}

	.table-item {
		&--mobile {
			appearance: none;
			background-color: transparent;
			text-align: left;
			display: flex;
			flex-direction: column;
			border: 1px solid ${colors.neutralForm};
			padding: 0.75rem 1rem;

			.item-head {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 0.75rem;

				h4 {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.item-content {
				width: 100%;
				display: grid;
				gap: 0.75rem;
				grid-template-columns: repeat(auto-fit, minmax(120px, 1fr) );

				p {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
		&--desktop {
			display: flex;
			padding: 0.5rem 1.25rem;

			& > * + * {
				margin-left: 0.5rem;
			}

			.item-col {
				flex: 1;
			}
		}
	}
`;

export default GlobalStyles;
