import React, { useEffect, useState } from 'react';
import validator from 'validator';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { required } from '../../../utils/form-default-errors';

import { Card } from '../../../components/Card';
import { Toggle } from '../../../components/Toggle';
import { Button } from '../../../components/Button';
import { Typography } from '../../../components/Typography';
import * as S from './MaintenanceMode.styles';
import { TextInput } from '../../../components';
import { toast } from 'react-hot-toast';

export const MaintenanceMode = ({ className, configuration }) => {
	const [enableMaintenanceMode, setEnableMaintenanceMode] = useState(configuration?.enableMaintenanceMode);

	async function updateMaintenanceMode(enabled) {
		try {
			const token = window.localStorage.getItem('wilsonnc_token');
			await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/configuration/maintenance`, {
				enabled,
				loginToken: token,
			});
			toast.success(enabled ? 'Maintenance Mode has been enabled for all users.' : 'Maintenance Mode has been disabled.');
		} catch (e) {
			toast.error(e.message);
			reset(undefined, { keepValues: true });
		}
	}

	return (
		<>
			<Card className={className} title="Platform">
				<S.Wrapper>
					<S.ToggleItemWrapper>
						<div className="flex justify-between items-center">
							<Typography tag="h5" weight="bold" style={{ flexGrow: 1 }}>
								Enable Maintenance Mode
							</Typography>
							<Toggle
								checked={enableMaintenanceMode}
								onChange={(e) => {
									setEnableMaintenanceMode(e.target.checked);
									updateMaintenanceMode(e.target.checked);
								}}
							/>
						</div>
						<Typography tag="p" variation="2">
							The MyWilson customer portal will be unavailable for users while Maintenance Mode is enabled
						</Typography>
					</S.ToggleItemWrapper>
				</S.Wrapper>
			</Card>
		</>
	);
};

MaintenanceMode.propTypes = {
	className: PropTypes.string,
	configuration: PropTypes.shape(),
};
