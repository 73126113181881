import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Card } from '../Card';
import { Table, DateCell, CurrencyCell } from '../Table';
import * as S from './PastPayments.styles';
import { Spinner } from '../Spinner';
import { EmptyComponent } from '../EmptyComponent';
import { formatDate } from '../../utils/time-formatter';
import { walletTransactionLog } from '../../services/walletService';
import { tableSorterDate } from '../Table/Table';

export const PastPayments = ({ className, isUserDashboard, filterCount, onFilterButtonClick, filterSelectedOptions, wallet = [] }) => {
	const [status, setStatus] = useState('loading');
	const [payments, setPayments] = useState([]);
	const currentLocation = useSelector((state) => state.location.value);

	const [columns] = useState([
		{
			Header: 'Date',
			accessor: 'date',
			Cell: DateCell,
			CellName: 'DateCell',
			sortType: tableSorterDate,
		},
		{
			Header: 'Amount',
			accessor: 'amount',
			Cell: CurrencyCell,
			CellName: 'CurrencyCell',
		},
		{
			Header: 'Method',
			accessor: 'methodName',
		},
		{
			Header: 'Type',
			accessor: 'type',
		},
		{
			Header: 'Payment Type',
			accessor: 'paymentType',
		},
	]);

	async function init() {
		try {
			let paymentArray = [];
			let rawPayments = await walletTransactionLog({ ...(filterSelectedOptions.startDate ? { startDate: filterSelectedOptions.startDate } : undefined), ...(filterSelectedOptions.endDate ? { endDate: filterSelectedOptions.endDate } : undefined) });
			rawPayments = rawPayments.map((payment) => {
				return {
					...payment,
					Method: wallet.find((item) => item.WalletAccountKey === payment.WalletAccountKey)?.AccountType || 'Card',
				};
			});
			if (rawPayments.length > 0) {
				paymentArray = rawPayments.filter((payment) => {
					let validPayment = currentLocation?.LocationID === payment.LocationID;
					if (!validPayment) return validPayment;
					if (filterSelectedOptions.billTypes.utility === true || filterSelectedOptions.billTypes.greenlight === true) {
						if (payment.AccountType === 1 && !filterSelectedOptions.billTypes.utility) validPayment = false;
						if (payment.AccountType === 2 && !filterSelectedOptions.billTypes.greenlight) validPayment = false;
					}
					if (filterSelectedOptions.amount.min != null && filterSelectedOptions.amount.min !== -1 && filterSelectedOptions.amount.max != null && filterSelectedOptions.amount.max !== -1) {
						if (payment.PaymentAmount < filterSelectedOptions.amount.min || payment.PaymentAmount > filterSelectedOptions.amount.max) validPayment = false;
					}
					if (filterSelectedOptions.paymentMethods.card === true || filterSelectedOptions.paymentMethods.bankDraft === true || filterSelectedOptions.paymentMethods.eCheck === true) {
						if (payment.Method === 'Card' && filterSelectedOptions.paymentMethods.card !== true) validPayment = false;
						if (payment.Method === 'ACH' && filterSelectedOptions.paymentMethods.bankDraft !== true) validPayment = false;
					}
					if (filterSelectedOptions.paymentTypes.autopay === true || filterSelectedOptions.paymentTypes.manual === true) {
						if (filterSelectedOptions.paymentTypes.autopay && !filterSelectedOptions.paymentTypes.manual && !payment.IsAutoPay) validPayment = false;
						if (!filterSelectedOptions.paymentTypes.autopay && filterSelectedOptions.paymentTypes.manual && payment.IsAutoPay) validPayment = false;
					}
					return validPayment;
				});
			}
			paymentArray.sort((a, b) => new Date(b.EffectivePaymentDate).getTime() - new Date(a.EffectivePaymentDate).getTime());
			setPayments(
				paymentArray.map((payment) => {
					return {
						id: `${new Date(payment.EffectivePaymentDate).getTime()}`,
						date: formatDate(payment.EffectivePaymentDate),
						amount: payment.PaymentAmount,
						method: payment.Method,
						methodName: payment.WalletName,
						type: payment.AccountType === 1 ? 'Utility' : 'Greenlight',
						paymentType: payment.IsAutoPay ? 'Autopay' : 'Manual',
					};
				})
			);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, [filterSelectedOptions]);

	const renderContent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (payments.length === 0 && filterCount === 0) {
			return <EmptyComponent icon={['fal', 'credit-card']} title="No Past Payments" description="Check back to view your recent Utilities and Greenlight payments." />;
		}
		return <Table columns={columns} data={payments} isFilterButton={isUserDashboard} filterCount={filterCount} onFilterButtonClick={onFilterButtonClick} />;
	};

	return (
		<Card className={className} title="Past Payments">
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</Card>
	);
};
PastPayments.propTypes = {
	className: PropTypes.string,
	isUserDashboard: PropTypes.bool,
	filterCount: PropTypes.number,
	onFilterButtonClick: PropTypes.func,
	filterSelectedOptions: PropTypes.shape(),
	wallet: PropTypes.array,
};
