import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.statusBad : 'inherit')};
	}
`;

export const TextArea = styled.textarea`
	appearance: none;
	padding: 0.4375rem 0.75rem;
	border-radius: 0.25em;
	border: 1px solid ${colors.neutralForm};
	color: black;
	font-size: 1.125em;
	line-height: 1.375em;
	outline: none;
	min-height: 2.5rem;
	height: 5rem;

	&:focus {
		border-color: ${colors.neutralButton};
	}

	${({ error }) =>
		error &&
		css`
			color: ${colors.statusBad};
			border-color: ${colors.statusBad};
			:focus {
				border-color: ${colors.statusBad};
			}
		`}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1.125em;
	color: ${colors.statusBad};
`;
