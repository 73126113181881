import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { MakePaymentPaymentAmount, MakePaymentPaymentMethod, MakePaymentPaymentDate, MakePaymentReview, MakePaymentConfirmation } from '../../pages';

export default function MakePaymentRouter() {
	return (
		<Switch>
			<Route exact path="/make-payment/amount" component={MakePaymentPaymentAmount} />
			<Route exact path="/make-payment/method" component={MakePaymentPaymentMethod} />
			<Route exact path="/make-payment/date" component={MakePaymentPaymentDate} />
			<Route exact path="/make-payment/review" component={MakePaymentReview} />
			<Route exact path="/make-payment/confirmation" component={MakePaymentConfirmation} />
		</Switch>
	);
}
