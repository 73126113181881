/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { clsx } from '../../utils/clsx';
import { useMediaQuery } from 'beautiful-react-hooks';
import PropTypes from 'prop-types';

import { Button, OnboardingFooter, OnboardingHeader, TextInput, Typography, Stepper, Card, Radio } from '../../components';
import { pattern, required } from '../../utils/form-default-errors';
import * as S from './CurrentCustomerProfileSearch.styles';
import { SearchSearchingModal } from '../SearchSearchingModal';
import { SearchMatchFound } from '../SearchMatchFound';
import { SearchNoMatchesModal } from '../SearchNoMatchesModal';
import { currentCustomerResidentialProfileInformation } from '../../app/slices/onboarding/onboardingSlice';
import { META_TITLE } from '../../utils/constants';
import { searchAccounts } from '../../services/userService';

export default function CurrentCustomerProfileSearch({ isCommercial = false, showDashboard }) {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');

	const [uniqueIdentifier, setUinqueIdentifier] = useState('last5SSN');
	const [searchingModal, setSearchingModal] = useState(false);
	const [resultFoundModal, setResultFoundModal] = useState(false);
	const [resultNotFoundModal, setResultNotFoundModal] = useState(false);
	const [accounts, setAccounts] = useState({});

	const stepData = useSelector((state) => state.onboarding);
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		setFocus,
	} = useForm({ defaultValues: { ...stepData.currentCustomer.residential.profileInformation } });

	const onBack = () => {
		history.push('/onboarding/current-customer/building-type');
	};

	const onNext = (data) => {
		if (isCommercial) {
			history.push('/onboarding/current-customer/commercial/confirm');
		} else {
			history.push('/onboarding/current-customer/residential/confirm');
		}
	};

	useEffect(() => {
		const resetValues = ['greenLightAccountId', 'last5SSN', 'utilitiesAccountId'];
		resetValues.forEach((value) => {
			setValue(value, '');
		});
	}, [uniqueIdentifier]);

	const onSubmitForm = async (data) => {
		const { firstName, greenLightAccountId, last5SSN, lastName, utilitiesAccountId, businessName } = data;
		setSearchingModal(true);
		try {
			const data = await searchAccounts({ businessName, lastName, firstName, customerId: utilitiesAccountId || greenLightAccountId, customerSsn: last5SSN });
			const { unassignedUtilityAccounts, unassignedGreenlightAccounts } = data;
			let tempAccounts = { unassignedUtilityAccounts: [], unassignedGreenlightAccounts: [] };
			let totalAccounts = 0;
			if (unassignedUtilityAccounts?.length) {
				totalAccounts = unassignedUtilityAccounts?.length;
				tempAccounts = { ...tempAccounts, unassignedUtilityAccounts };
			}
			if (unassignedGreenlightAccounts?.length) {
				totalAccounts = totalAccounts + unassignedGreenlightAccounts?.length;
				tempAccounts = { ...tempAccounts, unassignedGreenlightAccounts };
			}
			if (totalAccounts) {
				setResultFoundModal(true);
			} else {
				setResultNotFoundModal(true);
			}
			setAccounts(tempAccounts);
		} catch (e) {
			console.log('error', e);
		}
		setSearchingModal(false);
	};

	const onUniqueIdentifierChange = (value) => {
		setUinqueIdentifier(value);
		setValue('uniqueIdentifier', value);
		setTimeout(() => {
			switch (value) {
				case 'greenLightAccountId':
					setFocus('greenLightAccountId');
					break;
				case 'last5SSN':
					setFocus('last5SSN');
					break;
				case 'utilitiesAccountId':
					setFocus('utilitiesAccountId');
					break;
			}
		}, 100);
	};

	useEffect(() => {
		// Set document title
		document.title = `Customer Search | ${META_TITLE}`;

		// Focus on SSN last 5
		setFocus('last5SSN');
	}, []);

	return (
		<>
			<SearchSearchingModal isOpen={searchingModal} setIsOpen={setSearchingModal} />
			<SearchMatchFound isOpen={resultFoundModal} setIsOpen={setResultFoundModal} data={accounts} onContinue={onNext} storeResults={true} />
			<SearchNoMatchesModal isOpen={resultNotFoundModal} setIsOpen={setResultNotFoundModal} showContinue={true} />
			<S.Form onSubmit={handleSubmit(onSubmitForm)}>
				<OnboardingHeader showDashboard={showDashboard} />
				<Stepper
					activeStep={1}
					steps={[
						{ id: 1, label: 'Location' },
						{ id: 2, label: 'Profile' },
						{ id: 3, label: 'Account' },
						{ id: 4, label: 'Billing' },
					]}
				/>
				<S.FormContentWrapper>
					<S.FormContent>
						<Typography tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold" center>
							Profile Search
						</Typography>
						<S.SubHeadingWrapper>
							<Typography tag="h6" weight="semibold" center>
								Enter the required information below to retrieve your profile and account information
							</Typography>
						</S.SubHeadingWrapper>
						<Card title="Profile Information">
							<Typography tag="p" variation="2">
								*Indicates a Required Field
							</Typography>
							{!isCommercial ? (
								<S.ApplicationGrid>
									<TextInput
										containerClassName="item-firstName"
										label="First Name"
										id="firstName"
										error={errors.firstName}
										{...register('firstName', {
											// required: required('First Name'),
										})}
									/>
									<TextInput
										containerClassName="item-lastName"
										label={`Last Name${!isCommercial ? ' *' : ''}`}
										id="lastName"
										error={errors.lastName}
										{...register('lastName', {
											required: !isCommercial && required('Last Name'),
										})}
									/>
								</S.ApplicationGrid>
							) : (
								<S.ApplicationGrid>
									<TextInput
										containerClassName="item-bussinessName"
										label={`Business Name${isCommercial ? ' *' : ''}`}
										id="businessName"
										error={errors.businessName}
										{...register('businessName', {
											required: isCommercial && required('Business Name'),
										})}
									/>
								</S.ApplicationGrid>
							)}
							<Typography tag="h6" weight="semibold">
								Unique Identifier
							</Typography>
							<Typography tag="p" variation="2">
								Please provide one of the following
							</Typography>
							<S.RadioInputs>
								<S.RadioInputContainer>
									<Radio id="last5SSNRadio" label="Last 5 SSN" value="last5SSN" onChange={(e) => onUniqueIdentifierChange(e.target.value)} checked={uniqueIdentifier === 'last5SSN'} />
									<TextInput
										containerClassName={clsx('radio-input', uniqueIdentifier === 'last5SSN' && 'checked')}
										label=""
										id="last5SSN"
										error={errors.last5SSN}
										{...register('last5SSN', {
											required: uniqueIdentifier === 'last5SSN' && required('Last 5 SSN'),
											pattern: pattern('Last 5 SSN', /^\d{5}$/),
										})}
									/>
								</S.RadioInputContainer>
								<S.RadioInputContainer>
									<Radio id="greenLightAccountIdRadio" label="Greenlight Account ID" value="greenLightAccountId" onChange={(e) => onUniqueIdentifierChange(e.target.value)} checked={uniqueIdentifier === 'greenLightAccountId'} />
									<TextInput
										containerClassName={clsx('radio-input', uniqueIdentifier === 'greenLightAccountId' && 'checked')}
										label=""
										id="greenLightAccountId"
										error={errors.greenLightAccountId}
										{...register('greenLightAccountId', {
											required: uniqueIdentifier === 'greenLightAccountId' && required('Greenlight Account ID'),
										})}
									/>
									{uniqueIdentifier === 'greenLightAccountId' && <S.ExampleFormat>xxxxxx-01</S.ExampleFormat>}
								</S.RadioInputContainer>
								<S.RadioInputContainer>
									<Radio id="utilitiesAccountIdRadio" label="Utilities Account ID" value="utilitiesAccountId" onChange={(e) => onUniqueIdentifierChange(e.target.value)} checked={uniqueIdentifier === 'utilitiesAccountId'} />
									<TextInput
										containerClassName={clsx('radio-input', uniqueIdentifier === 'utilitiesAccountId' && 'checked')}
										label=""
										id="utilitiesAccountId"
										error={errors.utilitiesAccountId}
										{...register('utilitiesAccountId', {
											//required: uniqueIdentifier === 'utilitiesAccountId' && required('Utilities Account ID'),
										})}
									/>
									{uniqueIdentifier === 'utilitiesAccountId' && <S.ExampleFormat>The Numbers Before the Dash</S.ExampleFormat>}
								</S.RadioInputContainer>
							</S.RadioInputs>
						</Card>
					</S.FormContent>
				</S.FormContentWrapper>
				<OnboardingFooter>
					<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
						<Typography tag="span" variation="1" weight="extrablack">
							Back
						</Typography>
					</Button>
					<Button variant="outline" type="submit">
						<Typography tag="span" variation="1" weight="extrablack">
							Search
						</Typography>
					</Button>
				</OnboardingFooter>
			</S.Form>
		</>
	);
}

CurrentCustomerProfileSearch.propTypes = {
	isCommercial: PropTypes.bool,
	showDashboard: PropTypes.bool,
};
