/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'beautiful-react-hooks';

// Components
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';

// Styles
import * as S from './AddressInput.styles';
import { addressLookup } from '../../services/userService';

/**
 * Component
 */

export const AddressInput = ({ value, onChange, containerClassName, ...rest }) => {
	// Set state
	const [availableOptions, setAvailableOptions] = useState([]);
	const [optionsOpen, setOptionsOpen] = useState(false);

	// Handle address search function
	const handleSearchAddresses = useDebouncedCallback(
		(address) => {
			if (address) {
				addressLookup({ searchFor: address })
					.then((candidates) => {
						if (candidates) {
							setAvailableOptions(candidates.filter((candidate) => candidate.indexOf('*EMPTY') === -1));
						} else {
							setAvailableOptions([]);
						}
					})
					.catch(() => {
						setAvailableOptions([]);
					});
			} else {
				setAvailableOptions([]);
			}
		},
		[],
		200
	);

	// Handle address content changed
	useEffect(() => {
		handleSearchAddresses(value);
	}, [value]);

	// Handle on change address
	const handleOnChange = (e) => {
		// On change callback
		onChange(e);
	};

	// Render component
	return (
		<S.AddressInputContainer className={containerClassName}>
			<TextInput
				onFocus={() => {
					setOptionsOpen(true);
				}}
				onKeyUp={() => {}}
				onBlur={() => {
					setOptionsOpen(false);
				}}
				value={value}
				onChange={handleOnChange}
				containerClassName={containerClassName}
				{...rest}
			/>
			<S.AddressDropdown className={optionsOpen && availableOptions.length > 0 ? 'animate show' : 'animate'}>
				{availableOptions.map((option) => (
					<S.AddressOption
						key={option}
						onClick={() => {
							handleOnChange({ target: { value: option } });
						}}
					>
						<Typography>{option}</Typography>
					</S.AddressOption>
				))}
			</S.AddressDropdown>
		</S.AddressInputContainer>
	);
};

/**
 * Configuration
 */

AddressInput.displayName = 'AddressInput';
AddressInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	containerClassName: PropTypes.string,
};
AddressInput.defaultProps = {
	value: '',
	onChange: null,
	containerClassName: null,
};
