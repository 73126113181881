import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { newCustomerResidentialUtilities } from '../../app/slices/onboarding/onboardingSlice';
import { Button, OnboardingFooter, OnboardingHeader, TextInput, Typography, Stepper, Card, DropFile, Radio, DatePicker, Checkbox, ButtonToggle } from '../../components';
import { META_TITLE, New_Cust_Res_Onb_Utils } from '../../utils/constants';
import * as S from './OnboardingNewCustomerResidentialUtilities.styles';
import { RadioInput } from '../../components/RadioInput/RadioInput';
import { required } from '../../utils/form-default-errors';

export default function OnboardingNewCustomerResidentialUtilities({ showDashboard }) {
	const [Meters, setMeters] = useState([
		{
			name: 'Electric',
			added: false,
		},
		{
			name: 'Water',
			added: false,
		},
		{
			name: 'Gas',
			added: false,
		},
		{
			name: 'Sprinkler',
			added: false,
		},
	]);
	const [emailNotifications, setEmailNotifications] = useState({
		highConsumption: false,
		possibleWaterLeak: false,
	});

	const stepData = useSelector((state) => state.onboarding);
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector((state) => state.user.value);

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		clearErrors,
		watch,
	} = useForm({ defaultValues: { ...stepData.newCustomer.residential.utilities, meters: Meters, utilityPay: 'Monthly', emailNotifications } });

	const utilityStartDate = watch('utilityStartDate');
	const utilityPayWatch = watch('utilityPay');
	const enableUtilitiesForProperty = watch('enableUtilitiesForProperty');

	useEffect(() => {
		if (utilityStartDate) {
			clearErrors('utilityStartDate');
		}
	}, [utilityStartDate]);

	const onBack = () => {
		history.push('/onboarding/new-customer/residential/application');
	};

	const onNext = (data) => {
		const tempData = { ...data };
		tempData.meters = Meters;
		tempData.emailNotifications = emailNotifications;
		tempData.enableUtilitiesForProperty = enableUtilitiesForProperty;
		dispatch(newCustomerResidentialUtilities(tempData));
		localStorage.setItem(New_Cust_Res_Onb_Utils, JSON.stringify(tempData));
		history.push('/onboarding/new-customer/residential/greenlight');
	};

	const meterAddedToggler = (index) => {
		const tempMeters = [...Meters];
		tempMeters[index].added = !tempMeters[index].added;
		setMeters(tempMeters);
		setValue('meters', Meters);
	};

	const emailNotificationsChange = (type) => {
		const tempObj = { ...emailNotifications };
		tempObj[type] = !emailNotifications[type];
		setEmailNotifications(tempObj);
		setValue('emailNotifications', tempObj);
	};

	const renderUtilityIcon = (utility) => {
		let path = '';
		switch (utility) {
			case 'Electric':
				path = 'electricity.jpg';
				break;
			case 'Water':
				path = 'water.jpg';
				break;
			case 'Sprinkler':
				path = 'water.jpg';
				break;
			case 'Gas':
				path = 'gas.jpg';
				break;
			case 'Wastewater':
				path = 'wastewater.jpg';
				break;
			case 'Stormwater':
				path = 'stormwater.jpg';
				break;
			case 'Solid Waste':
				path = 'stormwater.jpg';
				break;
		}
		return <S.UtilityIcon image={`${process.env.PUBLIC_URL}/icons/${path}`} />;
	};

	useEffect(() => {
		// Set document title
		document.title = `New Customer | ${META_TITLE}`;
		let tempData = localStorage.getItem(New_Cust_Res_Onb_Utils);
		tempData = tempData && JSON.parse(tempData);
		if (tempData) {
			setMeters(tempData?.meters);
			setEmailNotifications(tempData?.emailNotifications);
			setValue('utilityPay', tempData?.utilityPay);
			setValue('enableUtilitiesForProperty', tempData?.enableUtilitiesForProperty);
			if (tempData?.utilityStartDate) {
				setValue('utilityStartDate', new Date(tempData?.utilityStartDate));
			}
		}
	}, []);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<OnboardingHeader showDashboard={showDashboard} />
			<Stepper
				activeStep={2}
				steps={[
					{ id: 1, label: 'Location' },
					{ id: 2, label: 'Application' },
					{ id: 3, label: 'Utilities' },
					{ id: 4, label: 'Greenlight' },
					{ id: 5, label: 'Review' },
				]}
			/>
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag="h1" weight="bold" center>
						Utility and Billing Selection
					</Typography>
					<Card title="Utilities">
						<RadioInput
							label="Do you want to set up Utilities for your property?"
							id="enableUtilitiesForProperty"
							options={[
								{
									label: 'Yes',
									value: 'Yes',
								},
								{
									label: 'No',
									value: 'No',
								},
							]}
							{...register('enableUtilitiesForProperty', {
								required: required('Selection'),
							})}
							stateValue={enableUtilitiesForProperty}
							error={errors.enableUtilitiesForProperty}
							onChangeFunc={(id, value) => setValue(id, value)}
						/>

						{enableUtilitiesForProperty === 'Yes' && (
							<>
								<Typography tag="h4" weight="bold" style={{ marginTop: '20px' }}>
									Metered Utilities
								</Typography>
								<Typography tag="p">Please select the utilities you would like to activate for your house.</Typography>
								{Meters.map((meter, i) => (
									<S.MeterContainer key={meter.name}>
										{renderUtilityIcon(meter.name)}
										<Typography tag="h3" weight="bold">
											{meter.name}
										</Typography>
										<S.AddSymbol onClick={() => meterAddedToggler(i)}>{!meter.added ? <FontAwesomeIcon icon={['fal', 'plus-circle']} /> : <FontAwesomeIcon icon={['fas', 'check-circle']} className="checkCircleIcon" />}</S.AddSymbol>
									</S.MeterContainer>
								))}
								<S.NonMeteredUtilitiesContainer>
									<Typography tag="h4" weight="bold">
										Other Included Utilities
									</Typography>
									<Typography tag="p">Depending on the requested location, the following services may be included.</Typography>
									{Meters.find((meter) => meter.name === 'Water').added && (
										<S.NonMeteredUtility>
											<FontAwesomeIcon icon={['fal', 'check']} />
											{renderUtilityIcon('Wastewater')}
											<Typography tag="h3" weight="bold">
												Wastewater
											</Typography>
										</S.NonMeteredUtility>
									)}
									<S.NonMeteredUtility>
										<FontAwesomeIcon icon={['fal', 'check']} />
										{renderUtilityIcon('Stormwater')}
										<Typography tag="h3" weight="bold">
											Stormwater
										</Typography>
									</S.NonMeteredUtility>
									<S.NonMeteredUtility>
										<FontAwesomeIcon icon={['fal', 'check']} />
										{renderUtilityIcon('Solid Waste')}
										<Typography tag="h3" weight="bold">
											Solid Waste
										</Typography>
									</S.NonMeteredUtility>
								</S.NonMeteredUtilitiesContainer>
							</>
						)}
					</Card>

					{enableUtilitiesForProperty === 'Yes' && (
						<Card title="Settings">
							{/*<S.Card2ParaContainer>
								<Typography tag="h6" weight="semibold">
									How do you want to pay for your utilities?
								</Typography>
							</S.Card2ParaContainer>
							<S.RadioContainer>
								<Radio id="utilityPayMonth" label="Monthly" value="Monthly" onChange={(e) => setValue('utilityPay', e.target.value)} checked={utilityPayWatch === 'Monthly'} name="utilityPay" />
								<Typography tag="span" variation="3">
									You will receive a traditional monthly statement
								</Typography>
							</S.RadioContainer>
							<S.RadioContainer>
								<Radio id="utilityPayPrepaid" label="Prepaid" value="Prepaid" onChange={(e) => setValue('utilityPay', e.target.value)} checked={utilityPayWatch === 'Prepaid'} name="utilityPay" />
								<Typography tag="span" variation="3">
									Utilities are billed daily through an app. You simply maintain a positive balance on the account. An Advisor will contact you if this option is selected.
								</Typography>
							</S.RadioContainer>*/}
							<S.DatePickerContainer>
								<DatePicker
									{...register('utilityStartDate', {
										required: true,
									})}
									error={errors?.hasOwnProperty('utilityStartDate')}
									label="Utility Start Date"
									placeholderText="Select a Date"
									id="utilityStartDate"
									selected={utilityStartDate}
									onChange={(date) => setValue('utilityStartDate', date)}
									minDate={new Date()}
								/>
							</S.DatePickerContainer>
							<Typography tag="h6" weight="semibold">
								Email Notifications
							</Typography>
							<Typography tag="span" variation="2">
								Emails will be sent to {user.email || user.altEmail || 'the email you use to log in'}.
							</Typography>
							<S.CheckboxesWrapper>
								<Checkbox label="High Consumption" checked={emailNotifications.highConsumption} onChange={() => emailNotificationsChange('highConsumption')} />
								<Checkbox label="Possible Water Leak" checked={emailNotifications.possibleWaterLeak} onChange={() => emailNotificationsChange('possibleWaterLeak')} />
							</S.CheckboxesWrapper>
						</Card>
					)}
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}

OnboardingNewCustomerResidentialUtilities.propTypes = {
	showDashboard: PropTypes.bool,
};
