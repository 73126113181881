import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Toggle } from '../Toggle';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { AutoPayWalletModal } from '../AutoPayWalletModal';
import * as S from './BillingPreferences.styles';
import { RquestPlanChangeModal } from '../../pages';
import { useSelector } from 'react-redux';
import { getUserPaperlessSettings, updateAccountAutopay, updateUserPaperlessSettings } from '../../services/userService';
import toast from 'react-hot-toast';

export const BillingPreferences = ({ className }) => {
	const currentLocation = useSelector((state) => state.location.value);

	const [paperlessBilling, setPaperlessBilling] = useState(false);
	const [utilityAutoPay, setUtilityAutoPay] = useState(currentLocation?.UtilityAccount?.AutoPay);
	const [greenlightAutoPay, setGreenlightAutoPay] = useState(currentLocation?.GreenlightAccount?.AutoPay);
	const [requestPlanChangeModal, setRequestPlanChangeModal] = useState(false);
	const [autoPayWalletModalOpen, setAutoPayWalletModalOpen] = useState(false);
	const [autoPayWalletModalCompletion, setAutoPayWalletModalCompletion] = useState(null);
	const [accountType, setAccountType] = useState(null);
	const [accountKey, setAccountKey] = useState(null);
	const [selectedWalletPayment, setSelectedWalletPayment] = useState(null);

	const isMounted = useRef(true);

	const requestPlanChange = (type, key) => {
		setAccountType(type);
		setAccountKey(key);
		setRequestPlanChangeModal(true);
	};

	const performAutoPayUpdate = async (setFunction, accountKey, value, selectedMethod) => {
		// Update value
		setFunction(value);

		// Update autopay
		try {
			const isUpdated = await updateAccountAutopay({
				accountKey,
				autopayStatus: value,
				...(value === true
					? {
							walletAccount: selectedMethod?.WalletAccountKey,
					  }
					: undefined),
			});
			if (isUpdated !== true) {
				toast.error('There was an issue updating your AutoPay settings. Please try again.');
				setFunction(!value);
			}
		} catch (e) {
			toast.error(e.message);
			setFunction(!value);
		}
	};

	const performPaperlessUpdate = async (value) => {
		// Update value
		setPaperlessBilling(value);

		// Update paperless
		try {
			const isUpdated = await updateUserPaperlessSettings({
				status: value,
			});
			if (isUpdated !== true) {
				toast.error('There was an issue updating your Paperless Billing settings. Please try again.');
				setPaperlessBilling(!value);
			}
		} catch (e) {
			toast.error(e.message);
			setPaperlessBilling(!value);
		}
	};

	const updateAutoPay = async (setFunction, accountKey, value) => {
		if (!value) {
			performAutoPayUpdate(setFunction, accountKey, false);
		} else {
			setAutoPayWalletModalOpen(true);
			setSelectedWalletPayment(null);
			setAutoPayWalletModalCompletion(() => {
				return (selectedMethod) => {
					performAutoPayUpdate(setFunction, accountKey, true, selectedMethod);
				};
			});
		}
	};

	async function init() {
		try {
			const { PaperlessStatus } = await getUserPaperlessSettings();
			if (isMounted.current) {
				setPaperlessBilling(PaperlessStatus);
			}
		} catch (e) {}
	}

	useEffect(() => {
		// Set state
		isMounted.current = true;

		// Run init
		init();

		// Handle actions on dismount
		return () => {
			isMounted.current = false;
		};
	}, []);

	const generatePlanForAccount = (account) => {
		if (account.IsBudgetBilling === true) {
			return (
				<>
					<Typography tag="p">Budget</Typography>
					<Typography tag="p" variation="2">
						You will receive a statement for the same amount each month.
					</Typography>
				</>
			);
		}
		if (account.IsPrePay === true) {
			return (
				<>
					<Typography tag="p">Prepay</Typography>
					<Typography tag="p" variation="2">
						Your daily consumption will be charged from your active balance.
					</Typography>
				</>
			);
		}
		return (
			<>
				<Typography tag="p">Monthly</Typography>
				<Typography tag="p" variation="2">
					You will receive a traditional monthly statement
				</Typography>
			</>
		);
	};

	return (
		<>
			<Card className={className} title="Billing">
				<RquestPlanChangeModal isOpen={requestPlanChangeModal} setIsOpen={setRequestPlanChangeModal} accountKey={accountKey} accountType={accountType} />
				<S.Wrapper>
					<S.ToggleItemWrapper>
						<div className="flex justify-between items-center">
							<Typography tag="h5" weight="bold" style={{ flexGrow: 1 }}>
								Paperless Billing
							</Typography>
							<Toggle checked={paperlessBilling} onChange={(e) => performPaperlessUpdate(e.target.checked)} />
						</div>
						<Typography tag="p" variation="2">
							*You will receive 2 additional paper bills after signing up to ensure paperless billing is working
						</Typography>
					</S.ToggleItemWrapper>

					{currentLocation?.UtilityAccount && (
						<S.BillingWrapper>
							<Typography tag="h5" weight="bold" style={{ flexGrow: 1 }}>
								Utilities
							</Typography>
							<div>
								<div className="flex justify-between items-center">
									<Typography tag="h6" weight="semibold">
										Plan
									</Typography>
									{/*<Button type="button" size="small" variant="text" onClick={() => requestPlanChange('Utility', currentLocation?.UtilityAccount.AccountKey)}>
										<Typography tag="span" variation="2" weight="extrablack">
											Request Billing Change
										</Typography>
									</Button>*/}
								</div>
								{generatePlanForAccount(currentLocation?.UtilityAccount)}
							</div>
							<S.CardWrapper>
								<div className="flex justify-between items-center">
									<Typography tag="h6" weight="semibold" style={{ flexGrow: 1 }}>
										Autopay
									</Typography>
									<Typography tag="p" variation="2">
										Payment will be automatically drafted each month on the due date
									</Typography>
									<Toggle checked={utilityAutoPay} onChange={(e) => updateAutoPay(setUtilityAutoPay, currentLocation?.UtilityAccount.AccountKey, e.target.checked)} />
									{/*<S.InfoIcon icon={['fal', 'info-circle']} data-for="utilityAutoPay" data-tip />
									<ReactTooltip id="utilityAutoPay" backgroundColor={colors.brandPrimary} effect="solid">
										<span>To update your AutoPay settings, please submit a support request.</span>
									</ReactTooltip>*/}
								</div>
							</S.CardWrapper>
						</S.BillingWrapper>
					)}
					{currentLocation?.GreenlightAccount && (
						<S.BillingWrapper>
							<Typography tag="h5" weight="bold">
								Greenlight
							</Typography>
							<div>
								<div className="flex justify-between items-center">
									<Typography tag="h6" weight="semibold" style={{ flexGrow: 1 }}>
										Plan
									</Typography>
									<Button type="button" size="small" variant="text" onClick={() => requestPlanChange('Greenlight', currentLocation?.GreenlightAccount.AccountKey)}>
										<Typography tag="span" variation="2" weight="extrablack">
											Request Billing Change
										</Typography>
									</Button>
								</div>
								{generatePlanForAccount(currentLocation?.GreenlightAccount)}
							</div>
							<S.CardWrapper>
								<div className="flex justify-between items-center">
									<Typography tag="h6" weight="semibold" style={{ flexGrow: 1 }}>
										Autopay
									</Typography>
									<Typography tag="p" variation="2">
										Payment will be automatically drafted each month on the due date
									</Typography>
									<Toggle checked={greenlightAutoPay} onChange={(e) => updateAutoPay(setGreenlightAutoPay, currentLocation?.GreenlightAccount.AccountKey, e.target.checked)} />
									{/*<S.InfoIcon icon={['fal', 'info-circle']} data-for="greenlightAutoPay" data-tip />
									<ReactTooltip id="greenlightAutoPay" backgroundColor={colors.brandPrimary} effect="solid">
										<span>To update your AutoPay settings, please submit a support request.</span>
									</ReactTooltip>*/}
								</div>
							</S.CardWrapper>
						</S.BillingWrapper>
					)}
				</S.Wrapper>
			</Card>
			<AutoPayWalletModal
				isOpen={autoPayWalletModalOpen}
				onCompletion={autoPayWalletModalCompletion}
				selectedWalletPayment={selectedWalletPayment}
				setSelectedWalletPayment={setSelectedWalletPayment}
				onRequestClose={() => {
					setAutoPayWalletModalOpen(false);
				}}
			/>
		</>
	);
};

BillingPreferences.propTypes = {
	className: PropTypes.string,
};
