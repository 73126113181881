import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 710px;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const DetailContainer = styled.div`
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
`;

export const Service = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0.5rem 0;
`;

export const HorizontalLine = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.neutralForm};
	margin: 0.5rem 0;
`;
