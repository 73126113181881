import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const RadioContainers = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-bottom: 0.5rem;

	& > div {
		margin-bottom: 15px;
	}
	label {
		margin-bottom: 0;
	}

	label > span {
		font-weight: 700;
	}
`;

export const DateContainer = styled.div`
	margin-bottom: 1.25rem;
	margin-left: 1.625rem;
`;

export const DatePickerContainer = styled.div`
	margin-left: 1.72rem;
	margin-top: 0rem;
	width: 20rem;

	.minimum-highlight-date {
		background-color: ${colors.statusGood};
		color: ${colors.white};
		border-radius: 5px;
	}
`;

export const DueDateContainer = styled.div`
	margin: 0px 0px 15px;
`;

export const DateWarningContainer = styled.div`
	background-color: ${colors.statusBadLight};
	color: ${colors.statusBad};
	border-radius: 5px;
	padding: 8px;
	margin: 10px 0px 0px;
`;

export const AutoPayContainer = styled.div``;
