import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	padding: 1rem 1rem 0.25rem 1rem;
	border-bottom: 1px solid ${colors.neutralForm};
`;

export const Container = styled.div`
	max-width: 27.125rem;

	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin: 1.5rem auto 1rem;
	z-index: 0;
`;

export const ProgressBar = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	border-radius: 9999px;
	border: none;
	height: 0.3125rem;
	overflow: hidden;
	z-index: -1;
`;

export const Line = styled.div`
	flex: 1;
	background-color: ${colors.brandPrimary};

	${({ status }) => {
		switch (status) {
			case 'completed':
				return css`
					background-color: ${colors.brandPrimary};
				`;
			default:
				return css`
					background-color: ${colors.neutralForm};
				`;
		}
	}}
`;

export const Dot = styled.button`
	pointer-events: none;

	position: relative;
	appearance: none;
	border-width: 1px;
	border-style: solid;
	border-radius: 9999px;
	min-width: 1.375rem;
	width: 1.375rem;
	height: 1.375rem;

	display: flex;
	place-content: center;

	> span {
		position: absolute;
		visibility: hidden;
		top: calc(-100% - 0.5rem);
		white-space: nowrap;
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1.125rem;
		text-align: center;
		color: ${colors.brandPrimary};
	}

	${({ status }) => {
		switch (status) {
			case 'active':
				return css`
					background-color: white;
					border-width: 5px;
					border-color: ${colors.brandPrimary};

					> span {
						visibility: visible;
						top: calc(-100% - 1.25rem);
					}
				`;
			case 'completed':
				return css`
					background-color: ${colors.brandPrimary};
					border-color: ${colors.brandPrimary};

					> span {
						visibility: visible;
					}
				`;
			default:
				return css`
					background-color: ${colors.neutralForm};
					border-color: ${colors.neutralForm};
				`;
		}
	}}
`;
