import React, { useEffect } from 'react';

import { PageHeader, BillingPreferences, Wallet } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './Settings.styles';

export default function Settings() {
	useEffect(() => {
		// Set document title
		document.title = `Wallet | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<PageHeader>Wallet</PageHeader>
			<Wallet className="a1" />
			<BillingPreferences className="a2" />
		</S.Wrapper>
	);
}
