import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5.625rem 1rem 2rem 1rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 29.75rem;
	margin-top: 2rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const FormButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
