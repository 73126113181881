import toast from 'react-hot-toast';

import api from './api';

export const errorHandler = (error) => {
	toast.error(error.message || 'Something went wrong, try again later!');
};

const ALL_USER = 'All_Users_';

// ================All User Service Request Types=============
export async function getRequestTypes() {
	const {
		data: { RequestTypes, HasErrors, ErrorMsg },
	} = await api.get(`${ALL_USER}Service_Request_Types`);
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return RequestTypes;
	}
}

// ===============All User Document Types=============
export async function getDocumentTypes() {
	const {
		data: { DocumentTypes, HasErrors, ErrorMsg },
	} = await api.get(`${ALL_USER}Document_Types`);
	if (HasErrors) {
		throw new Error(ErrorMsg);
	} else {
		return DocumentTypes;
	}
}
