import { configureStore } from '@reduxjs/toolkit';

import greenlightReducer from './slices/greenlight/greenlightSlice';
import userReducer from './slices/user/userSlice';
import locationReducer from './slices/location/locationSlice';
import menuReducer from './slices/menu/menuSlice';
import notificationsReducer from './slices/notifications/notificationsSlice';
import onboardingReducer from './slices/onboarding/onboardingSlice';
import makePaymentReducer from './slices/makePayment/makePaymentSlice';
import manageUtilityReducer from './slices/manageUtility/manageUtilitySlice';
import configurationReducer from './slices/configuration/configurationSlice';

export default configureStore({
	reducer: {
		user: userReducer,
		greenlight: greenlightReducer,
		location: locationReducer,
		menu: menuReducer,
		notifications: notificationsReducer,
		onboarding: onboardingReducer,
		makePayment: makePaymentReducer,
		manageUtility: manageUtilityReducer,
		configuration: configurationReducer,
	},
});
