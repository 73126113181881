import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { notAuthenticatedLinks } from '../NotAuthenticatedRouter';
import { Navigation } from '../../components';
import { AdminHome, AdminUsers, AdminUsersDetails, AdminUsersProperty, AdminRequests, AdminLinks, AdminCommunityEvents, AdminEmployees, Page404, AdminEmployeesDetails, AdminProfile, AdminRequestsDetails, AdminConfiguration, AdminMakePayment, Logout } from '../../pages';
import { useSelector } from 'react-redux';
import { OTCPayment } from '../../components/Navigation/Modules';

export const adminAuthenticatedLinks = (user) =>
	[
		{ id: 1, to: '/', icon: ['fal', 'tachometer'], label: 'Dashboard' },
		{ id: 2, to: '/users', icon: ['fal', 'users'], label: 'Users' },
		user.IsAdmin && { id: 3, to: '/requests', icon: ['fal', 'file-alt'], label: 'Requests' },
		user.IsAdmin && { id: 4, to: '/links', icon: ['fal', 'link'], label: 'Links' },
		user.IsAdmin && { id: 5, to: '/community-events', icon: ['fal', 'calendar'], label: 'Community Events' },
		user.IsAdmin && { id: 6, to: '/configuration', icon: ['fal', 'cog'], label: 'Configuration' },
		/*{ id: 5, to: '/employees', icon: 'user', label: 'Employees' },*/
	].filter(Boolean);
const modules = [
	{
		id: 1,
		Module: OTCPayment,
		props: {},
	},
];

export default function AdminAuthenticatedRouter() {
	const user = useSelector((state) => state.user.value);

	return (
		<Navigation links={adminAuthenticatedLinks(user)} modules={modules} isAdmin>
			<Switch>
				{/* General */}
				<Route exact path="/" component={AdminHome} />
				<Route exact path="/logout" component={Logout} />

				{/* Users */}
				<Route exact path="/users" component={AdminUsers} />
				<Route exact path="/users/:userId" component={AdminUsersDetails} />
				<Route exact path="/users/:userId/unassociated" component={AdminUsersDetails} />
				<Route exact path="/users/:userId/property/:propertyId" component={AdminUsersProperty} />
				<Route exact path="/users/:userId/unassociated/property/:propertyId" component={AdminUsersProperty} />

				{/* Requests */}
				<Route exact path="/requests" component={AdminRequests} />
				<Route exact path="/requests/:requestId" component={AdminRequestsDetails} />

				{/* Links */}
				{user.IsAdmin && <Route exact path="/links" component={AdminLinks} />}

				{/* Community Events */}
				{user.IsAdmin && <Route exact path="/community-events" component={AdminCommunityEvents} />}

				{/* Configuration */}
				{user.IsAdmin && <Route exact path="/configuration" component={AdminConfiguration} />}

				{/* Payment */}
				<Route exact path="/payment/manual" component={AdminMakePayment} />

				{/* Employees */}
				{/*<Route exact path="/employees" component={AdminEmployees} />*/}
				{/*<Route exact path="/employees/:userId" component={AdminEmployeesDetails} />*/}

				{/* Profile */}
				<Route exact path="/profile" component={AdminProfile} />

				{/* Redirects */}
				{notAuthenticatedLinks.map(({ id, to }) => (
					<Route key={id} path={to} component={() => <Redirect to="/" />} />
				))}
				<Route path="*" component={Page404} />
			</Switch>
		</Navigation>
	);
}
