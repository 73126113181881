import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { Typography } from '../Typography';
import { Button } from '../Button';
import { Card } from '../Card';
import * as S from './Wallet.styles';
import { Spinner } from '../Spinner';
import { PaymentAddNewMethodModal, UpdatePaymentMethodModal } from '../../components';
import { walletRetrieveAccounts } from '../../services/walletService';
import { EmptyComponent } from '../EmptyComponent';
import { RemovePaymentMethodModal } from '../RemovePaymentMethodModal';
import colors from '../../styles/colors';

export const Wallet = ({ className }) => {
	const [status, setStatus] = useState('loading');
	const [addMethodModalOpen, setAddMethodModalOpen] = useState(false);
	const [removeMethodModalOpen, setRemoveMethodModalOpen] = useState(false);
	const [updateMethodModalOpen, setUpdateMethodModalOpen] = useState(false);
	const [activeAccount, setActiveAccount] = useState(null);
	const [paymentMethods, setPaymentMethods] = useState([]);

	const promptUpdateAccount = (account) => {
		setActiveAccount(account);
		setUpdateMethodModalOpen(true);
	};

	const promptRemoveAccount = (account) => {
		setActiveAccount(account);
		setRemoveMethodModalOpen(true);
	};

	async function fetchPaymentMethods() {
		try {
			const itemArray = await walletRetrieveAccounts();
			setPaymentMethods(itemArray);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		fetchPaymentMethods();
	}, []);

	const renderExpirationIndicator = (method) => {
		const expirationDate = method.CardExpirationDate;
		const currentDate = moment(moment().format('MM/YYYY'), 'MM/YYYY');
		const dateObj = moment(expirationDate, 'MM/YYYY').isValid() ? moment(expirationDate, 'MM/YYYY') : currentDate.add(1, 'month');
		if (dateObj.isSameOrBefore(currentDate)) {
			return (
				<S.ExpirationContainer>
					<S.InfoIcon icon={['fal', 'exclamation-triangle']} data-for={`expiration${method.WalletAccountKey}`} data-tip />
					<ReactTooltip id={`expiration${method.WalletAccountKey}`} backgroundColor={colors.statusBad} effect="solid">
						<span>This payment method has expired. Please update your card information or add a new card.</span>
					</ReactTooltip>
				</S.ExpirationContainer>
			);
		}
		return null;
	};

	const renderComponent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (paymentMethods.length === 0) {
			return <EmptyComponent icon={['fal', 'credit-card']} title="No payment methods" description="Add a new payment method to get started" />;
		}
		return (
			<S.Wrapper>
				{paymentMethods.map((method) => (
					<S.CardItem key={method.WalletAccountKey}>
						{method.AccountType === 'Card' && (
							<div className="card-item-wrapper">
								<div className="flex items-center cardAccountNameColumn">
									<FontAwesomeIcon icon={['fal', 'credit-card']} />
									<Typography tag="p" weight="semibold" className="cardAccountName">
										{method.AccountName}
									</Typography>
									{method.CardExpirationDate && renderExpirationIndicator(method)}
								</div>
								{method.CardBrand && <Typography tag="p">{method.CardBrand}</Typography>}
								<Typography tag="p">**** **** **** {method.CardLast4}</Typography>
							</div>
						)}
						{method.AccountType === 'ACH' && (
							<div className="card-item-wrapper">
								<div className="flex items-center cardAccountNameColumn">
									<FontAwesomeIcon icon={['fal', 'landmark']} />
									<Typography tag="p" weight="semibold" className="cardAccountName">
										{method.AccountName}
									</Typography>
								</div>
								<Typography tag="p">Bank Account</Typography>
								<Typography tag="p">**** {method.ACHRoutingNumber}</Typography>
							</div>
						)}
						<S.ButtonWrapper>
							<Button
								variant="text"
								variation="warning"
								onClick={() => {
									promptRemoveAccount(method);
								}}
							>
								<Typography tag="span" variation="2" weight="extrablack">
									Remove
								</Typography>
							</Button>
							<Button
								variant="text"
								onClick={() => {
									promptUpdateAccount(method);
								}}
							>
								<FontAwesomeIcon icon={['fal', 'edit']} />
								<Typography tag="span" variation="2" weight="extrablack">
									Edit
								</Typography>
							</Button>
						</S.ButtonWrapper>
					</S.CardItem>
				))}
			</S.Wrapper>
		);
	};
	return (
		<>
			<Card
				className={className}
				title="Wallet"
				action={[
					{
						id: 1,
						onClick: () => {
							setAddMethodModalOpen(true);
						},
						icon: ['fal', 'plus'],
						label: 'Payment Method',
					},
				]}
			>
				{renderComponent()}
			</Card>
			<PaymentAddNewMethodModal isOpen={addMethodModalOpen} onRequestClose={() => setAddMethodModalOpen(false)} afterSubmit={fetchPaymentMethods} showSaveOption={false} defaultSave={true} enableACHOption={true} />
			<UpdatePaymentMethodModal isOpen={updateMethodModalOpen} onRequestClose={() => setUpdateMethodModalOpen(false)} account={activeAccount} afterSubmit={fetchPaymentMethods} />
			<RemovePaymentMethodModal isOpen={removeMethodModalOpen} onRequestClose={() => setRemoveMethodModalOpen(false)} account={activeAccount} afterSubmit={fetchPaymentMethods} />
		</>
	);
};
Wallet.propTypes = {
	className: PropTypes.string,
};
