import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;

	.page-header {
		grid-area: header;
	}
	.a1 {
		grid-area: a1;
	}

	grid-template-areas:
		'header'
		'a1';
`;
