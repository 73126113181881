import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchPropertyById } from '../../services/adminService';
import { Button, Typography, PageHeader, Tab, Spinner, ErrorComponent, CurrentInvoices, BillingPreferences, NotificationsPreferences, UtilitiesPanel, GreenlightPanel, ScheduledPayments, PastPayments, InvoiceHistory } from '../../components';
import * as S from './AdminUsersProperty.styles';
import { META_TITLE } from '../../utils/constants';

const OverviewContent = ({ property }) => {
	// NV - REQUIRES API CHANGE

	return (
		<S.GridWrapper>
			<CurrentInvoices className="a1" hideMakePaymentButton />
			<BillingPreferences className="a2" />
			<NotificationsPreferences className="a3" />
		</S.GridWrapper>
	);
};

OverviewContent.propTypes = {
	property: PropTypes.shape(),
};

const ServicesContent = ({ property }) => {
	// NV - ADMIN - REQUIRES API CHANGE

	return (
		<S.GridWrapper>
			<UtilitiesPanel className="a1" />
			<GreenlightPanel className="a2" />
		</S.GridWrapper>
	);
};

ServicesContent.propTypes = {
	property: PropTypes.shape(),
};

const PaymentHistoryContent = ({ property }) => {
	// NV - ADMIN - REQUIRES API CHANGE

	return (
		<S.GridWrapper>
			<ScheduledPayments className="a1" />
			<PastPayments className="a2" />
		</S.GridWrapper>
	);
};

PaymentHistoryContent.propTypes = {
	property: PropTypes.shape(),
};

const StatementsContent = ({ property }) => {
	// NV - ADMIN - REQUIRES API CHANGE

	return (
		<S.GridWrapper>
			<InvoiceHistory className="a1" />
		</S.GridWrapper>
	);
};

StatementsContent.propTypes = {
	property: PropTypes.shape(),
};

export default function AdminUsersProperty() {
	const history = useHistory();
	const { userId, propertyId } = useParams();
	const [pageStatus, setPageStatus] = useState('idle');
	const [property, setProperty] = useState(null);
	const userEmail = decodeURIComponent(userId);
	const tabContent = [
		{ id: 1, label: 'Overview', component: <OverviewContent property={property} /> },
		{ id: 2, label: 'Services', component: <ServicesContent property={property} /> },
		{ id: 3, label: 'Payment History', component: <PaymentHistoryContent property={property} /> },
		{ id: 4, label: 'Statements', component: <StatementsContent property={property} /> },
	];
	const [tabs, setTabs] = useState(tabContent);

	async function initializeData() {
		setPageStatus('loading');
		try {
			const data = await fetchPropertyById(propertyId);
			setProperty(data);
			setPageStatus('succeeded');
		} catch (e) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		setTabs(tabContent);
	}, [property]);

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		// Set document title
		document.title = `Property Details | ${META_TITLE}`;
	}, []);

	const renderContent = () => {
		if (pageStatus === 'loading') {
			return <Spinner />;
		} else if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		if (pageStatus === 'succeeded') {
			return (
				<S.Wrapper>
					<S.HeaderWrapper>
						<Button variant="text" onClick={() => history.push(`/users/${encodeURIComponent(userEmail)}`)}>
							<FontAwesomeIcon icon={['fal', 'arrow-left']} />
							<Typography tag="span" variation="2" weight="extrablack">
								Return to Citizen Overview
							</Typography>
						</Button>
						<PageHeader>Property</PageHeader>
					</S.HeaderWrapper>
					<Tab tabs={tabs} setTabs={setTabs} />
				</S.Wrapper>
			);
		}

		return null;
	};

	return renderContent();
}
