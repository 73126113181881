export const sortResourcesEvents = (a, b) => {
	const now = new Date();
	const aStartDate = new Date(a.StartDate).getTime();
	const bStartDate = new Date(b.StartDate).getTime();
	const aEndDate = new Date(a.EndDate).getTime();
	const bEndDate = new Date(b.EndDate).getTime();
	let aTotal = aStartDate + bStartDate;
	let bTotal = bStartDate + bEndDate;
	if (aStartDate > now.getTime()) {
		aTotal = aTotal * 10;
	} else if (aEndDate < now.getTime()) {
		aTotal = aTotal * -10;
	}
	if (bStartDate > now.getTime()) {
		bTotal = bTotal * 10;
	} else if (bEndDate < now.getTime()) {
		bTotal = bTotal * -10;
	}
	return bTotal - aTotal;
};
