import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	box-shadow: ${colors.defaultShadow};
	z-index: 1;
	padding: 0 1em;
	min-height: 3.75rem;
	height: 3.75rem;

	@media screen and (min-width: 768px) {
		min-height: 3.9375rem;
		height: 3.9375rem;
	}
`;

export const Image = styled.img`
	width: 100%;
	max-width: 4.6875rem;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
`;

export const User = styled.div`
	display: grid;
	appearance: none;
	place-items: center;
	background-color: ${colors.brandPrimary};
	color: white;
	width: 1.875em;
	height: 1.875em;
	border-radius: 100%;
	border: none;
	cursor: pointer;

	& > h6 {
		color: white;
	}

	&:hover {
		opacity: 0.8;
	}

	@media screen and (min-width: 768px) {
		width: 2em;
		height: 2em;
	}
`;
