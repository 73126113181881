import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalForm = styled.form`
	max-width: 75rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	padding: 2.5rem 1rem;
	padding-bottom: 0;
`;

export const Wrapper = styled.div`
	width: 480px;
	margin: 0 auto;

	@media screen and (max-width: 556px) {
		width: 90%;
	}
`;

export const DatePickerContainer = styled.div`
	margin-top: 2rem;
	margin-bottom: 2.25rem;
	display: flex;
	width: 100%;

	label {
		color: ${colors.black};
		font-weight: 600;
	}
	.react-datepicker-wrapper {
		width: auto !important;
		flex-shrink: 0 !important;
		flex-grow: 1 !important;
		margin: 0 0 0 5px;

		&:first-child {
			margin: 0 5px 0 0;
		}
	}
`;

export const SliderContainer = styled.div`
	margin-bottom: 2rem;

	label {
		color: ${colors.black};
		font-weight: 600;
	}
`;

export const Checkboxes = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	& > h6 {
		margin-bottom: 1.125rem;
	}
	& > div {
		margin-bottom: 1.125rem;
	}
`;

export const Footer = styled.div`
	width: 95%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 1.125rem;
	padding-bottom: 1.5rem;
	border-top: 1px solid ${colors.neutralForm};

	@media screen and (max-width: 556px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const ResetBtn = styled.div`
	font-size: 1.125rem;
	color: ${colors.brandPrimary};
	font-weight: 600;
	text-decoration: underline;
	cursor: pointer;
`;

export const BtnCancel = styled.div`
	margin-right: 0.8rem;
`;

export const BtnWrapper = styled.div`
	display: flex;
	@media screen and (max-width: 556px) {
		margin-left: auto;
	}
`;
