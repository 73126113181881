import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { PageHeader, UtilitiesPanel, GreenlightPanel, Spinner } from '../../components';
import { retrieveDailyUsageHistory } from '../../services/userService';
import { META_TITLE } from '../../utils/constants';
import { ManageUtilityRequestEquipmentModal } from '../ManageUtilityRequestEquipmentModal';
import * as S from './Services.styles';

export default function Home() {
	const [status, setStatus] = useState('loading');
	const currentLocation = useSelector((state) => state.location.value);
	const [reqEquipmentModal, setReqEquipmentModal] = useState(false);
	const [meterData, setMeterData] = useState([]);

	async function init() {
		if (currentLocation?.UtilityAccount?.AccountKey) {
			try {
				const meters = await retrieveDailyUsageHistory({ accountKey: currentLocation?.UtilityAccount?.AccountKey, usageDays: 15 });
				setMeterData(meters);
				setStatus('success');
			} catch (e) {
				toast.error(e.message);
				setStatus('error');
			}
		} else {
			setStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Services | ${META_TITLE}`;

		// Retrieve usage data
		if (currentLocation?.UtilityAccount != null) {
			init();
		} else {
			setStatus('success');
		}
	}, []);

	return (
		<>
			<ManageUtilityRequestEquipmentModal isOpen={reqEquipmentModal} setIsOpen={setReqEquipmentModal} />
			<S.Wrapper>
				<PageHeader>Services</PageHeader>
				{status === 'loading' ? (
					<Spinner />
				) : (
					<>
						{currentLocation?.UtilityAccount != null && <UtilitiesPanel className="a1" meters={meterData} />}
						{currentLocation?.GreenlightAccount != null && <GreenlightPanel className="a2" setReqEquipmentModal={setReqEquipmentModal} />}
					</>
				)}
			</S.Wrapper>
		</>
	);
}
