import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		margin-bottom: 1.75rem;
		color: ${({ error }) => (error ? colors.statusBad : 'inherit')};
	}
	.MuiSlider-valueLabel {
		color: ${colors.black};
		background-color: transparent;
		font-size: 1.125rem;
		font-weight: 400;
		top: -1px;
	}
	.MuiSlider-root {
		height: 9px;
		color: ${colors.brandPrimary};
	}
	.MuiSlider-rail {
		color: ${colors.neutralForm};
	}
`;
