import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { newCustomerResidentialGreenLight } from '../../app/slices/onboarding/onboardingSlice';
import { OnboardingHeader, Stepper } from '../../components';
import * as S from './OnboardingNewCustomerCommercialGreenlight.styles';
import { META_TITLE, New_Cust_Com_Onb_Greenlight } from '../../utils/constants';
import { CustomerCommercialGreenlight } from '../../components/CustomerCommercialGreenlight';

export default function OnboardingNewCustomerCommercialGreenlight({ showDashboard }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const onBack = () => {
		history.push('/onboarding/new-customer/commercial/utilities');
	};

	const onNext = (data) => {
		dispatch(newCustomerResidentialGreenLight(data));
		localStorage.setItem(New_Cust_Com_Onb_Greenlight, JSON.stringify(data));
		history.push('/onboarding/new-customer/commercial/review');
	};

	useEffect(() => {
		// Set document title
		document.title = `New Customer | ${META_TITLE}`;
	}, []);

	const getDataFromLocalStorage = () => {
		let tempData = localStorage.getItem(New_Cust_Com_Onb_Greenlight);
		if (tempData) {
			tempData = JSON.parse(tempData);
			return tempData;
		}
		return {};
	};

	return (
		<S.Wrapper>
			<OnboardingHeader showDashboard={showDashboard} />
			<Stepper
				activeStep={3}
				steps={[
					{ id: 1, label: 'Location' },
					{ id: 2, label: 'Application' },
					{ id: 3, label: 'Utilities' },
					{ id: 4, label: 'Greenlight' },
					{ id: 5, label: 'Review' },
				]}
			/>
			<CustomerCommercialGreenlight onboarding pageHeading="Add Greenlight" getDataFromLocalStorage={getDataFromLocalStorage} onBackFunc={onBack} onNextFunc={onNext} allowNo={true} />
		</S.Wrapper>
	);
}

OnboardingNewCustomerCommercialGreenlight.propTypes = {
	showDashboard: PropTypes.bool,
};
