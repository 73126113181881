import axios from 'axios';

const api = axios.create({
	baseURL: process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_MOCK_API_URL : process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
	const token = window.localStorage.getItem('wilsonnc_token');
	if (token) {
		const headers = { ...config.headers };
		headers.Authorization = `Bearer ${token}`;
		return { ...config, headers };
	}
	return config;
});

export default api;
