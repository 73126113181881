import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { paymentDate } from '../../app/slices/makePayment/makePaymentSlice';

import { Button, OnboardingFooter, Typography, Stepper, Card, Radio, DatePicker, MakePaymentHeader, Toggle } from '../../components';
import * as S from './MakePaymentPaymentDate.styles';
import { META_TITLE } from '../../utils/constants';
import { formatDate } from '../../utils/time-formatter';

export default function MakePaymentPaymentDate() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');
	const currentLocation = useSelector((state) => state.location.value);
	const [dateWarning, setDateWarning] = useState(null);

	const stepData = useSelector((state) => state.makePayment);
	const payMethod = stepData?.paymentMethod;
	const dispatch = useDispatch();
	const history = useHistory();
	const today = new Date();

	let minimumDueDate = new Date(8640000000000000);
	if (currentLocation?.UtilityAccount?.CurrentDueDate) {
		const date = new Date(currentLocation?.UtilityAccount?.CurrentDueDate);
		if (date.getTime() < minimumDueDate.getTime()) {
			minimumDueDate = date;
		}
	}
	if (currentLocation?.GreenlightAccount?.CurrentDueDate) {
		const date = new Date(currentLocation?.GreenlightAccount?.CurrentDueDate);
		if (date.getTime() < minimumDueDate.getTime()) {
			minimumDueDate = date;
		}
	}

	const {
		handleSubmit,
		register,
		formState: { errors },
		setValue,
		watch,
		getValues,
	} = useForm({ defaultValues: { ...stepData.paymentDate, date: 'immediately', immediatelyDate: today, enableAutopay: false } });

	const date = watch('date');
	const otherDate = watch('otherDate');
	const enableAutopay = watch('enableAutopay');

	useEffect(() => {
		if (Object.keys(stepData.paymentAmount).length === 0 || Object.keys(stepData.paymentMethod).length === 0) {
			history.push('/');
		}
		const s = stepData.paymentDate;
		if (s?.date) {
			setValue('date', s?.date);
		}
		if (s?.enableAutopay != null) {
			setValue('enableAutopay', s?.enableAutopay);
		}
	}, []);

	useEffect(() => {
		if (otherDate != null && otherDate.getTime() > minimumDueDate.getTime()) {
			setDateWarning('The selected date is after one of your bills is due. Scheduled payments will not prevent disconnection.');
		} else {
			setDateWarning(null);
		}
	}, [otherDate]);

	const autopayTypes = () => {
		const types = [];
		if (stepData.paymentAmount.utilities === true) {
			types.push('Utilities');
		}
		if (stepData.paymentAmount.greenLight === true) {
			types.push('Greenlight');
		}
		return types.length === 1 ? `${types[0]} payment` : `${types.join(' & ')} payments`;
	};

	function formatAMPM(date) {
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		var strTime = hours + ':' + minutes + ' ' + ampm;
		return strTime;
	}

	const onBack = () => {
		history.push('/make-payment/method');
	};

	const onNext = (data) => {
		dispatch(paymentDate(data));
		history.push('/make-payment/review');
	};

	useEffect(() => {
		// Set document title
		document.title = `Make Payment | ${META_TITLE}`;
	}, []);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<MakePaymentHeader />
			<Stepper
				activeStep={2}
				steps={[
					{ id: 1, label: 'Amount' },
					{ id: 2, label: 'Payment' },
					{ id: 3, label: 'Date' },
					{ id: 4, label: 'Review' },
					{ id: 5, label: 'Confirmation' },
				]}
			/>
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Payment Date
					</Typography>
					<Card title="Select Payment Date">
						<S.DueDateContainer>
							{currentLocation?.UtilityAccount != null && (
								<Typography tag="p" variation="2">
									Utilities payment due <strong>{formatDate(currentLocation?.UtilityAccount?.CurrentDueDate)}</strong>
								</Typography>
							)}
							{currentLocation?.GreenlightAccount != null && (
								<Typography tag="p" variation="2">
									Greenlight payment due <strong>{formatDate(currentLocation?.GreenlightAccount?.CurrentDueDate)}</strong>
								</Typography>
							)}
						</S.DueDateContainer>
						<S.RadioContainers>
							<Radio id="immediately" label="Pay Now" value="immediately" checked={date === 'immediately'} onChange={(e) => setValue('date', e.target.value)} />
							{/*<S.DateContainer>
								<Typography tag="p" variation="1">
									{`${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`}
								</Typography>
							</S.DateContainer>*/}
							<Radio id="anotherDate" label="Select Another Date" value="anotherDate" checked={date === 'anotherDate'} onChange={(e) => setValue('date', e.target.value)} />
							{date === 'anotherDate' && (
								<S.DatePickerContainer>
									<DatePicker
										{...register('otherDate', {
											required: true,
										})}
										error={!otherDate && errors?.hasOwnProperty('otherDate')}
										label=""
										placeholderText="Select a Date"
										id="otherDate"
										selected={otherDate}
										onChange={(date) => setValue('otherDate', date)}
										minDate={new Date()}
										daysHighlighted={[minimumDueDate]}
									/>
									{dateWarning && (
										<S.DateWarningContainer>
											<Typography tag="p" variation="2" weight="semibold">
												{dateWarning}
											</Typography>
										</S.DateWarningContainer>
									)}
								</S.DatePickerContainer>
							)}
						</S.RadioContainers>
						{stepData.paymentMethod.WalletAccountKey && stepData.paymentMethod.WalletAccountKey !== 'new' && (
							<S.AutoPayContainer>
								<div className="flex justify-between items-center">
									<Typography tag="h5" weight="bold" style={{ flexGrow: 1 }}>
										Enable Autopay
									</Typography>
									<Toggle checked={enableAutopay} onChange={(e) => setValue('enableAutopay', e.target.checked)} />
								</div>
								<Typography tag="p" variation="2" style={{ marginTop: '8px' }}>
									The payment method selected for this payment ({`${payMethod?.AccountName || payMethod?.AccountType}${payMethod?.AccountType === 'Card' ? ` **** **** **** ${payMethod?.CardLast4}` : ''}`}) will be used to enroll all accounts being paid in this payment in autopay.
									If any of the selected accounts are already enrolled in autopay, the payment method will be updated. If you want to configure autopay for accounts individually, you can do so in your wallet settings.
								</Typography>
							</S.AutoPayContainer>
						)}
					</Card>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
