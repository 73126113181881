import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { required } from '../../utils/form-default-errors';
import { adminChangeRequestStatus, listStaffUsers, requestChangeOwner, requestTakeOwnership, retrieveAdminUserRequests } from '../../services/adminService';
import { Button, Typography, PageHeader, Spinner, AdminRequestApplication, Modal, Select, TextArea, AdminRequestDocuments, AdminRequestGreenlight, AdminRequestHistory, AdminRequestUtilities, ErrorComponent, AdminRequestMessages, AdminRequestDescription } from '../../components';
import * as S from './AdminRequestsDetails.styles';
import { BADGE_LABELS, META_TITLE } from '../../utils/constants';
import { formatDate, formatToFullDateWithTime } from '../../utils/time-formatter';
import { getDocumentTypes, getRequestTypes } from '../../services/allUsers';

const reasonForDecliningOptions = [
	{ value: 'incomplete', label: 'Incomplete' },
	{ value: 'complete', label: 'Complete' },
];

export default function AdminRequestsDetails() {
	const history = useHistory();
	const { requestId } = useParams();
	const [pageStatus, setPageStatus] = useState('idle');
	const [isVerifySubmitting, setIsVerifySubmitting] = useState(false);
	const [isTakeOwnershipSubmitting, setIsTakeOwnershipSubmitting] = useState(false);
	const [isAssignOwnershipSubmitting, setIsAssignOwnershipSubmitting] = useState(false);
	const [request, setRequest] = useState(null);
	const [verifyOpen, setVerifyOpen] = useState(false);
	const [requestUpdatesOpen, setRequestUpdatesOpen] = useState(false);
	const [takeOwnershipOpen, setTakeOwnershipOpen] = useState(false);
	const [assignOwnershipOpen, setAssignOwnershipOpen] = useState(false);
	const [staffMemberOptions, setStaffMemberOptions] = useState([]);

	const user = useSelector((state) => state.user.value);

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm();

	async function takeOwnership() {
		setIsTakeOwnershipSubmitting(true);
		try {
			const requestUpdated = await requestTakeOwnership({ key: request.RequestKey });
			setIsTakeOwnershipSubmitting(false);
			if (requestUpdated) {
				toast.success('This request has successfully been assigned to you.');
				await initializeData();
				setTakeOwnershipOpen(false);
			} else {
				toast.error('There was an issue updating this request. Please try again.');
			}
		} catch (e) {
			setIsTakeOwnershipSubmitting(false);
			toast.error(e.message);
		}
	}

	async function assignOwnership(data) {
		setIsAssignOwnershipSubmitting(true);
		try {
			const requestUpdated = await requestChangeOwner({ key: request.RequestKey, staffId: parseInt(data.staffMember, 10) });
			setIsAssignOwnershipSubmitting(false);
			if (requestUpdated) {
				toast.success('This request has successfully been assigned.');
				await initializeData();
				setAssignOwnershipOpen(false);
			} else {
				toast.error('There was an issue updating this request. Please try again.');
			}
		} catch (e) {
			setIsAssignOwnershipSubmitting(false);
			toast.error(e.message);
		}
	}

	async function closeOpenRequest() {
		setIsVerifySubmitting(true);
		try {
			const requestUpdated = await adminChangeRequestStatus({ requestKey: request.RequestKey, isOpen: !request.IsOpen });
			setIsVerifySubmitting(false);
			if (requestUpdated) {
				toast.success(request.IsOpen ? 'The request was successfully closed.' : 'The request was successfully reopened.');
				if (request.IsOpen) {
					setTimeout(() => {
						history.push('/');
					}, 2500);
				} else {
					await initializeData();
					setVerifyOpen(false);
				}
			} else {
				toast.error('There was an issue updating this request. Please try again.');
			}
		} catch (e) {
			setIsVerifySubmitting(false);
			toast.error(e.message);
		}
	}

	async function onRequestUpdatesSubmit(data) {
		// NV - ADMIN - FINISH
		toast.success(JSON.stringify(data));
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const types = await getRequestTypes();
			const documentTypes = await getDocumentTypes();
			const requestObjs = await retrieveAdminUserRequests({});
			const data = requestObjs.find((obj) => `${obj.RequestKey}` === requestId);
			if (data != null) {
				let status = 'incomplete';
				if (data.IsOpen === true) {
					status = 'new';
					if (data.AssignedTo) {
						status = 'inprogress';
					}
				} else if (data.ClosedDate != null) {
					status = 'closed';
				}

				setRequest({
					...data,
					id: data.RequestKey,
					RequestKey: data.RequestKey,
					username: data.PortalUser,
					requestType: types.find((type) => type.RequestTypeID === data.RequestType)?.RequestType,
					dateRequested: formatToFullDateWithTime(new Date(data.RequestDate)),
					assignedTo: data.AssignedTo || '',
					status,
					documentTypes,
					Documents: data.Documents || [],
					Messages: data.Messages || [],
				});
				setPageStatus('succeeded');
			} else {
				setPageStatus('error');
			}
		} catch (e) {
			setPageStatus('error');
		}
	}

	async function fetchStaffMembers() {
		const users = await listStaffUsers({});
		setStaffMemberOptions(users.map((user) => ({ value: `${user.UserID}`, label: user.User })));
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
			fetchStaffMembers();
		}
	}, [pageStatus]);

	useEffect(() => {
		if (assignOwnershipOpen === true) {
			reset();
		}
	}, [assignOwnershipOpen]);

	useEffect(() => {
		// Set document title
		document.title = `Request Details | ${META_TITLE}`;
	}, []);

	const renderContent = () => {
		if (pageStatus === 'loading') {
			return <Spinner />;
		} else if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		if (pageStatus === 'succeeded') {
			return (
				<>
					<S.Wrapper>
						<S.HeaderWrapper>
							<Button variant="text" onClick={() => history.push('/requests')}>
								<FontAwesomeIcon icon={['fal', 'arrow-left']} />
								<Typography tag="span" variation="2" weight="extrablack">
									Return to Requests
								</Typography>
							</Button>
							<PageHeader
								action={[
									/*{ id: 1, onClick: () => setRequestUpdatesOpen(true), label: 'Request Updates', type: 'outline' },*/
									user?.email.toLowerCase() !== request?.assignedTo.toLowerCase() && { id: 1, onClick: () => setTakeOwnershipOpen(true), label: "I'll handle this", type: 'outline' },
									{ id: 2, onClick: () => setAssignOwnershipOpen(true), label: 'Assign to...' },
									request.IsOpen ? { id: 3, onClick: () => setVerifyOpen(true), label: 'Close Request' } : { id: 3, onClick: () => setVerifyOpen(true), label: 'Reopen Request' },
								].filter(Boolean)}
								badge={BADGE_LABELS[request.status]}
							>
								{request?.PortalUser}
							</PageHeader>
						</S.HeaderWrapper>
						<S.GridWrapper>
							<AdminRequestApplication className="a1" request={request} />
							<AdminRequestDocuments className="a2" request={request} loadRequest={initializeData} />
							<AdminRequestDescription className="a3" request={request} />
							<AdminRequestMessages className="a4" request={request} />
							{/*<AdminRequestUtilities className="a5" request={request} />*/}
							{/*<AdminRequestGreenlight className="a6" request={request} />*/}
							{/*<AdminRequestHistory className="a6" request={request} />*/}
						</S.GridWrapper>
					</S.Wrapper>

					<Modal shouldCloseOnOverlayClick allowScroll isOpen={verifyOpen} onRequestClose={() => setVerifyOpen(false)}>
						<S.ModalWrapper>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									{request.IsOpen ? 'Close Request' : 'Open Request'}
								</Typography>
								<Typography className="mt-2" tag="h6" weight="semibold" center>
									{request.IsOpen ? 'Are you sure you want to close this request? You will be able to reopen it later.' : 'Are you sure you want to reopen this request?'}
								</Typography>
							</S.TitleWrapper>
							<S.FormButtonWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={() => setVerifyOpen(false)}>
									<Typography tag="span" variation="1" weight="extrablack">
										Cancel
									</Typography>
								</Button>
								<Button onClick={closeOpenRequest} disabled={isVerifySubmitting}>
									<Typography tag="span" variation="1" weight="extrablack">
										{request.IsOpen ? 'Close Request' : 'Open Request'}
									</Typography>
								</Button>
							</S.FormButtonWrapper>
						</S.ModalWrapper>
					</Modal>

					{/*<Modal shouldCloseOnOverlayClick allowScroll isOpen={requestUpdatesOpen} onRequestClose={() => setRequestUpdatesOpen(false)}>
						<S.ModalWrapper>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									Request Updates
								</Typography>
							</S.TitleWrapper>
							<S.Form onSubmit={handleSubmit(onRequestUpdatesSubmit)}>
								<Controller
									name="reasonForDeclining"
									control={control}
									rules={{ required: required('Reason for Declining') }}
									render={({ field: { onChange, value } }) => (
										<Select label="Reason for Declining" id="reason-for-declining" error={errors.reasonForDeclining} options={reasonForDecliningOptions} value={reasonForDecliningOptions.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />
									)}
								/>
								<TextArea
									label="Message"
									id="message"
									error={errors.message}
									{...register('message', {
										required: required('Message'),
									})}
								/>
								<S.FormButtonWrapper>
									<Button variant="outline" variation="secondary" type="button" onClick={() => setRequestUpdatesOpen(false)}>
										<Typography tag="span" variation="1" weight="extrablack">
											Cancel
										</Typography>
									</Button>
									<Button>
										<Typography tag="span" variation="1" weight="extrablack">
											{isSubmitting ? 'Loading...' : 'Send Request'}
										</Typography>
									</Button>
								</S.FormButtonWrapper>
							</S.Form>
						</S.ModalWrapper>
					</Modal>*/}

					<Modal shouldCloseOnOverlayClick allowScroll isOpen={takeOwnershipOpen} onRequestClose={() => setTakeOwnershipOpen(false)}>
						<S.ModalWrapper>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									Take Ownership of this Request
								</Typography>
								<Typography className="mt-2" tag="h6" weight="semibold" center>
									Are you sure you want to take ownership of this request? You will be able to reassign it later.
								</Typography>
							</S.TitleWrapper>
							<S.FormButtonWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={() => setTakeOwnershipOpen(false)}>
									<Typography tag="span" variation="1" weight="extrablack">
										Cancel
									</Typography>
								</Button>
								<Button onClick={takeOwnership} disabled={isTakeOwnershipSubmitting}>
									<Typography tag="span" variation="1" weight="extrablack">
										Take Ownership
									</Typography>
								</Button>
							</S.FormButtonWrapper>
						</S.ModalWrapper>
					</Modal>

					<Modal shouldCloseOnOverlayClick isOpen={assignOwnershipOpen} onRequestClose={() => setAssignOwnershipOpen(false)}>
						<S.ModalWrapper large>
							<S.TitleWrapper>
								<Typography tag="h1" weight="bold" center>
									Assign Ownership of this Request
								</Typography>
								<Typography className="mt-2" tag="h6" weight="semibold" center>
									Are you sure you want to assign ownership of this request? You will be able to reassign it later.
								</Typography>
							</S.TitleWrapper>
							<S.Form onSubmit={handleSubmit(assignOwnership)}>
								<Controller
									name="staffMember"
									control={control}
									rules={{ required: required('Staff Member') }}
									render={({ field: { onChange, value } }) => <Select label="Staff Member" id="staff-member" error={errors.staffMember} options={staffMemberOptions} value={staffMemberOptions.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
								/>
								<S.FormButtonWrapper>
									<Button variant="outline" variation="secondary" type="button" onClick={() => setAssignOwnershipOpen(false)}>
										<Typography tag="span" variation="1" weight="extrablack">
											Cancel
										</Typography>
									</Button>
									<Button type="submit" disabled={isAssignOwnershipSubmitting}>
										<Typography tag="span" variation="1" weight="extrablack">
											Assign Request
										</Typography>
									</Button>
								</S.FormButtonWrapper>
							</S.Form>
						</S.ModalWrapper>
					</Modal>
				</>
			);
		}
		return null;
	};

	return renderContent();
}
