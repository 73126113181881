import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 768px) {
		display: grid;
		grid-template-columns: 200px 1fr;
		grid-template-areas:
			'header header'
			'settings chart'
			'full full';
	}
`;

export const SpinnerWrapper = styled.div`
	height: 300px;
`;

export const HeaderWrapper = styled.div`
	grid-area: header;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	height: auto;
	flex-direction: column;
	gap: 12px;

	h4 {
		width: 100%;
		cursor: pointer;
	}
	@media screen and (min-width: 768px) {
		flex-direction: row;

		h4 {
			width: 250px;
		}
	}
`;

export const DatePickerContainer = styled.div`
	position: absolute;

	.datePicker {
		visibility: hidden;
		height: 0;
		padding: 0;
		width: 0;
		position: absolute;
	}
	.datePickerPopper {
		left: -60px;
	}
`;

export const HeaderLeft = styled.div``;

export const HeaderCenter = styled.div`
	flex-grow: 1;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	h4 {
		margin: 0 20px;
	}
	svg {
		color: ${colors.neutralButton};
	}
`;

export const HeaderRight = styled.div``;

export const ChartWrapper = styled.div`
	grid-area: ${({ billingMode }) => (billingMode ? 'full' : 'chart')};
	${({ billingMode }) =>
		billingMode &&
		css`
			margin-left: 35px;
		`};
	display: block;
	position: relative;
	min-height: 300px;

	svg {
		overflow: visible;
	}

	.recharts-responsive-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`;

export const SettingsWrapper = styled.div`
	grid-area: settings;
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${colors.neutralForm};
	margin-top: 0.5rem;
	padding-top: 0.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}

	@media screen and (min-width: 768px) {
		margin-top: 0;
		padding-top: 0;
		border: none;
	}
`;

export const BadgeWrapper = styled.div`
	display: flex;
	align-items: flex-start;

	.badgeElectric {
		color: ${colors.accentElectric};
		border: 1px solid ${colors.accentElectric};
		background-color: transparent;
		margin: 0 0.4rem 0.4rem 0;
		cursor: pointer;

		&.active {
			background-color: ${colors.accentElectric};
			border: 1px solid ${colors.accentElectric};
			color: white;
		}
	}
	.badgeGas {
		color: ${colors.accentGas};
		border: 1px solid ${colors.accentGas};
		background-color: transparent;
		margin: 0 0.4rem 0.4rem 0;
		cursor: pointer;

		&.active {
			background-color: ${colors.accentGas};
			border: 1px solid ${colors.accentGas};
			color: white;
		}
	}
	.badgeWater {
		color: ${colors.accentWater};
		border: 1px solid ${colors.accentWater};
		background-color: transparent;
		margin: 0 0.4rem 0.4rem 0;
		cursor: pointer;

		&.active {
			background-color: ${colors.accentWater};
			border: 1px solid ${colors.accentWater};
			color: white;
		}
	}
	.badgeSprinkler {
		color: ${colors.accentSprinkler};
		border: 1px solid ${colors.accentSprinkler};
		background-color: transparent;
		margin: 0 0.4rem 0.4rem 0;
		cursor: pointer;

		&.active {
			background-color: ${colors.accentSprinkler};
			border: 1px solid ${colors.accentSprinkler};
			color: white;
		}
	}

	& > div {
		margin-right: 0.75rem;

		&:first-child {
			margin-top: 0;
		}
	}

	@media screen and (min-width: 768px) {
		flex-direction: column;

		& > div {
			margin-top: 0.5rem;
		}
	}
`;

export const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > div {
		margin-top: 0.5rem;
	}
`;
