import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Modal, Checkbox, Button } from '../../components';
import * as S from './SearchMatchFound.styles';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Current_Cus_Onb_Conf_Acc } from '../../utils/constants';

const SearchMatchFound = ({ isOpen, setIsOpen, data, onContinue, storeResults = true }) => {
	const { unassignedUtilityAccounts, unassignedGreenlightAccounts } = data;

	const [utilityAccounts, setUtilityAccounts] = useState([]);
	const [greenLightAccounts, setGreenLightAccounts] = useState([]);
	const [allDataLength, setAllDataLength] = useState(-1);

	useEffect(() => {
		let totalLength = 0;
		if (unassignedUtilityAccounts?.length) {
			totalLength = unassignedUtilityAccounts?.length;
		}
		if (unassignedGreenlightAccounts?.length) {
			totalLength = totalLength + unassignedGreenlightAccounts?.length;
		}
		setAllDataLength(totalLength);
	}, [data]);

	useEffect(() => {
		if (isOpen === true) {
			setUtilityAccounts([]);
			setGreenLightAccounts([]);
			setAllDataLength(-1);
		}
	}, [isOpen]);

	const handleUtilityAccount = (account) => {
		const isIncludedBefore = utilityAccounts.some((item) => item.AccountNumber === account.AccountNumber && item.LocationID === account.LocationID);
		if (isIncludedBefore) {
			setUtilityAccounts(utilityAccounts.filter((acc) => !(acc.AccountNumber === account?.AccountNumber && acc.LocationID === account?.LocationID)));
		} else {
			setUtilityAccounts([...utilityAccounts, account]);
		}
	};

	const handleGreenLightAccount = (account) => {
		const isIncludedBefore = greenLightAccounts.some((item) => item.AccountNumber === account.AccountNumber && item.LocationID === account.LocationID);
		if (isIncludedBefore) {
			setGreenLightAccounts(greenLightAccounts.filter((acc) => !(acc.AccountNumber === account?.AccountNumber && acc.LocationID === account?.LocationID)));
		} else {
			setGreenLightAccounts([...greenLightAccounts, account]);
		}
	};

	const handleContinue = () => {
		const tempSelectedAccounts = {
			unassignedUtilityAccounts: utilityAccounts,
			unassignedGreenlightAccounts: greenLightAccounts,
		};
		if (storeResults) {
			localStorage.setItem(Current_Cus_Onb_Conf_Acc, JSON.stringify(tempSelectedAccounts));
		}
		onContinue(tempSelectedAccounts);
	};

	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<S.ModalContainer>
				<Typography tag={isHigherThan768 ? 'h1' : 'h3'} weight="bold" center>
					{allDataLength === 1 ? '1 Match Found' : `${allDataLength} Matches Found`}
				</Typography>
				<S.CheckboxesContainer>
					{unassignedUtilityAccounts?.map((item, index) => (
						<S.CheckboxWrapper key={index} onClick={() => handleUtilityAccount(item)}>
							<Checkbox checked={utilityAccounts.some((account) => account.AccountNumber === item.AccountNumber && account.LocationID === item.LocationID)} onChange={() => handleUtilityAccount(item)} />
							<S.CheckboxContentContainer>
								<Typography tag="h5" weight="bold">
									{item?.Address} (Utility Account)
								</Typography>
								<Typography tag="p" variation="2">
									Account Name: {item?.AccountName}
								</Typography>
							</S.CheckboxContentContainer>
						</S.CheckboxWrapper>
					))}
					{unassignedGreenlightAccounts?.map((item, index) => (
						<S.CheckboxWrapper key={index} onClick={() => handleGreenLightAccount(item)}>
							<Checkbox checked={greenLightAccounts.some((account) => account.AccountNumber === item.AccountNumber && account.LocationID === item.LocationID)} onChange={() => handleGreenLightAccount(item)} />
							<S.CheckboxContentContainer>
								<Typography tag="h5" weight="bold">
									{item?.Address} (Greenlight Account)
								</Typography>
								<Typography tag="p" variation="2">
									Account Name: {item?.AccountName}
								</Typography>
							</S.CheckboxContentContainer>
						</S.CheckboxWrapper>
					))}
				</S.CheckboxesContainer>
				<S.ButtonsContainer>
					<Button type="button" size="medium" variant="outline" onClick={() => setIsOpen(false)}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Edit Search
						</Typography>
					</Button>
					<S.ButtonWrapper>
						<Button type="button" size="medium" variant="solid" onClick={handleContinue} disabled={!(utilityAccounts?.length + greenLightAccounts?.length)}>
							<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
								Continue
							</Typography>
						</Button>
					</S.ButtonWrapper>
				</S.ButtonsContainer>
			</S.ModalContainer>
		</Modal>
	);
};
export default SearchMatchFound;

SearchMatchFound.propTypes = {
	isOpen: PropTypes.bool?.isRequired,
	setIsOpen: PropTypes.func?.isRequired,
	onContinue: PropTypes.func?.isRequired,
	data: PropTypes.object?.isRequired,
	storeResults: PropTypes.bool,
};
