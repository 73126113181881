import 'isomorphic-fetch';
import StoryblokClient from 'storyblok-js-client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import MBPsIcon from '../../../assets/300MBPs-icon.svg';
import GLMonitorIcon from '../../../assets/GL-Monitor-Icon.svg';
import GLDeskIcon from '../../../assets/GL-Desk-Icon.svg';
import GLDollarTV from '../../../assets/GL-Dollar-TV-Icon.svg';
import GLIcon from '../../../assets/GL-icon.svg';
import GLTVIcon from '../../../assets/GL-tv-icon.svg';
import GLTVEliteIcon from '../../../assets/GL-tv-elite-icon.svg';

const Storyblok = new StoryblokClient({
	accessToken: process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN,
	region: 'us',
	...(process.env.REACT_APP_ENV === 'development'
		? {
				cache: {
					clear: 'auto',
				},
		  }
		: undefined),
});

const initialValue = {
	internetPackages: [
		{
			id: 'economy',
			icon: GLMonitorIcon,
			title: 'GL Economy',
			value: 'GL Economy',
			subTitle: "Enough bandwidth for 1-5 devices connected to your home's network. Great for email, web search, social media, and occasional streaming.",
			details: ['50 Mbps', 'Public GL Wifi'],
			routerIncluded: false,
		},
		{
			id: 'choice',
			icon: GLIcon,
			title: 'GL Choice',
			value: 'GL Choice',
			subTitle: "Enough bandwidth for 5-10 devices connected to your home's network. Great for email, web search, social media, streaming, and a few smarthome devices.",
			details: ['300 Mbps', 'Public GL WiFi', 'Premium Wifi with Security', 'Router included'],
			routerIncluded: true,
		},
		{
			id: 'elite',
			icon: GLDeskIcon,
			title: 'GL Elite',
			value: 'GL Elite',
			subTitle: "Enough bandwidth for 10+ devices connected to your home's network. Used for email, web search, social media, streaming, multiple smarthome devices, remote learning, gaming, and work from home applications.",
			details: ['1 Gbps', 'Premium Whole Home WiFi', 'Router included'],
			routerIncluded: true,
		},
	],
	bundles: [
		{
			id: 'choice',
			icon: MBPsIcon,
			title: 'GL Choice Bundle',
			value: 'GL Choice Bundle',
			subTitle: '',
			details: ['300 Mbps', 'Public GL WiFi'],
			details2: ['Cloud DVR', 'Start over', 'Lookback', 'On Demand', 'Up to 3 TVs (ask our Customer Service Advisors for more details)'],
			subHeadings: ['Internet & Wifi', 'Cable'],
		},
		{
			id: 'elite',
			icon: MBPsIcon,
			title: 'GL Elite Bundle',
			value: 'GL Elite Bundle',
			subTitle: '',
			details: ['1 Gbps', 'Public GL WiFi', 'Premium Whole Home WiFi'],
			details2: ['Cloud DVR', 'Start over', 'Lookback', 'On Demand', 'Up to 5 TVs (ask our Customer Service Advisors for more details)'],
			subHeadings: ['Internet & Wifi', 'Cable'],
		},
	],
	configuration: {},
};

const getIconWithName = (icon) => {
	switch (icon) {
		case 'GLMonitorIcon':
			return GLMonitorIcon;
		case 'GLIcon':
			return GLIcon;
		case 'GLDeskIcon':
			return GLDeskIcon;
		case 'MBPsIcon':
			return MBPsIcon;
		case 'GLDollarTV':
			return GLDollarTV;
		case 'GLTVIcon':
			return GLTVIcon;
		case 'GLTVEliteIcon':
			return GLTVEliteIcon;
	}
};

export const fetchGreenlightSchema = createAsyncThunk('greenlight/fetchGreenlightSchema', async () => {
	try {
		// Fetch internet packages
		const {
			data: { stories: internetPackages },
		} = await Storyblok.get('cdn/stories', {
			version: 'published',
			starts_with: 'internet-packages/',
			page: 0,
			per_page: 100,
			language: 'en',
			sort_by: 'content.order:asc',
		});

		// Fetch internet packages
		const {
			data: { stories: bundles },
		} = await Storyblok.get('cdn/stories', {
			version: 'published',
			starts_with: 'bundles/',
			page: 0,
			per_page: 100,
			language: 'en',
			sort_by: 'content.order:asc',
		});

		// Fetch configuration
		const {
			data: { stories: configuration },
		} = await Storyblok.get('cdn/stories', {
			version: 'published',
			starts_with: 'configuration/',
			page: 0,
			per_page: 100,
			language: 'en',
		});

		// Build configuration object
		const configObj = {};
		configuration.forEach((config) => {
			configObj[config.content.id] = config.content;
		});

		// Return data
		const data = {
			internetPackages: internetPackages.map((entry) => ({
				...entry.content,
				icon: getIconWithName(entry.content.icon),
				details: entry.content.details.map((item) => item.title),
				label: entry.content.label?.filename,
			})),
			bundles: bundles.map((entry) => ({
				...entry.content,
				icon: getIconWithName(entry.content.icon),
				details: entry.content.details.map((item) => item.title),
				details2: entry.content.details2.map((item) => item.title),
				subHeadings: entry.content.subHeadings.map((item) => item.title),
			})),
			configuration: configObj,
		};
		return data;
	} catch (e) {}
	return initialValue;
});

export const greenlightSlice = createSlice({
	name: 'greenlight',
	initialState: {
		status: 'idle',
		error: null,
		value: initialValue,
	},
	reducers: {
		updateGreenlightSchema: (state, action) => {
			state.value = action.payload;
		},
		clearGreenlightSchema: (state) => {
			state.value = {
				internetPackages: [],
				bundles: [],
				configuration: {},
			};
		},
	},
	extraReducers: {
		[fetchGreenlightSchema.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchGreenlightSchema.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchGreenlightSchema.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});

export const { updateGreenlightSchema, clearGreenlightSchema } = greenlightSlice.actions;

export default greenlightSlice.reducer;
