import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './Checkbox.styles';

export const Checkbox = forwardRef(({ id, checked, label, disabled, ...rest }, ref) => {
	return (
		<S.Wrapper>
			<S.Checkbox ref={ref} id={id} {...rest} checked={checked} disabled={disabled} type="checkbox" aria-labelledby={id ? `${id}-label` : null} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} />
			<FontAwesomeIcon width="1em" icon={['fal', 'check']} />
			{label && (
				<label htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					<Typography style={{ display: 'flex' }}>{label}</Typography>
				</label>
			)}
		</S.Wrapper>
	);
});

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
	checked: PropTypes.bool,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
};
