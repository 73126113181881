import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: white;
	margin: -1rem;
	padding: 1rem;

	& > button {
		padding: 0;
		text-align: left;
		margin-bottom: 0.5rem;

		svg {
			width: 0.625rem !important;
		}
	}

	.page-header {
		margin-bottom: 0.5rem;
	}
`;

export const GridWrapper = styled.div`
	display: grid;
	gap: 1.25rem;

	grid-template-areas:
		'a1'
		'a2'
		'a3';

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}
`;
