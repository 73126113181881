import React, { useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Button, OnboardingFooter, Typography, Stepper, Card, MakePaymentHeader } from '../../components';
import * as S from './MakePaymentConfirmation.styles';
import moment from 'moment';
import { META_TITLE } from '../../utils/constants';
import { clearPayment } from '../../app/slices/makePayment/makePaymentSlice';

export default function MakePaymentConfirmation() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');
	const user = useSelector((state) => state.user.value);
	const stepData = useSelector((state) => state.makePayment);
	const payMethod = stepData?.paymentMethod;
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const getTotalBalance = () => {
		const formState = stepData?.paymentAmount;
		let balance = 0;
		(user?.locations || []).forEach(({ UtilityAccount, GreenlightAccount }) => {
			if (UtilityAccount) {
				const { AccountKey } = UtilityAccount;
				const { type, amount } = formState[AccountKey] || {};
				if (amount && type) {
					balance += parseFloat(`${amount}`);
				}
			}
			if (GreenlightAccount) {
				const { AccountKey } = GreenlightAccount;
				const { type, amount } = formState[AccountKey] || {};
				if (amount && type) {
					balance += parseFloat(`${amount}`);
				}
			}
		});
		return balance;
	};

	const onNext = (e) => {
		e.preventDefault();
		dispatch(clearPayment());
		history.push('/payment-history');
	};

	const getDate = () => {
		const s = stepData?.paymentDate;
		if (s?.date === 'anotherDate') {
			return moment(s?.otherDate).format('MM/DD/YY');
		}
		return moment(s?.immediatelyDate).format('MM/DD/YY');
	};

	useEffect(() => {
		// Set document title
		document.title = `Make Payment | ${META_TITLE}`;
	}, []);

	return (
		<S.Form onSubmit={onNext}>
			<MakePaymentHeader />
			<Stepper
				activeStep={4}
				steps={[
					{ id: 1, label: 'Amount' },
					{ id: 2, label: 'Payment' },
					{ id: 3, label: 'Date' },
					{ id: 4, label: 'Review' },
					{ id: 5, label: 'Confirmation' },
				]}
			/>
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Confirmation
					</Typography>
					<Card title={stepData?.paymentDate?.date === 'anotherDate' ? 'Thank You for Scheduling a Payment' : 'Thank You for Your Payment'}>
						<S.CardText>
							<Typography tag="h6">
								{stepData?.paymentDate?.date === 'anotherDate'
									? 'Your payment has been scheduled. We will contact you if there are any errors with your payment. It may take up to 72 hours after processing for your payment to be reflected in your account balance.'
									: 'Your payment is currently being processed. We will contact you if there are any errors with your payment. It may take up to 72 hours for your payment to be reflected in your account balance.'}
							</Typography>
						</S.CardText>
						<Typography tag="h5" weight="bold">
							Payment Details
						</Typography>
						<S.DetailsWrapper>
							<Typography tag="h6">Payment Amount: ${getTotalBalance().toFixed(2)}</Typography>
							<Typography tag="h6">Payment Date: {getDate()}</Typography>
							<Typography tag="h6">Payment Method: {payMethod?.AccountType === 'Card' ? `${payMethod?.AccountName || 'Card'} (**** **** **** ${payMethod?.CardLast4})` : `${payMethod?.AccountName || 'Bank Account'} (ACH)`}</Typography>
							{location.state?.authorizationCode && <Typography tag="h6">Confirmation Code: {location.state?.authorizationCode}</Typography>}
						</S.DetailsWrapper>
					</Card>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Back to Dashboard
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
