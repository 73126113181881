import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
	name: 'menu',
	initialState: {
		opened: false,
	},
	reducers: {
		openMenu: (state) => {
			state.opened = true;
		},
		closeMenu: (state) => {
			state.opened = false;
		},
		toggleOpenedMenu: (state) => {
			state.opened = !state.opened;
		},
	},
});

export const { openMenu, closeMenu, toggleOpenedMenu } = menuSlice.actions;

export default menuSlice.reducer;
