import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { addGreenlightSettings } from '../../app/slices/manageUtility/manageUtilitySlice';
import { Button, OnboardingFooter, Typography, Card, DatePicker, MakePaymentHeader } from '../../components';
import * as S from './AddGreenlightSettings.styles';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Manage_Greenlight_Settings, META_TITLE } from '../../utils/constants';

export default function AddGreenlightSettings() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');

	const stepData = useSelector((state) => state.manageUtility);
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		unregister,
	} = useForm({ defaultValues: { ...stepData.addGreenlightSettings, greenlightStartDate: new Date() } });

	const greenlightStartDate = watch('greenlightStartDate');

	const onBack = () => {
		history.push('/manage-greenlight/edit');
	};

	const onNext = (data) => {
		dispatch(addGreenlightSettings(data));
		localStorage.setItem(Manage_Greenlight_Settings, JSON.stringify(data));

		history.push('/manage-greenlight/review');
	};

	useEffect(() => {
		// Set document title
		document.title = `Manage Greenlight | ${META_TITLE}`;

		let tempGreenlight = localStorage.getItem(Manage_Greenlight_Settings);
		if (tempGreenlight) {
			tempGreenlight = JSON.parse(tempGreenlight);
		}
		if (tempGreenlight) {
			setValue('greenlightStartDate', new Date(tempGreenlight?.greenlightStartDate));
		}
	}, []);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<MakePaymentHeader />
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Greenlight Settings
					</Typography>
					<Card title="Added Services">
						<Typography tag="h6" weight="semibold">
							Greenlight Start Date
						</Typography>
						<Typography tag="span" variation="2">
							Subject to schedule availability. An Advisor will confirm an appointment date and time shortly.
						</Typography>
						<S.DatePickerContainer>
							<DatePicker
								{...register('greenlightStartDate', {
									required: true,
								})}
								selected={greenlightStartDate}
								error={errors?.hasOwnProperty('greenlightStartDate')}
								id="greenlightStartDate"
								onChange={(date) => setValue('greenlightStartDate', date)}
								minDate={new Date()}
							/>
						</S.DatePickerContainer>
					</Card>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Review Changes
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
