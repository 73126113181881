import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OnboardingHeader, OnboardingFooter, Card, Typography, Stepper, Button } from '../../components';
import { clsx } from '../../utils/clsx';
import * as S from './OnboardingNewCustomerBuildingType.styles';
import { META_TITLE } from '../../utils/constants';

export default function OnboardingNewCustomerBuildingType({ isCurrentCustomer = false, showDashboard }) {
	const [locationType, setBuildingType] = useState('residential');
	const history = useHistory();

	function onBack() {
		history.push('/onboarding');
	}

	const onNext = () => {
		if (isCurrentCustomer) {
			if (locationType === 'residential') {
				history.push('/onboarding/current-customer/residential/search');
			} else if (locationType === 'commercial') {
				history.push('/onboarding/current-customer/commercial/search');
			}
		} else {
			if (locationType === 'residential') {
				history.push('/onboarding/new-customer/residential/application');
			} else if (locationType === 'commercial') {
				history.push('/onboarding/new-customer/commercial/application');
			}
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `New Customer | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<OnboardingHeader showDashboard={showDashboard} />
			<Stepper
				activeStep={0}
				steps={[
					{ id: 1, label: 'Location' },
					{ id: 2, label: 'Application' },
					{ id: 3, label: 'Utilities' },
					{ id: 4, label: 'Greenlight' },
					{ id: 5, label: 'Review' },
				]}
			/>

			<S.ContentWrapper>
				<S.ContentInnerWrapper>
					<Typography tag="h1" weight="bold" center>
						Service Location
					</Typography>

					<Card title="Building">
						<Typography tag="h4" weight="bold">
							Location Type
						</Typography>
						<Typography tag="p">{isCurrentCustomer ? 'Please select an ownership type for the location you are activating.' : 'Please select the type of location you are activating.'}</Typography>

						<S.OptionsCard>
							<S.OptionButton onClick={() => setBuildingType('residential')}>
								<div className={clsx('circle', locationType === 'residential' && 'checked')} />
								<FontAwesomeIcon className="icon" icon="home" />
								<div className="content-info">
									<Typography tag="h4" weight="bold">
										{isCurrentCustomer ? 'Individual' : 'Residential'}
									</Typography>
									<Typography tag="p">{isCurrentCustomer ? 'The account is in the name of a person.' : 'The property is used as a personal residence.'}</Typography>
								</div>
							</S.OptionButton>
							<S.OptionButton onClick={() => setBuildingType('commercial')}>
								<div className={clsx('circle', locationType === 'commercial' && 'checked')} />
								<FontAwesomeIcon className="icon" icon="building" />
								<div className="content-info">
									<Typography tag="h4" weight="bold">
										{isCurrentCustomer ? 'Organization' : 'Commercial'}
									</Typography>
									<Typography tag="p">{isCurrentCustomer ? 'The account is not in the name of a person.' : 'The property is used for business activities.'}</Typography>
								</div>
							</S.OptionButton>
						</S.OptionsCard>
					</Card>
				</S.ContentInnerWrapper>
			</S.ContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" onClick={onNext} disabled={!locationType}>
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Wrapper>
	);
}

OnboardingNewCustomerBuildingType.propTypes = {
	isCurrentCustomer: PropTypes.bool,
	showDashboard: PropTypes.bool,
};
