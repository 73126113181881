import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;

	font-family: monospace, monospace;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const Items = styled.div`
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(1, 1fr);

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const ItemWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const ContentSection = styled.div`
	padding: 20px 20px 0px;
	border-radius: 0.25em;

	div {
		border-radius: 0.25em;
	}
	.background {
		background-color: ${colors.neutralBackground};
		padding: 20px;

		* {
			padding-left: 0px !important;
		}
		*:first-child {
			padding-top: 0px !important;
		}
	}
	.upper {
		text-transform: capitalize;
	}
`;
