import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	padding-bottom: 3rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 44.375rem;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
`;

export const UtilitiesContainer = styled.div`
	padding-bottom: 1.5rem;
	border-bottom: 1px solid ${colors.neutralForm};
	margin-bottom: 0.5rem;

	& > p {
		margin-top: 0.9rem;
	}
`;

export const DetailContainer = styled.div`
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
`;
