import styled from 'styled-components';

export const Wrapper = styled.div`
	border: 1px solid #cdd5de;
	padding: 1.2rem 1.5rem;
	border-radius: 4px;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Heading = styled.div`
	display: flex;
	align-items: center;
`;

export const PackageImg = styled.img`
	margin-left: 0.5rem;
`;

export const PackagePrice = styled.div`
	margin-top: -0.5rem;
`;

export const PackageSubHeading = styled.div`
	margin-top: 0.9rem;
	margin-bottom: 1.8rem;
`;
export const PackageDetailsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-gap: 1rem;
	}
`;

export const PackageDetails = styled.div``;

export const SubHeadingWrapper = styled.div`
	margin-bottom: 0.6rem;
`;

export const LabelImageContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0px 0px;
`;

export const LabelImage = styled.img`
	width: 100%;
`;
