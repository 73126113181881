import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './AutoPayWalletModal.styles';
import { useMediaQuery } from 'beautiful-react-hooks';

import { Button, Modal, Typography, Radio, EmptyComponent, Spinner } from '../../components';
import { useEffect } from 'react';
import { walletRetrieveAccounts } from '../../services/walletService';

const AutoPayWalletModal = ({ isOpen, onCompletion, selectedWalletPayment, setSelectedWalletPayment, onRequestClose }) => {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const [status, setStatus] = useState('loading');
	const [paymentMethods, setPaymentMethods] = useState([]);

	const onSubmit = async (selectedMethod) => {
		// After submit actions
		onCompletion(selectedMethod);

		// Close modal
		onRequestClose();
	};

	async function fetchPaymentMethods() {
		try {
			const itemArray = await walletRetrieveAccounts();
			setPaymentMethods(itemArray);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		if (isOpen === true) {
			fetchPaymentMethods();
		}
	}, [isOpen]);

	const renderComponent = () => {
		if (status === 'loading') {
			return <Spinner />;
		}
		if (paymentMethods.length === 0) {
			return <EmptyComponent icon={['fal', 'credit-card']} title="No payment methods" description="Add a new payment method to get started" />;
		}
		return (
			<S.RadioContainers>
				{paymentMethods?.map((payMethod, index) => (
					<Radio
						key={payMethod?.WalletAccountKey}
						id={'paymentMethod' + index}
						label={payMethod?.AccountType === 'Card' ? `${payMethod?.AccountName} (**** **** **** ${payMethod?.CardLast4})` : `${payMethod?.AccountName} (ACH)`}
						value={payMethod?.WalletAccountKey}
						checked={selectedWalletPayment?.WalletAccountKey === payMethod?.WalletAccountKey}
						onChange={() => setSelectedWalletPayment(payMethod)}
					/>
				))}
			</S.RadioContainers>
		);
	};
	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onRequestClose}>
			<S.ModalContainer>
				<S.HeadingContainer>
					<Typography center tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold">
						Set up AutoPay
					</Typography>
					<Typography center tag="p">
						Select a payment method to set up AutoPay for your account.
					</Typography>
				</S.HeadingContainer>
				<S.ModalMainWrapper>{renderComponent()}</S.ModalMainWrapper>
				<S.ButtonsContainer>
					<Button type="button" variant="outline" variation="secondary" onClick={onRequestClose}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button
						variant="solid"
						onClick={() => {
							onSubmit(selectedWalletPayment);
						}}
						disabled={!paymentMethods || paymentMethods.length === 0 || selectedWalletPayment == null}
					>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Save &amp; Continue
						</Typography>
					</Button>
				</S.ButtonsContainer>
			</S.ModalContainer>
		</Modal>
	);
};
export default AutoPayWalletModal;

AutoPayWalletModal.propTypes = {
	isOpen: PropTypes.bool?.isRequired,
	onRequestClose: PropTypes.func?.isRequired,
	onCompletion: PropTypes.func,
	selectedWalletPayment: PropTypes.shape(),
	setSelectedWalletPayment: PropTypes.func?.isRequired,
};
