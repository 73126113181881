import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

const SIZES = {
	small: css`
		padding: 0.4375em 0.8125em;
		flex-grow: 1;
	`,
	medium: css`
		padding: 0.5625em 1.5em;
		flex-grow: 1;
	`,
};

export const Wrapper = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: space-between;

	${({ size }) => css`
		${Button} {
			${SIZES[size]}
		}
	`}
`;

export const Button = styled.button.attrs({ type: 'button' })`
	display: flex;
	justify-content: center;
	align-items: center;
	appearance: none;
	background-color: ${({ active, color }) => (active ? colors[color] : 'white')};
	color: ${({ active, color }) => (active ? 'white' : colors[color])};
	border: ${({ color }) => `1px solid ${colors[color]}`};
	letter-spacing: 1.5px;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	svg {
		font-size: 0.9rem;
	}

	&:first-child {
		border-top-left-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}
	&:last-child {
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
	}
`;
