import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { Button, OnboardingFooter, TextInput, Typography, Card, Toggle } from '..';
import { ChoicePackage } from '../ChoicePackage/ChoicePackage';
import * as S from './CustomerResidentialGreenlight.styles';
import { required } from '../../utils/form-default-errors';

import { RadioInput } from '../RadioInput/RadioInput';
import { New_Cust_Res_Onb_Greenlight } from '../../utils/constants';
import toast from 'react-hot-toast';

/**
 * Component
 */

export const CustomerResidentialGreenlight = ({ onboarding = false, pageHeading = '', getDataFromLocalStorage, onBackFunc, onNextFunc, selectionCard = () => null, allowNo = true }) => {
	const stepData = useSelector((state) => state.onboarding);

	const { internetPackages, bundles, configuration } = useSelector((state) => state.greenlight.value);

	// Create form
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		unregister,
	} = useForm({ defaultValues: { ...stepData.newCustomer.residential.greenLight } });

	// Watch form state
	const isGreenlightInternetService = watch('isGreenlightInternetService');
	const selectedServices = watch('selectedServices');
	const internetSelection = watch('internetSelection');
	const routerNeeded = watch('routerNeeded');
	const internetCableBundleSelection = watch('internetCableBundleSelection');
	const keepPhoneNumber = watch('keepPhoneNumber');
	const phoneNumber = watch('phoneNumber');
	const phoneProvider = watch('phoneProvider');
	const phoneProviderAccountNumber = watch('phoneProviderAccountNumber');

	// Create back handler
	const onBack = () => {
		onBackFunc();
	};

	// Create next handler
	const onNext = (data) => {
		if (allowNo === false && isGreenlightInternetService !== 'Yes') {
			toast.error('Please add at least one Greenlight service to continue.');
			return;
		}
		if (isGreenlightInternetService === 'Yes' && (selectedServices === 'internet' || selectedServices === 'internet-and-phone') && !internetSelection) {
			toast.error('Please select an Internet Package to continue.');
			return;
		}
		if (isGreenlightInternetService === 'Yes' && (selectedServices === 'internet-and-cable' || selectedServices === 'internet-cable-and-phone') && !internetCableBundleSelection) {
			toast.error('Please select a Greenlight Bundle to continue.');
			return;
		}

		// Run next function
		onNextFunc(data);
	};

	// Handle setting existing state
	useEffect(() => {
		let tempData = localStorage.getItem(New_Cust_Res_Onb_Greenlight);
		tempData = getDataFromLocalStorage();
		if (tempData) {
			const { isGreenlightInternetService, selectedServices, internetSelection, routerNeeded, internetCableBundleSelection, keepPhoneNumber, phoneNumber, phoneProvider, phoneProviderAccountNumber } = tempData;
			setValue('isGreenlightInternetService', isGreenlightInternetService);
			setValue('selectedServices', selectedServices);
			setValue('internetSelection', internetSelection);
			setValue('routerNeeded', routerNeeded);
			setValue('internetCableBundleSelection', internetCableBundleSelection);
			setValue('keepPhoneNumber', keepPhoneNumber);
			setValue('phoneNumber', phoneNumber);
			setValue('phoneProvider', phoneProvider);
			setValue('phoneProviderAccountNumber', phoneProviderAccountNumber);
		}
	}, []);

	// Handle updating state on update
	useEffect(() => {
		if (isGreenlightInternetService === 'No') {
			// Clear values
			setValue('selectedServices', null);
			setValue('internetSelection', null);
			setValue('routerNeeded', null);
			setValue('internetCableBundleSelection', null);
			setValue('keepPhoneNumber', null);
			setValue('phoneNumber', null);
			setValue('phoneProvider', null);
			setValue('phoneProviderAccountNumber', null);

			// Unregister fields
			unregister('selectedServices');
			unregister('internetSelection');
			unregister('routerNeeded');
			unregister('internetCableBundleSelection');
			unregister('keepPhoneNumber');
			unregister('phoneNumber');
			unregister('phoneProvider');
			unregister('phoneProviderAccountNumber');
		}
		if (selectedServices === 'internet') {
			// Clear values
			setValue('internetCableBundleSelection', null);
			setValue('keepPhoneNumber', null);
			setValue('phoneNumber', null);
			setValue('phoneProvider', null);
			setValue('phoneProviderAccountNumber', null);

			// Unregister fields
			unregister('internetCableBundleSelection');
			unregister('keepPhoneNumber');
			unregister('phoneNumber');
			unregister('phoneProvider');
			unregister('phoneProviderAccountNumber');
		} else if (selectedServices === 'internet-and-cable') {
			// Clear values
			setValue('internetSelection', null);
			setValue('routerNeeded', null);
			setValue('keepPhoneNumber', null);
			setValue('phoneNumber', null);
			setValue('phoneProvider', null);
			setValue('phoneProviderAccountNumber', null);

			// Unregister fields
			unregister('internetSelection');
			unregister('routerNeeded');
			unregister('keepPhoneNumber');
			unregister('phoneNumber');
			unregister('phoneProvider');
			unregister('phoneProviderAccountNumber');
		} else if (selectedServices === 'internet-and-phone') {
			// Clear values
			setValue('internetCableBundleSelection', null);

			// Unregister fields
			unregister('internetCableBundleSelection');
		} else if (selectedServices === 'internet-cable-and-phone') {
			// Clear values
			setValue('internetSelection', null);
			setValue('routerNeeded', null);

			// Unregister fields
			unregister('internetSelection');
			unregister('routerNeeded');
		}
		if (keepPhoneNumber === 'No') {
			// Clear values
			setValue('phoneNumber', null);
			setValue('phoneProvider', null);
			setValue('phoneProviderAccountNumber', null);

			// Unregister fields
			unregister('phoneNumber');
			unregister('phoneProvider');
			unregister('phoneProviderAccountNumber');
		}
	}, [isGreenlightInternetService, selectedServices, internetSelection, routerNeeded, internetCableBundleSelection, keepPhoneNumber, phoneNumber, phoneProvider, phoneProviderAccountNumber]);

	// Create selection card render function
	const selectionCardRender = useMemo(() => {
		return selectionCard;
	}, [selectionCard]);

	// Render content
	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<S.FormContentWrapper onboarding={onboarding}>
				<S.FormContent>
					<Typography tag="h1" weight="bold" center>
						{pageHeading}
					</Typography>

					{/* Render selection card */}
					{!onboarding && selectionCardRender()}

					{/* Greenlight Community Broadband */}
					<Card title="Greenlight Community Broadband">
						<RadioInput
							label="Do you want to add Greenlight Internet as a Service?"
							id="isGreenlightInternetService"
							options={[
								{
									label: 'Yes',
									value: 'Yes',
								},
								{
									label: 'No',
									value: 'No',
								},
							]}
							{...register('isGreenlightInternetService', {
								required: required('Selection'),
							})}
							stateValue={isGreenlightInternetService}
							error={errors.isGreenlightInternetService}
							onChangeFunc={(id, value) => setValue(id, value)}
						/>
						{isGreenlightInternetService === 'Yes' && (
							<RadioInput
								label="What Services are you interested in?"
								id="selectedServices"
								options={[
									{
										label: 'Internet',
										value: 'internet',
									},
									{
										label: 'Internet & Cable',
										value: 'internet-and-cable',
									},
									{
										label: 'Internet & Phone',
										value: 'internet-and-phone',
									},
									{
										label: 'Internet, Cable, & Phone',
										value: 'internet-cable-and-phone',
									},
								]}
								{...register('selectedServices', {
									required: required('Selected Services'),
								})}
								stateValue={selectedServices}
								error={errors.selectedServices}
								onChangeFunc={(id, value) => setValue(id, value)}
							/>
						)}
						<S.CallPreference>
							<Typography tag="p">
								Prefer to do this over the phone? Call our team at <a href="tel:252-399-2200">252-399-2200</a>
							</Typography>
						</S.CallPreference>
					</Card>

					{/* Greenlight Internet Options */}
					{(selectedServices === 'internet' || selectedServices === 'internet-and-phone') && (
						<Card title="Greenlight Internet">
							<S.CardHeadingWrapper>
								{configuration.greenlight_internet_packages_subtitle && configuration.greenlight_internet_packages_subtitle.is_visible && (
									<Typography tag="p" style={{ marginBottom: '20px' }}>
										{configuration.greenlight_internet_packages_subtitle.content}
									</Typography>
								)}
								<Typography tag="h4" weight="bold">
									Choose your Internet Package
								</Typography>
							</S.CardHeadingWrapper>
							<S.ChoicesBoxesContainer>
								{internetPackages?.map((pkg) => (
									<ChoicePackage
										key={pkg?.id}
										icon={pkg?.icon}
										title={pkg?.title}
										price={pkg?.price}
										subTitle={pkg?.subTitle}
										details={pkg?.details}
										label={pkg?.label}
										setValue={setValue}
										id="internetSelection"
										{...register('internetSelection', {})}
										checked={internetSelection === pkg?.id}
										value={pkg?.id}
										showToggle
									/>
								))}
							</S.ChoicesBoxesContainer>
							{internetSelection != null && !internetPackages.find((pkg) => pkg.id === internetSelection).routerIncluded && (
								<RadioInput
									label="Do you need a router?"
									id="routerNeeded"
									options={[
										{
											label: 'Yes',
											value: 'Yes',
										},
										{
											label: 'No',
											value: 'No',
										},
									]}
									{...register('routerNeeded', {
										required: required('Selection'),
									})}
									stateValue={routerNeeded}
									error={errors.routerNeeded}
									onChangeFunc={(id, value) => setValue(id, value)}
								/>
							)}
						</Card>
					)}

					{/* Greenlight Internet & Cable Options */}
					{(selectedServices === 'internet-and-cable' || selectedServices === 'internet-cable-and-phone') && (
						<Card title="Greenlight Internet & Cable">
							<S.CardHeadingWrapper>
								{configuration.greenlight_bundles_subtitle && configuration.greenlight_bundles_subtitle.is_visible && (
									<Typography tag="p" style={{ marginBottom: '20px' }}>
										{configuration.greenlight_bundles_subtitle.content}
									</Typography>
								)}
								<Typography tag="h4" weight="bold">
									Select your Bundle
								</Typography>
							</S.CardHeadingWrapper>
							{bundles?.map((bundle) => (
								<S.BoxMarginWrapper key={bundle?.id}>
									<ChoicePackage
										icon={bundle?.icon}
										title={bundle?.title}
										price={bundle?.price}
										subTitle={bundle?.subTitle}
										details={bundle?.details}
										details2={bundle?.details2}
										subHeadings={bundle?.subHeadings}
										showToggle
										setValue={setValue}
										id="internetCableBundleSelection"
										checked={internetCableBundleSelection === bundle?.id}
										value={bundle?.id}
										{...register('internetCableBundleSelection', {})}
									/>
								</S.BoxMarginWrapper>
							))}
							<S.CallPreference>
								<Typography tag="p">Interested in Premium Channels? Ask our Customer Service Advisors about how to add to your package.</Typography>
							</S.CallPreference>
						</Card>
					)}

					{/* Greenlight Phone Options */}
					{(selectedServices === 'internet-and-phone' || selectedServices === 'internet-cable-and-phone') && (
						<Card title="Home Phone">
							<RadioInput
								label="Do you want to keep your current phone number?"
								id="keepPhoneNumber"
								options={[
									{
										label: 'Yes',
										value: 'Yes',
									},
									{
										label: 'No',
										value: 'No',
									},
								]}
								{...register('keepPhoneNumber', {
									required: required('Selection'),
								})}
								stateValue={keepPhoneNumber}
								error={errors.keepPhoneNumber}
								onChangeFunc={(id, value) => setValue(id, value)}
							/>
							{keepPhoneNumber === 'Yes' && (
								<S.PhoneNumber>
									<TextInput
										containerClassName="phone-number-input"
										label="Current Phone Number"
										id="phoneNumber"
										error={errors.phoneNumber}
										{...register('phoneNumber', {
											required: required('Current phone number'),
										})}
									/>
									<TextInput containerClassName="phone-number-input" label="Current Provider" id="phoneProvider" error={errors.phoneProvider} {...register('phoneProvider', {})} />
									<TextInput containerClassName="phone-number-input" label="Current Provider Account Number" id="phoneProviderAccountNumber" error={errors.phoneProviderAccountNumber} {...register('phoneProviderAccountNumber', {})} />
								</S.PhoneNumber>
							)}
							<S.CallPreference>
								<Typography tag="p">If you don’t have your Current Provider or Current Provider Account Number today, we understand. An Advisor will let you know when we will need it.</Typography>
							</S.CallPreference>
						</Card>
					)}
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
};

CustomerResidentialGreenlight.displayName = 'Customer Residential Greenlight';
CustomerResidentialGreenlight.propTypes = {
	onboarding: PropTypes.bool,
	disableSettingsCard: PropTypes.bool,
	pageHeading: PropTypes.string,
	getDataFromLocalStorage: PropTypes.func,
	onBackFunc: PropTypes.func,
	onNextFunc: PropTypes.func,
	selectionCard: PropTypes.func,
	allowNo: PropTypes.bool,
};
