import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.form`
	max-widht: 75rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${colors.white};
	padding: 2.5rem 1rem;
`;

export const HeadingContainer = styled.div`
	margin-top: 3rem;
	margin-bottom: 2rem;
`;

export const Main = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
`;

export const SelectsContainer = styled.div`
	width: 100%;
	margin-left: auto;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.125rem;

	& > div {
		width: 48%;
	}

	label {
		font-weight: 600;
		color: ${colors.black};
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		& > div {
			width: 95%;
			margin-left: auto;
			margin-bottom: 0.75rem;
		}
	}
`;

export const MainWrapper = styled.div`
	width: 84.5%;
	margin-bottom: 1.125rem;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	margin-top: 1rem;
	& > button {
		margin-right: 1rem;
	}

	@media screen and (max-width: 400px) {
		flex-direction: column;
		& > button {
			margin-right: 0rem;
			margin-bottom: 1rem;
		}
	}
`;
