import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Typography } from '../Typography';
import * as S from './TextInput.styles';
import colors from '../../styles/colors';

export const TextInput = forwardRef(({ id, label, error, type = 'text', containerClassName, showWarning = false, warningText = '', ...rest }, ref) => {
	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography tag="label" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					{label}
					{showWarning && (
						<>
							<S.Warning icon={['fal', 'info-circle']} data-for={id} data-tip />
							<ReactTooltip id={id} type="error" backgroundColor={colors.statusBad}>
								<span>{warningText}</span>
							</ReactTooltip>
						</>
					)}
				</Typography>
			)}
			<S.TextInput ref={ref} id={id} error={error} {...rest} type={type} />
			{error && error.message && (
				<S.FloatingWrapper className="errorWrapper">
					<Typography tag="span" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

TextInput.displayName = 'TextInput';
TextInput.propTypes = {
	containerClassName: PropTypes.string,
	type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
	showWarning: PropTypes.bool,
	warningText: PropTypes.string,
};
