import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

export function formatToRelativeTime(date) {
	return timeAgo.format(date);
}

export function formatToDayMonth2DigitYear(date) {
	return date.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: '2-digit' });
}

export function formatToFullDateWithTime(date) {
	return date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' });
}

export const formatDate = (date) => {
	if (date === '0001-01-01T00:00:00') return null;
	return moment(date).format('MM/DD/YY');
};

export const renderArticleDateStatus = (article) => {
	const now = new Date();
	const startDate = new Date(article.StartDate);
	const endDate = new Date(article.EndDate);
	if (startDate.getTime() > now.getTime()) {
		return `Opens ${moment(startDate).format('MMM D YYYY')}`;
	} else if (endDate.getTime() < now.getTime()) {
		return `Expired ${moment(endDate).format('MMM D YYYY')}`;
	} else {
		return `Open ${moment(startDate).format('MMM D YYYY')} - ${moment(endDate).format('MMM D YYYY')}`;
	}
};
