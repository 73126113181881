import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'beautiful-react-hooks';
import PropTypes from 'prop-types';

import { required } from '../../utils/form-default-errors';
import { clearOnboarding } from '../../app/slices/onboarding/onboardingSlice';

import { Button, OnboardingFooter, OnboardingHeader, Typography, Stepper, Card, Radio, TextInput } from '../../components';
import * as S from './CurrentCustomerBillingSetup.styles';
import { ProfileSearchSetupCompleteModal } from '../ProfileSearchSetupCompleteModal';
import { RadioInput } from '../../components/RadioInput/RadioInput';
import { Current_Cus_Onb_Conf_Acc, META_TITLE, PLACEHOLDER_USER_DETAILS } from '../../utils/constants';
import { fetchUser } from '../../app/slices/user/userSlice';
import toast from 'react-hot-toast';
import axios from 'axios';
import colors from '../../styles/colors';
import { submitUserRequest, updateUserPaperlessSettings } from '../../services/userService';

const generalStyleContent = `
	#utilityCardElement,
	#utilityACH,
	#greenlightCardElement,
	#greenlightACH {
		font-family: 'Source Sans Pro', sans-serif;
		height: 100px;
		padding: 10px 12px;
		border-radius: 5px;
		border: 1px solid ${colors.neutralForm};
		box-sizing: border-box;
		line-height: 0;
		margin: 0px 0px 15px;
		width: 100%;
	}
	#utilityCardErrorMessage,
	#utilityACHErrorMessage,
	#greenlightCardErrorMessage,
	#greenlightACHErrorMessage {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.statusBad};
		font-size: 0.8rem;
		position: absolute;
		margin-top: -12px;
	}
`;

const customStyleContent = `
	.input-style {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.black};
	}
	.input-hint {
		font-family: 'Source Sans Pro', sans-serif;
		color: ${colors.neutralButton};
		border-color: ${colors.neutralForm};
	}
	/* All fields in form */
	.form-wrapper {
	}
	/* Credit Card Number */
	.input-wrapper {
	}
	/* Expiry Date */
	.sub-wrapper {
	}
	.valid-class {
		color: ${colors.black};
	}
	.invalid-class {
		color: ${colors.statusBad};
	}
`;

export default function CurrentCustomerBillingSetup({ isCommercial = false, showDashboard }) {
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const [completePopup, setCompletePopup] = useState(false);
	const [paymentError, setPaymentError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [queuedTokens, setQueuedTokens] = useState([]);

	// Create tokenpay instances
	const utilityCardTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_UTILITIES_PUBLISH_KEY);
	const utilityACHTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_UTILITIES_PUBLISH_KEY);
	const greenlightCardTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_GREENLIGHT_PUBLISH_KEY);
	const greenlightACHTokenpay = window.TokenPay(process.env.REACT_APP_BRIDGEPAY_GREENLIGHT_PUBLISH_KEY);

	const stepData = useSelector((state) => state.onboarding);
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
		clearErrors,
		resetField,
		trigger,
	} = useForm({
		defaultValues: {
			...stepData.currentCustomer.residential.billingSetup,
			utilityAutoPayMethod: 'Credit Card',
			greenLightAutoPayMethod: 'Credit Card',
		},
	});

	// Get selected accounts
	const selectedAccountsTemp = localStorage.getItem(Current_Cus_Onb_Conf_Acc) && JSON.parse(localStorage.getItem(Current_Cus_Onb_Conf_Acc));
	const { unassignedUtilityAccounts = [], unassignedGreenlightAccounts = [] } = selectedAccountsTemp || {};

	// Watch form values
	const utilityBillingAutopay = watch('utilityBillingAutopay');
	const utilityAutoPayMethod = watch('utilityAutoPayMethod');
	const greenLightAutopay = watch('greenLightAutopay');
	const greenLightAutoPayMethod = watch('greenLightAutoPayMethod');
	const utilityPaymentToken = watch('utilityPaymentToken');
	const greenlightPaymentToken = watch('greenlightPaymentToken');

	const onBack = () => {
		if (isCommercial) {
			history.push('/onboarding/current-customer/commercial/confirm');
		} else {
			history.push('/onboarding/current-customer/residential/confirm');
		}
	};

	const onNext = () => {
		localStorage.removeItem(Current_Cus_Onb_Conf_Acc);
		dispatch(clearOnboarding());
		dispatch(fetchUser({ placeholderUser: PLACEHOLDER_USER_DETAILS }));
	};

	const onSubmit = async (data) => {
		// Get parameters
		const { paperlessBilling, utilityPaymentToken, greenlightPaymentToken, utilityBillingName, greenlightBillingName, utilityBillingAutopay, utilityAutoPayMethod, greenLightAutopay, greenLightAutoPayMethod } = data;

		// Initialize form data
		const formData = {
			utilityBillingAutopay,
			greenLightAutopay,
			utilityAccounts: unassignedUtilityAccounts,
			greenlightAccounts: unassignedGreenlightAccounts,
			...(utilityBillingAutopay === 'Yes'
				? {
						utilityBillingAutopay,
						utilityAutoPayMethod,
						utilityBillingName,
				  }
				: undefined),
			...(greenLightAutopay === 'Yes'
				? {
						greenLightAutopay,
						greenLightAutoPayMethod,
						greenlightBillingName,
				  }
				: undefined),
		};

		// Process payment methods
		if (utilityPaymentToken) {
			// Initialize payment parameters
			let accountToken = '';
			let cardLast4 = '****';
			let expirationDate = '';

			// Fetch longterm token for payment method
			try {
				const { data } = await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/payment/token`, { utilityToken: utilityPaymentToken });
				if (data && data.accountToken) {
					if (data.brand != null && data.brand !== 'visa' && data.brand !== 'mastercard') {
						setIsSubmitting(false);
						setPaymentError('MyWilson currently only supports Visa and Mastercard cards. Please enter another payment method.');
						return;
					}
					accountToken = data.accountToken;
					cardLast4 = data.last4;
					expirationDate = data.expirationDate;
				} else {
					setIsSubmitting(false);
					setPaymentError('There was an issue verifying your payment method. Please try again.');
					return;
				}
			} catch (e) {
				setIsSubmitting(false);
				setPaymentError(e.message);
				return;
			}

			// Set payment parameters
			formData.utilityAccountToken = accountToken;
			formData.utilityLast4 = cardLast4;
			formData.utilityExpirationDate = expirationDate;
		}
		if (greenlightPaymentToken) {
			// Initialize payment parameters
			let accountToken = '';
			let cardLast4 = '****';
			let expirationDate = '';

			// Fetch longterm token for payment method
			try {
				const { data } = await axios.post(`${process.env.REACT_APP_INTERNAL_API_URL}/payment/token`, { greenlightToken: greenlightPaymentToken });
				if (data && data.accountToken) {
					if (data.brand != null && data.brand !== 'visa' && data.brand !== 'mastercard') {
						setIsSubmitting(false);
						setPaymentError('MyWilson currently only supports Visa and Mastercard cards. Please enter another payment method.');
						return;
					}
					accountToken = data.accountToken;
					cardLast4 = data.last4;
					expirationDate = data.expirationDate;
				} else {
					setIsSubmitting(false);
					setPaymentError('There was an issue verifying your payment method. Please try again.');
					return;
				}
			} catch (e) {
				setIsSubmitting(false);
				setPaymentError(e.message);
				return;
			}

			// Set payment parameters
			formData.greenlightAccountToken = accountToken;
			formData.greenlightLast4 = cardLast4;
			formData.greenlightExpirationDate = expirationDate;
		}

		// Submit request if necessary
		if (utilityBillingAutopay === 'Yes' || greenLightAutopay === 'Yes' || utilityPaymentToken || greenlightPaymentToken) {
			try {
				const { requestCreated } = await submitUserRequest({
					requestType: 2,
					requestDescription: JSON.stringify({ ...formData }),
				});
				if (!requestCreated) {
					toast.error('There was an issue creating your request. Please try again.');
					setIsSubmitting(false);
					return;
				}
			} catch (e) {
				toast.error(e.message);
				setIsSubmitting(false);
				return;
			}
		}

		// Submit paperless request
		const isUpdated = await updateUserPaperlessSettings({
			status: paperlessBilling === 'Yes',
		});
		if (!isUpdated) {
			toast.error('There was an issue updating your Paperless Billing settings. Please try again.');
			setIsSubmitting(false);
			return;
		}

		// Update state
		setIsSubmitting(false);

		// Close popup
		setCompletePopup(true);
	};

	useEffect(() => {
		if (utilityAutoPayMethod === 'Credit Card') {
			clearErrors();
			if (document.getElementById('utilityCardErrorMessage')) document.getElementById('utilityCardErrorMessage').innerHTML = '';
			if (document.getElementById('utilityACHErrorMessage')) document.getElementById('utilityACHErrorMessage').innerHTML = '';
		} else if (utilityAutoPayMethod === 'Bank Draft') {
			resetField('utilityBillingName');
			clearErrors();
			if (document.getElementById('utilityCardErrorMessage')) document.getElementById('utilityCardErrorMessage').innerHTML = '';
			if (document.getElementById('utilityACHErrorMessage')) document.getElementById('utilityACHErrorMessage').innerHTML = '';
		}
	}, [utilityAutoPayMethod]);

	useEffect(() => {
		if (greenLightAutoPayMethod === 'Credit Card') {
			clearErrors();
			if (document.getElementById('greenlightCardErrorMessage')) document.getElementById('greenlightCardErrorMessage').innerHTML = '';
			if (document.getElementById('greenlightACHErrorMessage')) document.getElementById('greenlightACHErrorMessage').innerHTML = '';
		} else if (greenLightAutoPayMethod === 'Bank Draft') {
			resetField('greenlightBillingName');
			clearErrors();
			if (document.getElementById('greenlightCardErrorMessage')) document.getElementById('greenlightCardErrorMessage').innerHTML = '';
			if (document.getElementById('greenlightACHErrorMessage')) document.getElementById('greenlightACHErrorMessage').innerHTML = '';
		}
	}, [greenLightAutoPayMethod]);

	useEffect(() => {
		setIsSubmitting(false);
	}, [errors]);

	useEffect(() => {
		if (paymentError != null) {
			toast.error(paymentError);
			setPaymentError(null);
		}
	}, [paymentError]);

	useEffect(() => {
		if (queuedTokens.length > 0) {
			// Ensure all tokens exist
			let allTokensExist = true;
			queuedTokens.forEach((token) => {
				if (token === 'utilityPaymentToken' && !utilityPaymentToken) allTokensExist = false;
				else if (token === 'greenlightPaymentToken' && !greenlightPaymentToken) allTokensExist = false;
			});

			// If all tokens exist, submit form
			if (allTokensExist === true) {
				setQueuedTokens([]);
				handleSubmit(onSubmit)();
			}
		} else {
			setIsSubmitting(false);
		}
	}, [queuedTokens, utilityPaymentToken, greenlightPaymentToken]);

	useEffect(() => {
		// Set document title
		document.title = `Billing Setup | ${META_TITLE}`;

		// Redirect if necessary
		if (unassignedUtilityAccounts.length === 0 && unassignedGreenlightAccounts.length === 0) {
			history.push('/');
		}

		// Initialize elements
		setTimeout(() => {
			// Initialize utility card element
			try {
				utilityCardTokenpay.initialize({
					dataElement: 'utilityCardElement',
					errorElement: 'utilityCardErrorMessage',
					iframeId: 'utilityPaymentCardFormFrame',
					useACH: false,
					useStyles: true,
					disableZip: false,
					disableCvv: false,
				});
			} catch (e) {}

			// Create listener for form submission
			const utilityCardForm = document.getElementById('utilityPaymentCardForm');
			if (utilityCardForm != null) {
				utilityCardForm.addEventListener('submit', async function (event) {
					event.preventDefault();
					setIsSubmitting(true);
					try {
						utilityCardTokenpay.createToken(
							function (result) {
								setValue('utilityPaymentToken', result.token);
							},
							function (result) {
								setIsSubmitting(false);
								setPaymentError('There was an error verifying your card. Please try again.');
							}
						);
					} catch (e) {
						setIsSubmitting(false);
						setPaymentError('There was an error verifying your card. Please try again.');
					}
					return false;
				});
			}

			// Initialize utility ACH element
			try {
				utilityACHTokenpay.initialize({
					dataElement: 'utilityACH',
					errorElement: 'utilityACHErrorMessage',
					iframeId: 'utilityPaymentACHFormFrame',
					useACH: true,
					useStyles: true,
					disableZip: true,
					disableCvv: true,
				});
			} catch (e) {}

			// Create listener for form submission
			const utilityACHForm = document.getElementById('utilityPaymentACHForm');
			if (utilityACHForm != null) {
				utilityACHForm.addEventListener('submit', function (event) {
					event.preventDefault();
					setIsSubmitting(true);
					try {
						utilityACHTokenpay.createToken(
							function (result) {
								setValue('utilityPaymentToken', result.token);
							},
							function (result) {
								setIsSubmitting(false);
								setPaymentError('There was an error verifying your ACH account. Please try again.');
							}
						);
					} catch (e) {
						setIsSubmitting(false);
						setPaymentError('There was an error verifying your ACH account. Please try again.');
					}
					return false;
				});
			}

			// Initialize greenlight card element
			try {
				greenlightCardTokenpay.initialize({
					dataElement: 'greenlightCardElement',
					errorElement: 'greenlightCardErrorMessage',
					iframeId: 'greenlightPaymentCardFormFrame',
					useACH: false,
					useStyles: true,
					disableZip: false,
					disableCvv: false,
				});
			} catch (e) {}

			// Create listener for form submission
			const greenlightCardForm = document.querySelector('#greenlightPaymentCardForm');
			if (greenlightCardForm != null) {
				greenlightCardForm.addEventListener('submit', async function (event) {
					event.preventDefault();
					setIsSubmitting(true);
					try {
						greenlightCardTokenpay.createToken(
							function (result) {
								setValue('greenlightPaymentToken', result.token);
							},
							function (result) {
								setIsSubmitting(false);
								setPaymentError('There was an error verifying your card. Please try again.');
							}
						);
					} catch (e) {
						setIsSubmitting(false);
						setPaymentError('There was an error verifying your card. Please try again.');
					}
					return false;
				});
			}

			// Initialize greenlight ACH element
			try {
				greenlightACHTokenpay.initialize({
					dataElement: 'greenlightACH',
					errorElement: 'greenlightACHErrorMessage',
					iframeId: 'greenlightPaymentACHFormFrame',
					useACH: true,
					useStyles: true,
					disableZip: true,
					disableCvv: true,
				});
			} catch (e) {}

			// Create listener for form submission
			const greenlightACHForm = document.getElementById('greenlightPaymentACHForm');
			if (greenlightACHForm != null) {
				greenlightACHForm.addEventListener('submit', function (event) {
					event.preventDefault();
					setIsSubmitting(true);
					try {
						greenlightACHTokenpay.createToken(
							function (result) {
								setValue('greenlightPaymentToken', result.token);
							},
							function (result) {
								setIsSubmitting(false);
								setPaymentError('There was an error verifying your ACH account. Please try again.');
							}
						);
					} catch (e) {
						setIsSubmitting(false);
						setPaymentError('There was an error verifying your ACH account. Please try again.');
					}
					return false;
				});
			}
		}, 100);
	}, []);

	return (
		<>
			<style>{generalStyleContent}</style>
			<style id="customStyles">{customStyleContent}</style>
			<ProfileSearchSetupCompleteModal onClickButton={onNext} isOpen={completePopup} setIsOpen={setCompletePopup} />
			<S.Form>
				<OnboardingHeader showDashboard={showDashboard} />
				<Stepper
					activeStep={3}
					steps={[
						{ id: 1, label: 'Location' },
						{ id: 2, label: 'Profile' },
						{ id: 3, label: 'Account' },
						{ id: 4, label: 'Billing' },
					]}
				/>
				<S.FormContentWrapper>
					<S.FormContent>
						<Typography tag={isHigherThan768 ? 'h1' : 'h2'} weight="bold" center>
							Billing Setup
						</Typography>
						<Card title="Billing">
							<S.ContentType1Container>
								<Typography tag="h5" weight="bold">
									Billing Method
								</Typography>
								<RadioInput
									label="Would you like to enroll in Paperless Billing?"
									icon="question-circle"
									tooltip="Billing statements are emailed to your login email. You will receive 2 final mailed statements to ensure no lapse in statement notification."
									id="paperlessBilling"
									options={[
										{
											label: 'Yes',
											value: 'Yes',
										},
										{
											label: 'No',
											value: 'No',
										},
									]}
									stateValue={watch('paperlessBilling')}
									{...register('paperlessBilling', {
										required: required('Selection'),
									})}
									error={errors.paperlessBilling}
									onChangeFunc={setValue}
								/>
							</S.ContentType1Container>
							{unassignedUtilityAccounts.length > 0 && (
								<>
									{/*<S.HorizontalLine />
									<S.ContentType1Container>
										<Typography tag="h5" weight="bold">
											Utility Billing
										</Typography>
										<RadioInput
											label="Would you like to enroll in AutoPay?"
											tooltip="When you enroll in AutoPay, your Utility bill will automatically be drafted on the due date each month using your chosen payment method."
											icon="question-circle"
											id="utilityBillingAutopay"
											options={[
												{
													label: 'Yes',
													value: 'Yes',
												},
												{
													label: 'No',
													value: 'No',
												},
											]}
											stateValue={watch('utilityBillingAutopay')}
											{...register('utilityBillingAutopay', {
												required: required('Selection'),
											})}
											error={errors.utilityBillingAutopay}
											onChangeFunc={setValue}
										/>
									</S.ContentType1Container>*/}

									<S.BillingSection isVisible={utilityBillingAutopay === 'Yes'}>
										<Typography tag="h6" weight="semibold">
											Select Autopay Method
										</Typography>
										<S.RadioInputsContainer2>
											<Radio id="utilityAutoPayMethod" label="Credit Card" value="Credit Card" onChange={(e) => setValue('utilityAutoPayMethod', e.target.value)} checked={utilityAutoPayMethod === 'Credit Card'} name="payMethodValue" />
											<S.SubFormContainer isVisible={utilityAutoPayMethod === 'Credit Card'}>
												<TextInput
													containerClassName="inputContainer"
													label="Cardholder Name"
													placeholder="Justin Case"
													id="utilityBillingName"
													error={errors.utilityBillingName}
													{...register('utilityBillingName', {
														required: utilityBillingAutopay === 'Yes' && utilityAutoPayMethod === 'Credit Card' && required('Cardholder Name'),
													})}
												/>
												<div className="inputContainer">
													<Typography tag="label">Card Details</Typography>
												</div>
												<form id="utilityPaymentCardForm" name="utilityPaymentCardForm" action={`${process.env.REACT_APP_BRIDGEPAY_URL}/WebSecurity/echo.aspx?id=utilityPaymentCardForm`} method="post">
													<div id="utilityCardElement" />
													<div id="utilityCardErrorMessage" />
												</form>
											</S.SubFormContainer>
											<Radio id="utilityBillingBankDraft" label="E-Check" value="Bank Draft" onChange={(e) => setValue('utilityAutoPayMethod', e.target.value)} checked={utilityAutoPayMethod === 'Bank Draft'} name="payMethodValue" />
											<S.SubFormContainer isVisible={utilityAutoPayMethod === 'Bank Draft'}>
												<div className="inputContainer">
													<Typography tag="label">ACH Details</Typography>
												</div>
												<form id="utilityPaymentACHForm" name="utilityPaymentACHForm" action={`${process.env.REACT_APP_BRIDGEPAY_URL}/WebSecurity/echo.aspx?id=utilityPaymentACHForm`} method="post">
													<div id="utilityACH" />
													<div id="utilityACHErrorMessage" />
												</form>
											</S.SubFormContainer>
										</S.RadioInputsContainer2>
									</S.BillingSection>
								</>
							)}
							{unassignedGreenlightAccounts.length > 0 && (
								<>
									{/*<S.HorizontalLine />
									<S.ContentType1Container>
										<Typography tag="h5" weight="bold">
											Greenlight Billing
										</Typography>
										<RadioInput
											label="Would you like to enroll in AutoPay?"
											icon="question-circle"
											tooltip="When you enroll in AutoPay, your Greenlight bill will automatically be drafted on the due date each month using your chosen payment method."
											id="greenLightAutopay"
											options={[
												{
													label: 'Yes',
													value: 'Yes',
												},
												{
													label: 'No',
													value: 'No',
												},
											]}
											stateValue={watch('greenLightAutopay')}
											{...register('greenLightAutopay', {
												required: required('Selection'),
											})}
											error={errors.greenLightAutopay}
											onChangeFunc={setValue}
										/>
									</S.ContentType1Container>*/}

									<S.BillingSection isVisible={greenLightAutopay === 'Yes'}>
										<Typography tag="h6" weight="semibold">
											Select Autopay Method
										</Typography>
										<S.RadioInputsContainer2>
											<Radio id="greenLightCreditCard" label="Credit Card" value="Credit Card" onChange={(e) => setValue('greenLightAutoPayMethod', e.target.value)} checked={greenLightAutoPayMethod === 'Credit Card'} name="greenLightAutoMethodValue" />
											<S.SubFormContainer isVisible={greenLightAutoPayMethod === 'Credit Card'}>
												<TextInput
													containerClassName="inputContainer"
													label="Name on Card"
													placeholder="Justin Case"
													id="greenlightBillingName"
													error={errors.greenlightBillingName}
													{...register('greenlightBillingName', {
														required: greenLightAutopay === 'Yes' && greenLightAutoPayMethod === 'Credit Card' && required('Name on Card'),
													})}
												/>
												<div className="inputContainer">
													<Typography tag="label">Card Details</Typography>
												</div>
												<form id="greenlightPaymentCardForm" name="greenlightPaymentCardForm" action={`${process.env.REACT_APP_BRIDGEPAY_URL}/WebSecurity/echo.aspx?id=greenlightPaymentCardForm`} method="post">
													<div id="greenlightCardElement" />
													<div id="greenlightCardErrorMessage" />
												</form>
											</S.SubFormContainer>
											<Radio id="greenLightAutoPayBankDraft" label="E-Check" value="Bank Draft" onChange={(e) => setValue('greenLightAutoPayMethod', e.target.value)} checked={greenLightAutoPayMethod === 'Bank Draft'} name="greenLightAutoMethodValue" />
											<S.SubFormContainer isVisible={greenLightAutoPayMethod === 'Bank Draft'}>
												<div className="inputContainer">
													<Typography tag="label">ACH Details</Typography>
												</div>
												<form id="greenlightPaymentACHForm" name="greenlightPaymentACHForm" action={`${process.env.REACT_APP_BRIDGEPAY_URL}/WebSecurity/echo.aspx?id=greenlightPaymentACHForm`} method="post">
													<div id="greenlightACH" />
													<div id="greenlightACHErrorMessage" />
												</form>
											</S.SubFormContainer>
										</S.RadioInputsContainer2>
									</S.BillingSection>
								</>
							)}
						</Card>
					</S.FormContent>
				</S.FormContentWrapper>

				<OnboardingFooter>
					<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Back
						</Typography>
					</Button>
					<Button
						variant="outline"
						type="button"
						disabled={isSubmitting}
						onClick={() => {
							const queue = [];
							trigger();
							if (utilityBillingAutopay === 'Yes') {
								queue.push('utilityPaymentToken');
								if (utilityAutoPayMethod === 'Credit Card') {
									document.getElementById('utilityPaymentCardForm').dispatchEvent(new Event('submit', { cancelable: true }));
								} else if (utilityAutoPayMethod === 'Bank Draft') {
									document.getElementById('utilityPaymentACHForm').dispatchEvent(new Event('submit', { cancelable: true }));
								}
							}
							if (greenLightAutopay === 'Yes') {
								queue.push('greenlightPaymentToken');
								if (greenLightAutoPayMethod === 'Credit Card') {
									document.getElementById('greenlightPaymentCardForm').dispatchEvent(new Event('submit', { cancelable: true }));
								} else if (greenLightAutoPayMethod === 'Bank Draft') {
									document.getElementById('greenlightPaymentACHForm').dispatchEvent(new Event('submit', { cancelable: true }));
								}
							}
							if (utilityBillingAutopay !== 'Yes' && greenLightAutopay !== 'Yes') {
								handleSubmit(onSubmit)();
							}
							setQueuedTokens(queue);
							return false;
						}}
					>
						<Typography tag="span" variation={isHigherThan768 ? '1' : '2'} weight="extrablack">
							Complete
						</Typography>
					</Button>
				</OnboardingFooter>
			</S.Form>
		</>
	);
}

CurrentCustomerBillingSetup.propTypes = {
	isCommercial: PropTypes.bool,
	showDashboard: PropTypes.bool,
};
