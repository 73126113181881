import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { formatToUSD } from '../../utils/currency-formatter';
import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './CurrentInvoices.styles';
import { Button } from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { openBase64PDF } from '../../utils/pdf';
import { formatDate } from '../../utils/time-formatter';
import { Badge } from '../Badge/Badge';
import { retrieveAccountBillPdf } from '../../services/userService';

export const CurrentInvoices = ({ className, hideMakePaymentButton }) => {
	const history = useHistory();
	const currentLocation = useSelector((state) => state.location.value);

	// Create invoices
	const invoices = [];
	if (currentLocation?.UtilityAccount != null) {
		invoices.push({
			id: currentLocation.UtilityAccount?.AccountNumber,
			key: currentLocation.UtilityAccount?.AccountKey,
			isAutoPay: currentLocation.UtilityAccount?.AutoPay,
			title: 'Utilities',
			values: {
				amountDue: currentLocation.UtilityAccount?.CurrentBalance || 0,
				billDate: currentLocation.UtilityAccount?.LastBillDate ? formatDate(currentLocation.UtilityAccount?.LastBillDate) : null,
				paymentDueDate: currentLocation.UtilityAccount?.CurrentDueDate ? formatDate(currentLocation.UtilityAccount?.CurrentDueDate) : null,
				disconnectionDate: currentLocation.UtilityAccount?.TerminationDate ? formatDate(currentLocation.UtilityAccount?.TerminationDate) : null,
			},
			IsBillPDFAvailable: currentLocation.UtilityAccount?.IsBillPDFAvailable,
			BillID: currentLocation.UtilityAccount?.BillID,
			BankDraftEnabled: currentLocation.UtilityAccount?.BankDraftEnabled,
		});
	}
	if (currentLocation?.GreenlightAccount != null) {
		invoices.push({
			id: currentLocation.GreenlightAccount?.AccountNumber,
			key: currentLocation.GreenlightAccount?.AccountKey,
			isAutoPay: currentLocation.GreenlightAccount?.AutoPay,
			title: 'Greenlight',
			values: {
				amountDue: currentLocation.GreenlightAccount?.CurrentBalance || 0,
				billDate: currentLocation.GreenlightAccount?.LastBillDate ? formatDate(currentLocation.GreenlightAccount?.LastBillDate) : null,
				paymentDueDate: currentLocation.GreenlightAccount?.CurrentDueDate ? formatDate(currentLocation.GreenlightAccount?.CurrentDueDate) : null,
				disconnectionDate: currentLocation.GreenlightAccount?.TerminationDate ? formatDate(currentLocation.GreenlightAccount?.TerminationDate) : null,
			},
			IsBillPDFAvailable: currentLocation.GreenlightAccount?.IsBillPDFAvailable,
			BillID: currentLocation.GreenlightAccount?.BillID,
			BankDraftEnabled: currentLocation.GreenlightAccount?.BankDraftEnabled,
		});
	}

	const fetchBillPDF = async (invoice) => {
		try {
			const pdfData = await retrieveAccountBillPdf({ accountKey: invoice.key, billId: parseInt(invoice.BillID) });
			if (pdfData != null) {
				openBase64PDF(pdfData);
			} else {
				history.push(`/statement/${invoice.key}/${invoice.BillID}`);
			}
		} catch (e) {
			toast.error(e.message);
		}
	};

	const handleOpenStatement = async (invoice) => {
		const promise = fetchBillPDF(invoice);
		toast.promise(promise, {
			loading: 'Fetching your statement...',
			success: 'Opening statement...',
		});
	};

	return (
		<Card
			className={className}
			title="Current Invoices"
			action={
				hideMakePaymentButton
					? []
					: [
							{
								id: 1,
								label: 'Make Payment',
								onClick: () => {
									history.push({ pathname: '/make-payment/amount', state: { backUrl: window.location.pathname } });
								},
							},
					  ]
			}
		>
			{invoices.map((invoice) => (
				<S.ItemWrapper key={invoice.id}>
					<S.HeaderWrapper>
						<Typography tag="h5" weight="bold">
							{invoice.title}{' '}
							<Typography weight="regular" style={{ fontSize: 'inherit' }}>
								({invoice.id})
							</Typography>
						</Typography>
						<S.HeaderCenter>
							{invoice.isAutoPay && (
								<Badge type="success">
									<Typography tag="p" variation="2">
										{invoice.BankDraftEnabled ? 'Bank Draft' : 'Auto Pay'} Scheduled for Due Date
									</Typography>
								</Badge>
							)}
						</S.HeaderCenter>
						{invoice.IsBillPDFAvailable && (
							<Button type="button" size="small" variant="outline" onClick={() => handleOpenStatement(invoice)}>
								<FontAwesomeIcon icon={['fal', 'external-link']} />
								<Typography tag="span" variation="2" weight="extrablack">
									View Statement
								</Typography>
							</Button>
						)}
					</S.HeaderWrapper>
					<S.InfoWrapper>
						<Container fluid>
							<Row>
								<Col xs={12} sm={6} md={3}>
									<S.Item>
										<Typography tag="h6" weight="semibold">
											Amount Due
										</Typography>
										<Typography tag="h2" weight="bold">
											{formatToUSD(invoice.values.amountDue)}
										</Typography>
									</S.Item>
								</Col>
								<Col xs={12} sm={6} md={3}>
									<S.Item>
										<Typography tag="h6" weight="semibold">
											Bill Date
										</Typography>
										<Typography tag="h2" weight="bold">
											{invoice.values.billDate}
										</Typography>
									</S.Item>
								</Col>
								<Col xs={12} sm={6} md={3}>
									<S.Item>
										<Typography tag="h6" weight="semibold">
											Payment Due Date
										</Typography>
										<Typography tag="h2" weight="bold">
											{invoice.values.paymentDueDate}
										</Typography>
									</S.Item>
								</Col>
								<Col xs={12} sm={6} md={3}>
									{invoice.values.disconnectionDate && (
										<S.Item warning>
											<Typography tag="h6" weight="semibold">
												Disconnection Date
											</Typography>
											<Typography tag="h2" weight="bold">
												{invoice.values.disconnectionDate}
											</Typography>
										</S.Item>
									)}
								</Col>
							</Row>
						</Container>
					</S.InfoWrapper>
				</S.ItemWrapper>
			))}
		</Card>
	);
};

CurrentInvoices.propTypes = {
	className: PropTypes.string,
	hideMakePaymentButton: PropTypes.bool,
};
