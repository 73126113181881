import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { required } from '../../utils/form-default-errors';

import * as S from './RemovePaymentMethodModal.styles';

import { Button, Modal, Typography } from '..';
import { walletRemoveAccount } from '../../services/walletService';
import toast from 'react-hot-toast';

const RemovePaymentMethodModal = ({ isOpen, afterSubmit, onRequestClose, account }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const removeMethod = async () => {
		setIsSubmitting(true);
		try {
			const accountRemoved = await walletRemoveAccount({ walletKey: account.WalletAccountKey });
			setIsSubmitting(false);
			if (accountRemoved) {
				if (afterSubmit) afterSubmit();
				toast.success('Your payment method has successfully been removed.');
				onRequestClose();
			} else {
				toast.error('There was an issue removing your payment method. Please try again.');
			}
		} catch (e) {
			setIsSubmitting(false);
			toast.error(e.message);
		}
	};

	return (
		<Modal shouldCloseOnOverlayClick allowScroll isOpen={isOpen} onRequestClose={onRequestClose}>
			<S.ModalWrapper>
				<S.TitleWrapper>
					<Typography tag="h1" weight="bold" center>
						Remove Payment Method
					</Typography>
					<Typography tag="h3" center>
						Are you sure you want to remove this payment method?
					</Typography>
				</S.TitleWrapper>
				<S.FormButtonWrapper>
					<Button variant="outline" variation="secondary" type="button" onClick={onRequestClose}>
						<Typography tag="span" variation="1" weight="extrablack">
							Cancel
						</Typography>
					</Button>
					<Button variation="warning" onClick={removeMethod}>
						<Typography tag="span" variation="1" weight="extrablack">
							{isSubmitting ? 'Loading...' : 'Remove Method'}
						</Typography>
					</Button>
				</S.FormButtonWrapper>
			</S.ModalWrapper>
		</Modal>
	);
};

export default RemovePaymentMethodModal;

RemovePaymentMethodModal.propTypes = {
	isOpen: PropTypes.bool,
	afterSubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
	account: PropTypes.shape(),
};
