import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-color: ${colors.brandPrimary};
	box-shadow: ${colors.defaultShadow};
	padding: 0 1rem;

	z-index: 1;
	min-height: 4.375rem;
	height: 4.375rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
