import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';
import colors from '../../styles/colors';

export const Link = styled(ReactLink)`
	color: ${colors.brandPrimary};
	font-weight: 700;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
`;
