import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const Items = styled.div`
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(1, 1fr);

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const ItemWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;
