import styled from 'styled-components';

export const WEIGHTS = {
	light: 300,
	regular: 400,
	semibold: 600,
	bold: 700,
	extrablack: 900,
};

export const SIZES = {
	h1: '2.5em', // 40px
	h2: '2.125em', // 34px
	h3: '1.75em', // 28px
	h4: '1.5em', // 24px
	h5: '1.25em', // 20px
	h6: '1em', // 16px
	p1: '1.125em', // 18px
	p2: '0.875em', // 14px
	span1: '1.125em', // 18px
	label: '1em', // 16px
	span2: '0.875em', // 14px
	span3: '1em', // 16px
};

export const LINE_HEIGHTS = {
	h1: 'normal',
	h2: 'normal',
	h3: 'normal',
	h4: 'normal',
	h5: 'normal',
	h6: 'normal',
	p1: 'normal',
	p2: 'normal',
	span1: 'normal',
	label: 'normal',
	span2: 'normal',
};

export const Typography = styled.span`
	font-weight: ${({ weight }) => WEIGHTS[weight]};
	font-size: ${({ as, variation }) => (as === 'span' || as === 'p' ? SIZES[as + variation] : SIZES[as])};
	line-height: ${({ as, variation }) => (as === 'span' || as === 'p' ? LINE_HEIGHTS[as + variation] : LINE_HEIGHTS[as])};
	margin-bottom: ${({ as }) => (as === 'label' ? '0.375em' : '0')};
	text-align: ${({ center }) => (center ? 'center' : 'inherit')};
`;
