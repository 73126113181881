import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './ImportantDates.styles';
import { Spinner } from '../Spinner';
import { getCurrentCommunityEvents } from '../../services/userService';
import { EmptyComponent } from '../EmptyComponent';
import { sortResourcesEvents } from '../../utils/sorting';

export const ImportantDates = ({ className }) => {
	const [status, setStatus] = useState('loading');
	const [items, setItems] = useState([]);

	const isMounted = useRef(true);

	async function init() {
		try {
			const itemArray = await getCurrentCommunityEvents();
			itemArray.sort(sortResourcesEvents);
			if (isMounted.current) {
				setItems(itemArray);
				setStatus('success');
			}
		} catch (e) {
			toast.error(e.message);
			if (isMounted.current) {
				setStatus('error');
			}
		}
	}

	function handleOnClick(item) {
		setTimeout(() => {
			window.open(item.URL, '_blank');
		});
	}

	useEffect(() => {
		// Set state
		isMounted.current = true;

		// Run init
		init();

		// Handle actions on dismount
		return () => {
			isMounted.current = false;
		};
	}, []);

	if (status === 'loading') {
		return <Spinner />;
	}
	return (
		<Card title="Community Events" className={className}>
			<S.ItemContainer>
				{items.length > 0 ? (
					items.map((item) => (
						<S.Item key={item.CommunityEventKey} onClick={() => handleOnClick(item)}>
							<Typography tag="h5" weight="bold">
								{item.Title}
							</Typography>
							{item.Description && (
								<Typography tag="p" variation="2">
									{item.Description}
								</Typography>
							)}
						</S.Item>
					))
				) : (
					<EmptyComponent icon={['fal', 'calendar']} title="No current community events" description="Check back later for community events from the City of Wilson" />
				)}
			</S.ItemContainer>
		</Card>
	);
};

ImportantDates.propTypes = {
	className: PropTypes.string,
};
