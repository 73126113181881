import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: white;
	margin: -1rem;
	padding: 1rem;
	overflow: hidden;

	& > button {
		padding: 0;
		text-align: left;
		margin-bottom: 0.5rem;

		svg {
			width: 0.625rem !important;
		}
	}

	.page-header {
		margin-bottom: 0.5rem;
	}
`;

export const GridWrapper = styled.div`
	display: grid;
	gap: 1.25rem;
	padding-top: 1rem;

	grid-template-areas:
		'a1'
		'a2'
		'a3'
		'a4'
		'a5'
		'a6';

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}
	.a4 {
		grid-area: a4;
	}
	.a5 {
		grid-area: a5;
	}
	.a6 {
		grid-area: a6;
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'a1 a1'
			'a2 a2'
			'a3 a3'
			'a4 a4'
			'a5 a5'
			'a6 a6';
	}
`;

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5.625rem 1rem 2rem 1rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 29.75rem;
	margin-top: 2rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const FormButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
