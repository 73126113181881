import React from 'react';

import { Typography, Card } from '..';
import PropTypes from 'prop-types';

import * as S from './CustomerCommercialGreenlightReviewCard.styles';

export default function CustomerCommercialGreenlightReviewCard({ greenLightData }) {
	return (
		<Card title="Greenlight">
			<S.Card2ContentWrapper>
				<Typography tag="h5" weight="bold">
					Services
				</Typography>
				<Typography tag="h6" weight="bold">
					Greenlight Service
				</Typography>
				<Typography tag="p" variation="2">
					A Sales Executive will reach out to you shortly on the phone number you provided.
				</Typography>
			</S.Card2ContentWrapper>
		</Card>
	);
}

CustomerCommercialGreenlightReviewCard.displayName = 'Customer Commercial Greenlight Review Card';
CustomerCommercialGreenlightReviewCard.propTypes = {
	greenLightData: PropTypes.object,
};
