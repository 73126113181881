import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

export const Modal = forwardRef(({ allowScroll = false, children, ...rest }, ref) => {
	return (
		<ReactModal ref={ref} closeTimeoutMS={200} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false} {...rest} className={allowScroll ? 'allowScroll' : undefined}>
			{children}
		</ReactModal>
	);
});

Modal.displayName = 'Modal';

Modal.propTypes = {
	allowScroll: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
