import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ManageUtilityAddRemove, ManageUtilitySettings, ManageUtilityReview, AddGreenlight, AddGreenlightSettings, AddGreenlightReview } from '../../pages';

export default function ManageUtilityRouter() {
	return (
		<Switch>
			{/* Utilities */}
			<Route exact path="/manage-utility/edit" component={ManageUtilityAddRemove} />
			<Route exact path="/manage-utility/settings" component={ManageUtilitySettings} />
			<Route exact path="/manage-utility/review" component={ManageUtilityReview} />

			{/* Greenlight */}
			<Route exact path="/manage-greenlight/edit" component={AddGreenlight} />
			<Route exact path="/manage-greenlight/settings" component={AddGreenlightSettings} />
			<Route exact path="/manage-greenlight/review" component={AddGreenlightReview} />
		</Switch>
	);
}
