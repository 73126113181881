import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	place-content: center;
	height: inherit;
	min-height: 31.25rem; /* 500px */
	background-color: white;
	box-shadow: ${colors.sidebarShadow};
	padding: 2em;

	& > p {
		max-width: 28em; /* 504px */
	}

	& > button {
		justify-self: center;
		margin-top: 5em;
	}
`;

export const BigText = styled.span`
	display: block;
	text-align: center;
	font-size: 9.375em;
	color: ${colors.neutralText};
	font-weight: 700;
`;
