import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatToUSD } from '../../../../utils/currency-formatter';
import { Typography } from '../../../Typography';
import { Button } from '../../../Button';
import * as S from './BalanceDue.styles';
import { useSelector } from 'react-redux';

export const BalanceDue = ({ total, utilities, greenlight, hideBalance = false, actions = [] }) => {
	const [opened, setOpened] = useState(false);
	const currentLocation = useSelector((state) => state.location.value);

	return (
		<S.Wrapper>
			{!hideBalance && (
				<>
					<S.CollapseHeader opened={opened} onClick={() => setOpened((prev) => !prev)}>
						<Typography tag="p" variation="2" weigth="semibold">
							Balance Due
						</Typography>
						<FontAwesomeIcon icon={['fal', 'angle-down']} />
					</S.CollapseHeader>
					<Typography tag="h4" weight="bold">
						{formatToUSD(total)}
					</Typography>
					<S.CollapseContent opened={opened}>
						{currentLocation?.UtilityAccount && (
							<S.ValueItem>
								<span>Utilities</span>
								<Typography tag="h6" weight="semibold">
									{formatToUSD(utilities)}
								</Typography>
							</S.ValueItem>
						)}
						{currentLocation?.GreenlightAccount && (
							<S.ValueItem>
								<span>Greenlight</span>
								<Typography tag="h6" weight="semibold">
									{formatToUSD(greenlight)}
								</Typography>
							</S.ValueItem>
						)}
					</S.CollapseContent>
				</>
			)}
			<S.ButtonWrapper>
				{actions.map(({ id, title, action }) => (
					<Button key={id} type="button" size="small" variant="outline" onClick={action}>
						<Typography tag="span" variation="2" weight="extrablack">
							{title}
						</Typography>
					</Button>
				))}
			</S.ButtonWrapper>
		</S.Wrapper>
	);
};
BalanceDue.propTypes = {
	total: PropTypes.number,
	utilities: PropTypes.number,
	greenlight: PropTypes.number,
	hideBalance: PropTypes.bool,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			title: PropTypes.string,
			action: PropTypes.func,
		})
	),
};
