import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'beautiful-react-hooks';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addRemoveUtilities } from '../../app/slices/manageUtility/manageUtilitySlice';

import { Button, OnboardingFooter, Typography, Card, MakePaymentHeader } from '../../components';
import { Manage_Utility, Manage_Utility_Settings, META_TITLE } from '../../utils/constants';
import * as S from './ManageUtilityAddRemove.styles';

export default function ManageUtilityAddRemove() {
	const isLowerThan768 = useMediaQuery('(max-width: 768px)');
	const currentLocation = useSelector((state) => state.location.value);
	const utilityServices = currentLocation?.UtilityAccount?.Services?.map((service) => service.toLowerCase()) || [];

	const [services, setServices] = useState([
		{
			name: 'Water',
			status: utilityServices.includes('water') ? 'added' : 'empty',
		},
		{
			name: 'Electric',
			status: utilityServices.includes('electric') ? 'added' : 'empty',
		},
		{
			name: 'Sprinkler',
			status: utilityServices.includes('sprinkler') ? 'added' : 'empty',
		},
		{
			name: 'Gas',
			status: utilityServices.includes('gas') ? 'added' : 'empty',
		},
	]);

	const stepData = useSelector((state) => state.manageUtility);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const {
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm({ defaultValues: { ...stepData.addRemoveUtilities, services } });

	const onServiceChange = (index) => {
		const tempServices = [...services];
		const status = tempServices?.[index]?.status;
		let setStatus;
		if (status === 'empty') {
			setStatus = 'added';
		} else if (status === 'added') {
			setStatus = 'removed';
		} else {
			setStatus = 'empty';
		}
		tempServices[index] = { ...tempServices?.[index], status: setStatus };
		setServices(tempServices);
		setValue('services', tempServices);
	};

	const onBack = () => {
		localStorage.removeItem(Manage_Utility);
		localStorage.removeItem(Manage_Utility_Settings);
		history.push(location.state?.backUrl || '/services');
	};

	const onNext = (data) => {
		// Copy object
		const dataObject = { ...data };

		// Set change status
		for (let i = 0; i < dataObject?.services.length; i += 1) {
			dataObject.services[i].changeStatus = dataObject?.services[i].status;
			if (utilityServices.includes(dataObject?.services[i].name.toLowerCase())) {
				if (dataObject?.services[i].status === 'added') {
					dataObject.services[i].changeStatus = 'unchanged';
				}
			} else {
				if (dataObject?.services[i].status === 'empty' || dataObject?.services[i].status === 'removed') {
					dataObject.services[i].changeStatus = 'unchanged';
				}
			}
		}

		const hasAddedUtilities = dataObject?.services?.some((utility) => utility.changeStatus === 'added');
		const hasRemovedUtilities = dataObject?.services?.some((utility) => utility.changeStatus === 'removed');

		if (hasAddedUtilities || hasRemovedUtilities) {
			dispatch(addRemoveUtilities(dataObject));
			localStorage.setItem(Manage_Utility, JSON.stringify(dataObject?.services));
			history.push('/manage-utility/settings');
		} else {
			toast.error('Please make a change to your Utilities account to continue.');
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `Manage Utilities | ${META_TITLE}`;

		let tempServices = localStorage.getItem(Manage_Utility);
		if (tempServices) {
			tempServices = JSON.parse(tempServices);
		}
		if (tempServices) {
			setServices(tempServices || services);
			setValue('services', tempServices || services);
		}
	}, []);

	return (
		<S.Form onSubmit={handleSubmit(onNext)}>
			<MakePaymentHeader />
			<S.FormContentWrapper>
				<S.FormContent>
					<Typography tag={isLowerThan768 ? 'h2' : 'h1'} weight="bold" center>
						Manage Utilities
					</Typography>
					<Card>
						<Typography tag="h4" weight="bold">
							Manage Utilities
						</Typography>
						<Typography tag="p" variation="1">
							Please select the utilities you would like to activate for your property.
						</Typography>
						<S.MainContainer>
							{services?.map((service, index) => (
								<S.Service key={service?.name}>
									<Typography tag="h3" weight="bold">
										{service?.name}
									</Typography>
									<S.AddSymbol onClick={() => onServiceChange(index)}>
										{service?.status === 'empty' ? (
											<FontAwesomeIcon icon={['fal', 'plus-circle']} />
										) : service?.status === 'added' ? (
											<FontAwesomeIcon icon={['fas', 'check-circle']} className="checkCircleIcon" />
										) : (
											<FontAwesomeIcon icon={['fas', 'minus-circle']} className="minusCircleIcon" />
										)}
									</S.AddSymbol>
								</S.Service>
							))}
						</S.MainContainer>
					</Card>
				</S.FormContent>
			</S.FormContentWrapper>

			<OnboardingFooter>
				<Button variant="outline" variation="tertiary" type="button" onClick={onBack}>
					<Typography tag="span" variation="1" weight="extrablack">
						Back
					</Typography>
				</Button>
				<Button variant="outline" type="submit">
					<Typography tag="span" variation="1" weight="extrablack">
						Next
					</Typography>
				</Button>
			</OnboardingFooter>
		</S.Form>
	);
}
