import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';

import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { bytesToSize } from '../../utils/filesize-formatter';
import * as S from './DropFile.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../styles/colors';
import { toast } from 'react-hot-toast';

export const DropFile = ({ label, description, options = {}, errorMessage, showWarning = false, warningText = '', onChangeFile, files = [], acceptedTypes = ['.pdf', '.png', '.jpeg', '.jpg', '.heic'], maxMB = 20 }) => {
	const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		...options,
		onDrop: (acceptedFiles, fileRejections) => {
			fileRejections.forEach((file) => {
				file.errors.forEach((err) => {
					if (err.code === 'file-too-large') {
						toast.error(err.message);
						return;
					}
				});
			});
		},
	});
	const [finalFiles, setFinalFiles] = useState(files);

	const removeFile = (file) => {
		const tempFiles = [...finalFiles];
		tempFiles.splice(tempFiles.indexOf(file), 1);
		setFinalFiles(tempFiles);
	};

	useEffect(() => {
		if (acceptedFiles != null) {
			setFinalFiles(acceptedFiles);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		onChangeFile?.(finalFiles);
	}, [finalFiles]);

	useEffect(() => {
		if (files?.length > 0 && files?.length !== finalFiles?.length) {
			setFinalFiles(files);
		}
	}, [files]);

	const renderFiles = () => {
		return finalFiles.map((file, i) => (
			<S.File key={file.path}>
				<FontAwesomeIcon icon={['fal', 'file']} />
				<div className="file-info">
					<Typography tag="p" className="filename-text">
						{file.name}
					</Typography>
					<Typography tag="p" variation="2" className="small-text">
						{bytesToSize(file.size)}
					</Typography>
				</div>
				<IconButton icon={['fal', 'times']} type="button" onClick={() => removeFile(file)} />
			</S.File>
		));
	};

	const renderEmptyFiles = () => (
		<S.EmptyWrapper>
			<Typography tag="p">No Files Uploaded</Typography>
		</S.EmptyWrapper>
	);

	return (
		<S.Wrapper>
			{errorMessage && (
				<S.FloatingWrapper>
					<Typography tag="span" variation="2">
						{errorMessage}
					</Typography>
				</S.FloatingWrapper>
			)}
			{(label || description) && (
				<div className="info-area">
					{label && (
						<Typography tag="h5" weight="bold">
							{label}
							{showWarning && (
								<>
									<S.Warning icon={['fal', 'info-circle']} data-for={label} data-tip />
									<ReactTooltip id={label} type="error" backgroundColor={colors.statusBad}>
										<span>{warningText}</span>
									</ReactTooltip>
								</>
							)}
						</Typography>
					)}
					{description && <Typography tag="p">{description}</Typography>}
				</div>
			)}
			<S.DropAreaWrapper {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
				<input {...getInputProps()} />
				<FontAwesomeIcon icon={['fal', 'file-plus']} />
				{isDragActive ? (
					<Typography tag="p" weight="semibold" className="dropfile-message">
						Drop the files here...
					</Typography>
				) : (
					<>
						<Typography tag="p" weight="semibold" className="dropfile-message">
							Drag and drop or <button type="button">browse</button> your files
						</Typography>
						<Typography tag="span" variation="2">
							City of Wilson supports {acceptedTypes.join(', ')} (Max {maxMB}MB)
						</Typography>
					</>
				)}
			</S.DropAreaWrapper>
			<S.FilesWrapper>
				<Typography tag="h6" weight="semibold" className="files-wrapper-title">
					Uploaded Files
				</Typography>
				{finalFiles?.length ? renderFiles() : renderEmptyFiles()}
			</S.FilesWrapper>
		</S.Wrapper>
	);
};
DropFile.propTypes = {
	label: PropTypes.string,
	description: PropTypes.string,
	options: PropTypes.shape(),
	errorMessage: PropTypes.string,
	onChangeFile: PropTypes.func,
	files: PropTypes.array,
	showWarning: PropTypes.bool,
	warningText: PropTypes.string,
	acceptedTypes: PropTypes.array,
	maxMB: PropTypes.number,
};
