import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: inline-flex;
	align-items: center;

	& > label {
		flex: 1;
		cursor: pointer;
		margin-bottom: 0;
		user-select: none;
	}
`;

export const Toggle = styled.input.attrs({ type: 'checkbox' })`
	position: relative;
	appearance: none;
	width: 2.75rem;
	border-radius: 9999px;
	display: flex;
	align-items: center;
	padding: 0.25rem !important;
	background-color: ${({ checked }) => (checked ? colors.brandPrimary : colors.neutralForm)};
	cursor: pointer;

	&::before {
		content: '';
		width: 1.125rem;
		height: 1.125rem;
		border-radius: 9999px;
		background-color: white;
		transform: ${({ checked }) => (checked ? 'translatex(100%)' : 'translatex(0%)')};
	}
	&:disabled {
		opacity: 0.5;
		cursor: default;
	}
`;
