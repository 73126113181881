import styled from 'styled-components';
import colors from '../../styles/colors';

export const ModalContainer = styled.div`
	max-widht: 72.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	padding: 2.5rem 1rem;

	svg {
		font-size: 2.5rem;
		color: ${colors.brandPrimary};
		margin-bottom: 0.75rem;
	}

	@media screen and (min-width: 768px) {
	}
`;

export const ParaContainer = styled.div`
	width: 375px;
	margin-top: 1.9rem;
	margin-bottom: 2rem;

	@media screen and (max-width: 420px) {
		width: auto;
	}
`;
