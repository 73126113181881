import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Typography } from '../Typography';
import { formatToFullDateWithTime } from '../../utils/time-formatter';
import * as S from './AdminRequestMessages.styles';
import { EmptyComponent } from '../EmptyComponent';

export const AdminRequestMessages = ({ className, request: { Messages } }) => {
	return (
		<Card className={className} title="Messages">
			<S.Wrapper>
				{Messages.length > 0 ? (
					Messages.map(({ MessageKey, MessageDate, MessageText }) => (
						<S.MessageWrapper key={MessageKey}>
							<Typography tag="p">{MessageText}</Typography>
							<Typography tag="p" className="time-detail">
								{formatToFullDateWithTime(new Date(MessageDate))}
							</Typography>
						</S.MessageWrapper>
					))
				) : (
					<EmptyComponent icon={['fal', 'comment']} title="No Request Messages" description="Check back to view messages pertaining to this request." />
				)}
			</S.Wrapper>
		</Card>
	);
};
AdminRequestMessages.propTypes = {
	className: PropTypes.string,
	request: PropTypes.shape(),
};
