import styled from 'styled-components';
import { Typography } from '../../components';

import colors from '../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.neutralBackground};
`;

export const FormContentWrapper = styled.div`
	height: 100%;
	overflow: auto;
	padding: 1rem;
	margin-top: 0.75rem;
`;

export const SubHeadingWrapper = styled.div`
	max-width: 23.25rem;
	margin: 0 auto;
	margin-bottom: 0.75rem;
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: 71.75rem;
	margin: 0 auto;
	display: grid;
	gap: 1rem;

	@media screen and (max-width: 768px) {
		h3 {
			font-size: 1.5rem;
		}
	}
`;

export const ApplicationGrid = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: repeat(1, 1fr);
	margin-top: 1rem;
	padding-bottom: 1.5rem;
	margin-bottom: 0.5rem;

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const CoApplicationGrid = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: repeat(1, 1fr);
	margin-top: 1rem;

	grid-template-areas:
		'coApplicantFirstName'
		'coApplicantLastName'
		'coApplicantDriverLicense'
		'coApplicantSsn'
		'coApplicantEmployer';

	.item-coApplicantFirstName {
		grid-area: coApplicantFirstName;
	}
	.item-coApplicantLastName {
		grid-area: coApplicantLastName;
	}
	.item-coApplicantDriverLicense {
		grid-area: coApplicantDriverLicense;
	}
	.item-coApplicantSsn {
		grid-area: coApplicantSsn;
	}
	.item-coApplicantEmployer {
		grid-area: coApplicantEmployer;
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);

		grid-template-areas:
			'coApplicantFirstName coApplicantFirstName coApplicantLastName coApplicantLastName'
			'coApplicantDriverLicense coApplicantSsn coApplicantEmployer coApplicantEmployer';
	}
`;

export const RadioInputContainer = styled.div`
	margin-bottom: 0.5rem;
	position: relative;

	label {
		margin-left: 0.7rem;
		margin-bottom: 0;
		cursor: pointer;
	}

	input {
		margin-top: 2px;
	}

	span {
		white-space: nowrap;
	}

	.radio-input {
		display: none !important;
		width: 7.75rem;
		margin-left: 2.25rem;
		margin-top: 0.3rem;
		margin-bottom: 1.4rem;
		&.checked {
			display: flex !important;
		}
	}
`;

export const RadioInputs = styled.div`
	margin-top: 0.875rem;
	margin-bottom: 2rem;
`;

export const OptionButton = styled.button`
	display: inline-flex;
	flex-direction: row;
	appearance: none;
	background-color: transparent;
	align-items: center;
	width: fit-content;
	border: none;
	text-align: left;
	& > * + * {
		margin-left: 1rem;
	}

	.circle {
		width: 1.25rem;
		height: 1.25rem;
		border: 1px solid ${colors.neutralText};
		border-radius: 9999px;
		margin-bottom: 0;

		&.checked {
			position: relative;
			overflow: hidden;
			border: 1px solid ${colors.brandPrimary};
			&::after {
				position: absolute;
				border-radius: 9999px;
				inset: 4px;
				content: '';
				background-color: ${colors.brandPrimary};
			}
		}
	}
`;

export const DocsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	& > * {
		border-bottom: 1px solid ${colors.neutralForm};
		padding-bottom: 1.5rem;
		margin-top: 1rem;

		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
		}
	}
`;

export const Divider = styled(Typography)`
	margin: -10px 0px 0px;
	color: ${colors.neutralText};
`;

export const ExampleFormat = styled.div`
	background-color: ${colors.neutralBackground};
	color: ${colors.neutralText};
	position: absolute;
	padding: 5px 10px;
	border-radius: 4px;
	top: 39px;
	left: 170px;
`;
