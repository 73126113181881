/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';
import colors from '../../styles/colors';

/**
 * Styles
 */

export const AddressInputContainer = styled.div`
	position: relative;
`;

export const AddressDropdown = styled.div`
	position: absolute;
	width: 100%;
	max-width: 370px;
	padding: 15px 15px 10px;
	margin: 10px 0 0;
	background-color: ${colors.white};
	box-shadow: 0px 4px 16px rgba(0 0 0 / 12%);
	border-radius: 8px;
	border: 1px solid ${colors.neutralForm};
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);
	pointer-events: none !important;
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	z-index: 99;

	* {
		pointer-events: none !important;
	}
	p {
		text-align: left;
		color: ${colors.black};
	}
	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		pointer-events: all !important;
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;

		* {
			pointer-events: all !important;
		}
	}
`;

export const AddressOption = styled.div`
	cursor: pointer;
	padding: 6px 0px;

	* {
		pointer-events: all !important;
	}
`;
