import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { formatToUSD } from '../../utils/currency-formatter';
import { Card } from '../Card';
import { Badge } from '../Badge';
import { Typography } from '../Typography';
import * as S from './RecentBills.styles';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../Spinner';
import { EmptyComponent } from '../EmptyComponent';
import { retrieveAccountBillHistory, retrieveAccountBillPdf } from '../../services/userService';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/time-formatter';
import { openBase64PDF } from '../../utils/pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../Button';

const BADGE_LABELS = {
	unpaid: { label: 'Unpaid', type: 'danger' },
	paid: { label: 'Paid', type: 'success' },
	pending: { label: 'Pending', type: 'warning' },
};

const RECENT_BILL_LIMIT = 4;

export const RecentBills = ({ className }) => {
	const [status, setStatus] = useState('loading');
	const [recentBills, setRecentBills] = useState([]);
	const currentLocation = useSelector((state) => state.location.value);
	const isHigherThan768 = useMediaQuery('(min-width: 768px)');
	const history = useHistory();

	const fetchBillPDF = async (bill) => {
		try {
			const pdfData = await retrieveAccountBillPdf({ accountKey: bill.AccountKey, billId: parseInt(bill.BillID) });
			if (pdfData != null) {
				openBase64PDF(pdfData);
			} else {
				history.push(`/statement/${bill.AccountKey}/${bill.BillID}`);
			}
		} catch (e) {
			toast.error(e.message);
		}
	};

	async function handleOnClick(bill) {
		if (bill.IsBillPDFAvailable === true) {
			const promise = fetchBillPDF(bill);
			toast.promise(promise, {
				loading: 'Fetching your statement...',
				success: 'Opening statement...',
			});
		} else {
			history.push(`/statement/${bill.AccountKey}/${bill.BillID}`);
		}
	}

	async function init() {
		try {
			const bills = [];
			if (currentLocation?.UtilityAccount != null) {
				let utilityBills = await retrieveAccountBillHistory({ accountKey: currentLocation?.UtilityAccount?.AccountKey });
				utilityBills = utilityBills.map((bill) => {
					return {
						...bill,
						type: 'Utilities',
						status: bill.PaidStatus ? bill.PaidStatus : '',
						AccountKey: currentLocation?.UtilityAccount?.AccountKey,
					};
				});
				bills.push(...utilityBills);
			}
			if (currentLocation?.GreenlightAccount != null) {
				let greenlightBills = await retrieveAccountBillHistory({ accountKey: currentLocation?.GreenlightAccount?.AccountKey });
				greenlightBills = greenlightBills.map((bill) => {
					return {
						...bill,
						type: 'Greenlight',
						status: bill.PaidStatus ? bill.PaidStatus : '',
						AccountKey: currentLocation?.GreenlightAccount?.AccountKey,
					};
				});
				bills.push(...greenlightBills);
			}
			bills.sort((a, b) => new Date(b.BillDate).getTime() - new Date(a.BillDate).getTime());
			if (bills.length > RECENT_BILL_LIMIT) {
				bills.length = RECENT_BILL_LIMIT;
			}
			setRecentBills(bills);
			setStatus('success');
		} catch (e) {
			setStatus('error');
		}
	}

	useEffect(() => {
		init();
	}, []);

	function renderCards() {
		if (recentBills.length === 0) {
			return <EmptyComponent icon={['fal', 'credit-card']} title="No Recent Bills" description="Check back to view your recent Utilities and Greenlight bills." />;
		} else if (isHigherThan768) {
			return (
				<S.DesktopWrapper>
					<S.Head>
						<Typography tag="h6" weight="bold">
							Bill Date
						</Typography>
						<Typography tag="h6" weight="bold">
							Due Date
						</Typography>
						<Typography tag="h6" weight="bold">
							Type
						</Typography>
						<Typography tag="h6" weight="bold">
							Amount
						</Typography>
						<Typography tag="h6" weight="bold">
							Status
						</Typography>
					</S.Head>
					{recentBills.map((item) => (
						<S.DesktopItem key={item.BillID}>
							<Typography tag="p">{formatDate(item.BillDate)}</Typography>
							<Typography tag="p">{formatDate(item.DueDate)}</Typography>
							<Typography tag="p">{item.type}</Typography>
							<Typography tag="p">{item.status ? formatToUSD(item.CurrentBalance) : '--'}</Typography>
							<S.ActionContainer>
								{item.status && item.status.toLowerCase() !== 'paid' ? (
									<Badge className="badgeWidth" type={BADGE_LABELS[item.status].type}>
										{BADGE_LABELS[item.status].label}
									</Badge>
								) : (
									<div className="fixedWidthBadge" />
								)}
								{item.IsBillPDFAvailable && (
									<Button type="button" size="small" variant="outline" onClick={() => handleOnClick(item)}>
										<FontAwesomeIcon icon={['fal', 'external-link']} />
										<Typography tag="span" variation="2" weight="extrablack">
											View
										</Typography>
									</Button>
								)}
							</S.ActionContainer>
						</S.DesktopItem>
					))}
				</S.DesktopWrapper>
			);
		}
		return recentBills.map((item) => (
			<S.MobileItem key={item.BillID}>
				<S.Head>
					<Typography tag="h4" weight="bold">
						{formatDate(item.BillDate)}
					</Typography>
					<S.ActionContainer>
						{item.IsBillPDFAvailable && (
							<Button type="button" size="small" variant="outline" onClick={() => handleOnClick(item)}>
								<FontAwesomeIcon icon={['fal', 'external-link']} />
								<Typography tag="span" variation="2" weight="extrablack">
									View
								</Typography>
							</Button>
						)}
						{item.status && item.status.toLowerCase() !== 'paid' && <Badge type={BADGE_LABELS[item.status].type}>{BADGE_LABELS[item.status].label}</Badge>}
					</S.ActionContainer>
				</S.Head>
				<Row>
					<Col xs={6} sm={4}>
						<S.Item>
							<Typography tag="h6" weight="bold">
								Due Date
							</Typography>
							<Typography tag="p">{formatDate(item.DueDate)}</Typography>
						</S.Item>
					</Col>
					<Col xs={6} sm={4}>
						<S.Item>
							<Typography tag="h6" weight="bold">
								Type
							</Typography>
							<Typography tag="p">{item.type}</Typography>
						</S.Item>
					</Col>
					<Col xs={6} sm={4}>
						<S.Item>
							<Typography tag="h6" weight="bold">
								Amount
							</Typography>
							<Typography tag="p">{item.status ? formatToUSD(item.AmountDue) : '--'}</Typography>
						</S.Item>
					</Col>
				</Row>
			</S.MobileItem>
		));
	}

	return (
		<Card className={className} title="Billing History" action={[{ id: 1, label: 'View All', onClick: () => history.push('/statements'), type: 'text' }]}>
			{status === 'loading' ? <Spinner /> : renderCards()}
		</Card>
	);
};

RecentBills.propTypes = {
	className: PropTypes.string,
};
