import React, { useEffect } from 'react';

import { PageHeader, SupportRequestForm } from '../../components';
import { META_TITLE } from '../../utils/constants';
import * as S from './Support.styles';

export default function Support() {
	useEffect(() => {
		// Set document title
		document.title = `Support | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<PageHeader>Support</PageHeader>
			<SupportRequestForm className="a1" />
		</S.Wrapper>
	);
}
