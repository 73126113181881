import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: inline-flex;

	& > label {
		margin-left: 0.4375em;
	}
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
	display: block;
	position: relative;
	appearance: none;
	width: 1.25em;
	height: 1.25em;
	border: 1px solid ${colors.neutralForm};
	border-radius: 9999px;
	cursor: pointer;

	&:checked {
		border-color: ${colors.brandPrimary};
		&:before {
			content: '';
			position: absolute;
			top: 0.25em;
			left: 0.25em;
			border-radius: 9999px;
			width: 0.625em;
			height: 0.625em;
			background-color: ${colors.brandPrimary};
		}
	}

	&:hover {
		opacity: 0.8;
	}
`;
