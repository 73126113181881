import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './AdminRequestApplication.styles';
import { formatToUSPhoneNumber } from '../../utils/phone-formatter';

export const AdminRequestApplication = ({ className, request }) => {
	return (
		<Card className={className} title="Application">
			<S.Wrapper>
				<Typography variant="h5" weight="bold">
					Application Information
				</Typography>
				<S.Items>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Username
						</Typography>
						<Typography tag="p">{request.PortalUser}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Request Type
						</Typography>
						<Typography tag="p">{request.requestType}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Date Requested
						</Typography>
						<Typography tag="p">{request.dateRequested}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Assigned To
						</Typography>
						<Typography tag="p">{request.assignedTo || 'Not Assigned'}</Typography>
					</S.ItemWrapper>

					{/*<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							First Name
						</Typography>
						<Typography tag="p">{user.firstName}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Last Name
						</Typography>
						<Typography tag="p">{user.lastName}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Service Address to Connect
						</Typography>
						<Typography tag="p">{user.serviceAddressToConnect}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Mailing Address
						</Typography>
						<Typography tag="p">{user.mailingAddress}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Driver&apos;s License State/Number
						</Typography>
						<Typography tag="p">{user.driverLicense}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							SSN
						</Typography>
						<Typography tag="p">{user.ssn}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Employer
						</Typography>
						<Typography tag="p">{user.employer}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Home Phone
						</Typography>
						<Typography tag="p">{formatToUSPhoneNumber(user.homePhone)}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Work Phone
						</Typography>
						<Typography tag="p">{formatToUSPhoneNumber(user.workPhone)}</Typography>
					</S.ItemWrapper>
					<S.ItemWrapper>
						<Typography tag="h6" weight="semibold">
							Cell Phone
						</Typography>
						<Typography tag="p">{formatToUSPhoneNumber(user.cellPhone)}</Typography>
					</S.ItemWrapper>*/}
				</S.Items>
			</S.Wrapper>
		</Card>
	);
};
AdminRequestApplication.propTypes = {
	className: PropTypes.string,
	request: PropTypes.shape(),
};
