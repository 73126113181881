/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';

// Styles
import * as S from './PasswordInput.styles';

/**
 * Component
 */

export const PasswordInput = ({ onChange, rulesError, value, disallowedContent, error, ...rest }) => {
	// Set state
	const initialState = {
		lowercase: false,
		uppercase: false,
		number: false,
		length: false,
		spaces: false,
	};
	const [validationState, setValidationState] = useState(initialState);
	const [validatorOpen, setValidatorOpen] = useState(false);
	const [disallowedStrings, setDisallowedStrings] = useState([]);

	// Handle password validation function
	const handleValidatePassword = (password) => {
		// Create new validation state
		const newState = { ...initialState };

		// Validate lowercase rule
		if (password.toUpperCase() !== password) {
			newState.lowercase = true;
		}

		// Validate lowercase rule
		if (password.toLowerCase() !== password) {
			newState.uppercase = true;
		}

		// Validate number role
		if (/\d/.test(password)) {
			newState.number = true;
		}

		// Validate length rule
		if (password.length >= 12 && password.length <= 30) {
			newState.length = true;
		}

		// Validate banned characters rule
		if (!/\s/g.test(password)) {
			newState.spaces = true;
		}

		// Set new validation state
		setValidationState(newState);
	};

	// Handle password content changed
	useEffect(() => {
		// Validate password
		handleValidatePassword(value);
	}, [value]);

	// Handle password comparison content changed
	useEffect(() => {
		// Check if new content is different
		if (disallowedContent.sort().join(',') !== disallowedStrings.sort().join(',')) {
			// Set new comparison content
			setDisallowedStrings(disallowedContent);

			// Validate password
			handleValidatePassword(value);
		}
	}, [disallowedContent]);

	// Handle rules error content changed
	useEffect(() => {
		// Check if no rules error
		if (value !== '') {
			if (rulesError == null) {
				// Close validator
				setValidatorOpen(false);
			} else {
				// Open validator
				setValidatorOpen(true);
			}
		}
	}, [rulesError]);

	// Handle on change password
	const handleOnChange = (e) => {
		// On change callback
		onChange(e);
	};

	// Render component
	return (
		<S.PasswordInputContainer>
			<TextInput
				type="password"
				name="password"
				error={error}
				containerClassName="modalInput"
				onFocus={() => {
					setValidatorOpen(true);
				}}
				onKeyUp={() => {}}
				onBlur={() => {
					setValidatorOpen(false);
				}}
				onChange={handleOnChange}
				{...rest}
			/>
			<S.PasswordValidator className={validatorOpen ? 'animate show' : 'animate'}>
				<Typography tag="p" variation="3" className="introduction">
					Your password must:
				</Typography>
				<S.ValidatorRule>
					<S.ValidatorState validated={validationState.lowercase ? 1 : 0} icon={validationState.lowercase ? ['far', 'check-circle'] : ['far', 'circle']} />
					<S.ValidatorText validated={validationState.lowercase ? 1 : 0} tag="p" variation="2">
						Contain at least one lowercase letter
					</S.ValidatorText>
				</S.ValidatorRule>
				<S.ValidatorRule>
					<S.ValidatorState validated={validationState.uppercase ? 1 : 0} icon={validationState.uppercase ? ['far', 'check-circle'] : ['far', 'circle']} />
					<S.ValidatorText validated={validationState.uppercase ? 1 : 0} tag="p" variation="2">
						Contain at least one uppercase letter
					</S.ValidatorText>
				</S.ValidatorRule>
				<S.ValidatorRule>
					<S.ValidatorState validated={validationState.number ? 1 : 0} icon={validationState.number ? ['far', 'check-circle'] : ['far', 'circle']} />
					<S.ValidatorText validated={validationState.number ? 1 : 0} tag="p" variation="2">
						Contain at least one number
					</S.ValidatorText>
				</S.ValidatorRule>
				<S.ValidatorRule>
					<S.ValidatorState validated={validationState.length ? 1 : 0} icon={validationState.length ? ['far', 'check-circle'] : ['far', 'circle']} />
					<S.ValidatorText validated={validationState.length ? 1 : 0} tag="p" variation="2">
						Be between 12 and 30 characters long
					</S.ValidatorText>
				</S.ValidatorRule>
				<S.ValidatorRule>
					<S.ValidatorState validated={validationState.spaces ? 1 : 0} icon={validationState.spaces ? ['far', 'check-circle'] : ['far', 'circle']} />
					<S.ValidatorText validated={validationState.spaces ? 1 : 0} tag="p" variation="2">
						Not contain any spaces
					</S.ValidatorText>
				</S.ValidatorRule>
			</S.PasswordValidator>
		</S.PasswordInputContainer>
	);
};

/**
 * Configuration
 */

PasswordInput.displayName = 'PasswordInput';
PasswordInput.propTypes = {
	onChange: PropTypes.func,
	rulesError: PropTypes.shape(),
	value: PropTypes.string,
	disallowedContent: PropTypes.arrayOf(PropTypes.string),
	error: PropTypes.shape(),
};
PasswordInput.defaultProps = {
	value: '',
	error: null,
	rulesError: null,
};
