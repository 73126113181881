import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.5rem;

		@media screen and (min-width: 768px) {
			margin-top: 1rem;
		}
	}
`;

export const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-top: 1.5rem;
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;
		padding-bottom: 1rem;

		& > * + * {
			margin-top: 0;
			margin-left: 1.25rem;
		}
	}

	.item-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;

		button.desktop-only {
			padding: 0;
			margin-top: 0.4375rem;
			margin-bottom: 1rem;
		}

		& > *:not(.desktop-only) + * {
			margin-top: 1.5rem;
		}

		.value-info-wrapper {
			display: flex;
			flex-direction: column;

			& > * + * {
				margin-top: 1.5rem;
			}

			@media screen and (min-width: 768px) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1.5rem;

				& > * + * {
					margin-top: 0;
				}
			}
		}
		.value-info {
			display: flex;
			flex-direction: column;

			&.o3 {
				order: 3;
			}

			& > * + * {
				margin-top: 0.5rem;
			}
			.editable-heading {
				display: flex;
				align-items: center;

				button {
					margin-left: 0.5rem;
					color: ${colors.brandPrimary};
				}
			}
			.badge-wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 0.75rem;
			}
		}
	}

	&:last-child {
		padding-bottom: 0;
		border: none;
	}
`;

export const UtilityBadge = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${colors.statusGood};
	border-radius: 4rem;
	padding: 0.3125rem 0.875rem;
	text-transform: capitalize;
	color: ${colors.statusGood};

	svg {
		margin-right: 0.25rem;
	}
`;

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5.625rem 1rem 2rem 1rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 29.75rem;
	margin-top: 2rem;

	& > * + * {
		margin-top: 1rem;
	}
`;

export const FormButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
