export const getAllUrlParams = (url) => {
	// Get query string from url (optional) or window
	let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

	// We'll store the parameters here
	const obj = {};

	// If query string exists
	if (queryString) {
		// Stuff after # is not part of query string, so get rid of it
		[queryString] = queryString.split('#');

		// Split our query string into its component parts
		const arr = queryString.split('&');
		for (let i = 0; i < arr.length; i += 1) {
			// Separate the keys and the values
			const a = arr[i].split('=');

			// Set parameter name and value (use 'true' if empty)
			const paramName = a[0];
			const paramValue = typeof a[1] === 'undefined' ? true : a[1];

			// If the paramName ends with square brackets, e.g. colors[] or colors[2]
			if (paramName.match(/\[(\d+)?\]$/)) {
				// Create key if it doesn't exist
				const key = paramName.replace(/\[(\d+)?\]/, '');
				if (!obj[key]) obj[key] = [];

				// If it's an indexed array e.g. colors[2]
				if (paramName.match(/\[\d+\]$/)) {
					// Get the index value and add the entry at the appropriate position
					const index = /\[(\d+)\]/.exec(paramName)[1];
					obj[key][index] = paramValue;
				} else {
					// Otherwise add the value to the end of the array
					obj[key].push(paramValue);
				}
			} else if (!obj[paramName]) {
				// We're dealing with a string

				// If it doesn't exist, create property
				obj[paramName] = paramValue;
			} else if (obj[paramName] && typeof obj[paramName] === 'string') {
				// If property does exist and it's a string, convert it to an array
				obj[paramName] = [obj[paramName]];
				obj[paramName].push(paramValue);
			} else {
				// Otherwise add the property
				obj[paramName].push(paramValue);
			}
		}
	}
	return obj;
};
